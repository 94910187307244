@charset "UTF-8";
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Material Design Lite */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
@import 'node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import "~ng-pick-datetime-ex/assets/style/picker.min.css";
@import 'font-awesome/css/font-awesome.css';
html {
  color: rgba(0,0,0, 0.87);
  font-size: 1em;
  line-height: 1.4;
}

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: #b3d4fc;
  text-shadow: none;
}

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 */
.hidden {
  display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}
@media print, (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /*
   * Don't show links that are fragment identifiers,
   * or use the `javascript:` pseudo protocol
   */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /*
   * Printing Tables:
   * http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Remove the unwanted box around FAB buttons */
/* More info: http://goo.gl/IPwKi */
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*
* Make body take up the entire screen
* Remove body margin so layout containers don't cause extra overflow.
*/
body {
  width: 100%;
  min-height: 100%;
  margin: 0;
}

/*
 * Main display reset for IE support.
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block;
}

/*
* Apply no display to elements with the hidden attribute.
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
html, body {
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

/**
* Styles for HTML elements
*/
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
  font-size: 0.6em;
}

h1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 24px;
}

h2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
}

h3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  margin-top: 24px;
  margin-bottom: 24px;
}

h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 24px;
  margin-bottom: 16px;
}

h5 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  margin-top: 24px;
  margin-bottom: 16px;
}

h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  margin-top: 24px;
  margin-bottom: 16px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 16px;
}

a {
  color: rgb(255,64,129);
  font-weight: 500;
}

blockquote {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: 0.08em;
}
blockquote:before {
  position: absolute;
  left: -0.5em;
  content: "“";
}
blockquote:after {
  content: "”";
  margin-left: -0.05em;
}

mark {
  background-color: #f4ff81;
}

dt {
  font-weight: 700;
}

address {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  font-style: normal;
}

ul, ol {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

/**
 * Class Name Styles
 */
.mdl-typography--display-4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
}

.mdl-typography--display-4-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 112px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.04em;
  opacity: 0.54;
}

.mdl-typography--display-3 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
}

.mdl-typography--display-3-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;
  opacity: 0.54;
}

.mdl-typography--display-2 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
}

.mdl-typography--display-2-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.54;
}

.mdl-typography--display-1 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
}

.mdl-typography--display-1-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  opacity: 0.54;
}

.mdl-typography--headline {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
}

.mdl-typography--headline-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.87;
}

.mdl-typography--title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
}

.mdl-typography--title-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  opacity: 0.87;
}

.mdl-typography--subhead {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
}

.mdl-typography--subhead-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  opacity: 0.87;
}

.mdl-typography--body-2 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-color-contrast {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-color-contrast {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-2-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-2-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--body-1-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.mdl-typography--body-1-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--caption-force-preferred-font {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--caption-color-contrast {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54;
}

.mdl-typography--caption-force-preferred-font-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.54;
}

.mdl-typography--menu {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--menu-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--button {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
}

.mdl-typography--button-color-contrast {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  opacity: 0.87;
}

.mdl-typography--text-left {
  text-align: left;
}

.mdl-typography--text-right {
  text-align: right;
}

.mdl-typography--text-center {
  text-align: center;
}

.mdl-typography--text-justify {
  text-align: justify;
}

.mdl-typography--text-nowrap {
  white-space: nowrap;
}

.mdl-typography--text-lowercase {
  text-transform: lowercase;
}

.mdl-typography--text-uppercase {
  text-transform: uppercase;
}

.mdl-typography--text-capitalize {
  text-transform: capitalize;
}

.mdl-typography--font-thin {
  font-weight: 200 !important;
}

.mdl-typography--font-light {
  font-weight: 300 !important;
}

.mdl-typography--font-regular {
  font-weight: 400 !important;
}

.mdl-typography--font-medium {
  font-weight: 500 !important;
}

.mdl-typography--font-bold {
  font-weight: 700 !important;
}

.mdl-typography--font-black {
  font-weight: 900 !important;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-color-text--red {
  color: rgb(244,67,54) !important;
}

.mdl-color--red {
  background-color: rgb(244,67,54) !important;
}

.mdl-color-text--red-50 {
  color: rgb(255,235,238) !important;
}

.mdl-color--red-50 {
  background-color: rgb(255,235,238) !important;
}

.mdl-color-text--red-100 {
  color: rgb(255,205,210) !important;
}

.mdl-color--red-100 {
  background-color: rgb(255,205,210) !important;
}

.mdl-color-text--red-200 {
  color: rgb(239,154,154) !important;
}

.mdl-color--red-200 {
  background-color: rgb(239,154,154) !important;
}

.mdl-color-text--red-300 {
  color: rgb(229,115,115) !important;
}

.mdl-color--red-300 {
  background-color: rgb(229,115,115) !important;
}

.mdl-color-text--red-400 {
  color: rgb(239,83,80) !important;
}

.mdl-color--red-400 {
  background-color: rgb(239,83,80) !important;
}

.mdl-color-text--red-500 {
  color: rgb(244,67,54) !important;
}

.mdl-color--red-500 {
  background-color: rgb(244,67,54) !important;
}

.mdl-color-text--red-600 {
  color: rgb(229,57,53) !important;
}

.mdl-color--red-600 {
  background-color: rgb(229,57,53) !important;
}

.mdl-color-text--red-700 {
  color: rgb(211,47,47) !important;
}

.mdl-color--red-700 {
  background-color: rgb(211,47,47) !important;
}

.mdl-color-text--red-800 {
  color: rgb(198,40,40) !important;
}

.mdl-color--red-800 {
  background-color: rgb(198,40,40) !important;
}

.mdl-color-text--red-900 {
  color: rgb(183,28,28) !important;
}

.mdl-color--red-900 {
  background-color: rgb(183,28,28) !important;
}

.mdl-color-text--red-A100 {
  color: rgb(255,138,128) !important;
}

.mdl-color--red-A100 {
  background-color: rgb(255,138,128) !important;
}

.mdl-color-text--red-A200 {
  color: rgb(255,82,82) !important;
}

.mdl-color--red-A200 {
  background-color: rgb(255,82,82) !important;
}

.mdl-color-text--red-A400 {
  color: rgb(255,23,68) !important;
}

.mdl-color--red-A400 {
  background-color: rgb(255,23,68) !important;
}

.mdl-color-text--red-A700 {
  color: rgb(213,0,0) !important;
}

.mdl-color--red-A700 {
  background-color: rgb(213,0,0) !important;
}

.mdl-color-text--pink {
  color: rgb(233,30,99) !important;
}

.mdl-color--pink {
  background-color: rgb(233,30,99) !important;
}

.mdl-color-text--pink-50 {
  color: rgb(252,228,236) !important;
}

.mdl-color--pink-50 {
  background-color: rgb(252,228,236) !important;
}

.mdl-color-text--pink-100 {
  color: rgb(248,187,208) !important;
}

.mdl-color--pink-100 {
  background-color: rgb(248,187,208) !important;
}

.mdl-color-text--pink-200 {
  color: rgb(244,143,177) !important;
}

.mdl-color--pink-200 {
  background-color: rgb(244,143,177) !important;
}

.mdl-color-text--pink-300 {
  color: rgb(240,98,146) !important;
}

.mdl-color--pink-300 {
  background-color: rgb(240,98,146) !important;
}

.mdl-color-text--pink-400 {
  color: rgb(236,64,122) !important;
}

.mdl-color--pink-400 {
  background-color: rgb(236,64,122) !important;
}

.mdl-color-text--pink-500 {
  color: rgb(233,30,99) !important;
}

.mdl-color--pink-500 {
  background-color: rgb(233,30,99) !important;
}

.mdl-color-text--pink-600 {
  color: rgb(216,27,96) !important;
}

.mdl-color--pink-600 {
  background-color: rgb(216,27,96) !important;
}

.mdl-color-text--pink-700 {
  color: rgb(194,24,91) !important;
}

.mdl-color--pink-700 {
  background-color: rgb(194,24,91) !important;
}

.mdl-color-text--pink-800 {
  color: rgb(173,20,87) !important;
}

.mdl-color--pink-800 {
  background-color: rgb(173,20,87) !important;
}

.mdl-color-text--pink-900 {
  color: rgb(136,14,79) !important;
}

.mdl-color--pink-900 {
  background-color: rgb(136,14,79) !important;
}

.mdl-color-text--pink-A100 {
  color: rgb(255,128,171) !important;
}

.mdl-color--pink-A100 {
  background-color: rgb(255,128,171) !important;
}

.mdl-color-text--pink-A200 {
  color: rgb(255,64,129) !important;
}

.mdl-color--pink-A200 {
  background-color: rgb(255,64,129) !important;
}

.mdl-color-text--pink-A400 {
  color: rgb(245,0,87) !important;
}

.mdl-color--pink-A400 {
  background-color: rgb(245,0,87) !important;
}

.mdl-color-text--pink-A700 {
  color: rgb(197,17,98) !important;
}

.mdl-color--pink-A700 {
  background-color: rgb(197,17,98) !important;
}

.mdl-color-text--purple {
  color: rgb(156,39,176) !important;
}

.mdl-color--purple {
  background-color: rgb(156,39,176) !important;
}

.mdl-color-text--purple-50 {
  color: rgb(243,229,245) !important;
}

.mdl-color--purple-50 {
  background-color: rgb(243,229,245) !important;
}

.mdl-color-text--purple-100 {
  color: rgb(225,190,231) !important;
}

.mdl-color--purple-100 {
  background-color: rgb(225,190,231) !important;
}

.mdl-color-text--purple-200 {
  color: rgb(206,147,216) !important;
}

.mdl-color--purple-200 {
  background-color: rgb(206,147,216) !important;
}

.mdl-color-text--purple-300 {
  color: rgb(186,104,200) !important;
}

.mdl-color--purple-300 {
  background-color: rgb(186,104,200) !important;
}

.mdl-color-text--purple-400 {
  color: rgb(171,71,188) !important;
}

.mdl-color--purple-400 {
  background-color: rgb(171,71,188) !important;
}

.mdl-color-text--purple-500 {
  color: rgb(156,39,176) !important;
}

.mdl-color--purple-500 {
  background-color: rgb(156,39,176) !important;
}

.mdl-color-text--purple-600 {
  color: rgb(142,36,170) !important;
}

.mdl-color--purple-600 {
  background-color: rgb(142,36,170) !important;
}

.mdl-color-text--purple-700 {
  color: rgb(123,31,162) !important;
}

.mdl-color--purple-700 {
  background-color: rgb(123,31,162) !important;
}

.mdl-color-text--purple-800 {
  color: rgb(106,27,154) !important;
}

.mdl-color--purple-800 {
  background-color: rgb(106,27,154) !important;
}

.mdl-color-text--purple-900 {
  color: rgb(74,20,140) !important;
}

.mdl-color--purple-900 {
  background-color: rgb(74,20,140) !important;
}

.mdl-color-text--purple-A100 {
  color: rgb(234,128,252) !important;
}

.mdl-color--purple-A100 {
  background-color: rgb(234,128,252) !important;
}

.mdl-color-text--purple-A200 {
  color: rgb(224,64,251) !important;
}

.mdl-color--purple-A200 {
  background-color: rgb(224,64,251) !important;
}

.mdl-color-text--purple-A400 {
  color: rgb(213,0,249) !important;
}

.mdl-color--purple-A400 {
  background-color: rgb(213,0,249) !important;
}

.mdl-color-text--purple-A700 {
  color: rgb(170,0,255) !important;
}

.mdl-color--purple-A700 {
  background-color: rgb(170,0,255) !important;
}

.mdl-color-text--deep-purple {
  color: rgb(103,58,183) !important;
}

.mdl-color--deep-purple {
  background-color: rgb(103,58,183) !important;
}

.mdl-color-text--deep-purple-50 {
  color: rgb(237,231,246) !important;
}

.mdl-color--deep-purple-50 {
  background-color: rgb(237,231,246) !important;
}

.mdl-color-text--deep-purple-100 {
  color: rgb(209,196,233) !important;
}

.mdl-color--deep-purple-100 {
  background-color: rgb(209,196,233) !important;
}

.mdl-color-text--deep-purple-200 {
  color: rgb(179,157,219) !important;
}

.mdl-color--deep-purple-200 {
  background-color: rgb(179,157,219) !important;
}

.mdl-color-text--deep-purple-300 {
  color: rgb(149,117,205) !important;
}

.mdl-color--deep-purple-300 {
  background-color: rgb(149,117,205) !important;
}

.mdl-color-text--deep-purple-400 {
  color: rgb(126,87,194) !important;
}

.mdl-color--deep-purple-400 {
  background-color: rgb(126,87,194) !important;
}

.mdl-color-text--deep-purple-500 {
  color: rgb(103,58,183) !important;
}

.mdl-color--deep-purple-500 {
  background-color: rgb(103,58,183) !important;
}

.mdl-color-text--deep-purple-600 {
  color: rgb(94,53,177) !important;
}

.mdl-color--deep-purple-600 {
  background-color: rgb(94,53,177) !important;
}

.mdl-color-text--deep-purple-700 {
  color: rgb(81,45,168) !important;
}

.mdl-color--deep-purple-700 {
  background-color: rgb(81,45,168) !important;
}

.mdl-color-text--deep-purple-800 {
  color: rgb(69,39,160) !important;
}

.mdl-color--deep-purple-800 {
  background-color: rgb(69,39,160) !important;
}

.mdl-color-text--deep-purple-900 {
  color: rgb(49,27,146) !important;
}

.mdl-color--deep-purple-900 {
  background-color: rgb(49,27,146) !important;
}

.mdl-color-text--deep-purple-A100 {
  color: rgb(179,136,255) !important;
}

.mdl-color--deep-purple-A100 {
  background-color: rgb(179,136,255) !important;
}

.mdl-color-text--deep-purple-A200 {
  color: rgb(124,77,255) !important;
}

.mdl-color--deep-purple-A200 {
  background-color: rgb(124,77,255) !important;
}

.mdl-color-text--deep-purple-A400 {
  color: rgb(101,31,255) !important;
}

.mdl-color--deep-purple-A400 {
  background-color: rgb(101,31,255) !important;
}

.mdl-color-text--deep-purple-A700 {
  color: rgb(98,0,234) !important;
}

.mdl-color--deep-purple-A700 {
  background-color: rgb(98,0,234) !important;
}

.mdl-color-text--indigo {
  color: rgb(63,81,181) !important;
}

.mdl-color--indigo {
  background-color: rgb(63,81,181) !important;
}

.mdl-color-text--indigo-50 {
  color: rgb(232,234,246) !important;
}

.mdl-color--indigo-50 {
  background-color: rgb(232,234,246) !important;
}

.mdl-color-text--indigo-100 {
  color: rgb(197,202,233) !important;
}

.mdl-color--indigo-100 {
  background-color: rgb(197,202,233) !important;
}

.mdl-color-text--indigo-200 {
  color: rgb(159,168,218) !important;
}

.mdl-color--indigo-200 {
  background-color: rgb(159,168,218) !important;
}

.mdl-color-text--indigo-300 {
  color: rgb(121,134,203) !important;
}

.mdl-color--indigo-300 {
  background-color: rgb(121,134,203) !important;
}

.mdl-color-text--indigo-400 {
  color: rgb(92,107,192) !important;
}

.mdl-color--indigo-400 {
  background-color: rgb(92,107,192) !important;
}

.mdl-color-text--indigo-500 {
  color: rgb(63,81,181) !important;
}

.mdl-color--indigo-500 {
  background-color: rgb(63,81,181) !important;
}

.mdl-color-text--indigo-600 {
  color: rgb(57,73,171) !important;
}

.mdl-color--indigo-600 {
  background-color: rgb(57,73,171) !important;
}

.mdl-color-text--indigo-700 {
  color: rgb(48,63,159) !important;
}

.mdl-color--indigo-700 {
  background-color: rgb(48,63,159) !important;
}

.mdl-color-text--indigo-800 {
  color: rgb(40,53,147) !important;
}

.mdl-color--indigo-800 {
  background-color: rgb(40,53,147) !important;
}

.mdl-color-text--indigo-900 {
  color: rgb(26,35,126) !important;
}

.mdl-color--indigo-900 {
  background-color: rgb(26,35,126) !important;
}

.mdl-color-text--indigo-A100 {
  color: rgb(140,158,255) !important;
}

.mdl-color--indigo-A100 {
  background-color: rgb(140,158,255) !important;
}

.mdl-color-text--indigo-A200 {
  color: rgb(83,109,254) !important;
}

.mdl-color--indigo-A200 {
  background-color: rgb(83,109,254) !important;
}

.mdl-color-text--indigo-A400 {
  color: rgb(61,90,254) !important;
}

.mdl-color--indigo-A400 {
  background-color: rgb(61,90,254) !important;
}

.mdl-color-text--indigo-A700 {
  color: rgb(48,79,254) !important;
}

.mdl-color--indigo-A700 {
  background-color: rgb(48,79,254) !important;
}

.mdl-color-text--blue {
  color: rgb(33,150,243) !important;
}

.mdl-color--blue {
  background-color: rgb(33,150,243) !important;
}

.mdl-color-text--blue-50 {
  color: rgb(227,242,253) !important;
}

.mdl-color--blue-50 {
  background-color: rgb(227,242,253) !important;
}

.mdl-color-text--blue-100 {
  color: rgb(187,222,251) !important;
}

.mdl-color--blue-100 {
  background-color: rgb(187,222,251) !important;
}

.mdl-color-text--blue-200 {
  color: rgb(144,202,249) !important;
}

.mdl-color--blue-200 {
  background-color: rgb(144,202,249) !important;
}

.mdl-color-text--blue-300 {
  color: rgb(100,181,246) !important;
}

.mdl-color--blue-300 {
  background-color: rgb(100,181,246) !important;
}

.mdl-color-text--blue-400 {
  color: rgb(66,165,245) !important;
}

.mdl-color--blue-400 {
  background-color: rgb(66,165,245) !important;
}

.mdl-color-text--blue-500 {
  color: rgb(33,150,243) !important;
}

.mdl-color--blue-500 {
  background-color: rgb(33,150,243) !important;
}

.mdl-color-text--blue-600 {
  color: rgb(30,136,229) !important;
}

.mdl-color--blue-600 {
  background-color: rgb(30,136,229) !important;
}

.mdl-color-text--blue-700 {
  color: rgb(25,118,210) !important;
}

.mdl-color--blue-700 {
  background-color: rgb(25,118,210) !important;
}

.mdl-color-text--blue-800 {
  color: rgb(21,101,192) !important;
}

.mdl-color--blue-800 {
  background-color: rgb(21,101,192) !important;
}

.mdl-color-text--blue-900 {
  color: rgb(13,71,161) !important;
}

.mdl-color--blue-900 {
  background-color: rgb(13,71,161) !important;
}

.mdl-color-text--blue-A100 {
  color: rgb(130,177,255) !important;
}

.mdl-color--blue-A100 {
  background-color: rgb(130,177,255) !important;
}

.mdl-color-text--blue-A200 {
  color: rgb(68,138,255) !important;
}

.mdl-color--blue-A200 {
  background-color: rgb(68,138,255) !important;
}

.mdl-color-text--blue-A400 {
  color: rgb(41,121,255) !important;
}

.mdl-color--blue-A400 {
  background-color: rgb(41,121,255) !important;
}

.mdl-color-text--blue-A700 {
  color: rgb(41,98,255) !important;
}

.mdl-color--blue-A700 {
  background-color: rgb(41,98,255) !important;
}

.mdl-color-text--light-blue {
  color: rgb(3,169,244) !important;
}

.mdl-color--light-blue {
  background-color: rgb(3,169,244) !important;
}

.mdl-color-text--light-blue-50 {
  color: rgb(225,245,254) !important;
}

.mdl-color--light-blue-50 {
  background-color: rgb(225,245,254) !important;
}

.mdl-color-text--light-blue-100 {
  color: rgb(179,229,252) !important;
}

.mdl-color--light-blue-100 {
  background-color: rgb(179,229,252) !important;
}

.mdl-color-text--light-blue-200 {
  color: rgb(129,212,250) !important;
}

.mdl-color--light-blue-200 {
  background-color: rgb(129,212,250) !important;
}

.mdl-color-text--light-blue-300 {
  color: rgb(79,195,247) !important;
}

.mdl-color--light-blue-300 {
  background-color: rgb(79,195,247) !important;
}

.mdl-color-text--light-blue-400 {
  color: rgb(41,182,246) !important;
}

.mdl-color--light-blue-400 {
  background-color: rgb(41,182,246) !important;
}

.mdl-color-text--light-blue-500 {
  color: rgb(3,169,244) !important;
}

.mdl-color--light-blue-500 {
  background-color: rgb(3,169,244) !important;
}

.mdl-color-text--light-blue-600 {
  color: rgb(3,155,229) !important;
}

.mdl-color--light-blue-600 {
  background-color: rgb(3,155,229) !important;
}

.mdl-color-text--light-blue-700 {
  color: rgb(2,136,209) !important;
}

.mdl-color--light-blue-700 {
  background-color: rgb(2,136,209) !important;
}

.mdl-color-text--light-blue-800 {
  color: rgb(2,119,189) !important;
}

.mdl-color--light-blue-800 {
  background-color: rgb(2,119,189) !important;
}

.mdl-color-text--light-blue-900 {
  color: rgb(1,87,155) !important;
}

.mdl-color--light-blue-900 {
  background-color: rgb(1,87,155) !important;
}

.mdl-color-text--light-blue-A100 {
  color: rgb(128,216,255) !important;
}

.mdl-color--light-blue-A100 {
  background-color: rgb(128,216,255) !important;
}

.mdl-color-text--light-blue-A200 {
  color: rgb(64,196,255) !important;
}

.mdl-color--light-blue-A200 {
  background-color: rgb(64,196,255) !important;
}

.mdl-color-text--light-blue-A400 {
  color: rgb(0,176,255) !important;
}

.mdl-color--light-blue-A400 {
  background-color: rgb(0,176,255) !important;
}

.mdl-color-text--light-blue-A700 {
  color: rgb(0,145,234) !important;
}

.mdl-color--light-blue-A700 {
  background-color: rgb(0,145,234) !important;
}

.mdl-color-text--cyan {
  color: rgb(0,188,212) !important;
}

.mdl-color--cyan {
  background-color: rgb(0,188,212) !important;
}

.mdl-color-text--cyan-50 {
  color: rgb(224,247,250) !important;
}

.mdl-color--cyan-50 {
  background-color: rgb(224,247,250) !important;
}

.mdl-color-text--cyan-100 {
  color: rgb(178,235,242) !important;
}

.mdl-color--cyan-100 {
  background-color: rgb(178,235,242) !important;
}

.mdl-color-text--cyan-200 {
  color: rgb(128,222,234) !important;
}

.mdl-color--cyan-200 {
  background-color: rgb(128,222,234) !important;
}

.mdl-color-text--cyan-300 {
  color: rgb(77,208,225) !important;
}

.mdl-color--cyan-300 {
  background-color: rgb(77,208,225) !important;
}

.mdl-color-text--cyan-400 {
  color: rgb(38,198,218) !important;
}

.mdl-color--cyan-400 {
  background-color: rgb(38,198,218) !important;
}

.mdl-color-text--cyan-500 {
  color: rgb(0,188,212) !important;
}

.mdl-color--cyan-500 {
  background-color: rgb(0,188,212) !important;
}

.mdl-color-text--cyan-600 {
  color: rgb(0,172,193) !important;
}

.mdl-color--cyan-600 {
  background-color: rgb(0,172,193) !important;
}

.mdl-color-text--cyan-700 {
  color: rgb(0,151,167) !important;
}

.mdl-color--cyan-700 {
  background-color: rgb(0,151,167) !important;
}

.mdl-color-text--cyan-800 {
  color: rgb(0,131,143) !important;
}

.mdl-color--cyan-800 {
  background-color: rgb(0,131,143) !important;
}

.mdl-color-text--cyan-900 {
  color: rgb(0,96,100) !important;
}

.mdl-color--cyan-900 {
  background-color: rgb(0,96,100) !important;
}

.mdl-color-text--cyan-A100 {
  color: rgb(132,255,255) !important;
}

.mdl-color--cyan-A100 {
  background-color: rgb(132,255,255) !important;
}

.mdl-color-text--cyan-A200 {
  color: rgb(24,255,255) !important;
}

.mdl-color--cyan-A200 {
  background-color: rgb(24,255,255) !important;
}

.mdl-color-text--cyan-A400 {
  color: rgb(0,229,255) !important;
}

.mdl-color--cyan-A400 {
  background-color: rgb(0,229,255) !important;
}

.mdl-color-text--cyan-A700 {
  color: rgb(0,184,212) !important;
}

.mdl-color--cyan-A700 {
  background-color: rgb(0,184,212) !important;
}

.mdl-color-text--teal {
  color: rgb(0,150,136) !important;
}

.mdl-color--teal {
  background-color: rgb(0,150,136) !important;
}

.mdl-color-text--teal-50 {
  color: rgb(224,242,241) !important;
}

.mdl-color--teal-50 {
  background-color: rgb(224,242,241) !important;
}

.mdl-color-text--teal-100 {
  color: rgb(178,223,219) !important;
}

.mdl-color--teal-100 {
  background-color: rgb(178,223,219) !important;
}

.mdl-color-text--teal-200 {
  color: rgb(128,203,196) !important;
}

.mdl-color--teal-200 {
  background-color: rgb(128,203,196) !important;
}

.mdl-color-text--teal-300 {
  color: rgb(77,182,172) !important;
}

.mdl-color--teal-300 {
  background-color: rgb(77,182,172) !important;
}

.mdl-color-text--teal-400 {
  color: rgb(38,166,154) !important;
}

.mdl-color--teal-400 {
  background-color: rgb(38,166,154) !important;
}

.mdl-color-text--teal-500 {
  color: rgb(0,150,136) !important;
}

.mdl-color--teal-500 {
  background-color: rgb(0,150,136) !important;
}

.mdl-color-text--teal-600 {
  color: rgb(0,137,123) !important;
}

.mdl-color--teal-600 {
  background-color: rgb(0,137,123) !important;
}

.mdl-color-text--teal-700 {
  color: rgb(0,121,107) !important;
}

.mdl-color--teal-700 {
  background-color: rgb(0,121,107) !important;
}

.mdl-color-text--teal-800 {
  color: rgb(0,105,92) !important;
}

.mdl-color--teal-800 {
  background-color: rgb(0,105,92) !important;
}

.mdl-color-text--teal-900 {
  color: rgb(0,77,64) !important;
}

.mdl-color--teal-900 {
  background-color: rgb(0,77,64) !important;
}

.mdl-color-text--teal-A100 {
  color: rgb(167,255,235) !important;
}

.mdl-color--teal-A100 {
  background-color: rgb(167,255,235) !important;
}

.mdl-color-text--teal-A200 {
  color: rgb(100,255,218) !important;
}

.mdl-color--teal-A200 {
  background-color: rgb(100,255,218) !important;
}

.mdl-color-text--teal-A400 {
  color: rgb(29,233,182) !important;
}

.mdl-color--teal-A400 {
  background-color: rgb(29,233,182) !important;
}

.mdl-color-text--teal-A700 {
  color: rgb(0,191,165) !important;
}

.mdl-color--teal-A700 {
  background-color: rgb(0,191,165) !important;
}

.mdl-color-text--green {
  color: rgb(76,175,80) !important;
}

.mdl-color--green {
  background-color: rgb(76,175,80) !important;
}

.mdl-color-text--green-50 {
  color: rgb(232,245,233) !important;
}

.mdl-color--green-50 {
  background-color: rgb(232,245,233) !important;
}

.mdl-color-text--green-100 {
  color: rgb(200,230,201) !important;
}

.mdl-color--green-100 {
  background-color: rgb(200,230,201) !important;
}

.mdl-color-text--green-200 {
  color: rgb(165,214,167) !important;
}

.mdl-color--green-200 {
  background-color: rgb(165,214,167) !important;
}

.mdl-color-text--green-300 {
  color: rgb(129,199,132) !important;
}

.mdl-color--green-300 {
  background-color: rgb(129,199,132) !important;
}

.mdl-color-text--green-400 {
  color: rgb(102,187,106) !important;
}

.mdl-color--green-400 {
  background-color: rgb(102,187,106) !important;
}

.mdl-color-text--green-500 {
  color: rgb(76,175,80) !important;
}

.mdl-color--green-500 {
  background-color: rgb(76,175,80) !important;
}

.mdl-color-text--green-600 {
  color: rgb(67,160,71) !important;
}

.mdl-color--green-600 {
  background-color: rgb(67,160,71) !important;
}

.mdl-color-text--green-700 {
  color: rgb(56,142,60) !important;
}

.mdl-color--green-700 {
  background-color: rgb(56,142,60) !important;
}

.mdl-color-text--green-800 {
  color: rgb(46,125,50) !important;
}

.mdl-color--green-800 {
  background-color: rgb(46,125,50) !important;
}

.mdl-color-text--green-900 {
  color: rgb(27,94,32) !important;
}

.mdl-color--green-900 {
  background-color: rgb(27,94,32) !important;
}

.mdl-color-text--green-A100 {
  color: rgb(185,246,202) !important;
}

.mdl-color--green-A100 {
  background-color: rgb(185,246,202) !important;
}

.mdl-color-text--green-A200 {
  color: rgb(105,240,174) !important;
}

.mdl-color--green-A200 {
  background-color: rgb(105,240,174) !important;
}

.mdl-color-text--green-A400 {
  color: rgb(0,230,118) !important;
}

.mdl-color--green-A400 {
  background-color: rgb(0,230,118) !important;
}

.mdl-color-text--green-A700 {
  color: rgb(0,200,83) !important;
}

.mdl-color--green-A700 {
  background-color: rgb(0,200,83) !important;
}

.mdl-color-text--light-green {
  color: rgb(139,195,74) !important;
}

.mdl-color--light-green {
  background-color: rgb(139,195,74) !important;
}

.mdl-color-text--light-green-50 {
  color: rgb(241,248,233) !important;
}

.mdl-color--light-green-50 {
  background-color: rgb(241,248,233) !important;
}

.mdl-color-text--light-green-100 {
  color: rgb(220,237,200) !important;
}

.mdl-color--light-green-100 {
  background-color: rgb(220,237,200) !important;
}

.mdl-color-text--light-green-200 {
  color: rgb(197,225,165) !important;
}

.mdl-color--light-green-200 {
  background-color: rgb(197,225,165) !important;
}

.mdl-color-text--light-green-300 {
  color: rgb(174,213,129) !important;
}

.mdl-color--light-green-300 {
  background-color: rgb(174,213,129) !important;
}

.mdl-color-text--light-green-400 {
  color: rgb(156,204,101) !important;
}

.mdl-color--light-green-400 {
  background-color: rgb(156,204,101) !important;
}

.mdl-color-text--light-green-500 {
  color: rgb(139,195,74) !important;
}

.mdl-color--light-green-500 {
  background-color: rgb(139,195,74) !important;
}

.mdl-color-text--light-green-600 {
  color: rgb(124,179,66) !important;
}

.mdl-color--light-green-600 {
  background-color: rgb(124,179,66) !important;
}

.mdl-color-text--light-green-700 {
  color: rgb(104,159,56) !important;
}

.mdl-color--light-green-700 {
  background-color: rgb(104,159,56) !important;
}

.mdl-color-text--light-green-800 {
  color: rgb(85,139,47) !important;
}

.mdl-color--light-green-800 {
  background-color: rgb(85,139,47) !important;
}

.mdl-color-text--light-green-900 {
  color: rgb(51,105,30) !important;
}

.mdl-color--light-green-900 {
  background-color: rgb(51,105,30) !important;
}

.mdl-color-text--light-green-A100 {
  color: rgb(204,255,144) !important;
}

.mdl-color--light-green-A100 {
  background-color: rgb(204,255,144) !important;
}

.mdl-color-text--light-green-A200 {
  color: rgb(178,255,89) !important;
}

.mdl-color--light-green-A200 {
  background-color: rgb(178,255,89) !important;
}

.mdl-color-text--light-green-A400 {
  color: rgb(118,255,3) !important;
}

.mdl-color--light-green-A400 {
  background-color: rgb(118,255,3) !important;
}

.mdl-color-text--light-green-A700 {
  color: rgb(100,221,23) !important;
}

.mdl-color--light-green-A700 {
  background-color: rgb(100,221,23) !important;
}

.mdl-color-text--lime {
  color: rgb(205,220,57) !important;
}

.mdl-color--lime {
  background-color: rgb(205,220,57) !important;
}

.mdl-color-text--lime-50 {
  color: rgb(249,251,231) !important;
}

.mdl-color--lime-50 {
  background-color: rgb(249,251,231) !important;
}

.mdl-color-text--lime-100 {
  color: rgb(240,244,195) !important;
}

.mdl-color--lime-100 {
  background-color: rgb(240,244,195) !important;
}

.mdl-color-text--lime-200 {
  color: rgb(230,238,156) !important;
}

.mdl-color--lime-200 {
  background-color: rgb(230,238,156) !important;
}

.mdl-color-text--lime-300 {
  color: rgb(220,231,117) !important;
}

.mdl-color--lime-300 {
  background-color: rgb(220,231,117) !important;
}

.mdl-color-text--lime-400 {
  color: rgb(212,225,87) !important;
}

.mdl-color--lime-400 {
  background-color: rgb(212,225,87) !important;
}

.mdl-color-text--lime-500 {
  color: rgb(205,220,57) !important;
}

.mdl-color--lime-500 {
  background-color: rgb(205,220,57) !important;
}

.mdl-color-text--lime-600 {
  color: rgb(192,202,51) !important;
}

.mdl-color--lime-600 {
  background-color: rgb(192,202,51) !important;
}

.mdl-color-text--lime-700 {
  color: rgb(175,180,43) !important;
}

.mdl-color--lime-700 {
  background-color: rgb(175,180,43) !important;
}

.mdl-color-text--lime-800 {
  color: rgb(158,157,36) !important;
}

.mdl-color--lime-800 {
  background-color: rgb(158,157,36) !important;
}

.mdl-color-text--lime-900 {
  color: rgb(130,119,23) !important;
}

.mdl-color--lime-900 {
  background-color: rgb(130,119,23) !important;
}

.mdl-color-text--lime-A100 {
  color: rgb(244,255,129) !important;
}

.mdl-color--lime-A100 {
  background-color: rgb(244,255,129) !important;
}

.mdl-color-text--lime-A200 {
  color: rgb(238,255,65) !important;
}

.mdl-color--lime-A200 {
  background-color: rgb(238,255,65) !important;
}

.mdl-color-text--lime-A400 {
  color: rgb(198,255,0) !important;
}

.mdl-color--lime-A400 {
  background-color: rgb(198,255,0) !important;
}

.mdl-color-text--lime-A700 {
  color: rgb(174,234,0) !important;
}

.mdl-color--lime-A700 {
  background-color: rgb(174,234,0) !important;
}

.mdl-color-text--yellow {
  color: rgb(255,235,59) !important;
}

.mdl-color--yellow {
  background-color: rgb(255,235,59) !important;
}

.mdl-color-text--yellow-50 {
  color: rgb(255,253,231) !important;
}

.mdl-color--yellow-50 {
  background-color: rgb(255,253,231) !important;
}

.mdl-color-text--yellow-100 {
  color: rgb(255,249,196) !important;
}

.mdl-color--yellow-100 {
  background-color: rgb(255,249,196) !important;
}

.mdl-color-text--yellow-200 {
  color: rgb(255,245,157) !important;
}

.mdl-color--yellow-200 {
  background-color: rgb(255,245,157) !important;
}

.mdl-color-text--yellow-300 {
  color: rgb(255,241,118) !important;
}

.mdl-color--yellow-300 {
  background-color: rgb(255,241,118) !important;
}

.mdl-color-text--yellow-400 {
  color: rgb(255,238,88) !important;
}

.mdl-color--yellow-400 {
  background-color: rgb(255,238,88) !important;
}

.mdl-color-text--yellow-500 {
  color: rgb(255,235,59) !important;
}

.mdl-color--yellow-500 {
  background-color: rgb(255,235,59) !important;
}

.mdl-color-text--yellow-600 {
  color: rgb(253,216,53) !important;
}

.mdl-color--yellow-600 {
  background-color: rgb(253,216,53) !important;
}

.mdl-color-text--yellow-700 {
  color: rgb(251,192,45) !important;
}

.mdl-color--yellow-700 {
  background-color: rgb(251,192,45) !important;
}

.mdl-color-text--yellow-800 {
  color: rgb(249,168,37) !important;
}

.mdl-color--yellow-800 {
  background-color: rgb(249,168,37) !important;
}

.mdl-color-text--yellow-900 {
  color: rgb(245,127,23) !important;
}

.mdl-color--yellow-900 {
  background-color: rgb(245,127,23) !important;
}

.mdl-color-text--yellow-A100 {
  color: rgb(255,255,141) !important;
}

.mdl-color--yellow-A100 {
  background-color: rgb(255,255,141) !important;
}

.mdl-color-text--yellow-A200 {
  color: rgb(255,255,0) !important;
}

.mdl-color--yellow-A200 {
  background-color: rgb(255,255,0) !important;
}

.mdl-color-text--yellow-A400 {
  color: rgb(255,234,0) !important;
}

.mdl-color--yellow-A400 {
  background-color: rgb(255,234,0) !important;
}

.mdl-color-text--yellow-A700 {
  color: rgb(255,214,0) !important;
}

.mdl-color--yellow-A700 {
  background-color: rgb(255,214,0) !important;
}

.mdl-color-text--amber {
  color: rgb(255,193,7) !important;
}

.mdl-color--amber {
  background-color: rgb(255,193,7) !important;
}

.mdl-color-text--amber-50 {
  color: rgb(255,248,225) !important;
}

.mdl-color--amber-50 {
  background-color: rgb(255,248,225) !important;
}

.mdl-color-text--amber-100 {
  color: rgb(255,236,179) !important;
}

.mdl-color--amber-100 {
  background-color: rgb(255,236,179) !important;
}

.mdl-color-text--amber-200 {
  color: rgb(255,224,130) !important;
}

.mdl-color--amber-200 {
  background-color: rgb(255,224,130) !important;
}

.mdl-color-text--amber-300 {
  color: rgb(255,213,79) !important;
}

.mdl-color--amber-300 {
  background-color: rgb(255,213,79) !important;
}

.mdl-color-text--amber-400 {
  color: rgb(255,202,40) !important;
}

.mdl-color--amber-400 {
  background-color: rgb(255,202,40) !important;
}

.mdl-color-text--amber-500 {
  color: rgb(255,193,7) !important;
}

.mdl-color--amber-500 {
  background-color: rgb(255,193,7) !important;
}

.mdl-color-text--amber-600 {
  color: rgb(255,179,0) !important;
}

.mdl-color--amber-600 {
  background-color: rgb(255,179,0) !important;
}

.mdl-color-text--amber-700 {
  color: rgb(255,160,0) !important;
}

.mdl-color--amber-700 {
  background-color: rgb(255,160,0) !important;
}

.mdl-color-text--amber-800 {
  color: rgb(255,143,0) !important;
}

.mdl-color--amber-800 {
  background-color: rgb(255,143,0) !important;
}

.mdl-color-text--amber-900 {
  color: rgb(255,111,0) !important;
}

.mdl-color--amber-900 {
  background-color: rgb(255,111,0) !important;
}

.mdl-color-text--amber-A100 {
  color: rgb(255,229,127) !important;
}

.mdl-color--amber-A100 {
  background-color: rgb(255,229,127) !important;
}

.mdl-color-text--amber-A200 {
  color: rgb(255,215,64) !important;
}

.mdl-color--amber-A200 {
  background-color: rgb(255,215,64) !important;
}

.mdl-color-text--amber-A400 {
  color: rgb(255,196,0) !important;
}

.mdl-color--amber-A400 {
  background-color: rgb(255,196,0) !important;
}

.mdl-color-text--amber-A700 {
  color: rgb(255,171,0) !important;
}

.mdl-color--amber-A700 {
  background-color: rgb(255,171,0) !important;
}

.mdl-color-text--orange {
  color: rgb(255,152,0) !important;
}

.mdl-color--orange {
  background-color: rgb(255,152,0) !important;
}

.mdl-color-text--orange-50 {
  color: rgb(255,243,224) !important;
}

.mdl-color--orange-50 {
  background-color: rgb(255,243,224) !important;
}

.mdl-color-text--orange-100 {
  color: rgb(255,224,178) !important;
}

.mdl-color--orange-100 {
  background-color: rgb(255,224,178) !important;
}

.mdl-color-text--orange-200 {
  color: rgb(255,204,128) !important;
}

.mdl-color--orange-200 {
  background-color: rgb(255,204,128) !important;
}

.mdl-color-text--orange-300 {
  color: rgb(255,183,77) !important;
}

.mdl-color--orange-300 {
  background-color: rgb(255,183,77) !important;
}

.mdl-color-text--orange-400 {
  color: rgb(255,167,38) !important;
}

.mdl-color--orange-400 {
  background-color: rgb(255,167,38) !important;
}

.mdl-color-text--orange-500 {
  color: rgb(255,152,0) !important;
}

.mdl-color--orange-500 {
  background-color: rgb(255,152,0) !important;
}

.mdl-color-text--orange-600 {
  color: rgb(251,140,0) !important;
}

.mdl-color--orange-600 {
  background-color: rgb(251,140,0) !important;
}

.mdl-color-text--orange-700 {
  color: rgb(245,124,0) !important;
}

.mdl-color--orange-700 {
  background-color: rgb(245,124,0) !important;
}

.mdl-color-text--orange-800 {
  color: rgb(239,108,0) !important;
}

.mdl-color--orange-800 {
  background-color: rgb(239,108,0) !important;
}

.mdl-color-text--orange-900 {
  color: rgb(230,81,0) !important;
}

.mdl-color--orange-900 {
  background-color: rgb(230,81,0) !important;
}

.mdl-color-text--orange-A100 {
  color: rgb(255,209,128) !important;
}

.mdl-color--orange-A100 {
  background-color: rgb(255,209,128) !important;
}

.mdl-color-text--orange-A200 {
  color: rgb(255,171,64) !important;
}

.mdl-color--orange-A200 {
  background-color: rgb(255,171,64) !important;
}

.mdl-color-text--orange-A400 {
  color: rgb(255,145,0) !important;
}

.mdl-color--orange-A400 {
  background-color: rgb(255,145,0) !important;
}

.mdl-color-text--orange-A700 {
  color: rgb(255,109,0) !important;
}

.mdl-color--orange-A700 {
  background-color: rgb(255,109,0) !important;
}

.mdl-color-text--deep-orange {
  color: rgb(255,87,34) !important;
}

.mdl-color--deep-orange {
  background-color: rgb(255,87,34) !important;
}

.mdl-color-text--deep-orange-50 {
  color: rgb(251,233,231) !important;
}

.mdl-color--deep-orange-50 {
  background-color: rgb(251,233,231) !important;
}

.mdl-color-text--deep-orange-100 {
  color: rgb(255,204,188) !important;
}

.mdl-color--deep-orange-100 {
  background-color: rgb(255,204,188) !important;
}

.mdl-color-text--deep-orange-200 {
  color: rgb(255,171,145) !important;
}

.mdl-color--deep-orange-200 {
  background-color: rgb(255,171,145) !important;
}

.mdl-color-text--deep-orange-300 {
  color: rgb(255,138,101) !important;
}

.mdl-color--deep-orange-300 {
  background-color: rgb(255,138,101) !important;
}

.mdl-color-text--deep-orange-400 {
  color: rgb(255,112,67) !important;
}

.mdl-color--deep-orange-400 {
  background-color: rgb(255,112,67) !important;
}

.mdl-color-text--deep-orange-500 {
  color: rgb(255,87,34) !important;
}

.mdl-color--deep-orange-500 {
  background-color: rgb(255,87,34) !important;
}

.mdl-color-text--deep-orange-600 {
  color: rgb(244,81,30) !important;
}

.mdl-color--deep-orange-600 {
  background-color: rgb(244,81,30) !important;
}

.mdl-color-text--deep-orange-700 {
  color: rgb(230,74,25) !important;
}

.mdl-color--deep-orange-700 {
  background-color: rgb(230,74,25) !important;
}

.mdl-color-text--deep-orange-800 {
  color: rgb(216,67,21) !important;
}

.mdl-color--deep-orange-800 {
  background-color: rgb(216,67,21) !important;
}

.mdl-color-text--deep-orange-900 {
  color: rgb(191,54,12) !important;
}

.mdl-color--deep-orange-900 {
  background-color: rgb(191,54,12) !important;
}

.mdl-color-text--deep-orange-A100 {
  color: rgb(255,158,128) !important;
}

.mdl-color--deep-orange-A100 {
  background-color: rgb(255,158,128) !important;
}

.mdl-color-text--deep-orange-A200 {
  color: rgb(255,110,64) !important;
}

.mdl-color--deep-orange-A200 {
  background-color: rgb(255,110,64) !important;
}

.mdl-color-text--deep-orange-A400 {
  color: rgb(255,61,0) !important;
}

.mdl-color--deep-orange-A400 {
  background-color: rgb(255,61,0) !important;
}

.mdl-color-text--deep-orange-A700 {
  color: rgb(221,44,0) !important;
}

.mdl-color--deep-orange-A700 {
  background-color: rgb(221,44,0) !important;
}

.mdl-color-text--brown {
  color: rgb(121,85,72) !important;
}

.mdl-color--brown {
  background-color: rgb(121,85,72) !important;
}

.mdl-color-text--brown-50 {
  color: rgb(239,235,233) !important;
}

.mdl-color--brown-50 {
  background-color: rgb(239,235,233) !important;
}

.mdl-color-text--brown-100 {
  color: rgb(215,204,200) !important;
}

.mdl-color--brown-100 {
  background-color: rgb(215,204,200) !important;
}

.mdl-color-text--brown-200 {
  color: rgb(188,170,164) !important;
}

.mdl-color--brown-200 {
  background-color: rgb(188,170,164) !important;
}

.mdl-color-text--brown-300 {
  color: rgb(161,136,127) !important;
}

.mdl-color--brown-300 {
  background-color: rgb(161,136,127) !important;
}

.mdl-color-text--brown-400 {
  color: rgb(141,110,99) !important;
}

.mdl-color--brown-400 {
  background-color: rgb(141,110,99) !important;
}

.mdl-color-text--brown-500 {
  color: rgb(121,85,72) !important;
}

.mdl-color--brown-500 {
  background-color: rgb(121,85,72) !important;
}

.mdl-color-text--brown-600 {
  color: rgb(109,76,65) !important;
}

.mdl-color--brown-600 {
  background-color: rgb(109,76,65) !important;
}

.mdl-color-text--brown-700 {
  color: rgb(93,64,55) !important;
}

.mdl-color--brown-700 {
  background-color: rgb(93,64,55) !important;
}

.mdl-color-text--brown-800 {
  color: rgb(78,52,46) !important;
}

.mdl-color--brown-800 {
  background-color: rgb(78,52,46) !important;
}

.mdl-color-text--brown-900 {
  color: rgb(62,39,35) !important;
}

.mdl-color--brown-900 {
  background-color: rgb(62,39,35) !important;
}

.mdl-color-text--grey {
  color: rgb(158,158,158) !important;
}

.mdl-color--grey {
  background-color: rgb(158,158,158) !important;
}

.mdl-color-text--grey-50 {
  color: rgb(250,250,250) !important;
}

.mdl-color--grey-50 {
  background-color: rgb(250,250,250) !important;
}

.mdl-color-text--grey-100 {
  color: rgb(245,245,245) !important;
}

.mdl-color--grey-100 {
  background-color: rgb(245,245,245) !important;
}

.mdl-color-text--grey-200 {
  color: rgb(238,238,238) !important;
}

.mdl-color--grey-200 {
  background-color: rgb(238,238,238) !important;
}

.mdl-color-text--grey-300 {
  color: rgb(224,224,224) !important;
}

.mdl-color--grey-300 {
  background-color: rgb(224,224,224) !important;
}

.mdl-color-text--grey-400 {
  color: rgb(189,189,189) !important;
}

.mdl-color--grey-400 {
  background-color: rgb(189,189,189) !important;
}

.mdl-color-text--grey-500 {
  color: rgb(158,158,158) !important;
}

.mdl-color--grey-500 {
  background-color: rgb(158,158,158) !important;
}

.mdl-color-text--grey-600 {
  color: rgb(117,117,117) !important;
}

.mdl-color--grey-600 {
  background-color: rgb(117,117,117) !important;
}

.mdl-color-text--grey-700 {
  color: rgb(97,97,97) !important;
}

.mdl-color--grey-700 {
  background-color: rgb(97,97,97) !important;
}

.mdl-color-text--grey-800 {
  color: rgb(66,66,66) !important;
}

.mdl-color--grey-800 {
  background-color: rgb(66,66,66) !important;
}

.mdl-color-text--grey-900 {
  color: rgb(33,33,33) !important;
}

.mdl-color--grey-900 {
  background-color: rgb(33,33,33) !important;
}

.mdl-color-text--blue-grey {
  color: rgb(96,125,139) !important;
}

.mdl-color--blue-grey {
  background-color: rgb(96,125,139) !important;
}

.mdl-color-text--blue-grey-50 {
  color: rgb(236,239,241) !important;
}

.mdl-color--blue-grey-50 {
  background-color: rgb(236,239,241) !important;
}

.mdl-color-text--blue-grey-100 {
  color: rgb(207,216,220) !important;
}

.mdl-color--blue-grey-100 {
  background-color: rgb(207,216,220) !important;
}

.mdl-color-text--blue-grey-200 {
  color: rgb(176,190,197) !important;
}

.mdl-color--blue-grey-200 {
  background-color: rgb(176,190,197) !important;
}

.mdl-color-text--blue-grey-300 {
  color: rgb(144,164,174) !important;
}

.mdl-color--blue-grey-300 {
  background-color: rgb(144,164,174) !important;
}

.mdl-color-text--blue-grey-400 {
  color: rgb(120,144,156) !important;
}

.mdl-color--blue-grey-400 {
  background-color: rgb(120,144,156) !important;
}

.mdl-color-text--blue-grey-500 {
  color: rgb(96,125,139) !important;
}

.mdl-color--blue-grey-500 {
  background-color: rgb(96,125,139) !important;
}

.mdl-color-text--blue-grey-600 {
  color: rgb(84,110,122) !important;
}

.mdl-color--blue-grey-600 {
  background-color: rgb(84,110,122) !important;
}

.mdl-color-text--blue-grey-700 {
  color: rgb(69,90,100) !important;
}

.mdl-color--blue-grey-700 {
  background-color: rgb(69,90,100) !important;
}

.mdl-color-text--blue-grey-800 {
  color: rgb(55,71,79) !important;
}

.mdl-color--blue-grey-800 {
  background-color: rgb(55,71,79) !important;
}

.mdl-color-text--blue-grey-900 {
  color: rgb(38,50,56) !important;
}

.mdl-color--blue-grey-900 {
  background-color: rgb(38,50,56) !important;
}

.mdl-color--black {
  background-color: rgb(0,0,0) !important;
}

.mdl-color-text--black {
  color: rgb(0,0,0) !important;
}

.mdl-color--white {
  background-color: rgb(255,255,255) !important;
}

.mdl-color-text--white {
  color: rgb(255,255,255) !important;
}

.mdl-color--primary {
  background-color: rgb(63,81,181) !important;
}

.mdl-color--primary-contrast {
  background-color: rgb(255,255,255) !important;
}

.mdl-color--primary-dark {
  background-color: rgb(48,63,159) !important;
}

.mdl-color--accent {
  background-color: rgb(255,64,129) !important;
}

.mdl-color--accent-contrast {
  background-color: rgb(255,255,255) !important;
}

.mdl-color-text--primary {
  color: rgb(63,81,181) !important;
}

.mdl-color-text--primary-contrast {
  color: rgb(255,255,255) !important;
}

.mdl-color-text--primary-dark {
  color: rgb(48,63,159) !important;
}

.mdl-color-text--accent {
  color: rgb(255,64,129) !important;
}

.mdl-color-text--accent-contrast {
  color: rgb(255,255,255) !important;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-ripple {
  background: rgb(0,0,0);
  border-radius: 50%;
  height: 50px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 50px;
  overflow: hidden;
}
.mdl-ripple.is-animating {
  transition: transform 0.3s cubic-bezier(0, 0, 0.2, 1), width 0.3s cubic-bezier(0, 0, 0.2, 1), height 0.3s cubic-bezier(0, 0, 0.2, 1), opacity 0.6s cubic-bezier(0, 0, 0.2, 1);
}
.mdl-ripple.is-visible {
  opacity: 0.3;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-animation--default {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-animation--fast-out-slow-in {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-animation--linear-out-slow-in {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.mdl-animation--fast-out-linear-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-badge {
  position: relative;
  white-space: nowrap;
  margin-right: 24px;
}
.mdl-badge:not([data-badge]) {
  margin-right: auto;
}
.mdl-badge[data-badge]:after {
  content: attr(data-badge);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -24px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 12px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: rgb(255,64,129);
  color: rgb(255,255,255);
}
.mdl-button .mdl-badge[data-badge]:after {
  top: -10px;
  right: -5px;
}
.mdl-badge.mdl-badge--no-background[data-badge]:after {
  color: rgb(255,64,129);
  background: rgba(255,255,255,0.2);
  box-shadow: 0 0 1px gray;
}
.mdl-badge.mdl-badge--overlap {
  margin-right: 10px;
}
.mdl-badge.mdl-badge--overlap:after {
  right: -10px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-button {
  background: transparent;
  border: none;
  border-radius: 2px;
  color: rgb(0,0,0);
  position: relative;
  height: 36px;
  margin: 0;
  min-width: 64px;
  padding: 0 16px;
  display: inline-block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  line-height: 36px;
  vertical-align: middle;
}
.mdl-button::-moz-focus-inner {
  border: 0;
}
.mdl-button:hover {
  background-color: rgba(158,158,158, 0.20);
}
.mdl-button:focus:not(:active) {
  background-color: rgba(0,0,0, 0.12);
}
.mdl-button:active {
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button.mdl-button--colored {
  color: rgb(63,81,181);
}
.mdl-button.mdl-button--colored:focus:not(:active) {
  background-color: rgba(0,0,0, 0.12);
}

input.mdl-button[type=submit] {
  -webkit-appearance: none;
}

.mdl-button--raised {
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mdl-button--raised:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--raised:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--raised.mdl-button--colored {
  background: rgb(63,81,181);
  color: rgb(255,255,255);
}
.mdl-button--raised.mdl-button--colored:hover {
  background-color: rgb(63,81,181);
}
.mdl-button--raised.mdl-button--colored:active {
  background-color: rgb(63,81,181);
}
.mdl-button--raised.mdl-button--colored:focus:not(:active) {
  background-color: rgb(63,81,181);
}
.mdl-button--raised.mdl-button--colored .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  background: rgba(158,158,158, 0.20);
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal;
}
.mdl-button--fab .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}
.mdl-button--fab.mdl-button--mini-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}
.mdl-button--fab .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}
.mdl-button--fab:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--fab:focus:not(:active) {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36);
  background-color: rgba(158,158,158, 0.40);
}
.mdl-button--fab.mdl-button--colored {
  background: rgb(255,64,129);
  color: rgb(255,255,255);
}
.mdl-button--fab.mdl-button--colored:hover {
  background-color: rgb(255,64,129);
}
.mdl-button--fab.mdl-button--colored:focus:not(:active) {
  background-color: rgb(255,64,129);
}
.mdl-button--fab.mdl-button--colored:active {
  background-color: rgb(255,64,129);
}
.mdl-button--fab.mdl-button--colored .mdl-ripple {
  background: rgb(255,255,255);
}

.mdl-button--icon {
  border-radius: 50%;
  font-size: 24px;
  height: 32px;
  margin-left: 0;
  margin-right: 0;
  min-width: 32px;
  width: 32px;
  padding: 0;
  overflow: hidden;
  color: inherit;
  line-height: normal;
}
.mdl-button--icon .material-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}
.mdl-button--icon.mdl-button--mini-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
}
.mdl-button--icon.mdl-button--mini-icon .material-icons {
  top: 0px;
  left: 0px;
}
.mdl-button--icon .mdl-button__ripple-container {
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}

.mdl-button__ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}
.mdl-button[disabled] .mdl-button__ripple-container .mdl-ripple, .mdl-button.mdl-button--disabled .mdl-button__ripple-container .mdl-ripple {
  background-color: transparent;
}

.mdl-button--primary.mdl-button--primary {
  color: rgb(63,81,181);
}
.mdl-button--primary.mdl-button--primary .mdl-ripple {
  background: rgb(255,255,255);
}
.mdl-button--primary.mdl-button--primary.mdl-button--raised, .mdl-button--primary.mdl-button--primary.mdl-button--fab {
  color: rgb(255,255,255);
  background-color: rgb(63,81,181);
}

.mdl-button--accent.mdl-button--accent {
  color: rgb(255,64,129);
}
.mdl-button--accent.mdl-button--accent .mdl-ripple {
  background: rgb(255,255,255);
}
.mdl-button--accent.mdl-button--accent.mdl-button--raised, .mdl-button--accent.mdl-button--accent.mdl-button--fab {
  color: rgb(255,255,255);
  background-color: rgb(255,64,129);
}

.mdl-button[disabled][disabled], .mdl-button.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
  cursor: default;
  background-color: transparent;
}
.mdl-button--fab[disabled][disabled], .mdl-button--fab.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
}
.mdl-button--raised[disabled][disabled], .mdl-button--raised.mdl-button--disabled.mdl-button--disabled {
  background-color: rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
  box-shadow: none;
}
.mdl-button--colored[disabled][disabled], .mdl-button--colored.mdl-button--disabled.mdl-button--disabled {
  color: rgba(0,0,0, 0.26);
}

.mdl-button .material-icons {
  vertical-align: middle;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  min-height: 200px;
  overflow: hidden;
  width: 330px;
  z-index: 1;
  position: relative;
  background: rgb(255,255,255);
  border-radius: 2px;
  box-sizing: border-box;
}

.mdl-card__media {
  background-color: rgb(255,64,129);
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box;
}

.mdl-card__title {
  align-items: center;
  color: rgb(0,0,0);
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: 16px 16px;
  perspective-origin: 165px 56px;
  transform-origin: 165px 56px;
  box-sizing: border-box;
}
.mdl-card__title.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: 24px;
  font-weight: 300;
  line-height: normal;
  overflow: hidden;
  transform-origin: 149px 48px;
  margin: 0;
}

.mdl-card__subtitle-text {
  font-size: 14px;
  color: rgba(0,0,0, 0.54);
  margin: 0;
}

.mdl-card__supporting-text {
  color: rgba(0,0,0, 0.54);
  font-size: 1rem;
  line-height: 18px;
  overflow: hidden;
  padding: 16px 16px;
  width: 90%;
}
.mdl-card__supporting-text.mdl-card--border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card__actions {
  font-size: 16px;
  line-height: normal;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  padding: 8px;
  box-sizing: border-box;
}
.mdl-card__actions.mdl-card--border {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mdl-card--expand {
  flex-grow: 1;
}

.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-checkbox {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
}
.mdl-checkbox.is-upgraded {
  padding-left: 24px;
}

.mdl-checkbox__input {
  line-height: 24px;
}
.mdl-checkbox.is-upgraded .mdl-checkbox__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-checkbox__box-outline {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 2px;
  z-index: 2;
}
.mdl-checkbox.is-checked .mdl-checkbox__box-outline {
  border: 2px solid rgb(63,81,181);
}
fieldset[disabled] .mdl-checkbox .mdl-checkbox__box-outline, .mdl-checkbox.is-disabled .mdl-checkbox__box-outline {
  border: 2px solid rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-checkbox__focus-helper {
  position: absolute;
  top: 3px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
}
.mdl-checkbox.is-focused .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.mdl-checkbox.is-focused.is-checked .mdl-checkbox__focus-helper {
  box-shadow: 0 0 0px 8px rgba(63,81,181, 0.26);
  background-color: rgba(63,81,181, 0.26);
}

.mdl-checkbox__tick-outline {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mask: url("/images/tick-mask.svg?embed");
  background: transparent;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background;
}
.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background: rgb(63,81,181) url("/images/tick.svg?embed");
}
fieldset[disabled] .mdl-checkbox.is-checked .mdl-checkbox__tick-outline, .mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
  background: rgba(0,0,0, 0.26) url("/images/tick.svg?embed");
}

.mdl-checkbox__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
fieldset[disabled] .mdl-checkbox .mdl-checkbox__label, .mdl-checkbox.is-disabled .mdl-checkbox__label {
  color: rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-checkbox__ripple-container {
  position: absolute;
  z-index: 2;
  top: -6px;
  left: -10px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}
.mdl-checkbox__ripple-container .mdl-ripple {
  background: rgb(63,81,181);
}
fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container, .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container {
  cursor: auto;
}
fieldset[disabled] .mdl-checkbox .mdl-checkbox__ripple-container .mdl-ripple, .mdl-checkbox.is-disabled .mdl-checkbox__ripple-container .mdl-ripple {
  background: transparent;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-chip {
  height: 32px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 32px;
  padding: 0 12px;
  border: 0;
  border-radius: 16px;
  background-color: rgb(222, 222, 222);
  display: inline-block;
  color: rgba(0,0,0, 0.87);
  margin: 2px 0;
  font-size: 0;
  white-space: nowrap;
}
.mdl-chip__text {
  font-size: 13px;
  vertical-align: middle;
  display: inline-block;
}
.mdl-chip__action {
  height: 24px;
  width: 24px;
  background: transparent;
  opacity: 0.54;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  margin: 0 0 0 4px;
  font-size: 13px;
  text-decoration: none;
  color: rgba(0,0,0, 0.87);
  border: none;
  outline: none;
  overflow: hidden;
}
.mdl-chip__contact {
  height: 32px;
  width: 32px;
  border-radius: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  overflow: hidden;
  text-align: center;
  font-size: 18px;
  line-height: 32px;
}
.mdl-chip:focus {
  outline: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mdl-chip:active {
  background-color: rgb(214, 214, 214);
}
.mdl-chip--deletable {
  padding-right: 4px;
}
.mdl-chip--contact {
  padding-left: 0;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-data-table {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
  white-space: nowrap;
  font-size: 13px;
  background-color: rgb(255,255,255);
}
.mdl-data-table thead {
  padding-bottom: 3px;
}
.mdl-data-table thead .mdl-data-table__select {
  margin-top: 0;
}
.mdl-data-table tbody tr {
  position: relative;
  height: 48px;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: background-color;
}
.mdl-data-table tbody tr.is-selected {
  background-color: #e0e0e0;
}
.mdl-data-table tbody tr:hover {
  background-color: #eeeeee;
}
.mdl-data-table td, .mdl-data-table th {
  padding: 0 18px 12px 18px;
  text-align: right;
}
.mdl-data-table td:first-of-type, .mdl-data-table th:first-of-type {
  padding-left: 24px;
}
.mdl-data-table td:last-of-type, .mdl-data-table th:last-of-type {
  padding-right: 24px;
}
.mdl-data-table td {
  position: relative;
  vertical-align: middle;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 12px;
  box-sizing: border-box;
}
.mdl-data-table td .mdl-data-table__select {
  vertical-align: middle;
}
.mdl-data-table th {
  position: relative;
  vertical-align: bottom;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0;
  height: 48px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 8px;
  box-sizing: border-box;
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending, .mdl-data-table th.mdl-data-table__header--sorted-descending {
  color: rgba(0, 0, 0, 0.87);
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  word-wrap: normal;
  font-feature-settings: "liga";
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  content: "\e5d8";
  margin-right: 5px;
  vertical-align: sub;
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover {
  cursor: pointer;
}
.mdl-data-table th.mdl-data-table__header--sorted-ascending:hover:before, .mdl-data-table th.mdl-data-table__header--sorted-descending:hover:before {
  color: rgba(0, 0, 0, 0.26);
}
.mdl-data-table th.mdl-data-table__header--sorted-descending:before {
  content: "\e5db";
}

.mdl-data-table__select {
  width: 16px;
}

.mdl-data-table__cell--non-numeric.mdl-data-table__cell--non-numeric {
  text-align: left;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
  width: 280px;
}
.mdl-dialog__title {
  padding: 24px 24px 0;
  margin: 0;
  font-size: 2.5rem;
}
.mdl-dialog__actions {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.mdl-dialog__actions > * {
  margin-right: 8px;
  height: 36px;
}
.mdl-dialog__actions > *:first-child {
  margin-right: 0;
}
.mdl-dialog__actions--full-width {
  padding: 0 0 8px 0;
}
.mdl-dialog__actions--full-width > * {
  height: 48px;
  flex: 0 0 100%;
  padding-right: 16px;
  margin-right: 0;
  text-align: right;
}
.mdl-dialog__content {
  padding: 20px 24px 24px 24px;
  color: rgba(0,0,0, 0.54);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-mega-footer {
  padding: 16px 40px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66);
}

.mdl-mega-footer--top-section:after,
.mdl-mega-footer--middle-section:after,
.mdl-mega-footer--bottom-section:after,
.mdl-mega-footer__top-section:after,
.mdl-mega-footer__middle-section:after,
.mdl-mega-footer__bottom-section:after {
  content: "";
  display: block;
  clear: both;
}

.mdl-mega-footer--left-section,
.mdl-mega-footer__left-section {
  margin-bottom: 16px;
}

.mdl-mega-footer--right-section,
.mdl-mega-footer__right-section {
  margin-bottom: 16px;
}

.mdl-mega-footer--right-section a,
.mdl-mega-footer__right-section a {
  display: block;
  margin-bottom: 16px;
  color: inherit;
  text-decoration: none;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--left-section,
  .mdl-mega-footer__left-section {
    float: left;
  }
  .mdl-mega-footer--right-section,
  .mdl-mega-footer__right-section {
    float: right;
  }
  .mdl-mega-footer--right-section a,
  .mdl-mega-footer__right-section a {
    display: inline-block;
    margin-left: 16px;
    line-height: 36px;
    vertical-align: middle;
  }
}
.mdl-mega-footer--social-btn,
.mdl-mega-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none;
}

.mdl-mega-footer--drop-down-section,
.mdl-mega-footer__drop-down-section {
  display: block;
  position: relative;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer__drop-down-section {
    width: 33%;
  }
  .mdl-mega-footer--drop-down-section:nth-child(1),
  .mdl-mega-footer--drop-down-section:nth-child(2),
  .mdl-mega-footer__drop-down-section:nth-child(1),
  .mdl-mega-footer__drop-down-section:nth-child(2) {
    float: left;
  }
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(3) {
    float: right;
  }
  .mdl-mega-footer--drop-down-section:nth-child(3):after,
  .mdl-mega-footer__drop-down-section:nth-child(3):after {
    clear: right;
  }
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    clear: right;
    float: right;
  }
  .mdl-mega-footer--middle-section:after,
  .mdl-mega-footer__middle-section:after {
    content: "";
    display: block;
    clear: both;
  }
  .mdl-mega-footer--bottom-section,
  .mdl-mega-footer__bottom-section {
    padding-top: 0;
  }
}
@media screen and (min-width: 1024px) {
  .mdl-mega-footer--drop-down-section,
  .mdl-mega-footer--drop-down-section:nth-child(3),
  .mdl-mega-footer--drop-down-section:nth-child(4),
  .mdl-mega-footer__drop-down-section,
  .mdl-mega-footer__drop-down-section:nth-child(3),
  .mdl-mega-footer__drop-down-section:nth-child(4) {
    width: 24%;
    float: left;
  }
}
.mdl-mega-footer--heading-checkbox,
.mdl-mega-footer__heading-checkbox {
  position: absolute;
  width: 100%;
  height: 55.8px;
  padding: 32px;
  margin: 0;
  margin-top: -16px;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}
.mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
  font-family: "Material Icons";
  content: "\e5ce";
}

.mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list, .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list {
  display: none;
}
.mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
.mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
  font-family: "Material Icons";
  content: "\e5cf";
}

.mdl-mega-footer--heading,
.mdl-mega-footer__heading {
  position: relative;
  width: 100%;
  padding-right: 39.8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 23.8px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: rgb(224,224,224);
}

.mdl-mega-footer--heading:after,
.mdl-mega-footer__heading:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 23.8px;
  height: 23.8px;
  background-size: cover;
}

.mdl-mega-footer--link-list,
.mdl-mega-footer__link-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}
.mdl-mega-footer--link-list:after,
.mdl-mega-footer__link-list:after {
  clear: both;
  display: block;
  content: "";
}

.mdl-mega-footer--link-list li,
.mdl-mega-footer__link-list li {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  line-height: 20px;
}

.mdl-mega-footer--link-list a,
.mdl-mega-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

@media screen and (min-width: 760px) {
  .mdl-mega-footer--heading-checkbox,
  .mdl-mega-footer__heading-checkbox {
    display: none;
  }
  .mdl-mega-footer--heading-checkbox + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox + .mdl-mega-footer__heading:after {
    content: "";
  }
  .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer--link-list, .mdl-mega-footer--heading-checkbox:checked ~ .mdl-mega-footer__link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer--link-list,
  .mdl-mega-footer__heading-checkbox:checked ~ .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading + .mdl-mega-footer__link-list,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading + .mdl-mega-footer--link-list {
    display: block;
  }
  .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer--heading:after, .mdl-mega-footer--heading-checkbox:checked + .mdl-mega-footer__heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer--heading:after,
  .mdl-mega-footer__heading-checkbox:checked + .mdl-mega-footer__heading:after {
    content: "";
  }
}
.mdl-mega-footer--bottom-section,
.mdl-mega-footer__bottom-section {
  padding-top: 16px;
  margin-bottom: 16px;
}

.mdl-logo {
  margin-bottom: 16px;
  color: white;
}

.mdl-mega-footer--bottom-section .mdl-mega-footer--link-list li,
.mdl-mega-footer__bottom-section .mdl-mega-footer__link-list li {
  float: left;
  margin-bottom: 0;
  margin-right: 16px;
}

@media screen and (min-width: 760px) {
  .mdl-logo {
    float: left;
    margin-bottom: 0;
    margin-right: 16px;
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-mini-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 32px 16px;
  color: rgb(158,158,158);
  background-color: rgb(66,66,66);
}
.mdl-mini-footer:after {
  content: "";
  display: block;
}
.mdl-mini-footer .mdl-logo {
  line-height: 36px;
}

.mdl-mini-footer--link-list,
.mdl-mini-footer__link-list {
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.mdl-mini-footer--link-list li,
.mdl-mini-footer__link-list li {
  margin-bottom: 0;
  margin-right: 16px;
}
@media screen and (min-width: 760px) {
  .mdl-mini-footer--link-list li,
  .mdl-mini-footer__link-list li {
    line-height: 36px;
  }
}
.mdl-mini-footer--link-list a,
.mdl-mini-footer__link-list a {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
}

.mdl-mini-footer--left-section,
.mdl-mini-footer__left-section {
  display: inline-block;
  order: 0;
}

.mdl-mini-footer--right-section,
.mdl-mini-footer__right-section {
  display: inline-block;
  order: 1;
}

.mdl-mini-footer--social-btn,
.mdl-mini-footer__social-btn {
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0;
  background-color: rgb(158,158,158);
  border: none;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-icon-toggle {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0;
}

.mdl-icon-toggle__input {
  line-height: 32px;
}
.mdl-icon-toggle.is-upgraded .mdl-icon-toggle__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-icon-toggle__label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 32px;
  width: 32px;
  min-width: 32px;
  color: rgb(97,97,97);
  border-radius: 50%;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  background-color: transparent;
  will-change: background-color;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-icon-toggle__label.material-icons {
  line-height: 32px;
  font-size: 24px;
}
.mdl-icon-toggle.is-checked .mdl-icon-toggle__label {
  color: rgb(63,81,181);
}
.mdl-icon-toggle.is-disabled .mdl-icon-toggle__label {
  color: rgba(0,0,0, 0.26);
  cursor: auto;
  transition: none;
}
.mdl-icon-toggle.is-focused .mdl-icon-toggle__label {
  background-color: rgba(0,0,0, 0.12);
}
.mdl-icon-toggle.is-focused.is-checked .mdl-icon-toggle__label {
  background-color: rgba(63,81,181, 0.26);
}

.mdl-icon-toggle__ripple-container {
  position: absolute;
  z-index: 2;
  top: -2px;
  left: -2px;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}
.mdl-icon-toggle__ripple-container .mdl-ripple {
  background: rgb(97,97,97);
}
.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container {
  cursor: auto;
}
.mdl-icon-toggle.is-disabled .mdl-icon-toggle__ripple-container .mdl-ripple {
  background: transparent;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-list {
  display: block;
  padding: 8px 0;
  list-style: none;
}

.mdl-list__item {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04em;
  line-height: 1;
  display: flex;
  min-height: 48px;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 16px;
  cursor: default;
  color: rgba(0,0,0, 0.87);
  overflow: hidden;
}
.mdl-list__item .mdl-list__item-primary-content {
  order: 0;
  flex-grow: 2;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-icon {
  margin-right: 32px;
}
.mdl-list__item .mdl-list__item-primary-content .mdl-list__item-avatar {
  margin-right: 16px;
}
.mdl-list__item .mdl-list__item-secondary-content {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-left: 16px;
}
.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-action label {
  display: inline;
}
.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-secondary-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  color: rgba(0,0,0, 0.54);
}
.mdl-list__item .mdl-list__item-secondary-content .mdl-list__item-sub-header {
  padding: 0 0 0 16px;
}

.mdl-list__item-icon,
.mdl-list__item-icon.material-icons {
  height: 24px;
  width: 24px;
  font-size: 24px;
  box-sizing: border-box;
  color: rgb(117,117,117);
}

.mdl-list__item-avatar,
.mdl-list__item-avatar.material-icons {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: rgb(117,117,117);
  font-size: 40px;
  color: white;
}

.mdl-list__item--two-line {
  height: 72px;
}
.mdl-list__item--two-line .mdl-list__item-primary-content {
  height: 36px;
  line-height: 20px;
  display: block;
}
.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-avatar {
  float: left;
}
.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-icon {
  float: left;
  margin-top: 6px;
}
.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-secondary-content {
  height: 36px;
}
.mdl-list__item--two-line .mdl-list__item-primary-content .mdl-list__item-sub-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  line-height: 18px;
  color: rgba(0,0,0, 0.54);
  display: block;
  padding: 0;
}

.mdl-list__item--three-line {
  height: 88px;
}
.mdl-list__item--three-line .mdl-list__item-primary-content {
  height: 52px;
  line-height: 20px;
  display: block;
}
.mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-avatar, .mdl-list__item--three-line .mdl-list__item-primary-content .mdl-list__item-icon {
  float: left;
}
.mdl-list__item--three-line .mdl-list__item-secondary-content {
  height: 52px;
}
.mdl-list__item--three-line .mdl-list__item-text-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  line-height: 18px;
  height: 52px;
  color: rgba(0,0,0, 0.54);
  display: block;
  padding: 0;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-menu__container {
  display: block;
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  overflow: visible;
  height: 0;
  width: 0;
  visibility: hidden;
  z-index: -1;
}
.mdl-menu__container.is-visible, .mdl-menu__container.is-animating {
  z-index: 999;
  visibility: visible;
}

.mdl-menu__outline {
  display: block;
  background: rgb(255,255,255);
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  will-change: transform;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: -1;
}
.mdl-menu__container.is-visible .mdl-menu__outline {
  opacity: 1;
  transform: scale(1);
  z-index: 999;
}
.mdl-menu__outline.mdl-menu--bottom-right {
  transform-origin: 100% 0;
}
.mdl-menu__outline.mdl-menu--top-left {
  transform-origin: 0 100%;
}
.mdl-menu__outline.mdl-menu--top-right {
  transform-origin: 100% 100%;
}

.mdl-menu {
  position: absolute;
  list-style: none;
  top: 0;
  left: 0;
  height: auto;
  width: auto;
  min-width: 124px;
  padding: 8px 0;
  margin: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  z-index: -1;
}
.mdl-menu__container.is-visible .mdl-menu {
  opacity: 1;
  z-index: 999;
}
.mdl-menu.is-animating {
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1), clip 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-menu.mdl-menu--bottom-right {
  left: auto;
  right: 0;
}
.mdl-menu.mdl-menu--top-left {
  top: auto;
  bottom: 0;
}
.mdl-menu.mdl-menu--top-right {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}
.mdl-menu.mdl-menu--unaligned {
  top: auto;
  left: auto;
}

.mdl-menu__item {
  display: block;
  border: none;
  color: rgba(0,0,0, 0.87);
  background-color: transparent;
  text-align: left;
  margin: 0;
  padding: 0 16px;
  outline-color: rgb(189,189,189);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
}
.mdl-menu__container.is-visible .mdl-menu__item {
  opacity: 1;
}
.mdl-menu__item::-moz-focus-inner {
  border: 0;
}
.mdl-menu__item--full-bleed-divider {
  border-bottom: 1px solid rgba(0,0,0, 0.12);
}
.mdl-menu__item[disabled], .mdl-menu__item[data-mdl-disabled] {
  color: rgb(189,189,189);
  background-color: transparent;
  cursor: auto;
}
.mdl-menu__item[disabled]:hover, .mdl-menu__item[data-mdl-disabled]:hover {
  background-color: transparent;
}
.mdl-menu__item[disabled]:focus, .mdl-menu__item[data-mdl-disabled]:focus {
  background-color: transparent;
}
.mdl-menu__item[disabled] .mdl-ripple, .mdl-menu__item[data-mdl-disabled] .mdl-ripple {
  background: transparent;
}
.mdl-menu__item:hover {
  background-color: rgb(238,238,238);
}
.mdl-menu__item:focus {
  outline: none;
  background-color: rgb(238,238,238);
}
.mdl-menu__item:active {
  background-color: rgb(224,224,224);
}

.mdl-menu__item--ripple-container {
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  overflow: hidden;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-progress {
  display: block;
  position: relative;
  height: 4px;
  width: 500px;
  max-width: 100%;
}

.mdl-progress > .bar {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0%;
  transition: width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.mdl-progress > .progressbar {
  background-color: rgb(63,81,181);
  z-index: 1;
  left: 0;
}

.mdl-progress > .bufferbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(63,81,181), rgb(63,81,181));
  z-index: 0;
  left: 0;
}

.mdl-progress > .auxbar {
  right: 0;
}

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar,
  .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    background-image: linear-gradient(to right, rgba(255,255,255, 0.7), rgba(255,255,255, 0.7)), linear-gradient(to right, rgb(63,81,181), rgb(63,81,181));
    mask: url("/images/buffer.svg?embed");
  }
}
.mdl-progress:not(.mdl-progress--indeterminate) > .auxbar,
.mdl-progress:not(.mdl-progress__indeterminate) > .auxbar {
  background-image: linear-gradient(to right, rgba(255,255,255, 0.9), rgba(255,255,255, 0.9)), linear-gradient(to right, rgb(63,81,181), rgb(63,81,181));
}

.mdl-progress.mdl-progress--indeterminate > .bar1,
.mdl-progress.mdl-progress__indeterminate > .bar1 {
  background-color: rgb(63,81,181);
  animation-name: indeterminate1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.mdl-progress.mdl-progress--indeterminate > .bar3,
.mdl-progress.mdl-progress__indeterminate > .bar3 {
  background-image: none;
  background-color: rgb(63,81,181);
  animation-name: indeterminate2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes indeterminate1 {
  0% {
    left: 0%;
    width: 0%;
  }
  50% {
    left: 25%;
    width: 75%;
  }
  75% {
    left: 100%;
    width: 0%;
  }
}
@keyframes indeterminate2 {
  0% {
    left: 0%;
    width: 0%;
  }
  50% {
    left: 0%;
    width: 0%;
  }
  75% {
    left: 0%;
    width: 25%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-navigation {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.mdl-navigation__link {
  color: rgb(66,66,66);
  text-decoration: none;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}
.mdl-navigation__link .material-icons {
  vertical-align: middle;
}

.mdl-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none;
}

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none;
}

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdl-layout__title,
.mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box;
}

.mdl-layout-spacer {
  flex-grow: 1;
}

.mdl-layout__drawer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-right: 1px solid rgb(224,224,224);
  background: rgb(250,250,250);
  transform: translateX(-250px);
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  color: rgb(66,66,66);
  overflow: visible;
  overflow-y: auto;
  z-index: 5;
}
.mdl-layout__drawer.is-visible {
  transform: translateX(0);
}
.mdl-layout__drawer.is-visible ~ .mdl-layout__content.mdl-layout__content {
  overflow: hidden;
}
.mdl-layout__drawer > * {
  flex-shrink: 0;
}
.mdl-layout__drawer > .mdl-layout__title, .mdl-layout__drawer > .mdl-layout-title {
  line-height: 64px;
  padding-left: 40px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__drawer > .mdl-layout__title, .mdl-layout__drawer > .mdl-layout-title {
    line-height: 56px;
    padding-left: 16px;
  }
}
.mdl-layout__drawer .mdl-navigation {
  flex-direction: column;
  align-items: stretch;
  padding-top: 16px;
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  display: block;
  flex-shrink: 0;
  padding: 16px 40px;
  margin: 0;
  color: #757575;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 16px 16px;
  }
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: rgb(224,224,224);
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
  background-color: rgb(224,224,224);
  color: rgb(0,0,0);
}
@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer {
    transform: translateX(0);
  }
}

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 0;
  color: rgb(255,255,255);
  z-index: 4;
}
.mdl-layout__header .mdl-layout__drawer-button {
  position: absolute;
  color: rgb(255,255,255);
  background-color: inherit;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header .mdl-layout__drawer-button {
    margin: 4px;
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__drawer-button {
    margin: 4px;
    color: rgba(0, 0, 0, 0.5);
  }
}
@media screen and (min-width: 1025px) {
  .mdl-layout__drawer-button {
    line-height: 54px;
  }
  .mdl-layout--no-desktop-drawer-button .mdl-layout__drawer-button, .mdl-layout--fixed-drawer > .mdl-layout__drawer-button, .mdl-layout--no-drawer-button .mdl-layout__drawer-button {
    display: none;
  }
}

.mdl-layout__header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: rgb(63,81,181);
  color: rgb(255,255,255);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    min-height: 56px;
  }
}
.mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen) > .mdl-layout__header {
  margin-left: 240px;
  width: calc(100% - 240px);
}
@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
    padding-left: 40px;
  }
}
.mdl-layout__header > .mdl-layout-icon {
  position: absolute;
  left: 40px;
  top: 16px;
  height: 32px;
  width: 32px;
  overflow: hidden;
  z-index: 3;
  display: block;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header > .mdl-layout-icon {
    left: 16px;
    top: 12px;
  }
}
.mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
  display: none;
}
.mdl-layout__header.is-compact {
  max-height: 64px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact {
    max-height: 56px;
  }
}
.mdl-layout__header.is-compact.has-tabs {
  height: 112px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact.has-tabs {
    min-height: 104px;
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    display: none;
  }
  .mdl-layout--fixed-header > .mdl-layout__header {
    display: flex;
  }
}

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none;
}

.mdl-layout__header--seamed {
  box-shadow: none;
}

.mdl-layout__header--scroll {
  box-shadow: none;
}

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden;
}
.mdl-layout__header--waterfall.is-casting-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mdl-layout__header--waterfall.mdl-layout__header--waterfall-hide-top {
  justify-content: flex-end;
}

.mdl-layout__header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  align-self: stretch;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px;
}
.mdl-layout--no-drawer-button .mdl-layout__header-row {
  padding-left: 40px;
}
@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__header-row {
    padding-left: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header-row {
    height: 56px;
    padding: 0 16px 0 72px;
  }
  .mdl-layout--no-drawer-button .mdl-layout__header-row {
    padding-left: 16px;
  }
}
.mdl-layout__header-row > * {
  flex-shrink: 0;
}
.mdl-layout__header--scroll .mdl-layout__header-row {
  width: 100%;
}
.mdl-layout__header-row .mdl-navigation {
  margin: 0;
  padding: 0;
  height: 64px;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation {
    height: 56px;
  }
}
.mdl-layout__header-row .mdl-navigation__link {
  display: block;
  color: rgb(255,255,255);
  line-height: 64px;
  padding: 0 24px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation__link {
    line-height: 56px;
    padding: 0 16px;
  }
}

.mdl-layout__obfuscator {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-layout__obfuscator.is-visible {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}
@supports (pointer-events: auto) {
  .mdl-layout__obfuscator {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }
  .mdl-layout__obfuscator.is-visible {
    pointer-events: auto;
    opacity: 1;
  }
}

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  position: relative;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.mdl-layout--fixed-drawer > .mdl-layout__content {
  margin-left: 240px;
}
.mdl-layout__container.has-scrolling-header .mdl-layout__content {
  overflow: visible;
}
@media screen and (max-width: 1024px) {
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 0;
  }
  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% -
        112px);
  padding: 0 0 0 56px;
  display: flex;
  background-color: rgb(63,81,181);
  overflow-y: hidden;
  overflow-x: scroll;
}
.mdl-layout__tab-bar::-webkit-scrollbar {
  display: none;
}
.mdl-layout--no-drawer-button .mdl-layout__tab-bar {
  padding-left: 16px;
  width: calc(100% -
          32px);
}
@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar {
    padding-left: 16px;
    width: calc(100% -
            32px);
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar {
    width: calc(100% -
          60px);
    padding: 0 0 0 60px;
  }
  .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
    width: calc(100% -
            8px);
    padding-left: 4px;
  }
}
.mdl-layout--fixed-tabs .mdl-layout__tab-bar {
  padding: 0;
  overflow: hidden;
  width: 100%;
}

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}
.mdl-layout__container > .mdl-layout__tab-bar-container {
  position: absolute;
  top: 0;
  left: 0;
}

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: rgb(63,81,181);
  color: transparent;
  cursor: pointer;
  user-select: none;
}
.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button, .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button {
  width: 16px;
}
.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button .material-icons, .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button .material-icons {
  position: relative;
  left: -4px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar-button {
    width: 60px;
  }
}
.mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
  display: none;
}
.mdl-layout__tab-bar-button .material-icons {
  line-height: 48px;
}
.mdl-layout__tab-bar-button.is-active {
  color: rgb(255,255,255);
}

.mdl-layout__tab-bar-left-button {
  left: 0;
}

.mdl-layout__tab-bar-right-button {
  right: 0;
}

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.6);
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__tab {
    padding: 0 12px 0 12px;
  }
}
.mdl-layout--fixed-tabs .mdl-layout__tab {
  float: none;
  flex-grow: 1;
  padding: 0;
}
.mdl-layout.is-upgraded .mdl-layout__tab.is-active {
  color: rgb(255,255,255);
}
.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0;
  left: 0;
  position: absolute;
  background: rgb(255,64,129);
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}
.mdl-layout__tab .mdl-layout__tab-ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}
.mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
  background-color: rgb(255,255,255);
}

.mdl-layout__tab-panel {
  display: block;
}
.mdl-layout.is-upgraded .mdl-layout__tab-panel {
  display: none;
}
.mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
  display: block;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-radio {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  height: 24px;
  margin: 0;
  padding-left: 0;
}
.mdl-radio.is-upgraded {
  padding-left: 24px;
}

.mdl-radio__button {
  line-height: 24px;
}
.mdl-radio.is-upgraded .mdl-radio__button {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-radio__outer-circle {
  position: absolute;
  top: 4px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
  border: 2px solid rgba(0,0,0, 0.54);
  border-radius: 50%;
  z-index: 2;
}
.mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid rgb(63,81,181);
}
.mdl-radio__outer-circle fieldset[disabled] .mdl-radio, .mdl-radio.is-disabled .mdl-radio__outer-circle {
  border: 2px solid rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-radio__inner-circle {
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 8px;
  left: 4px;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  cursor: pointer;
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  transform: scale(0, 0);
  border-radius: 50%;
  background: rgb(63,81,181);
}
.mdl-radio.is-checked .mdl-radio__inner-circle {
  transform: scale(1, 1);
}
fieldset[disabled] .mdl-radio .mdl-radio__inner-circle, .mdl-radio.is-disabled .mdl-radio__inner-circle {
  background: rgba(0,0,0, 0.26);
  cursor: auto;
}
.mdl-radio.is-focused .mdl-radio__inner-circle {
  box-shadow: 0 0 0px 10px rgba(0, 0, 0, 0.1);
}

.mdl-radio__label {
  cursor: pointer;
}
fieldset[disabled] .mdl-radio .mdl-radio__label, .mdl-radio.is-disabled .mdl-radio__label {
  color: rgba(0,0,0, 0.26);
  cursor: auto;
}

.mdl-radio__ripple-container {
  position: absolute;
  z-index: 2;
  top: -9px;
  left: -13px;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
}
.mdl-radio__ripple-container .mdl-ripple {
  background: rgb(63,81,181);
}
fieldset[disabled] .mdl-radio .mdl-radio__ripple-container, .mdl-radio.is-disabled .mdl-radio__ripple-container {
  cursor: auto;
}
fieldset[disabled] .mdl-radio .mdl-radio__ripple-container .mdl-ripple, .mdl-radio.is-disabled .mdl-radio__ripple-container .mdl-ripple {
  background: transparent;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
_:-ms-input-placeholder, :root .mdl-slider.mdl-slider.is-upgraded {
  -ms-appearance: none;
  height: 32px;
  margin: 0;
}

.mdl-slider {
  width: calc(100% - 40px);
  margin: 0 20px;
}
.mdl-slider.is-upgraded {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2px;
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  outline: 0;
  padding: 0;
  color: rgb(63,81,181);
  align-self: center;
  z-index: 1;
  cursor: pointer;
  /**************************** Tracks ****************************/
  /**************************** Thumbs ****************************/
  /**************************** 0-value ****************************/
  /**************************** Disabled ****************************/
}
.mdl-slider.is-upgraded::-moz-focus-outer {
  border: 0;
}
.mdl-slider.is-upgraded::-ms-tooltip {
  display: none;
}
.mdl-slider.is-upgraded::-webkit-slider-runnable-track {
  background: transparent;
}
.mdl-slider.is-upgraded::-moz-range-track {
  background: transparent;
  border: none;
}
.mdl-slider.is-upgraded::-ms-track {
  background: none;
  color: transparent;
  height: 2px;
  width: 100%;
  border: none;
}
.mdl-slider.is-upgraded::-ms-fill-lower {
  padding: 0;
  background: linear-gradient(to right, transparent, transparent 16px, rgb(63,81,181) 16px, rgb(63,81,181) 0);
}
.mdl-slider.is-upgraded::-ms-fill-upper {
  padding: 0;
  background: linear-gradient(to left, transparent, transparent 16px, rgba(0,0,0, 0.26) 16px, rgba(0,0,0, 0.26) 0);
}
.mdl-slider.is-upgraded::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  background: rgb(63,81,181);
  border: none;
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), border 0.18s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-slider.is-upgraded::-moz-range-thumb {
  -moz-appearance: none;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
  border-radius: 50%;
  background-image: none;
  background: rgb(63,81,181);
  border: none;
}
.mdl-slider.is-upgraded:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(63,81,181, 0.26);
}
.mdl-slider.is-upgraded:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(63,81,181, 0.26);
}
.mdl-slider.is-upgraded:active::-webkit-slider-thumb {
  background-image: none;
  background: rgb(63,81,181);
  transform: scale(1.5);
}
.mdl-slider.is-upgraded:active::-moz-range-thumb {
  background-image: none;
  background: rgb(63,81,181);
  transform: scale(1.5);
}
.mdl-slider.is-upgraded::-ms-thumb {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  background: rgb(63,81,181);
  transform: scale(0.375);
  transition: transform 0.18s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-slider.is-upgraded:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, rgb(63,81,181) 0%, rgb(63,81,181) 37.5%, rgba(63,81,181, 0.26) 37.5%, rgba(63,81,181, 0.26) 100%);
  transform: scale(1);
}
.mdl-slider.is-upgraded:active::-ms-thumb {
  background: rgb(63,81,181);
  transform: scale(0.5625);
}
.mdl-slider.is-upgraded.is-lowest-value::-webkit-slider-thumb {
  border: 2px solid rgba(0,0,0, 0.26);
  background: transparent;
}
.mdl-slider.is-upgraded.is-lowest-value::-moz-range-thumb {
  border: 2px solid rgba(0,0,0, 0.26);
  background: transparent;
}
.mdl-slider.is-upgraded.is-lowest-value + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}
.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-webkit-slider-thumb {
  box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
  background: rgba(0,0,0, 0.12);
}
.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-moz-range-thumb {
  box-shadow: 0 0 0 10px rgba(0,0,0, 0.12);
  background: rgba(0,0,0, 0.12);
}
.mdl-slider.is-upgraded.is-lowest-value:active::-webkit-slider-thumb {
  border: 1.6px solid rgba(0,0,0, 0.26);
  transform: scale(1.5);
}
.mdl-slider.is-upgraded.is-lowest-value:active + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 9px;
}
.mdl-slider.is-upgraded.is-lowest-value:active::-moz-range-thumb {
  border: 1.5px solid rgba(0,0,0, 0.26);
  transform: scale(1.5);
}
.mdl-slider.is-upgraded.is-lowest-value::-ms-thumb {
  background: radial-gradient(circle closest-side, transparent 0%, transparent 66.67%, rgba(0,0,0, 0.26) 66.67%, rgba(0,0,0, 0.26) 100%);
}
.mdl-slider.is-upgraded.is-lowest-value:focus:not(:active)::-ms-thumb {
  background: radial-gradient(circle closest-side, rgba(0,0,0, 0.12) 0%, rgba(0,0,0, 0.12) 25%, rgba(0,0,0, 0.26) 25%, rgba(0,0,0, 0.26) 37.5%, rgba(0,0,0, 0.12) 37.5%, rgba(0,0,0, 0.12) 100%);
  transform: scale(1);
}
.mdl-slider.is-upgraded.is-lowest-value:active::-ms-thumb {
  transform: scale(0.5625);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 77.78%, rgba(0,0,0, 0.26) 77.78%, rgba(0,0,0, 0.26) 100%);
}
.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-lower {
  background: transparent;
}
.mdl-slider.is-upgraded.is-lowest-value::-ms-fill-upper {
  margin-left: 6px;
}
.mdl-slider.is-upgraded.is-lowest-value:active::-ms-fill-upper {
  margin-left: 9px;
}
.mdl-slider.is-upgraded:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded:disabled::-webkit-slider-thumb {
  transform: scale(0.667);
  background: rgba(0,0,0, 0.26);
}
.mdl-slider.is-upgraded:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded:disabled::-moz-range-thumb {
  transform: scale(0.667);
  background: rgba(0,0,0, 0.26);
}
.mdl-slider.is-upgraded:disabled + .mdl-slider__background-flex > .mdl-slider__background-lower {
  background-color: rgba(0,0,0, 0.26);
  left: -6px;
}
.mdl-slider.is-upgraded:disabled + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}
.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-webkit-slider-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-webkit-slider-thumb {
  border: 3px solid rgba(0,0,0, 0.26);
  background: transparent;
  transform: scale(0.667);
}
.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-moz-range-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-moz-range-thumb {
  border: 3px solid rgba(0,0,0, 0.26);
  background: transparent;
  transform: scale(0.667);
}
.mdl-slider.is-upgraded.is-lowest-value:disabled:active + .mdl-slider__background-flex > .mdl-slider__background-upper {
  left: 6px;
}
.mdl-slider.is-upgraded:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded:disabled:active::-ms-thumb, .mdl-slider.is-upgraded:disabled::-ms-thumb {
  transform: scale(0.25);
  background: rgba(0,0,0, 0.26);
}
.mdl-slider.is-upgraded.is-lowest-value:disabled:focus::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-thumb, .mdl-slider.is-upgraded.is-lowest-value:disabled::-ms-thumb {
  transform: scale(0.25);
  background: radial-gradient(circle closest-side, transparent 0%, transparent 50%, rgba(0,0,0, 0.26) 50%, rgba(0,0,0, 0.26) 100%);
}
.mdl-slider.is-upgraded:disabled::-ms-fill-lower {
  margin-right: 6px;
  background: linear-gradient(to right, transparent, transparent 25px, rgba(0,0,0, 0.26) 25px, rgba(0,0,0, 0.26) 0);
}
.mdl-slider.is-upgraded:disabled::-ms-fill-upper {
  margin-left: 6px;
}
.mdl-slider.is-upgraded.is-lowest-value:disabled:active::-ms-fill-upper {
  margin-left: 6px;
}

.mdl-slider__ie-container {
  height: 18px;
  overflow: visible;
  border: none;
  margin: none;
  padding: none;
}

.mdl-slider__container {
  height: 18px;
  position: relative;
  background: none;
  display: flex;
  flex-direction: row;
}

.mdl-slider__background-flex {
  background: transparent;
  position: absolute;
  height: 2px;
  width: calc(100% - 52px);
  top: 50%;
  left: 0;
  margin: 0 26px;
  display: flex;
  overflow: hidden;
  border: 0;
  padding: 0;
  transform: translate(0, -1px);
}

.mdl-slider__background-lower {
  background: rgb(63,81,181);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0;
}

.mdl-slider__background-upper {
  background: rgba(0,0,0, 0.26);
  flex: 0;
  position: relative;
  border: 0;
  padding: 0;
  transition: left 0.18s cubic-bezier(0.4, 0, 0.2, 1);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-snackbar {
  position: fixed;
  bottom: 0;
  left: 50%;
  cursor: default;
  background-color: #323232;
  z-index: 3;
  display: block;
  display: flex;
  justify-content: space-between;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  will-change: transform;
  transform: translate(0, 80px);
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
  pointer-events: none;
}
@media (max-width: 479px) {
  .mdl-snackbar {
    width: 100%;
    left: 0;
    min-height: 48px;
    max-height: 80px;
  }
}
@media (min-width: 480px) {
  .mdl-snackbar {
    min-width: 288px;
    max-width: 568px;
    border-radius: 2px;
    transform: translate(-50%, 80px);
  }
}
.mdl-snackbar--active {
  transform: translate(0, 0);
  pointer-events: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
}
@media (min-width: 480px) {
  .mdl-snackbar--active {
    transform: translate(-50%, 0);
  }
}
.mdl-snackbar__text {
  padding: 14px 12px 14px 24px;
  vertical-align: middle;
  color: white;
  float: left;
}
.mdl-snackbar__action {
  background: transparent;
  border: none;
  color: rgb(255,64,129);
  float: right;
  text-transform: uppercase;
  padding: 14px 24px 14px 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  overflow: hidden;
  outline: none;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  align-self: center;
}
.mdl-snackbar__action::-moz-focus-inner {
  border: 0;
}
.mdl-snackbar__action:not([aria-hidden]) {
  opacity: 1;
  pointer-events: auto;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-spinner {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
}
.mdl-spinner:not(.is-upgraded).is-active:after {
  content: "Loading...";
}
.mdl-spinner.is-upgraded.is-active {
  animation: mdl-spinner__container-rotate 1568.2352941176ms linear infinite;
}

@keyframes mdl-spinner__container-rotate {
  to {
    transform: rotate(360deg);
  }
}
.mdl-spinner__layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.mdl-spinner__layer-1 {
  border-color: rgb(66,165,245);
}
.mdl-spinner--single-color .mdl-spinner__layer-1 {
  border-color: rgb(63,81,181);
}
.mdl-spinner.is-active .mdl-spinner__layer-1 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-2 {
  border-color: rgb(244,67,54);
}
.mdl-spinner--single-color .mdl-spinner__layer-2 {
  border-color: rgb(63,81,181);
}
.mdl-spinner.is-active .mdl-spinner__layer-2 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-3 {
  border-color: rgb(253,216,53);
}
.mdl-spinner--single-color .mdl-spinner__layer-3 {
  border-color: rgb(63,81,181);
}
.mdl-spinner.is-active .mdl-spinner__layer-3 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdl-spinner__layer-4 {
  border-color: rgb(76,175,80);
}
.mdl-spinner--single-color .mdl-spinner__layer-4 {
  border-color: rgb(63,81,181);
}
.mdl-spinner.is-active .mdl-spinner__layer-4 {
  animation: mdl-spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, mdl-spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes mdl-spinner__fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}
/**
* HACK: Even though the intention is to have the current .mdl-spinner__layer-N
* at `opacity: 1`, we set it to `opacity: 0.99` instead since this forces Chrome
* to do proper subpixel rendering for the elements being animated. This is
* especially visible in Chrome 39 on Ubuntu 14.04. See:
*
* - https://github.com/Polymer/paper-spinner/issues/9
* - https://code.google.com/p/chromium/issues/detail?id=436255
*/
@keyframes mdl-spinner__layer-1-fade-in-out {
  from {
    opacity: 0.99;
  }
  25% {
    opacity: 0.99;
  }
  26% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
  }
}
@keyframes mdl-spinner__layer-2-fade-in-out {
  from {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 0.99;
  }
  50% {
    opacity: 0.99;
  }
  51% {
    opacity: 0;
  }
}
@keyframes mdl-spinner__layer-3-fade-in-out {
  from {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 0.99;
  }
  75% {
    opacity: 0.99;
  }
  76% {
    opacity: 0;
  }
}
@keyframes mdl-spinner__layer-4-fade-in-out {
  from {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 0.99;
  }
  90% {
    opacity: 0.99;
  }
  100% {
    opacity: 0;
  }
}
/**
* Patch the gap that appear between the two adjacent
* div.mdl-spinner__circle-clipper while the spinner is rotating
* (appears on Chrome 38, Safari 7.1, and IE 11).
*
* Update: the gap no longer appears on Chrome when .mdl-spinner__layer-N's
* opacity is 0.99, but still does on Safari and IE.
*/
.mdl-spinner__gap-patch {
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.mdl-spinner__gap-patch .mdl-spinner__circle {
  width: 1000%;
  left: -450%;
}

.mdl-spinner__circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}
.mdl-spinner__circle-clipper.mdl-spinner__left {
  float: left;
}
.mdl-spinner__circle-clipper.mdl-spinner__right {
  float: right;
}
.mdl-spinner__circle-clipper .mdl-spinner__circle {
  width: 200%;
}

.mdl-spinner__circle {
  box-sizing: border-box;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mdl-spinner__left .mdl-spinner__circle {
  border-right-color: transparent !important;
  transform: rotate(129deg);
}
.mdl-spinner.is-active .mdl-spinner__left .mdl-spinner__circle {
  animation: mdl-spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}
.mdl-spinner__right .mdl-spinner__circle {
  left: -100%;
  border-left-color: transparent !important;
  transform: rotate(-129deg);
}
.mdl-spinner.is-active .mdl-spinner__right .mdl-spinner__circle {
  animation: mdl-spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@keyframes mdl-spinner__left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}
@keyframes mdl-spinner__right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-switch {
  position: relative;
  z-index: 1;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  margin: 0;
  padding: 0;
  overflow: visible;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mdl-switch.is-upgraded {
  padding-left: 28px;
}

.mdl-switch__input {
  line-height: 24px;
}
.mdl-switch.is-upgraded .mdl-switch__input {
  position: absolute;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

.mdl-switch__track {
  background: rgba(0,0,0, 0.26);
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 36px;
  border-radius: 14px;
  cursor: pointer;
}
.mdl-switch.is-checked .mdl-switch__track {
  background: rgba(63,81,181, 0.5);
}
.mdl-switch__track fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__track {
  background: rgba(0,0,0, 0.12);
  cursor: auto;
}

.mdl-switch__thumb {
  background: rgb(250,250,250);
  position: absolute;
  left: 0;
  top: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.28s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: left;
}
.mdl-switch.is-checked .mdl-switch__thumb {
  background: rgb(63,81,181);
  left: 16px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.mdl-switch__thumb fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__thumb {
  background: rgb(189,189,189);
  cursor: auto;
}

.mdl-switch__focus-helper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-4px, -4px);
  display: inline-block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: transparent;
}
.mdl-switch.is-focused .mdl-switch__focus-helper {
  box-shadow: 0 0 0px 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
.mdl-switch.is-focused.is-checked .mdl-switch__focus-helper {
  box-shadow: 0 0 0px 20px rgba(63,81,181, 0.26);
  background-color: rgba(63,81,181, 0.26);
}

.mdl-switch__label {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  left: 24px;
}
.mdl-switch__label fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__label {
  color: rgb(189,189,189);
  cursor: auto;
}

.mdl-switch__ripple-container {
  position: absolute;
  z-index: 2;
  top: -12px;
  left: -14px;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  transition-duration: 0.4s;
  transition-timing-function: step-end;
  transition-property: left;
}
.mdl-switch__ripple-container .mdl-ripple {
  background: rgb(63,81,181);
}
.mdl-switch__ripple-container fieldset[disabled] .mdl-switch, .mdl-switch.is-disabled .mdl-switch__ripple-container {
  cursor: auto;
}
fieldset[disabled] .mdl-switch .mdl-switch__ripple-container .mdl-ripple, .mdl-switch.is-disabled .mdl-switch__ripple-container .mdl-ripple {
  background: transparent;
}
.mdl-switch.is-checked .mdl-switch__ripple-container {
  left: 2px;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-tabs {
  display: block;
  width: 100%;
}

.mdl-tabs__tab-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: space-between;
  align-items: flex-start;
  height: 48px;
  padding: 0 0 0 0;
  margin: 0;
  border-bottom: 1px solid rgb(224,224,224);
}

.mdl-tabs__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0,0,0, 0.54);
  overflow: hidden;
}
.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active {
  color: rgba(0,0,0, 0.87);
}
.mdl-tabs.is-upgraded .mdl-tabs__tab.is-active:after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0px;
  left: 0px;
  position: absolute;
  background: rgb(63,81,181);
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}
.mdl-tabs__tab .mdl-tabs__ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 1;
  overflow: hidden;
}
.mdl-tabs__tab .mdl-tabs__ripple-container .mdl-ripple {
  background: rgb(63,81,181);
}

.mdl-tabs__panel {
  display: block;
}
.mdl-tabs.is-upgraded .mdl-tabs__panel {
  display: none;
}
.mdl-tabs.is-upgraded .mdl-tabs__panel.is-active {
  display: block;
}

@keyframes border-expand {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-textfield {
  position: relative;
  font-size: 16px;
  display: inline-block;
  box-sizing: border-box;
  width: 300px;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
}
.mdl-textfield .mdl-button {
  position: absolute;
  bottom: 20px;
}

.mdl-textfield--align-right {
  text-align: right;
}

.mdl-textfield--full-width {
  width: 100%;
}

.mdl-textfield--expandable {
  min-width: 32px;
  width: auto;
  min-height: 32px;
}
.mdl-textfield--expandable .mdl-button--icon {
  top: 16px;
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid rgba(0,0,0, 0.12);
  display: block;
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: none;
  text-align: left;
  color: inherit;
}
.mdl-textfield__input[type=number] {
  -moz-appearance: textfield;
}
.mdl-textfield__input[type=number]::-webkit-inner-spin-button, .mdl-textfield__input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mdl-textfield.is-focused .mdl-textfield__input {
  outline: none;
}
.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: rgb(213,0,0);
  box-shadow: none;
}
fieldset[disabled] .mdl-textfield .mdl-textfield__input, .mdl-textfield.is-disabled .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0,0,0, 0.12);
  color: rgba(0,0,0, 0.26);
}

.mdl-textfield textarea.mdl-textfield__input {
  display: block;
}

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0,0,0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.mdl-textfield.is-dirty .mdl-textfield__label, .mdl-textfield.has-placeholder .mdl-textfield__label {
  visibility: hidden;
}
.mdl-textfield--floating-label .mdl-textfield__label, .mdl-select--floating-label .mdl-textfield__label {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label, .has-placeholder.mdl-select--floating-label .mdl-textfield__label {
  transition: none;
}
fieldset[disabled] .mdl-textfield .mdl-textfield__label, .mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
  color: rgba(0,0,0, 0.26);
}
.mdl-textfield--floating-label.is-focused .mdl-textfield__label, .is-focused.mdl-select--floating-label .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__label, .is-dirty.mdl-select--floating-label .mdl-textfield__label, .mdl-textfield--floating-label.has-placeholder .mdl-textfield__label, .has-placeholder.mdl-select--floating-label .mdl-textfield__label {
  color: rgb(63,81,181);
  font-size: 12px;
  top: 4px;
  visibility: visible;
}
.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label, .is-focused.mdl-select--floating-label .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label, .is-dirty.mdl-select--floating-label .mdl-textfield__expandable-holder .mdl-textfield__label, .mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label, .has-placeholder.mdl-select--floating-label .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px;
}
.mdl-textfield--floating-label.is-invalid .mdl-textfield__label, .is-invalid.mdl-select--floating-label .mdl-textfield__label {
  color: rgb(213,0,0);
  font-size: 12px;
}
.mdl-textfield__label:after {
  background-color: rgb(63,81,181);
  bottom: 20px;
  content: "";
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}
.mdl-textfield.is-focused .mdl-textfield__label:after {
  left: 0;
  visibility: visible;
  width: 100%;
}
.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: rgb(213,0,0);
}

.mdl-textfield__error {
  color: rgb(213,0,0);
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}
.mdl-textfield.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.mdl-textfield__expandable-holder {
  display: inline-block;
  position: relative;
  margin-left: 32px;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-block;
  max-width: 0.1px;
}
.mdl-textfield.is-focused .mdl-textfield__expandable-holder, .mdl-textfield.is-dirty .mdl-textfield__expandable-holder {
  max-width: 600px;
}
.mdl-textfield__expandable-holder .mdl-textfield__label:after {
  bottom: 0;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-tooltip {
  transform: scale(0);
  transform-origin: top center;
  z-index: 999;
  background: rgba(97,97,97, 0.9);
  border-radius: 2px;
  color: rgb(255,255,255);
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  max-width: 170px;
  position: fixed;
  top: -500px;
  left: -500px;
  padding: 8px;
  text-align: center;
}

.mdl-tooltip.is-active {
  animation: pulse 200ms cubic-bezier(0, 0, 0.2, 1) forwards;
}

.mdl-tooltip--large {
  line-height: 14px;
  font-size: 14px;
  padding: 16px;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--4dp {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--8dp {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--16dp {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.mdl-shadow--24dp {
  box-shadow: 0 9px 46px 8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.2);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
* NOTE: Some rules here are applied using duplicate selectors.
* This is on purpose to increase their specificity when applied.
* For example: `.mdl-cell--1-col-phone.mdl-cell--1-col-phone`
*/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 0 auto;
  align-items: stretch;
}
.mdl-grid.mdl-grid--no-spacing {
  padding: 0;
}

.mdl-cell {
  box-sizing: border-box;
}

.mdl-cell--top {
  align-self: flex-start;
}

.mdl-cell--middle {
  align-self: center;
}

.mdl-cell--bottom {
  align-self: flex-end;
}

.mdl-cell--stretch {
  align-self: stretch;
}

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0;
}

.mdl-cell--order-1 {
  order: 1;
}

.mdl-cell--order-2 {
  order: 2;
}

.mdl-cell--order-3 {
  order: 3;
}

.mdl-cell--order-4 {
  order: 4;
}

.mdl-cell--order-5 {
  order: 5;
}

.mdl-cell--order-6 {
  order: 6;
}

.mdl-cell--order-7 {
  order: 7;
}

.mdl-cell--order-8 {
  order: 8;
}

.mdl-cell--order-9 {
  order: 9;
}

.mdl-cell--order-10 {
  order: 10;
}

.mdl-cell--order-11 {
  order: 11;
}

.mdl-cell--order-12 {
  order: 12;
}

@media (max-width: 479px) {
  .mdl-grid {
    padding: 8px;
  }
  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%;
  }
  .mdl-cell--hide-phone {
    display: none !important;
  }
  .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
    order: 1;
  }
  .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
    order: 2;
  }
  .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
    order: 3;
  }
  .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
    order: 4;
  }
  .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
    order: 5;
  }
  .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
    order: 6;
  }
  .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
    order: 7;
  }
  .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
    order: 8;
  }
  .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
    order: 9;
  }
  .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
    order: 10;
  }
  .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
    order: 11;
  }
  .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
    order: 12;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: 25%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: 50%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: 75%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: 100%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: 100%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: 100%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: 100%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: 100%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: 100%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: 100%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: 100%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(25% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: 25%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(50% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: 50%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(75% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: 75%;
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdl-grid {
    padding: 8px;
  }
  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 50%;
  }
  .mdl-cell--hide-tablet {
    display: none !important;
  }
  .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
    order: 1;
  }
  .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
    order: 2;
  }
  .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
    order: 3;
  }
  .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
    order: 4;
  }
  .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
    order: 5;
  }
  .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
    order: 6;
  }
  .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
    order: 7;
  }
  .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
    order: 8;
  }
  .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
    order: 9;
  }
  .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
    order: 10;
  }
  .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
    order: 11;
  }
  .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
    order: 12;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: 12.5%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: 25%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: 37.5%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: 50%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: 62.5%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: 75%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: 87.5%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: 100%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: 100%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: 100%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: 100%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: 12.5%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(25% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: 25%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: 37.5%;
  }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(50% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: 50%;
  }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: 62.5%;
  }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(75% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: 75%;
  }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: 87.5%;
  }
}
@media (min-width: 840px) {
  .mdl-grid {
    padding: 8px;
  }
  .mdl-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 33.3333333333%;
  }
  .mdl-cell--hide-desktop {
    display: none !important;
  }
  .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
    order: 1;
  }
  .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
    order: 2;
  }
  .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
    order: 3;
  }
  .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
    order: 4;
  }
  .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
    order: 5;
  }
  .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
    order: 6;
  }
  .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
    order: 7;
  }
  .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
    order: 8;
  }
  .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
    order: 9;
  }
  .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
    order: 10;
  }
  .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
    order: 11;
  }
  .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
    order: 12;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: 8.3333333333%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: 16.6666666667%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: 25%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: 33.3333333333%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: 41.6666666667%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: 50%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: 58.3333333333%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: 66.6666666667%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: 75%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: 83.3333333333%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: 91.6666666667%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: 8.3333333333%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: 16.6666666667%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: 25%;
  }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: 33.3333333333%;
  }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: 41.6666666667%;
  }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: 50%;
  }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: 58.3333333333%;
  }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: 66.6666666667%;
  }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: 75%;
  }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: 83.3333333333%;
  }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: 91.6666666667%;
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-tabs__tab.disabled {
  cursor: not-allowed;
  color: rgba(0,0,0, 0.26);
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-checkbox__tick-outline {
  mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnDQogICB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iDQogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIg0KICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIg0KICAgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyINCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyINCiAgIHZlcnNpb249IjEuMSINCiAgIHZpZXdCb3g9IjAgMCAxIDEiDQogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4NCiAgPGRlZnM+DQogICAgPGNsaXBQYXRoIGlkPSJjbGlwIj4NCiAgICAgIDxwYXRoDQogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiDQogICAgICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPg0KICAgIDwvY2xpcFBhdGg+DQogICAgPG1hc2sgaWQ9Im1hc2siIG1hc2tVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIG1hc2tDb250ZW50VW5pdHM9Im9iamVjdEJvdW5kaW5nQm94Ij4NCiAgICAgIDxwYXRoDQogICAgICAgICBkPSJNIDAsMCAwLDEgMSwxIDEsMCAwLDAgeiBNIDAuODUzNDM3NSwwLjE2NzE4NzUgMC45NTk2ODc1LDAuMjczMTI1IDAuNDI5Mzc1LDAuODAzNDM3NSAwLjMyMzEyNSwwLjkwOTY4NzUgMC4yMTcxODc1LDAuODAzNDM3NSAwLjA0MDMxMjUsMC42MjY4NzUgMC4xNDY1NjI1LDAuNTIwNjI1IDAuMzIzMTI1LDAuNjk3NSAwLjg1MzQzNzUsMC4xNjcxODc1IHoiDQogICAgICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPg0KICAgIDwvbWFzaz4NCiAgPC9kZWZzPg0KICA8cmVjdA0KICAgICB3aWR0aD0iMSINCiAgICAgaGVpZ2h0PSIxIg0KICAgICB4PSIwIg0KICAgICB5PSIwIg0KICAgICBjbGlwLXBhdGg9InVybCgjY2xpcCkiDQogICAgIHN0eWxlPSJmaWxsOiMwMDAwMDA7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOm5vbmUiIC8+DQo8L3N2Zz4=");
}
.mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background: rgb(63,81,181) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
}
fieldset[disabled] .mdl-checkbox.is-checked .mdl-checkbox__tick-outline, .mdl-checkbox.is-checked.is-disabled .mdl-checkbox__tick-outline {
  background: rgba(0,0,0, 0.26) url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIgogICB4bWxuczpjYz0iaHR0cDovL2NyZWF0aXZlY29tbW9ucy5vcmcvbnMjIgogICB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgdmVyc2lvbj0iMS4xIgogICB2aWV3Qm94PSIwIDAgMSAxIgogICBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiBtZWV0Ij4KICA8cGF0aAogICAgIGQ9Ik0gMC4wNDAzODA1OSwwLjYyNjc3NjcgMC4xNDY0NDY2MSwwLjUyMDcxMDY4IDAuNDI5Mjg5MzIsMC44MDM1NTMzOSAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IE0gMC4yMTcxNTcyOSwwLjgwMzU1MzM5IDAuODUzNTUzMzksMC4xNjcxNTcyOSAwLjk1OTYxOTQxLDAuMjczMjIzMyAwLjMyMzIyMzMsMC45MDk2MTk0MSB6IgogICAgIGlkPSJyZWN0Mzc4MCIKICAgICBzdHlsZT0iZmlsbDojZmZmZmZmO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPgo8L3N2Zz4K");
}

@supports (-webkit-appearance: none) {
  .mdl-progress:not(.mdl-progress--indeterminate):not(.mdl-progress--indeterminate) > .auxbar,
  .mdl-progress:not(.mdl-progress__indeterminate):not(.mdl-progress__indeterminate) > .auxbar {
    mask: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI0IiB2aWV3UG9ydD0iMCAwIDEyIDQiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPGVsbGlwc2UgY3g9IjIiIGN5PSIyIiByeD0iMiIgcnk9IjIiPg0KICAgIDxhbmltYXRlIGF0dHJpYnV0ZU5hbWU9ImN4IiBmcm9tPSIyIiB0bz0iLTEwIiBkdXI9IjAuNnMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICA8L2VsbGlwc2U+DQogIDxlbGxpcHNlIGN4PSIxNCIgY3k9IjIiIHJ4PSIyIiByeT0iMiIgY2xhc3M9ImxvYWRlciI+DQogICAgPGFuaW1hdGUgYXR0cmlidXRlTmFtZT0iY3giIGZyb209IjE0IiB0bz0iMiIgZHVyPSIwLjZzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgLz4NCiAgPC9lbGxpcHNlPg0KPC9zdmc+");
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
body::after {
  display: none;
}
@media (min-width: 1024px) {
  body::after {
    content: "desktop";
  }
}
@media (max-width: 768px) {
  body::after {
    content: "tablet";
  }
}
@media (max-width: 600px) {
  body::after {
    content: "mobile";
  }
}

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
body:after {
  display: none;
}
@media (min-width: 768px) {
  body:after {
    content: "desktop";
  }
}
@media (max-width: 768px) {
  body:after {
    content: "tablet";
  }
}
@media (max-width: 600px) {
  body:after {
    content: "mobile";
  }
}

/*
 * VNC 'global-components' - UI components universal library
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/*
 * VNC 'global-components' - UI components universal library
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
body:after {
  display: none;
}
@media (min-width: 768px) {
  body:after {
    content: "desktop";
  }
}
@media (max-width: 768px) {
  body:after {
    content: "tablet";
  }
}
@media (max-width: 600px) {
  body:after {
    content: "mobile";
  }
}

body {
  font-family: "Manrope";
}

.vnc-button {
  height: 32px;
  margin-left: 12px;
  padding: 0 12px;
  border-radius: 4px;
  border: solid 1px #8B96A0;
  font-family: Manrope;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #FFFFFF;
  cursor: pointer;
  color: #606B76;
  outline: 0;
  position: relative;
}
.vnc-button:hover {
  border: solid 1px #388EDA;
  color: #388EDA;
}
.vnc-button.vnc-primary {
  background-color: #337CBD;
  color: #FFFFFF;
  padding: 0 24px;
}
.vnc-button.vnc-primary:hover {
  background-color: #388EDA;
}
.vnc-button mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  margin-right: 8px;
}
.vnc-button.vnc-link {
  background-color: transparent;
  color: #388EDA;
  padding: 0;
  align-items: center;
  font-size: 14px;
  display: flex;
  text-transform: none;
  border: none;
  margin: 0;
}
.vnc-button.vnc-link:hover {
  border: none;
  color: #388EDA;
  background-color: transparent;
}

.vnc-form label.vnc-label {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
}
.vnc-form .form-description {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: normal;
  color: #79848f;
}
.vnc-form .ql-container {
  height: 180px;
  border-radius: 0 0 6px 6px;
}
.vnc-form .ql-toolbar {
  border-radius: 6px 6px 0 0;
}
.vnc-form .ql-toolbar .ql-icon-picker .ql-picker-label {
  margin-top: -3px;
}
.vnc-form .ql-editor p, .vnc-form .ql-editor.ql-blank::before {
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.vnc-form .ql-editor.ql-blank::before {
  color: #a8b0b9;
}

.vnc-group-label {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
}

.component-showcase {
  padding: 20px;
  display: flex;
}
.component-showcase > * {
  margin: 20px;
}

.cdk-overlay-backdrop.vnc-dialog-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.cdk-overlay-backdrop.vnc-dialog-backdrop-A600_0-5-alpha {
  background: #131313;
  opacity: 0.5;
}

.cdk-overlay-backdrop.vnc-dialog-backdrop-A600_0-7-alpha {
  background: #131313;
  opacity: 0.7;
}

.cdk-overlay-backdrop.vnc-dialog-backdrop-highlight {
  background: #F2F9FE;
}

.vnc-dialog-panel .mat-dialog-container {
  padding: 0;
}

.hoverable {
  cursor: pointer;
}

.mat-snack-bar-container {
  border-radius: 8px !important;
  box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2);
  background: #191a1c;
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: white;
}

.cdk-virtual-scroll-viewport::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--small--vertical::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}
.vnc-scroll-bar--small--vertical::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--small--vertical::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--small--vertical::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--small--horizontal::-webkit-scrollbar {
  overflow-x: scroll;
  height: 6px;
}
.vnc-scroll-bar--small--horizontal::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--small--horizontal::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--small--horizontal::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
  box-shadow: 0 1px 2px 0 rgba(7, 29, 51, 0.2), 0 2px 3px 0 rgba(7, 29, 51, 0.1);
}

.vnc-scroll-bar--medium--vertical::-webkit-scrollbar {
  overflow-y: scroll;
  width: 10px;
}
.vnc-scroll-bar--medium--vertical::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--medium--vertical::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--medium--vertical::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar {
  overflow-x: scroll;
  height: 10px;
}
.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
  box-shadow: 0 1px 2px 0 rgba(7, 29, 51, 0.2), 0 2px 3px 0 rgba(7, 29, 51, 0.1);
}

.vnc-scroll-bar--drag--vertical::-webkit-scrollbar {
  overflow-y: scroll;
  width: 9px;
}
.vnc-scroll-bar--drag--vertical::-webkit-scrollbar-track {
  background: #F7F8FA;
}
.vnc-scroll-bar--drag--vertical::-webkit-scrollbar-thumb {
  background: #DADEE2;
  border: solid 1px #A8B0B9;
}
.vnc-scroll-bar--drag--vertical::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar {
  overflow-x: scroll;
  height: 9px;
}
.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar-track {
  background: #F7F8FA;
}
.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar-thumb {
  background: #DADEE2;
  border: solid 1px #A8B0B9;
}
.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
  box-shadow: 0 1px 2px 0 rgba(7, 29, 51, 0.2), 0 2px 3px 0 rgba(7, 29, 51, 0.1);
}

.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item {
  align-items: center;
  display: flex;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item[disabled] {
  opacity: 0.8;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item mat-icon {
  font-size: 18px;
  color: #8b96a0;
  height: 18px;
  line-height: 18px;
  margin-right: 8px;
  width: 18px;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item svg {
  margin-right: 8px;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item span {
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #131313;
}

.mat-simple-snackbar-action .mat-button-wrapper {
  color: white;
}

.mat-snack-bar-container.vnc-notification {
  background: #000000;
  padding: 0;
}
.mat-snack-bar-container.vnc-notification .action .action-label {
  cursor: pointer !important;
}

.action-link {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
  color: #337cbd;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.action-link mat-icon {
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  margin-left: 4px;
}

.customise-tooltip.mat-tooltip {
  margin: 5px;
  padding: 10px 16px;
  font-size: 12px;
  opacity: 0.9;
  border-radius: 4px;
  background-color: #191a1c;
  font-family: "Source Sans Pro";
  color: #eaeef2;
}

.cdk-overlay-pane .vnc-file-item-menu {
  width: 165px;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item {
  height: 40px;
  line-height: 40px;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item[disabled] {
  opacity: 0.8;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item mat-icon {
  font-size: 18px;
  color: #8b96a0;
  height: 18px;
  line-height: 18px;
  margin-right: 11px;
  width: 18px;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item span {
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #131313;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item .item-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item .item-option .first-item {
  display: flex;
  align-items: center;
}

.vnc-search-help-dialog-panel {
  margin-top: 40px;
}
.vnc-search-help-dialog-panel .mat-dialog-container {
  padding: 0;
}

.vnc-search-file-preview-dialog-panel .mat-dialog-container {
  padding: 0;
}

.cdk-overlay-pane .vnc-file-item-filter-menu {
  width: 288px;
  min-width: 288px;
  overflow: hidden;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content {
  padding: 0px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .scroll-item-bar {
  max-height: 230px;
  overflow: auto;
}
.cdk-overlay-pane .vnc-file-item-filter-menu vnc-input .vnc-form {
  margin: 0;
}
.cdk-overlay-pane .vnc-file-item-filter-menu vnc-input .vnc-form input {
  height: 32px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item {
  height: 40px;
  line-height: 40px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item[disabled] {
  opacity: 0.8;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item mat-icon {
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  margin-right: 11px;
  width: 18px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item mat-icon.check-icon {
  color: #388EDA;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item span.item-lbl {
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #131313;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item .item-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item .item-option .first-item {
  display: flex;
  align-items: center;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item.operation-button {
  display: flex;
  float: right;
  height: 30px;
  margin-top: 5px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item.operation-button:hover {
  background: transparent;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item.search-item:hover {
  background: transparent;
}

.vnc-file-header-menu .mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
  line-height: 24px;
}

.vnc-file-preview-menu-item {
  width: 240px !important;
}
.vnc-file-preview-menu-item .mat-menu-content .hr-item {
  box-shadow: inset 0 -1px 0 0 #e3e7eb;
  height: 1px;
  width: 90%;
  margin: 3px auto;
}
.vnc-file-preview-menu-item .mat-menu-content .mat-menu-item .svg-item-in-menu {
  margin-right: 11px;
  display: flex;
}

.incoming-call-menu-item .mat-menu-item vnc-icon-container svg {
  height: 18px;
  width: 18px;
}

.gc-list-menu-item-component {
  overflow: hidden !important;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item {
  background: transparent;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item .inner-items {
  width: 100%;
  margin: 0px;
  padding: 0px 16px;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item .icon-lable .default-label-item {
  font-size: 14px;
  font-weight: normal;
  font-family: "Source Sans Pro";
  color: #131313;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item .icon-drop-item-default .label-icon .lbl {
  margin-left: 8px !important;
  font-weight: normal !important;
}

.vnc-chat-header-kebab-menu {
  width: 240px !important;
}
.vnc-chat-header-kebab-menu .mat-menu-item {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 36px;
  height: 36px;
  padding: 0 12px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 8px;
}
.vnc-chat-header-kebab-menu .mat-menu-item .menu-icon-wr {
  width: 24px;
  height: 24px;
  padding: 3px;
}
.vnc-chat-header-kebab-menu .mat-menu-item:hover {
  background-color: #F7F8FA;
}
.vnc-chat-header-kebab-menu .mat-menu-item .item-label {
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #131313;
}
.vnc-chat-header-kebab-menu .mat-menu-item svg-icon {
  display: flex;
}

/*
 * VNC 'global-components' - UI components universal library
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/*
 * VNC 'global-components' - UI components universal library
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
body:after {
  display: none;
}
@media (min-width: 768px) {
  body:after {
    content: "desktop";
  }
}
@media (max-width: 768px) {
  body:after {
    content: "tablet";
  }
}
@media (max-width: 600px) {
  body:after {
    content: "mobile";
  }
}

/*
 * VNC 'global-components' - UI components universal library
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/*
 * VNC 'global-components' - UI components universal library
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
body:after {
  display: none;
}
@media (min-width: 768px) {
  body:after {
    content: "desktop";
  }
}
@media (max-width: 768px) {
  body:after {
    content: "tablet";
  }
}
@media (max-width: 600px) {
  body:after {
    content: "mobile";
  }
}

body {
  font-family: "Manrope";
}

.vnc-button {
  height: 32px;
  margin-left: 12px;
  padding: 0 12px;
  border-radius: 4px;
  border: solid 1px #8B96A0;
  font-family: Manrope;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #FFFFFF;
  cursor: pointer;
  color: #606B76;
  outline: 0;
  position: relative;
}
.vnc-button:hover {
  border: solid 1px #388EDA;
  color: #388EDA;
}
.vnc-button.vnc-primary {
  background-color: #337CBD;
  color: #FFFFFF;
  padding: 0 24px;
}
.vnc-button.vnc-primary:hover {
  background-color: #388EDA;
}
.vnc-button mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
  line-height: 18px;
  margin-right: 8px;
}
.vnc-button.vnc-link {
  background-color: transparent;
  color: #388EDA;
  padding: 0;
  align-items: center;
  font-size: 14px;
  display: flex;
  text-transform: none;
  border: none;
  margin: 0;
}
.vnc-button.vnc-link:hover {
  border: none;
  color: #388EDA;
  background-color: transparent;
}

.vnc-form label.vnc-label {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
}
.vnc-form .form-description {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: normal;
  color: #79848f;
}
.vnc-form .ql-container {
  height: 180px;
  border-radius: 0 0 6px 6px;
}
.vnc-form .ql-toolbar {
  border-radius: 6px 6px 0 0;
}
.vnc-form .ql-toolbar .ql-icon-picker .ql-picker-label {
  margin-top: -3px;
}
.vnc-form .ql-editor p, .vnc-form .ql-editor.ql-blank::before {
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.vnc-form .ql-editor.ql-blank::before {
  color: #a8b0b9;
}

.vnc-group-label {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
}

.component-showcase {
  padding: 20px;
  display: flex;
}
.component-showcase > * {
  margin: 20px;
}

.cdk-overlay-backdrop.vnc-dialog-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.cdk-overlay-backdrop.vnc-dialog-backdrop-A600_0-5-alpha {
  background: #131313;
  opacity: 0.5;
}

.cdk-overlay-backdrop.vnc-dialog-backdrop-A600_0-7-alpha {
  background: #131313;
  opacity: 0.7;
}

.cdk-overlay-backdrop.vnc-dialog-backdrop-highlight {
  background: #F2F9FE;
}

.vnc-dialog-panel .mat-dialog-container {
  padding: 0;
}

.hoverable {
  cursor: pointer;
}

.mat-snack-bar-container {
  border-radius: 8px !important;
  box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2);
  background: #191a1c;
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: white;
}

.cdk-virtual-scroll-viewport::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.cdk-virtual-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--small--vertical::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}
.vnc-scroll-bar--small--vertical::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--small--vertical::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--small--vertical::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--small--horizontal::-webkit-scrollbar {
  overflow-x: scroll;
  height: 6px;
}
.vnc-scroll-bar--small--horizontal::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--small--horizontal::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--small--horizontal::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
  box-shadow: 0 1px 2px 0 rgba(7, 29, 51, 0.2), 0 2px 3px 0 rgba(7, 29, 51, 0.1);
}

.vnc-scroll-bar--medium--vertical::-webkit-scrollbar {
  overflow-y: scroll;
  width: 10px;
}
.vnc-scroll-bar--medium--vertical::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--medium--vertical::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--medium--vertical::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar {
  overflow-x: scroll;
  height: 10px;
}
.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar-track {
  background: #F7F8FA;
  box-shadow: inset 0 1px 0 0 #F5F8FA, inset 0 -1px 0 0 #F5F8FA;
}
.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.vnc-scroll-bar--medium--horizontal::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
  box-shadow: 0 1px 2px 0 rgba(7, 29, 51, 0.2), 0 2px 3px 0 rgba(7, 29, 51, 0.1);
}

.vnc-scroll-bar--drag--vertical::-webkit-scrollbar {
  overflow-y: scroll;
  width: 9px;
}
.vnc-scroll-bar--drag--vertical::-webkit-scrollbar-track {
  background: #F7F8FA;
}
.vnc-scroll-bar--drag--vertical::-webkit-scrollbar-thumb {
  background: #DADEE2;
  border: solid 1px #A8B0B9;
}
.vnc-scroll-bar--drag--vertical::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}

.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar {
  overflow-x: scroll;
  height: 9px;
}
.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar-track {
  background: #F7F8FA;
}
.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar-thumb {
  background: #DADEE2;
  border: solid 1px #A8B0B9;
}
.vnc-scroll-bar--drag--horizontal::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
  box-shadow: 0 1px 2px 0 rgba(7, 29, 51, 0.2), 0 2px 3px 0 rgba(7, 29, 51, 0.1);
}

.vnc-scrollbar-verical-show-on-hover {
  scrollbar-color: #a8b0b9 #f7f8fa;
}
.vnc-scrollbar-verical-show-on-hover::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}
.vnc-scrollbar-verical-show-on-hover::-webkit-scrollbar-track {
  background-color: #f7f8fa;
  background-color: transparent;
}
.vnc-scrollbar-verical-show-on-hover::-webkit-scrollbar-thumb {
  background: #a8b0b9;
  background: transparent;
  min-height: 96px;
}
.vnc-scrollbar-verical-show-on-hover:hover::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}
.vnc-scrollbar-verical-show-on-hover:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 1px 0 0 #f5f8fa, inset 0 -1px 0 0 #f5f8fa;
  background-color: #f7f8fa;
}
.vnc-scrollbar-verical-show-on-hover:hover::-webkit-scrollbar-thumb {
  background-color: #dadee2;
}
.vnc-scrollbar-verical-show-on-hover:hover::-webkit-scrollbar-thumb:hover {
  background-color: #a8b0b9;
}

.vnc-scrollbar-verical-show-on-hover-chat {
  scrollbar-color: #a8b0b9 #f7f8fa;
}
.vnc-scrollbar-verical-show-on-hover-chat #scroll-bar::-webkit-scrollbar {
  overflow-y: scroll;
  width: 10px;
}
.vnc-scrollbar-verical-show-on-hover-chat #scroll-bar::-webkit-scrollbar-track {
  background-color: #f7f8fa;
  background-color: transparent;
}
.vnc-scrollbar-verical-show-on-hover-chat #scroll-bar::-webkit-scrollbar-thumb {
  background: #a8b0b9;
  background: transparent;
  min-height: 96px;
}
.vnc-scrollbar-verical-show-on-hover-chat:hover #scroll-bar::-webkit-scrollbar {
  overflow-y: scroll;
  width: 10px;
}
.vnc-scrollbar-verical-show-on-hover-chat:hover #scroll-bar::-webkit-scrollbar-track {
  box-shadow: inset 0 1px 0 0 #f5f8fa, inset 0 -1px 0 0 #f5f8fa;
  background-color: #f7f8fa;
}
.vnc-scrollbar-verical-show-on-hover-chat:hover #scroll-bar::-webkit-scrollbar-thumb {
  background-color: #dadee2;
}
.vnc-scrollbar-verical-show-on-hover-chat:hover #scroll-bar::-webkit-scrollbar-thumb:hover {
  background-color: #a8b0b9;
}

.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item {
  align-items: center;
  display: flex;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item[disabled] {
  opacity: 0.8;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item mat-icon {
  font-size: 18px;
  color: #8b96a0;
  height: 18px;
  line-height: 18px;
  margin-right: 8px;
  width: 18px;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item svg {
  margin-right: 8px;
}
.cdk-overlay-pane .vnc-list-item-menu .mat-menu-content .mat-menu-item span {
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #131313;
}

.mat-simple-snackbar-action .mat-button-wrapper {
  color: white;
}
.mat-simple-snackbar-action .taskid-text-snackbar {
  color: #0085C7;
}

.mat-snack-bar-container.vnc-notification {
  background: #000000;
  padding: 0;
}
.mat-snack-bar-container.vnc-notification .taskid-text-snackbar {
  color: #337cbd;
  cursor: pointer !important;
}
.mat-snack-bar-container.vnc-notification .action .action-label {
  cursor: pointer !important;
}

.action-link {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
  color: #337cbd;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.action-link mat-icon {
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  margin-left: 4px;
}

.toogle-left-sidebar-tooltip.mat-tooltip,
.global-search-tooltip.mat-tooltip {
  margin: 5px;
  padding: 10px 16px;
  line-height: 1;
}

.customise-tooltip.mat-tooltip {
  margin: 5px;
  padding: 10px 16px;
  font-size: 12px;
  opacity: 0.9;
  border-radius: 4px;
  background-color: #191a1c;
  font-family: "Source Sans Pro";
  color: #eaeef2;
}

.document-tooltip.mat-tooltip {
  margin: 5px;
  padding: 1px 16px;
  font-size: 12px;
  opacity: 0.9;
  border-radius: 4px;
  background-color: #191a1c;
  font-family: "Source Sans Pro";
  color: #eaeef2;
}

.cdk-overlay-pane .vnc-file-item-menu {
  width: 165px;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item {
  height: 40px;
  line-height: 40px;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item[disabled] {
  opacity: 0.8;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item mat-icon {
  font-size: 18px;
  color: #8b96a0;
  height: 18px;
  line-height: 18px;
  margin-right: 11px;
  width: 18px;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item span {
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #131313;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item .item-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cdk-overlay-pane .vnc-file-item-menu .mat-menu-content .mat-menu-item .item-option .first-item {
  display: flex;
  align-items: center;
}

.vnc-search-help-dialog-panel {
  margin-top: 40px;
}
.vnc-search-help-dialog-panel .mat-dialog-container {
  padding: 0;
}

.vnc-search-file-preview-dialog-panel .mat-dialog-container {
  padding: 0;
}

.cdk-overlay-pane .vnc-file-item-filter-menu {
  width: 288px;
  min-width: 288px;
  overflow: hidden;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content {
  padding: 0px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .scroll-item-bar {
  max-height: 230px;
  overflow: auto;
}
.cdk-overlay-pane .vnc-file-item-filter-menu vnc-input .vnc-form {
  margin: 0;
}
.cdk-overlay-pane .vnc-file-item-filter-menu vnc-input .vnc-form input {
  height: 32px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item {
  height: 40px;
  line-height: 40px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item[disabled] {
  opacity: 0.8;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item mat-icon {
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  margin-right: 11px;
  width: 18px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item mat-icon.check-icon {
  color: #388EDA;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item span.item-lbl {
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #131313;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item .item-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item .item-option .first-item {
  display: flex;
  align-items: center;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item.operation-button {
  display: flex;
  float: right;
  height: 30px;
  margin-top: 5px;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item.operation-button:hover {
  background: transparent;
}
.cdk-overlay-pane .vnc-file-item-filter-menu .mat-menu-content .mat-menu-item.search-item:hover {
  background: transparent;
}

.vnc-file-header-menu .mat-menu-item .mat-icon {
  margin-right: 16px;
  vertical-align: middle;
  line-height: 24px;
}

.vnc-file-preview-menu-item {
  width: 240px !important;
}
.vnc-file-preview-menu-item .mat-menu-content .hr-item {
  box-shadow: inset 0 -1px 0 0 #e3e7eb;
  height: 1px;
  width: 90%;
  margin: 3px auto;
}
.vnc-file-preview-menu-item .mat-menu-content .mat-menu-item .svg-item-in-menu {
  margin-right: 11px;
  display: flex;
}

.incoming-call-menu-item .mat-menu-item vnc-icon-container svg {
  height: 18px;
  width: 18px;
}

.gc-list-menu-item-component {
  overflow: hidden !important;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item {
  background: transparent;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item .inner-items {
  width: 100%;
  margin: 0px;
  padding: 0px 16px;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item .icon-lable .default-label-item {
  font-size: 14px;
  font-weight: normal;
  font-family: "Source Sans Pro";
  color: #131313;
}
.gc-list-menu-item-component .item-drop-down vp-dropdown-item .drop-down-item .icon-drop-item-default .label-icon .lbl {
  margin-left: 8px !important;
  font-weight: normal !important;
}

:root {
  --neutral-800: $neutral_800;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/*/
This is the mixins common file
/*/
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
@media only screen and (max-width : 599px) {
  [hidden-xs] {
    display: none;
  }
}

@media only screen and (min-width : 600px) and (max-width : 767px) {
  [hidden-sm] {
    display: none;
  }
}

@media only screen and (min-width : 600px) {
  [hidden-gt-sm] {
    display: none;
  }
}

@media only screen and (min-width : 1024px) {
  [hidden-gt-md] {
    display: none;
  }
}

@media only screen and (max-width : 599px) {
  .hidden-xs {
    display: none;
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/* Material Design Lite */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-navigation {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.mdl-navigation__link {
  color: rgb(66,66,66);
  text-decoration: none;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  opacity: 0.87;
}
.mdl-navigation__link .material-icons {
  vertical-align: middle;
}

.mdl-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.mdl-layout.is-small-screen .mdl-layout--large-screen-only {
  display: none;
}

.mdl-layout:not(.is-small-screen) .mdl-layout--small-screen-only {
  display: none;
}

.mdl-layout__container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdl-layout__title,
.mdl-layout-title {
  display: block;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box;
}

.mdl-layout-spacer {
  flex-grow: 1;
}

.mdl-layout__drawer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 240px;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-right: 1px solid rgb(224,224,224);
  background: rgb(250,250,250);
  transform: translateX(-250px);
  transform-style: preserve-3d;
  will-change: transform;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: transform;
  color: rgb(66,66,66);
  overflow: visible;
  overflow-y: auto;
  z-index: 5;
}
.mdl-layout__drawer.is-visible {
  transform: translateX(0);
}
.mdl-layout__drawer.is-visible ~ .mdl-layout__content.mdl-layout__content {
  overflow: hidden;
}
.mdl-layout__drawer > * {
  flex-shrink: 0;
}
.mdl-layout__drawer > .mdl-layout__title, .mdl-layout__drawer > .mdl-layout-title {
  line-height: 64px;
  padding-left: 40px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__drawer > .mdl-layout__title, .mdl-layout__drawer > .mdl-layout-title {
    line-height: 56px;
    padding-left: 16px;
  }
}
.mdl-layout__drawer .mdl-navigation {
  flex-direction: column;
  align-items: stretch;
  padding-top: 16px;
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
  display: block;
  flex-shrink: 0;
  padding: 16px 40px;
  margin: 0;
  color: #757575;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 16px 16px;
  }
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link:hover {
  background-color: rgb(224,224,224);
}
.mdl-layout__drawer .mdl-navigation .mdl-navigation__link--current {
  background-color: rgb(224,224,224);
  color: rgb(0,0,0);
}
@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__drawer {
    transform: translateX(0);
  }
}

.mdl-layout__drawer-button {
  display: block;
  position: absolute;
  height: 48px;
  width: 48px;
  border: 0;
  flex-shrink: 0;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  line-height: 56px;
  font-family: Helvetica, Arial, sans-serif;
  margin: 8px 12px;
  top: 0;
  left: 0;
  color: rgb(255,255,255);
  z-index: 4;
}
.mdl-layout__header .mdl-layout__drawer-button {
  position: absolute;
  color: rgb(255,255,255);
  background-color: inherit;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header .mdl-layout__drawer-button {
    margin: 4px;
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__drawer-button {
    margin: 4px;
    color: rgba(0, 0, 0, 0.5);
  }
}
@media screen and (min-width: 1025px) {
  .mdl-layout__drawer-button {
    line-height: 54px;
  }
  .mdl-layout--no-desktop-drawer-button .mdl-layout__drawer-button, .mdl-layout--fixed-drawer > .mdl-layout__drawer-button, .mdl-layout--no-drawer-button .mdl-layout__drawer-button {
    display: none;
  }
}

.mdl-layout__header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  min-height: 64px;
  max-height: 1000px;
  z-index: 3;
  background-color: rgb(63,81,181);
  color: rgb(255,255,255);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: max-height, box-shadow;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    min-height: 56px;
  }
}
.mdl-layout--fixed-drawer.is-upgraded:not(.is-small-screen) > .mdl-layout__header {
  margin-left: 240px;
  width: calc(100% - 240px);
}
@media screen and (min-width: 1025px) {
  .mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
    padding-left: 40px;
  }
}
.mdl-layout__header > .mdl-layout-icon {
  position: absolute;
  left: 40px;
  top: 16px;
  height: 32px;
  width: 32px;
  overflow: hidden;
  z-index: 3;
  display: block;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header > .mdl-layout-icon {
    left: 16px;
    top: 12px;
  }
}
.mdl-layout.has-drawer .mdl-layout__header > .mdl-layout-icon {
  display: none;
}
.mdl-layout__header.is-compact {
  max-height: 64px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact {
    max-height: 56px;
  }
}
.mdl-layout__header.is-compact.has-tabs {
  height: 112px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header.is-compact.has-tabs {
    min-height: 104px;
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header {
    display: none;
  }
  .mdl-layout--fixed-header > .mdl-layout__header {
    display: flex;
  }
}

.mdl-layout__header--transparent.mdl-layout__header--transparent {
  background-color: transparent;
  box-shadow: none;
}

.mdl-layout__header--seamed {
  box-shadow: none;
}

.mdl-layout__header--scroll {
  box-shadow: none;
}

.mdl-layout__header--waterfall {
  box-shadow: none;
  overflow: hidden;
}
.mdl-layout__header--waterfall.is-casting-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mdl-layout__header--waterfall.mdl-layout__header--waterfall-hide-top {
  justify-content: flex-end;
}

.mdl-layout__header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  box-sizing: border-box;
  align-self: stretch;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0 40px 0 80px;
}
.mdl-layout--no-drawer-button .mdl-layout__header-row {
  padding-left: 40px;
}
@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__header-row {
    padding-left: 40px;
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header-row {
    height: 56px;
    padding: 0 16px 0 72px;
  }
  .mdl-layout--no-drawer-button .mdl-layout__header-row {
    padding-left: 16px;
  }
}
.mdl-layout__header-row > * {
  flex-shrink: 0;
}
.mdl-layout__header--scroll .mdl-layout__header-row {
  width: 100%;
}
.mdl-layout__header-row .mdl-navigation {
  margin: 0;
  padding: 0;
  height: 64px;
  flex-direction: row;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation {
    height: 56px;
  }
}
.mdl-layout__header-row .mdl-navigation__link {
  display: block;
  color: rgb(255,255,255);
  line-height: 64px;
  padding: 0 24px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__header-row .mdl-navigation__link {
    line-height: 56px;
    padding: 0 16px;
  }
}

.mdl-layout__obfuscator {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  visibility: hidden;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-layout__obfuscator.is-visible {
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}
@supports (pointer-events: auto) {
  .mdl-layout__obfuscator {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }
  .mdl-layout__obfuscator.is-visible {
    pointer-events: auto;
    opacity: 1;
  }
}

.mdl-layout__content {
  -ms-flex: 0 1 auto;
  position: relative;
  display: inline-block;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  z-index: 1;
  -webkit-overflow-scrolling: touch;
}
.mdl-layout--fixed-drawer > .mdl-layout__content {
  margin-left: 240px;
}
.mdl-layout__container.has-scrolling-header .mdl-layout__content {
  overflow: visible;
}
@media screen and (max-width: 1024px) {
  .mdl-layout--fixed-drawer > .mdl-layout__content {
    margin-left: 0;
  }
  .mdl-layout__container.has-scrolling-header .mdl-layout__content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.mdl-layout__tab-bar {
  height: 96px;
  margin: 0;
  width: calc(100% -
        112px);
  padding: 0 0 0 56px;
  display: flex;
  background-color: rgb(63,81,181);
  overflow-y: hidden;
  overflow-x: scroll;
}
.mdl-layout__tab-bar::-webkit-scrollbar {
  display: none;
}
.mdl-layout--no-drawer-button .mdl-layout__tab-bar {
  padding-left: 16px;
  width: calc(100% -
          32px);
}
@media screen and (min-width: 1025px) {
  .mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar {
    padding-left: 16px;
    width: calc(100% -
            32px);
  }
}
@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar {
    width: calc(100% -
          60px);
    padding: 0 0 0 60px;
  }
  .mdl-layout--no-drawer-button .mdl-layout__tab-bar {
    width: calc(100% -
            8px);
    padding-left: 4px;
  }
}
.mdl-layout--fixed-tabs .mdl-layout__tab-bar {
  padding: 0;
  overflow: hidden;
  width: 100%;
}

.mdl-layout__tab-bar-container {
  position: relative;
  height: 48px;
  width: 100%;
  border: none;
  margin: 0;
  z-index: 2;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}
.mdl-layout__container > .mdl-layout__tab-bar-container {
  position: absolute;
  top: 0;
  left: 0;
}

.mdl-layout__tab-bar-button {
  display: inline-block;
  position: absolute;
  top: 0;
  height: 48px;
  width: 56px;
  z-index: 4;
  text-align: center;
  background-color: rgb(63,81,181);
  color: transparent;
  cursor: pointer;
  user-select: none;
}
.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button, .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button {
  width: 16px;
}
.mdl-layout--no-desktop-drawer-button .mdl-layout__tab-bar-button .material-icons, .mdl-layout--no-drawer-button .mdl-layout__tab-bar-button .material-icons {
  position: relative;
  left: -4px;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__tab-bar-button {
    width: 60px;
  }
}
.mdl-layout--fixed-tabs .mdl-layout__tab-bar-button {
  display: none;
}
.mdl-layout__tab-bar-button .material-icons {
  line-height: 48px;
}
.mdl-layout__tab-bar-button.is-active {
  color: rgb(255,255,255);
}

.mdl-layout__tab-bar-left-button {
  left: 0;
}

.mdl-layout__tab-bar-right-button {
  right: 0;
}

.mdl-layout__tab {
  margin: 0;
  border: none;
  padding: 0 24px 0 24px;
  float: left;
  position: relative;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  text-decoration: none;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(255,255,255, 0.6);
  overflow: hidden;
}
@media screen and (max-width: 1024px) {
  .mdl-layout__tab {
    padding: 0 12px 0 12px;
  }
}
.mdl-layout--fixed-tabs .mdl-layout__tab {
  float: none;
  flex-grow: 1;
  padding: 0;
}
.mdl-layout.is-upgraded .mdl-layout__tab.is-active {
  color: rgb(255,255,255);
}
.mdl-layout.is-upgraded .mdl-layout__tab.is-active::after {
  height: 2px;
  width: 100%;
  display: block;
  content: " ";
  bottom: 0;
  left: 0;
  position: absolute;
  background: rgb(255,64,129);
  animation: border-expand 0.2s cubic-bezier(0.4, 0, 0.4, 1) 0.01s alternate forwards;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}
.mdl-layout__tab .mdl-layout__tab-ripple-container {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}
.mdl-layout__tab .mdl-layout__tab-ripple-container .mdl-ripple {
  background-color: rgb(255,255,255);
}

.mdl-layout__tab-panel {
  display: block;
}
.mdl-layout.is-upgraded .mdl-layout__tab-panel {
  display: none;
}
.mdl-layout.is-upgraded .mdl-layout__tab-panel.is-active {
  display: block;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*
* NOTE: Some rules here are applied using duplicate selectors.
* This is on purpose to increase their specificity when applied.
* For example: `.mdl-cell--1-col-phone.mdl-cell--1-col-phone`
*/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-grid {
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto 0 auto;
  align-items: stretch;
}
.mdl-grid.mdl-grid--no-spacing {
  padding: 0;
}

.mdl-cell {
  box-sizing: border-box;
}

.mdl-cell--top {
  align-self: flex-start;
}

.mdl-cell--middle {
  align-self: center;
}

.mdl-cell--bottom {
  align-self: flex-end;
}

.mdl-cell--stretch {
  align-self: stretch;
}

.mdl-grid.mdl-grid--no-spacing > .mdl-cell {
  margin: 0;
}

.mdl-cell--order-1 {
  order: 1;
}

.mdl-cell--order-2 {
  order: 2;
}

.mdl-cell--order-3 {
  order: 3;
}

.mdl-cell--order-4 {
  order: 4;
}

.mdl-cell--order-5 {
  order: 5;
}

.mdl-cell--order-6 {
  order: 6;
}

.mdl-cell--order-7 {
  order: 7;
}

.mdl-cell--order-8 {
  order: 8;
}

.mdl-cell--order-9 {
  order: 9;
}

.mdl-cell--order-10 {
  order: 10;
}

.mdl-cell--order-11 {
  order: 11;
}

.mdl-cell--order-12 {
  order: 12;
}

@media (max-width: 479px) {
  .mdl-grid {
    padding: 8px;
  }
  .mdl-cell {
    margin: 8px;
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 100%;
  }
  .mdl-cell--hide-phone {
    display: none !important;
  }
  .mdl-cell--order-1-phone.mdl-cell--order-1-phone {
    order: 1;
  }
  .mdl-cell--order-2-phone.mdl-cell--order-2-phone {
    order: 2;
  }
  .mdl-cell--order-3-phone.mdl-cell--order-3-phone {
    order: 3;
  }
  .mdl-cell--order-4-phone.mdl-cell--order-4-phone {
    order: 4;
  }
  .mdl-cell--order-5-phone.mdl-cell--order-5-phone {
    order: 5;
  }
  .mdl-cell--order-6-phone.mdl-cell--order-6-phone {
    order: 6;
  }
  .mdl-cell--order-7-phone.mdl-cell--order-7-phone {
    order: 7;
  }
  .mdl-cell--order-8-phone.mdl-cell--order-8-phone {
    order: 8;
  }
  .mdl-cell--order-9-phone.mdl-cell--order-9-phone {
    order: 9;
  }
  .mdl-cell--order-10-phone.mdl-cell--order-10-phone {
    order: 10;
  }
  .mdl-cell--order-11-phone.mdl-cell--order-11-phone {
    order: 11;
  }
  .mdl-cell--order-12-phone.mdl-cell--order-12-phone {
    order: 12;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: calc(25% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-phone.mdl-cell--1-col-phone {
    width: 25%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-phone.mdl-cell--2-col-phone {
    width: 50%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: calc(75% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-phone.mdl-cell--3-col-phone {
    width: 75%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-phone.mdl-cell--4-col-phone {
    width: 100%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-phone.mdl-cell--5-col-phone {
    width: 100%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-phone.mdl-cell--6-col-phone {
    width: 100%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-phone.mdl-cell--7-col-phone {
    width: 100%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-phone.mdl-cell--8-col-phone {
    width: 100%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-phone.mdl-cell--9-col-phone {
    width: 100%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-phone.mdl-cell--10-col-phone {
    width: 100%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-phone.mdl-cell--11-col-phone {
    width: 100%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-phone.mdl-cell--12-col-phone {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: calc(25% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-phone.mdl-cell--1-offset-phone {
    margin-left: 25%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: calc(50% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-phone.mdl-cell--2-offset-phone {
    margin-left: 50%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: calc(75% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-phone.mdl-cell--3-offset-phone {
    margin-left: 75%;
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdl-grid {
    padding: 8px;
  }
  .mdl-cell {
    margin: 8px;
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 50%;
  }
  .mdl-cell--hide-tablet {
    display: none !important;
  }
  .mdl-cell--order-1-tablet.mdl-cell--order-1-tablet {
    order: 1;
  }
  .mdl-cell--order-2-tablet.mdl-cell--order-2-tablet {
    order: 2;
  }
  .mdl-cell--order-3-tablet.mdl-cell--order-3-tablet {
    order: 3;
  }
  .mdl-cell--order-4-tablet.mdl-cell--order-4-tablet {
    order: 4;
  }
  .mdl-cell--order-5-tablet.mdl-cell--order-5-tablet {
    order: 5;
  }
  .mdl-cell--order-6-tablet.mdl-cell--order-6-tablet {
    order: 6;
  }
  .mdl-cell--order-7-tablet.mdl-cell--order-7-tablet {
    order: 7;
  }
  .mdl-cell--order-8-tablet.mdl-cell--order-8-tablet {
    order: 8;
  }
  .mdl-cell--order-9-tablet.mdl-cell--order-9-tablet {
    order: 9;
  }
  .mdl-cell--order-10-tablet.mdl-cell--order-10-tablet {
    order: 10;
  }
  .mdl-cell--order-11-tablet.mdl-cell--order-11-tablet {
    order: 11;
  }
  .mdl-cell--order-12-tablet.mdl-cell--order-12-tablet {
    order: 12;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: calc(12.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-tablet.mdl-cell--1-col-tablet {
    width: 12.5%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: calc(25% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-tablet.mdl-cell--2-col-tablet {
    width: 25%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: calc(37.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-tablet.mdl-cell--3-col-tablet {
    width: 37.5%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-tablet.mdl-cell--4-col-tablet {
    width: 50%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: calc(62.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-tablet.mdl-cell--5-col-tablet {
    width: 62.5%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: calc(75% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-tablet.mdl-cell--6-col-tablet {
    width: 75%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: calc(87.5% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-tablet.mdl-cell--7-col-tablet {
    width: 87.5%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-tablet.mdl-cell--8-col-tablet {
    width: 100%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-tablet.mdl-cell--9-col-tablet {
    width: 100%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-tablet.mdl-cell--10-col-tablet {
    width: 100%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-tablet.mdl-cell--11-col-tablet {
    width: 100%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-tablet.mdl-cell--12-col-tablet {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: calc(12.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-tablet.mdl-cell--1-offset-tablet {
    margin-left: 12.5%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: calc(25% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-tablet.mdl-cell--2-offset-tablet {
    margin-left: 25%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: calc(37.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-tablet.mdl-cell--3-offset-tablet {
    margin-left: 37.5%;
  }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: calc(50% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-tablet.mdl-cell--4-offset-tablet {
    margin-left: 50%;
  }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: calc(62.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-tablet.mdl-cell--5-offset-tablet {
    margin-left: 62.5%;
  }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: calc(75% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-tablet.mdl-cell--6-offset-tablet {
    margin-left: 75%;
  }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: calc(87.5% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-tablet.mdl-cell--7-offset-tablet {
    margin-left: 87.5%;
  }
}
@media (min-width: 840px) {
  .mdl-grid {
    padding: 8px;
  }
  .mdl-cell {
    margin: 8px;
    width: calc(33.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell {
    width: 33.3333333333%;
  }
  .mdl-cell--hide-desktop {
    display: none !important;
  }
  .mdl-cell--order-1-desktop.mdl-cell--order-1-desktop {
    order: 1;
  }
  .mdl-cell--order-2-desktop.mdl-cell--order-2-desktop {
    order: 2;
  }
  .mdl-cell--order-3-desktop.mdl-cell--order-3-desktop {
    order: 3;
  }
  .mdl-cell--order-4-desktop.mdl-cell--order-4-desktop {
    order: 4;
  }
  .mdl-cell--order-5-desktop.mdl-cell--order-5-desktop {
    order: 5;
  }
  .mdl-cell--order-6-desktop.mdl-cell--order-6-desktop {
    order: 6;
  }
  .mdl-cell--order-7-desktop.mdl-cell--order-7-desktop {
    order: 7;
  }
  .mdl-cell--order-8-desktop.mdl-cell--order-8-desktop {
    order: 8;
  }
  .mdl-cell--order-9-desktop.mdl-cell--order-9-desktop {
    order: 9;
  }
  .mdl-cell--order-10-desktop.mdl-cell--order-10-desktop {
    order: 10;
  }
  .mdl-cell--order-11-desktop.mdl-cell--order-11-desktop {
    order: 11;
  }
  .mdl-cell--order-12-desktop.mdl-cell--order-12-desktop {
    order: 12;
  }
  .mdl-cell--1-col,
  .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: calc(8.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--1-col,
  .mdl-grid--no-spacing > .mdl-cell--1-col-desktop.mdl-cell--1-col-desktop {
    width: 8.3333333333%;
  }
  .mdl-cell--2-col,
  .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: calc(16.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--2-col,
  .mdl-grid--no-spacing > .mdl-cell--2-col-desktop.mdl-cell--2-col-desktop {
    width: 16.6666666667%;
  }
  .mdl-cell--3-col,
  .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: calc(25% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--3-col,
  .mdl-grid--no-spacing > .mdl-cell--3-col-desktop.mdl-cell--3-col-desktop {
    width: 25%;
  }
  .mdl-cell--4-col,
  .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: calc(33.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--4-col,
  .mdl-grid--no-spacing > .mdl-cell--4-col-desktop.mdl-cell--4-col-desktop {
    width: 33.3333333333%;
  }
  .mdl-cell--5-col,
  .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: calc(41.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--5-col,
  .mdl-grid--no-spacing > .mdl-cell--5-col-desktop.mdl-cell--5-col-desktop {
    width: 41.6666666667%;
  }
  .mdl-cell--6-col,
  .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: calc(50% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--6-col,
  .mdl-grid--no-spacing > .mdl-cell--6-col-desktop.mdl-cell--6-col-desktop {
    width: 50%;
  }
  .mdl-cell--7-col,
  .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: calc(58.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--7-col,
  .mdl-grid--no-spacing > .mdl-cell--7-col-desktop.mdl-cell--7-col-desktop {
    width: 58.3333333333%;
  }
  .mdl-cell--8-col,
  .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: calc(66.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--8-col,
  .mdl-grid--no-spacing > .mdl-cell--8-col-desktop.mdl-cell--8-col-desktop {
    width: 66.6666666667%;
  }
  .mdl-cell--9-col,
  .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: calc(75% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--9-col,
  .mdl-grid--no-spacing > .mdl-cell--9-col-desktop.mdl-cell--9-col-desktop {
    width: 75%;
  }
  .mdl-cell--10-col,
  .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: calc(83.3333333333% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--10-col,
  .mdl-grid--no-spacing > .mdl-cell--10-col-desktop.mdl-cell--10-col-desktop {
    width: 83.3333333333%;
  }
  .mdl-cell--11-col,
  .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: calc(91.6666666667% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--11-col,
  .mdl-grid--no-spacing > .mdl-cell--11-col-desktop.mdl-cell--11-col-desktop {
    width: 91.6666666667%;
  }
  .mdl-cell--12-col,
  .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: calc(100% - 16px);
  }
  .mdl-grid--no-spacing > .mdl-cell--12-col,
  .mdl-grid--no-spacing > .mdl-cell--12-col-desktop.mdl-cell--12-col-desktop {
    width: 100%;
  }
  .mdl-cell--1-offset,
  .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: calc(8.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--1-offset-desktop.mdl-cell--1-offset-desktop {
    margin-left: 8.3333333333%;
  }
  .mdl-cell--2-offset,
  .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: calc(16.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--2-offset-desktop.mdl-cell--2-offset-desktop {
    margin-left: 16.6666666667%;
  }
  .mdl-cell--3-offset,
  .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: calc(25% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--3-offset-desktop.mdl-cell--3-offset-desktop {
    margin-left: 25%;
  }
  .mdl-cell--4-offset,
  .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: calc(33.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--4-offset-desktop.mdl-cell--4-offset-desktop {
    margin-left: 33.3333333333%;
  }
  .mdl-cell--5-offset,
  .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: calc(41.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--5-offset-desktop.mdl-cell--5-offset-desktop {
    margin-left: 41.6666666667%;
  }
  .mdl-cell--6-offset,
  .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: calc(50% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--6-offset-desktop.mdl-cell--6-offset-desktop {
    margin-left: 50%;
  }
  .mdl-cell--7-offset,
  .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: calc(58.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--7-offset-desktop.mdl-cell--7-offset-desktop {
    margin-left: 58.3333333333%;
  }
  .mdl-cell--8-offset,
  .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: calc(66.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--8-offset-desktop.mdl-cell--8-offset-desktop {
    margin-left: 66.6666666667%;
  }
  .mdl-cell--9-offset,
  .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: calc(75% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--9-offset-desktop.mdl-cell--9-offset-desktop {
    margin-left: 75%;
  }
  .mdl-cell--10-offset,
  .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: calc(83.3333333333% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--10-offset-desktop.mdl-cell--10-offset-desktop {
    margin-left: 83.3333333333%;
  }
  .mdl-cell--11-offset,
  .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: calc(91.6666666667% + 8px);
  }
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset,
  .mdl-grid.mdl-grid--no-spacing > .mdl-cell--11-offset-desktop.mdl-cell--11-offset-desktop {
    margin-left: 91.6666666667%;
  }
}
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Typography */
/* Shadows */
/* Animations */
/* Dialog */
.mdl-popover {
  background: #fff;
  border: none;
  display: block;
  visibility: hidden;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: absolute;
}
.mdl-popover.is-visible, .mdl-popover.is-animating {
  visibility: visible;
  z-index: 999;
}

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*\
    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* ==========  COLORS  ========== */
/**
*
* Material design color palettes.
* @see http://www.google.com/design/spec/style/color.html
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  Color Palettes  ========== */
/* colors.scss */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* ==========  IMAGES  ========== */
/* ==========  Color & Themes  ========== */
/* ==========  Typography  ========== */
/* ==========  Components  ========== */
/* ==========  Standard Buttons  ========== */
/* ==========  Icon Toggles  ========== */
/* ==========  Radio Buttons  ========== */
/* ==========  Ripple effect  ========== */
/* ==========  Layout  ========== */
/* ==========  Content Tabs  ========== */
/* ==========  Checkboxes  ========== */
/* ==========  Switches  ========== */
/* ==========  Spinner  ========== */
/* ==========  Text fields  ========== */
/* ==========  Card  ========== */
/* ==========  Sliders ========== */
/* ========== Progress ========== */
/* ==========  List ========== */
/* ==========  Item ========== */
/* ==========  Dropdown menu ========== */
/* ==========  Tooltips  ========== */
/* ==========  Footer  ========== */
/* TEXTFIELD */
/* SWITCH */
/* SPINNER */
/* RADIO */
/* MENU */
/* LIST */
/* LAYOUT */
/* ICON TOGGLE */
/* FOOTER */
/*mega-footer*/
/*mini-footer*/
/* CHECKBOX */
/* CARD */
/* Card dimensions */
/* Cover image */
/* BUTTON */
/**
 *
 * Dimensions
 *
 */
/* ANIMATION */
/* PROGRESS */
/* BADGE */
/* SHADOWS */
/* GRID */
/* DATA TABLE */
/* DIALOG */
/* SNACKBAR */
/* TOOLTIP */
/* CHIP */
.mdl-select--floating-label {
  padding: 0;
}
.mdl-select--floating-label .mdl-textfield.is-dirty .mdl-textfield__label {
  color: rgb(63,81,181);
  font-size: 12px;
  top: 4px;
  visibility: visible;
}
.mdl-select .mdl-popover.direction-up {
  bottom: 22px;
}
.mdl-select .mdl-popover.direction-up.mdl-popover--above {
  bottom: 66px;
}
.mdl-select__toggle {
  cursor: pointer;
  float: right;
  margin-top: -1.75rem;
}
.mdl-select .mdl-textfield__input {
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  padding-right: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mdl-select .mdl-textfield__input:focus {
  outline: none;
}
.mdl-select .mdl-textfield.is-focused .mdl-select__toggle {
  color: rgb(63,81,181);
}
.mdl-select .mdl-textfield.is-invalid .mdl-select__toggle {
  color: rgb(213,0,0);
}
.mdl-select .mdl-textfield.is-disabled .mdl-select__toggle {
  color: rgba(0,0,0, 0.26);
}
.mdl-select .mdl-list {
  max-height: 280px;
  overflow-y: auto;
  padding: 0;
}
.mdl-select .mdl-list__item {
  cursor: pointer;
}
.mdl-select .mdl-list__item:hover {
  background-color: rgb(238,238,238);
}
.mdl-select .mdl-list__item.is-active {
  color: rgb(63,81,181);
  background-color: rgb(224,224,224);
}
.mdl-select .mdl-option--disabled .mdl-list__item {
  cursor: default;
  opacity: 0.5;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/*
==========================================================
                  GENERAL DEFINITIONS
==========================================================
*/
[layout] {
  display: flex;
}

[layout*=row],
[layout*=column] {
  width: 100%;
  max-width: 100%;
}

[layout^=row] {
  flex-direction: row;
}

[layout^=column] {
  flex-direction: column;
}

[layout*=row][layout*=reverse] {
  flex-direction: row-reverse;
}

[layout*=column][layout*=reverse] {
  flex-direction: column-reverse;
}

[layout*=rows],
[layout*=columns] {
  flex-wrap: wrap;
}

[layout=none] {
  flex: none;
}

/*
==========================================================
                  MAIN AXIS ALIGNMENT
==========================================================
*/
[layout*=column][layout*=top-],
[layout*=row][layout*=-left] {
  justify-content: flex-start;
}

[layout~=centered],
[layout*=column][layout*=center-],
[layout*=row][layout*=-center] {
  justify-content: center;
}

[layout*=column][layout*=bottom-],
[layout*=row][layout*=-right] {
  justify-content: flex-end;
}

[layout*=column][layout*=spread-],
[layout*=row][layout*=-spread] {
  justify-content: space-around;
}

[layout*=column][layout*=justify-],
[layout*=row][layout*=-justify] {
  justify-content: space-between;
}

/*
==========================================================
                CROSS AXIS ALIGNMENT
==========================================================
*/
[layout*=column][layout*=-left],
[layout*=row][layout*=top-] {
  align-items: flex-start;
}

[layout~=centered],
[layout*=column][layout*=-center],
[layout*=row][layout*=center-] {
  align-items: center;
}

[layout*=column][layout*=-right],
[layout*=row][layout*=bottom-] {
  align-items: flex-end;
}

[layout*=column][layout*=-stretch],
[layout*=row][layout*=stretch-] {
  align-items: stretch;
}

/*
==========================================================
               MULTI-LINE ALIGNMENT
==========================================================
*/
[layout*=rows][layout*=top-],
[layout*=columns][layout*=-left] {
  align-content: flex-start;
}

[layout*=rows][layout*=bottom-],
[layout*=columns][layout*=-right] {
  align-content: flex-end;
}

[layout*=rows][layout*=center-],
[layout*=columns][layout*=-center] {
  align-content: center;
}

[layout*=rows][layout*=justify-],
[layout*=columns][layout*=-justify] {
  align-content: space-between;
}

[layout*=rows][layout*=spread-],
[layout*=columns][layout*=-spread] {
  align-content: space-around;
}

[layout*=rows][layout*=stretch-],
[layout*=columns][layout*=-stretch] {
  align-content: stretch;
}

/*
==========================================================
                       IE10+ HACKS
==========================================================
*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  [layout*=column]:not([layout*=row]) > * {
    max-width: auto;
  }
  [layout*=column][self*=top] {
    height: auto !important;
  }
  [self~=size-] > * {
    height: auto;
  }
}
/*
==========================================================
                    SELF ALIGNMENT
==========================================================
*/
[layout*=column]:not([layout*=row]) [self*=left],
[layout*=row]:not([layout*=column]) [self*=top] {
  align-self: flex-start;
}

[self~=center] {
  align-self: center;
}

[layout*=column]:not([layout*=row]) [self*=right],
[layout*=row]:not([layout*=column]) [self*=bottom] {
  align-self: flex-end;
}

[self*=stretch] {
  align-self: stretch;
}

/* ALIGN VIA MARGINS
========================================================== */
/* Container: */
[layout][self*=center] {
  margin-left: auto;
  margin-right: auto;
}

[layout][self*=right] {
  margin-right: 0;
}

[layout][self*=left] {
  margin-left: 0;
}

/* Items: */
[layout*=column] [self*=bottom] {
  margin-top: auto;
}

[layout*=column] [self*=top] {
  margin-bottom: auto;
}

[layout*=row] [self*=left] {
  margin-right: auto;
}

[layout*=row] [self*=right] {
  margin-left: auto;
}

/*
==========================================================
                      SELF SIZE
==========================================================
*/
/* Relative size */
[self~=size-1of5] {
  width: 20%;
}

[self~=size-1of4] {
  width: 25%;
}

[self~=size-1of3] {
  width: 33.33333%;
}

[self~=size-2of5] {
  width: 40%;
}

[self~=size-1of2] {
  width: 50%;
}

[self~=size-3of5] {
  width: 60%;
}

[self~=size-2of3] {
  width: 66.6666%;
}

[self~=size-3of4] {
  width: 75%;
}

[self~=size-4of5] {
  width: 80%;
}

[self~=size-1of1] {
  width: 100%;
}

/* Proportional size*/
[layout*=row][layout*=-stretch] > *:not([self*=size-]),
[layout*=column][layout*=stretch-] > *:not([self*=size-]),
[self~=size-x1] {
  flex: 1 0 0% !important;
}

[self~=size-x2] {
  flex: 2 0 0% !important;
}

[self~=size-x3] {
  flex: 3 0 0% !important;
}

[self~=size-x4] {
  flex: 4 0 0% !important;
}

[self~=size-x5] {
  flex: 5 0 0% !important;
}

[self~=size-x6] {
  flex: 6 0 0% !important;
}

[self~=size-x7] {
  flex: 7 0 0% !important;
}

[self~=size-x8] {
  flex: 8 0 0% !important;
}

[self~=size-x9] {
  flex: 9 0 0% !important;
}

[self*=size-auto] {
  flex: 1 1 auto;
}

[self*=size-x0] {
  flex: 0 0 auto;
}

/* Fixed max-width size */
[self~=size-xxlarge] {
  max-width: 1440px;
  width: 100%;
}

[self~=size-xlarge] {
  max-width: 1200px;
  width: 100%;
}

[self~=size-large] {
  max-width: 960px;
  width: 100%;
}

[self~=size-larger] {
  max-width: 840px;
  width: 100%;
}

[self~=size-medium] {
  max-width: 720px;
  width: 100%;
}

[self~=size-smaller] {
  max-width: 600px;
  width: 100%;
}

[self~=size-small] {
  max-width: 480px;
  width: 100%;
}

[self~=size-xsmall] {
  max-width: 360px;
  width: 100%;
}

[self~=size-xxsmall] {
  max-width: 240px;
  width: 100%;
}

[self*=size-x]:not([self*=small]):not([self*=large]) {
  flex-shrink: 1;
}

[self~=first] {
  order: -1;
}

[self~=order-1] {
  order: 1;
}

[self~=order-2] {
  order: 2;
}

[self~=order-3] {
  order: 3;
}

[self~=last] {
  order: 999;
}

[layout*=column]:not([layout*=row]) > * {
  flex-shrink: 0;
  flex-basis: auto;
}

/* Optional additional features — (un)comment to include/exclude */
@media screen and (max-width: 64em) {
  [layout*=lg-row] {
    flex-direction: row;
  }
  [layout*=lg-column] {
    flex-direction: column;
  }
  [layout*=lg-rows],
  [layout*=lg-columns] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 52em) {
  [layout*=md-row] {
    flex-direction: row;
  }
  [layout*=md-column] {
    flex-direction: column;
  }
  [layout*=md-rows],
  [layout*=md-columns] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 40em) {
  [layout*=sm-row] {
    flex-direction: row;
  }
  [layout*=sm-column] {
    flex-direction: column;
  }
  [layout*=sm-rows],
  [layout*=sm-columns] {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 64em) {
  [self*=lg-full] {
    flex: 1 1 100% !important;
    width: 100%;
    max-width: 100%;
  }
  [self*=lg-half] {
    flex: 1 1 50% !important;
    width: 50%;
    max-width: 50%;
  }
  [self~=lg-first] {
    order: -1;
  }
  [self~=lg-last] {
    order: 999;
  }
  [self~=lg-hide] {
    display: none;
  }
  [self~=lg-show] {
    display: inherit;
  }
}
@media screen and (max-width: 52em) {
  [self*=md-full] {
    flex: 1 1 100% !important;
    width: 100%;
    max-width: 100%;
  }
  [self*=md-half] {
    flex: 1 1 50% !important;
    width: 50%;
    max-width: 50%;
  }
  [self~=md-first] {
    order: -1;
  }
  [self~=md-last] {
    order: 999;
  }
  [self~=md-hide] {
    display: none;
  }
  [self~=md-show] {
    display: inherit;
  }
}
@media screen and (max-width: 40em) {
  [self*=sm-full] {
    flex: 1 1 100% !important;
    width: 100%;
    max-width: 100%;
  }
  [self*=sm-half] {
    flex: 1 1 50% !important;
    width: 50%;
    max-width: 50%;
  }
  [self~=sm-first] {
    order: -1;
  }
  [self~=sm-last] {
    order: 999;
  }
  [self~=sm-hide] {
    display: none;
  }
  [self~=sm-show] {
    display: inherit;
  }
}
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.material-icons {
  display: inline-block;
  font-family: "Material Icons";
  font-size: 24px;
  /* Preferred icon size */
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for IE. */
  font-feature-settings: "liga";
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

p {
  font-size: 1em;
}

* {
  font-family: "Roboto";
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/*
 Usage:
  For mdl-menu with arrow at left middle: <mdl-menu class='menu-rightward'>
  For mdl-menu with arrow at top right: <mdl-menu class='menu-downward__right--10'>

  For mdl-tooltip with arrow at bottom middle: <mdl-menu class='tooltip-upward'>
*/
.tooltip-upward {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-upward::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  bottom: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  border-top: 7px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
}

.menu-upward .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-upward .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  bottom: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  border-top: 7px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
}

.tooltip-downward {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-downward::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  border-right: 6px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
}

.menu-downward .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-downward .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  border-right: 6px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
}

.tooltip-leftward {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-leftward::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  right: -6px;
  filter: drop-shadow(2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 7px solid #fff;
  -webkit-filter: drop-shadow(2px 0 1px rgba(4, 4, 4, 0.2));
}

.menu-leftward .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-leftward .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  right: -6px;
  filter: drop-shadow(2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 7px solid #fff;
  -webkit-filter: drop-shadow(2px 0 1px rgba(4, 4, 4, 0.2));
}

.tooltip-rightward {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-rightward::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  left: -6px;
  filter: drop-shadow(-2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 6px solid transparent;
  -webkit-filter: drop-shadow(-2px 0 1px rgba(4, 4, 4, 0.2));
}

.menu-rightward .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-rightward .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  left: -6px;
  filter: drop-shadow(-2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 6px solid transparent;
  -webkit-filter: drop-shadow(-2px 0 1px rgba(4, 4, 4, 0.2));
}

.tooltip-upward__right--10 {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-upward__right--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  bottom: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  border-top: 7px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  right: 10px;
  left: initial;
}

.menu-upward__right--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-upward__right--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  bottom: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  border-top: 7px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  right: 10px;
  left: initial;
}

.tooltip-downward__right--10 {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-downward__right--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  border-right: 6px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  right: 10px;
  left: initial;
}

.menu-downward__right--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-downward__right--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  border-right: 6px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  right: 10px;
  left: initial;
}

.tooltip-leftward__top--10 {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-leftward__top--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  right: -6px;
  filter: drop-shadow(2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 7px solid #fff;
  -webkit-filter: drop-shadow(2px 0 1px rgba(4, 4, 4, 0.2));
  top: 10px;
  bottom: initial;
}

.menu-leftward__top--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-leftward__top--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  right: -6px;
  filter: drop-shadow(2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 7px solid #fff;
  -webkit-filter: drop-shadow(2px 0 1px rgba(4, 4, 4, 0.2));
  top: 10px;
  bottom: initial;
}

.tooltip-rightward__top--10 {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-rightward__top--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  left: -6px;
  filter: drop-shadow(-2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 6px solid transparent;
  -webkit-filter: drop-shadow(-2px 0 1px rgba(4, 4, 4, 0.2));
  top: 10px;
  bottom: initial;
}

.menu-rightward__top--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-rightward__top--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  left: -6px;
  filter: drop-shadow(-2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 6px solid transparent;
  -webkit-filter: drop-shadow(-2px 0 1px rgba(4, 4, 4, 0.2));
  top: 10px;
  bottom: initial;
}

.tooltip-upward__left--10 {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-upward__left--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  bottom: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  border-top: 7px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  right: initial;
  left: 10px;
}

.menu-upward__left--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-upward__left--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  bottom: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  border-top: 7px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 2px 1px rgba(4, 4, 4, 0.2));
  right: initial;
  left: 10px;
}

.tooltip-downward__left--10 {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-downward__left--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  border-right: 6px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  right: initial;
  left: 10px;
}

.menu-downward__left--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-downward__left--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: -6px;
  left: calc(50% - 6px);
  filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  border-right: 6px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 6px solid transparent;
  -webkit-filter: drop-shadow(0 -2px 1px rgba(4, 4, 4, 0.2));
  right: initial;
  left: 10px;
}

.tooltip-leftward__bottom--10 {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-leftward__bottom--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  right: -6px;
  filter: drop-shadow(2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 7px solid #fff;
  -webkit-filter: drop-shadow(2px 0 1px rgba(4, 4, 4, 0.2));
  top: initial;
  bottom: 10px;
}

.menu-leftward__bottom--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-leftward__bottom--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  right: -6px;
  filter: drop-shadow(2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 7px solid #fff;
  -webkit-filter: drop-shadow(2px 0 1px rgba(4, 4, 4, 0.2));
  top: initial;
  bottom: 10px;
}

.tooltip-rightward__bottom--10 {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.tooltip-rightward__bottom--10::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  left: -6px;
  filter: drop-shadow(-2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 6px solid transparent;
  -webkit-filter: drop-shadow(-2px 0 1px rgba(4, 4, 4, 0.2));
  top: initial;
  bottom: 10px;
}

.menu-rightward__bottom--10 .mdl-menu__outline {
  border-radius: 4px;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.14), 1px 0 1px -2px rgba(0, 0, 0, 0.2), -1px 0 5px 0 rgba(0, 0, 0, 0.12);
}
.menu-rightward__bottom--10 .mdl-menu__container::after {
  position: absolute;
  z-index: 999;
  width: 0;
  height: 0;
  content: "";
  top: calc(50% - 6px);
  left: -6px;
  filter: drop-shadow(-2 0 1px rgba(4, 4, 4, 0.2));
  border-top: 6px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 6px solid transparent;
  -webkit-filter: drop-shadow(-2px 0 1px rgba(4, 4, 4, 0.2));
  top: initial;
  bottom: 10px;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/*
  class='mdl-button brand-button' for Cancel button
  class='mdl-button submit-button' for Post/Submit/Ok button
 */
.submit-button, .mat-button.brand-button {
  border-radius: 16.5px;
  background-color: #5c5c5c;
  color: #fff;
  font-size: 0.85em;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 20px;
  max-height: 34px;
}
.submit-button:hover, .mat-button.brand-button:hover {
  background: #4D4D4D;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.submit-button:disabled, .mat-button.brand-button:disabled {
  box-shadow: none;
}

.submit-button {
  background-color: #0071bb;
}
.submit-button:hover {
  background: #2c74b3;
}

.mdl-button--fab {
  display: flex;
  width: 56epx;
  min-width: 56epx;
  height: 56epx;
  margin: 5px;
  color: #737373;
  justify-content: center;
  align-items: center;
}
.mdl-button--fab mdl-icon {
  font-size: 24px;
}
.mdl-button--fab i.fa {
  font-size: 22px;
}
@media only screen and (max-width : 599px) {
  .mdl-button--fab {
    display: flex;
    width: 56px;
    min-width: 56px;
    height: 56px;
    margin: 5px;
    color: #737373;
    justify-content: center;
    align-items: center;
  }
  .mdl-button--fab mdl-icon {
    font-size: 24px;
  }
  .mdl-button--fab i.fa {
    font-size: 22px;
  }
}

.mdl-list__item .mdl-button--fab {
  display: flex;
  width: 42px;
  min-width: 42px;
  height: 42px;
  margin: 5px;
  color: #737373;
  justify-content: center;
  align-items: center;
}
.mdl-list__item .mdl-button--fab mdl-icon {
  font-size: 19px;
}
.mdl-list__item .mdl-button--fab i.fa {
  font-size: 17px;
}
@media only screen and (max-width : 599px) {
  .mdl-list__item .mdl-button--fab {
    display: flex;
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin: 5px;
    color: #737373;
    justify-content: center;
    align-items: center;
  }
  .mdl-list__item .mdl-button--fab mdl-icon {
    font-size: 17px;
  }
  .mdl-list__item .mdl-button--fab i.fa {
    font-size: 15px;
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
body {
  overflow: hidden;
}

.full-width {
  padding: 0;
}
.full-width > .mdl-cell {
  width: 100%;
  margin: 0;
}

.mdl-snackbar__action {
  color: #0071bb;
}

mdl-layout-content {
  z-index: initial !important; /* to be able to add elements on top of header */
  max-width: 100%;
  overflow-x: hidden;
  background-color: #E3E7EB;
}
mdl-layout-content .mdl-grid > .mdl-cell {
  margin-top: 3px;
  margin-bottom: 0;
}
mdl-layout-content.noscroll {
  overflow-y: hidden !important;
}

.mdl-layout__header {
  z-index: 4;
}

.brand-color,
.brand-color.mdl-button,
.brand-color.mdl-button:hover,
.brand-color.mdl-button:focus,
.brand-color.mdl-button:focus:not(:active) {
  color: #FFF;
  background-color: #0071bb;
}

.brand-button-color,
.brand-button-color.mdl-button,
.brand-button-color.mdl-button:hover,
.brand-button-color.mdl-button:focus,
.brand-button-color.mdl-button:focus:not(:active) {
  color: #FFF;
  background-color: #29b6f6;
}

.loader-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}
.loader-wrapper .loader-container {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.loader-wrapper .mdl-spinner {
  margin-right: 5px;
  vertical-align: middle;
}

.full-width {
  width: 100vw;
  padding: 0;
  margin: 0;
}

.sidebar-collapsed {
  width: 60px;
  margin: 0;
}

.sidebar-expanded {
  width: 350px;
  margin: 0;
}

.preserve-font-size-ios {
  -webkit-text-size-adjust: 100%;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media only screen and (min-width : 1024px) {
  .content-in-sidebar-collapsed {
    width: calc(100% - 80px);
  }
  .content-in-sidebar-expanded {
    width: calc(100% - 370px);
  }
}
.pointer {
  cursor: pointer;
}

tag-input tag {
  align-items: center;
}

.position-relative {
  position: relative;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.mdl-dialog {
  min-width: 450px;
  padding: 0;
  height: auto;
}
.mdl-dialog.modal-profile {
  min-width: 600px;
}
@media (max-width: 768px) {
  .mdl-dialog.modal-profile {
    min-width: 100%;
  }
}
.mdl-dialog .dialog-body {
  max-height: calc(100vh - 150px);
  margin-top: 60px;
  overflow: auto;
}
@media (max-width: 768px) {
  .mdl-dialog {
    width: 100%;
    height: 100%;
  }
  .mdl-dialog .dialog-body {
    max-height: calc(100vh - 60px);
  }
}

.dialog-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  min-height: 60px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: normal;
}

.dialog-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 56px;
  height: auto;
  margin: auto;
  cursor: pointer;
}

.dialog-body {
  margin-top: 60px;
}

.dialog-header h4 {
  margin: 0;
  font-size: 18px;
}
@media only screen and (min-width : 1024px) {
  .dialog-header h4 {
    text-transform: uppercase;
  }
}

.dialog__mobile-search input {
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  border: 0;
  outline: none;
}
.dialog__mobile-search input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: rgba(255, 255, 255, 0.5);
}
.dialog__mobile-search input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.dialog__mobile-search input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: rgba(255, 255, 255, 0.5);
}
.dialog__mobile-search input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(255, 255, 255, 0.5);
}
.dialog__mobile-search input::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(255, 255, 255, 0.5);
}

.mat-media-dialog-container .mat-dialog-container {
  background: transparent;
}
@media (max-width: 600px) {
  .mat-media-dialog-container .mat-dialog-container {
    padding: 0;
  }
}

.mat-under-development-dialog .mat-dialog-container {
  padding: 0;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
@media only screen and (min-width : 768px) {
  .content-in-sidebar-collapsed .task-filter {
    padding-left: 30px;
  }
}

.task-filter {
  display: flex;
  padding: 0 13px;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width : 768px) {
  .task-filter {
    height: 60px;
  }
}
@media only screen and (max-width : 1023px) {
  .task-filter {
    padding: 0 14px;
    background-color: #f6f6f6;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    height: 42px;
  }
  .task-filter .mdl-button {
    padding: 0;
  }
  .task-filter .mdl-select .mdl-textfield__input {
    padding-right: 2.5em;
    text-align: right;
    border-bottom: 0;
  }
}
@media only screen and (max-width : 1023px) and (max-width : 1023px) {
  .task-filter .mdl-select .mdl-textfield__input {
    font-size: 14px;
  }
}
.task-filter.notask {
  display: none;
}
.task-filter .mdl-textfield {
  width: 120px;
  padding: 0;
}
.task-filter mdl-icon {
  vertical-align: middle;
  margin-left: 5px;
}
.task-filter .mdl-selection-option .mdl-list__item.is-active {
  color: #000000;
  background-color: #f0f0f0;
}

.filter-sort-div {
  height: 42px;
  line-height: 42px;
}

.align-popover .mdl-popover {
  left: -30px;
}

.tasks-reload-btn {
  margin-right: 15px;
  font-size: 22px;
  vertical-align: middle;
  cursor: pointer;
}

.sort-by {
  width: 43px;
  height: 14px;
  margin-right: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3.57;
  color: #6f6f6f;
  text-align: left;
  letter-spacing: -0.1px;
}
@media only screen and (max-width : 599px) {
  .sort-by {
    display: none;
  }
}

.sort-icon {
  width: 9px;
  height: 6px;
  font-family: Material-Design-Iconic-Font;
  font-size: 16.2px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  color: #414141;
  text-align: left;
  letter-spacing: normal;
}

button.due-date-asc,
span.is-animating,
span.is-visible {
  background-color: transparent;
}

.filter-option-label {
  height: 19px;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  color: #000;
  text-align: left;
  letter-spacing: -0.2px;
  display: flex;
}
@media only screen and (max-width : 1023px) {
  .filter-option-label {
    font-size: 14px;
    margin-left: 0px;
  }
}
.filter-option-label .text-label {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px;
  margin-left: 5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}
@media only screen and (max-width : 1023px) {
  .filter-option-label .text-label {
    max-width: 110px;
    margin-left: 0px;
  }
}

.cdk-overlay-pane .mat-menu-panel {
  box-shadow: 0px 1px 13px 0 rgba(0, 0, 0, 0.5);
  border-radius: 2.6px;
  max-height: calc(100vh - 140px);
  max-width: 294px !important;
}

.task-filter-menu .mat-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.task-filter-menu .mat-menu-item mdl-icon {
  font-size: 18px;
  vertical-align: middle;
  width: 25px;
}
.task-filter-menu .mat-menu-item mat-icon {
  font-size: 18px;
  vertical-align: middle;
  color: #000000;
}
.task-filter-menu .mat-menu-item svg {
  margin-right: 16px;
}
.task-filter-menu .mat-menu-item svg g {
  fill: #000000;
}
.task-filter-menu .mat-menu-item svg g path {
  fill: #000000;
}
.task-filter-menu .mat-menu-item span {
  margin-left: 10px;
  font-size: 16px;
  margin-right: 10px;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-filter-menu .mat-menu-item .rounded-rectangle {
  width: 60px;
  text-align: center;
  position: relative;
  color: #888888;
}
.task-filter-menu .mat-menu-item .rounded-rectangle span {
  padding: 3px 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  background-color: #888;
  font-size: 14px;
}

.viewMoreOptions {
  margin-left: -15px;
}
.viewMoreOptions.sub-options {
  margin-left: 0;
}
.viewMoreOptions .mat-menu-item {
  padding-right: 40px;
  width: 220px;
  position: relative;
}
.viewMoreOptions .mat-menu-item:hover, .viewMoreOptions .mat-menu-item.selected {
  background-color: #F7F8FA;
}
.viewMoreOptions .mat-icon {
  display: inline;
  font-size: 18px;
}
.viewMoreOptions .mat-icon.active-view {
  color: #317bbc;
  position: absolute;
  right: 3px;
  top: 13px;
  margin: 0;
}
.viewMoreOptions .mat-divider {
  border-color: #DADEE2;
}
.viewMoreOptions .title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: left;
  color: #606B76;
  padding: 16px;
  text-transform: uppercase;
}

.filter-sort-div-mobile {
  cursor: pointer;
}
.filter-sort-div-mobile .mat-icon {
  vertical-align: middle;
}

.filter-task-div {
  display: flex;
  justify-content: center;
}
.filter-task-div .open-tasks-checkbox mdl-checkbox {
  width: 100px;
  padding-left: 20px;
  height: 21px;
}
.filter-task-div .open-tasks-checkbox mdl-checkbox .mdl-checkbox__box-outline {
  width: 14px;
  height: 14px;
  top: 5px;
}
.filter-task-div .open-tasks-checkbox mdl-checkbox .mdl-checkbox__label {
  width: 105px;
  align-items: center;
  display: flex;
}
.filter-task-div .open-tasks-checkbox input {
  vertical-align: middle;
}
.filter-task-div .open-tasks-checkbox span {
  font-size: 14px;
  vertical-align: middle;
}
.filter-task-div .my-tasks-checkbox {
  margin-left: 15px;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.task-header-menubar {
  display: flex;
  height: 40px;
  padding: 0 13px 0 12px;
  margin: 0 13px 5px;
  background-color: #e0e6eb;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width : 1023px) {
  .task-header-menubar {
    padding: 0 13px 0 18px;
    margin: 0 0 6px;
  }
}
@media only screen and (max-width : 1023px) {
  .task-header-menubar {
    display: none;
  }
}
.task-header-menubar.notask {
  display: none;
}
.task-header-menubar .filtersortmenu mdl-checkbox {
  width: 35px;
}
.task-header-menubar .filtersortmenu mdl-checkbox .mdl-checkbox__box-outline {
  width: 15px;
  height: 15px;
}
.task-header-menubar .mdl-tooltip--large {
  padding: 10px;
  background: #000;
  opacity: 0.8;
}
@media only screen and (max-width : 1023px) {
  .task-header-menubar .mdl-tooltip {
    display: none;
  }
}

@media (max-width: 1023px) {
  .task-header-menubar-device {
    position: fixed;
    top: 0;
    z-index: 9999;
    display: flex;
    width: 100%;
    height: 50px;
    color: #fff;
    background: #0071bb;
  }
  .task-header-menubar-device .header-menubar-selected-count {
    color: #fff;
  }
  .task-header-menubar-device .filtersortmenu mdl-checkbox .mdl-checkbox__box-outline {
    width: 16px;
    height: 16px;
    border-color: #fff;
  }
  .task-header-menubar-device .filtersortmenu mdl-checkbox.is-checked .mdl-checkbox__focus-helper {
    background: #fff;
  }
  .task-header-menubar-device .icons__holder_icon .material-icons {
    font-size: 20px;
  }
  .task-header-menubar-device .icons__holder_icon .mat-icons {
    font-size: 20px;
  }
  .task-header-menubar-device .icons__holder_icon svg g {
    fill: #fff;
  }
  .task-header-menubar-device .icons__holder_icon svg g path {
    fill: #fff;
  }
  .task-header-menubar-device .icons__holder_icon {
    color: #fff;
  }
}

.clear_icon_device {
  display: none;
}
@media only screen and (max-width : 1023px) {
  .clear_icon_device {
    display: flex;
    height: 24px;
    padding: 0 15px 0 0;
    cursor: default;
  }
}

.clear_icon_desktop {
  display: none;
}
@media only screen and (min-width : 1024px) {
  .clear_icon_desktop {
    display: flex;
  }
}

.icons__holder {
  display: flex;
}
.icons__holder_icon {
  height: 24px;
  padding: 0 0 0 15px;
  color: #888;
  cursor: default;
}
.icons__holder_icon .material-icons {
  font-size: 18px;
  vertical-align: middle;
}
.icons__holder_icon .material-icons:hover {
  cursor: pointer;
}
.icons__holder_icon.active {
  color: #333;
  cursor: pointer;
}

.header-menubar-selected-count {
  font-size: 15px;
  color: #000;
}

.task-context-menu-popover mat-list-item {
  height: 20px;
}
.task-context-menu-popover mat-list-item:hover {
  cursor: pointer;
  background: #F8F8F8;
}
.task-context-menu-popover mat-list-item .mat-icon {
  font-size: 20px;
  margin-right: 15px;
  color: #000000;
  height: 20px !important;
  width: 20px !important;
}
.task-context-menu-popover mat-list-item svg {
  font-size: 20px;
  margin-right: 15px;
}
.task-context-menu-popover mat-list-item svg g {
  fill: #000000;
}
.task-context-menu-popover mdl-icon {
  margin-right: 15px;
  color: #000000;
  font-size: 20px;
}

@media only screen and (max-width : 1023px) and (orientation: landscape) {
  .task-context-menu-popover {
    height: 150px;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
vp-sidebar .vp-sidebar .sidebar-block-features:first-child {
  margin-top: 0;
}

.vp-sidebar {
  height: calc(100% - 72px);
}
.vp-sidebar .sidebar-block-header {
  min-height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}
.vp-sidebar .sidebar-block-header .feature-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.vp-sidebar .sidebar-block-header .toggle-panel-btn {
  position: absolute;
  right: -8px;
  width: 18px;
  height: 18px;
}
.vp-sidebar .sidebar-block-header .toggle-panel-btn #collapseLeftButton {
  background-color: #f4f8fa;
  padding: 0;
  width: 18px;
  height: 18px;
}
.vp-sidebar .sidebar-block-header .toggle-panel-btn #collapseLeftButton:hover {
  background-color: #34a0f7;
  border: 1px solid #34A0F7;
}
.vp-sidebar .sidebar-block-header .toggle-panel-btn #collapseLeftButton:hover svg g path {
  fill: #fff;
}
.vp-sidebar .sidebar-block-header .toggle-panel-btn #collapseButton {
  background-color: #f4f8fa;
  padding: 0;
  width: 18px;
  height: 18px;
}
.vp-sidebar .sidebar-block-header .toggle-panel-btn #collapseButton:hover {
  background-color: #34a0f7;
  border: 1px solid #34A0F7;
}
.vp-sidebar .sidebar-block-header .toggle-panel-btn #collapseButton:hover svg g path {
  fill: #fff;
}
.vp-sidebar .sidebar-block-header .sidebar-item {
  position: relative;
  display: flex;
  height: 60px;
  line-height: 60px;
  cursor: pointer;
  align-items: center;
  width: 100%;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-icon {
  display: flex;
  min-width: 60px;
  height: 100%;
  padding-bottom: 2px;
  color: #8f8f8f;
  justify-content: center;
  align-items: center;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-icon i.fa,
.vp-sidebar .sidebar-block-header .sidebar-item .item-icon mdl-icon {
  vertical-align: middle;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-icon img {
  width: 30px;
  height: 30px;
  padding: 3px;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-icon ~ .item-title {
  padding-left: 3px;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-title {
  flex: 1 1 auto;
  width: 200px;
  padding-left: 20px;
  overflow: hidden;
  color: #565656;
  vertical-align: top;
  align-self: stretch;
  order: 0;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-title mdl-icon {
  vertical-align: middle;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-badge {
  flex: 0 1 auto;
  width: 50px;
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  align-self: stretch;
  order: 0;
}
.vp-sidebar .sidebar-block-header .sidebar-item .item-badge span {
  display: inline-block;
  min-width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 11px;
  line-height: 10px;
  color: #fff;
  text-align: center;
  background-color: #FE4100;
  border-radius: 30px;
}
.vp-sidebar .sidebar-block-header .sidebar-item .new-task-btn {
  width: 45px;
  height: 45px;
  background-color: #34A0F7;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
}
.vp-sidebar .sidebar-block-header .sidebar-item .new-task-btn mdl-icon {
  color: #fff;
  line-height: 1.5;
}
.vp-sidebar .sidebar-block-header .sidebar-item .sidebar-collapse-btn {
  margin-right: 0;
  position: fixed;
  left: 76px;
  top: 105px;
  z-index: 2;
  background: transparent;
  padding: 0px;
}
.vp-sidebar .sidebar-block-header .sidebar-item .mdl-button:active {
  background-color: transparent;
}
.vp-sidebar .sidebar-block-header .sidebar-item .mdl-button:hover {
  background-color: transparent;
}
.vp-sidebar .sidebar-block-header .sidebar-item .mat-button {
  min-width: 36px;
}
.vp-sidebar .sidebar-block-header .sidebar-item.active {
  position: relative;
  padding-left: 0;
  background-color: #F9F9FA;
}
.vp-sidebar .task-sidebar-block {
  height: 100%;
  margin-top: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vp-sidebar .task-sidebar-block .feature-title {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}
.vp-sidebar .task-sidebar-block .header-title-div {
  display: flex;
  margin: 15px 24px 15px 12px;
  cursor: pointer;
}
.vp-sidebar .task-sidebar-block .header-title-div.collapsed {
  margin: 15px 24px 15px 0px;
}
.vp-sidebar .task-sidebar-block .header-title-div .header-title {
  font-size: 14px;
  font-weight: 600;
  width: 210px;
  text-transform: uppercase;
}
.vp-sidebar .task-sidebar-block .header-title-div .icon-div {
  display: flex;
}
.vp-sidebar .task-sidebar-block .header-title-div .icon-div .add-list-icon {
  margin-right: 10px;
  color: #8B96A0;
}
.vp-sidebar .task-sidebar-block .header-title-div .icon-div mdl-icon {
  font-size: 18px;
  color: #8B96A0;
}
.vp-sidebar .task-sidebar-block .header-title-div .icon-div .mat-icon {
  font-size: 18px;
}
.vp-sidebar .task-sidebar-block .header-title-div .item-icon {
  display: flex;
  min-width: 60px;
  height: 100%;
  padding-bottom: 2px;
  color: #888;
  justify-content: center;
  align-items: center;
}
.vp-sidebar .task-sidebar-block .header-title-div .item-icon i.fa,
.vp-sidebar .task-sidebar-block .header-title-div .item-icon mdl-icon {
  font-size: 18px;
  vertical-align: middle;
}
.vp-sidebar .task-sidebar-block .header-title-div .item-icon .mat-icon {
  height: 18px !important;
  width: 18px !important;
  font-size: 18px !important;
}
.vp-sidebar .task-sidebar-block .header-title-div .item-icon img {
  width: 30px;
  height: 30px;
  padding: 3px;
}
.vp-sidebar .task-sidebar-block .sidebar-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 40px;
  padding-right: 15px;
  margin-bottom: 2px;
  cursor: pointer;
  border-width: 0;
  outline: none;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}
.vp-sidebar .task-sidebar-block .sidebar-item.collapsible {
  height: 40px;
  line-height: 40px;
  background: #e5e5e5;
  border-bottom: 2px solid #d3d3d3;
}
.vp-sidebar .task-sidebar-block .sidebar-item .sidebar-item-div {
  display: flex;
  width: 100%;
  align-items: center;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-icon {
  display: flex;
  min-width: 60px;
  height: 100%;
  padding-bottom: 2px;
  color: #8B96A0;
  justify-content: center;
  align-items: center;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-icon i.fa,
.vp-sidebar .task-sidebar-block .sidebar-item .item-icon mdl-icon {
  font-size: 18px;
  vertical-align: middle;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-icon .mat-icon {
  height: 18px !important;
  width: 18px !important;
  font-size: 18px !important;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-icon img {
  width: 30px;
  height: 30px;
  padding: 3px;
}
.vp-sidebar .task-sidebar-block .sidebar-item .rounded-rectangle {
  display: grid;
  width: 41px;
  height: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  justify-content: center;
  align-content: center;
  align-self: center;
}
.vp-sidebar .task-sidebar-block .sidebar-item .tag-title {
  width: 150px;
  padding-left: 75px;
  overflow: hidden;
  font-style: italic;
  color: #000;
  vertical-align: top;
  order: 0;
  align-self: stretch;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-title {
  width: 150px;
  overflow: hidden;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  color: #000;
  letter-spacing: -0.1px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-title mdl-icon {
  vertical-align: middle;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-badge {
  flex: 0 1 auto;
  width: 50px;
  overflow: hidden;
  text-align: right;
  vertical-align: middle;
  order: 0;
  align-self: stretch;
}
.vp-sidebar .task-sidebar-block .sidebar-item .item-badge span {
  display: inline-block;
  min-width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 11px;
  line-height: 10px;
  color: #000;
  text-align: center;
  background-color: #FE4100;
  border-radius: 30px;
}
.vp-sidebar .task-sidebar-block .sidebar-item.active {
  color: #fff;
  background-color: #337CBD;
}
.vp-sidebar .task-sidebar-block .sidebar-item.active .item-title {
  color: #fff;
}
.vp-sidebar .task-sidebar-block .sidebar-item.active .item-icon {
  color: #fff;
}
.vp-sidebar .task-sidebar-block .sidebar-item.active .item-icon .mat-icon {
  color: #fff;
}
.vp-sidebar .task-sidebar-block .sidebar-item.active .item-icon svg g {
  fill: white;
}
.vp-sidebar .task-sidebar-block .sidebar-item.active .rounded-rectangle {
  display: grid;
  width: 41px;
  font-size: 12px;
  border-radius: 12.5px;
  mix-blend-mode: undefined;
  justify-content: center;
  align-content: center;
  align-self: center;
}
.vp-sidebar .task-sidebar-block .sidebar-item.active::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  content: "";
  background-color: #5997DF;
}
.vp-sidebar .task-sidebar-block .sidebar-item .more-count-div {
  height: 100%;
  display: flex;
}
.vp-sidebar .task-sidebar-block .sidebar-item .dropbox {
  display: none;
}
.vp-sidebar .task-sidebar-block .sidebar-item:hover {
  overflow: inherit;
}
.vp-sidebar .task-sidebar-block .sidebar-item:hover .task-count {
  display: none;
}
.vp-sidebar .task-sidebar-block .sidebar-item:hover .dropbox {
  display: grid;
  width: 41px;
  justify-content: center;
  align-content: center;
  align-self: center;
}
.vp-sidebar .task-sidebar-block .sidebar-item:hover .dropbox mdl-icon {
  font-size: 18px;
  color: #888;
}
.vp-sidebar.collapsed .feature-title {
  display: none;
}
.vp-sidebar.collapsed .sidebar-item {
  padding-right: 0;
}
.vp-sidebar.collapsed .sidebar-item .item-title {
  display: none;
}
.vp-sidebar.collapsed .sidebar-item .item-badge {
  display: none;
}
.vp-sidebar .sidebar-collapse-icon {
  font-size: 32px;
}
.vp-sidebar .separate-bar {
  height: 1px;
  background-color: #dbdbdb;
}

vp-sidebar-drawer .drawer__links {
  position: relative;
  padding-top: 20px;
}
vp-sidebar-drawer .drawer__links .__row {
  position: relative;
  display: flex;
  padding: 10px 15px 20px;
  justify-content: space-between;
}
vp-sidebar-drawer .drawer__links .__row.selected {
  background: #0071bb;
}
vp-sidebar-drawer .drawer__links .__row.selected .left_col {
  color: #fff;
}
vp-sidebar-drawer .drawer__links .left_col {
  display: flex;
  width: 100%;
  color: #000;
  justify-content: flex-start;
  align-items: center;
}
vp-sidebar-drawer .drawer__links .left_col i.fa {
  padding: 1px 7px 0 9px;
  font-size: 16px;
}
vp-sidebar-drawer .drawer__links .left_col span {
  padding-left: 20px;
  font-size: 16px;
  line-height: 16px;
}
vp-sidebar-drawer .drawer__links .left_col mdl-icon {
  font-size: 18px;
}
vp-sidebar-drawer .drawer__links .left_col mat-icon {
  font-size: 18px;
  height: 18px;
  width: 18px;
}
vp-sidebar-drawer .drawer__links .subitem-line hr {
  width: 90%;
  margin: auto;
}
vp-sidebar-drawer .drawer__links .subitem-color {
  padding-top: 6px;
  color: #5a5757;
}
vp-sidebar-drawer .drawer__links .right_col {
  display: flex;
  width: 24%;
  visibility: hidden;
  justify-content: flex-end;
}
vp-sidebar-drawer .drawer__links .right_col span {
  padding: 3px 12px;
  font-weight: 600;
  color: #fff;
  background: #185e9b;
  border-radius: 20px;
}
vp-sidebar-drawer .drawer__links .visible {
  visibility: visible;
}

.ng2-dropdown-menu {
  z-index: 100001;
}

.mdl-layout__drawer.is-visible {
  z-index: 8;
  width: 80%;
  border: 0;
}

.mdl-layout__obfuscator {
  z-index: 7;
  display: none;
}
.mdl-layout__obfuscator.is-visible {
  display: block;
}

.profile__cover {
  position: relative;
  width: 100%;
  height: 210px;
  padding: 30px 15px 0;
  background-color: #0071bb;
  background-position: 50% 90%;
  background-size: cover;
  grid-column-gap: 10px;
}
.profile__cover vp-avatar {
  justify-content: left;
}
.profile__cover vp-avatar .avatar.list-avatar {
  border-radius: 50%;
  overflow: hidden;
  height: 80px;
  width: 80px;
  font-size: 20px;
  text-align: center;
  border: 2px solid #fff;
  cursor: pointer;
}
@media only screen and (min-width : 1024px) {
  .profile__cover vp-avatar .avatar.list-avatar {
    width: 90px;
    height: 90px;
  }
}
.profile__cover vp-avatar .avatar.list-avatar .profile-avtar {
  font-size: 30px;
}
.profile__fullname {
  display: inline;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  word-break: break-all;
  align-self: center;
  grid-column: 2/-1;
}
.profile__h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  color: #fff;
  align-self: end;
  grid-row: 2/3;
}

.vp-task-option-list-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-task-option-list-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-option-list-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-task-option-list-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (max-width : 599px) {
  .vp-task-option-list-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}

.task-option-list_dialog mat-list {
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.task-option-list_dialog mat-list mat-list-item .mat-list-item-content {
  padding: 0 !important;
  justify-content: space-between;
}
.task-option-list_dialog mat-list mat-list-item:hover {
  cursor: pointer;
  background-color: #eee;
}
.task-option-list_dialog mat-list mat-list-item.selected {
  background: #0071bb;
}
.task-option-list_dialog mat-list mat-list-item.selected .list-item {
  color: #fff;
}
.task-option-list_dialog mat-list mat-list-item.selected mat-icon {
  color: #fff;
}
.task-option-list_dialog mat-list mat-list-item .rounded-rectangle {
  display: flex;
  width: 15%;
  justify-content: flex-end;
}
.task-option-list_dialog mat-list mat-list-item .rounded-rectangle span {
  padding: 3px 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  background-color: #888;
}
.task-option-list_dialog mat-list mat-list-item .visible {
  visibility: visible;
}
.task-option-list_dialog mat-list mat-list-item .list-item {
  width: 80%;
  padding-left: 30px;
}
.task-option-list_dialog mat-list mat-list-item mdl-icon {
  font-size: 18px;
}
.task-option-list_dialog mat-list mat-list-item mat-icon {
  font-size: 18px;
}
.task-option-list_dialog mat-list mat-list-item mat-icon.bullet {
  font-weight: bold;
}

.vp-tag-option-list-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-tag-option-list-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-tag-option-list-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-tag-option-list-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (max-width : 599px) {
  .vp-tag-option-list-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}

.tag-option-list_dialog .mdl-textfield {
  width: 100%;
}
.tag-option-list_dialog mat-list {
  max-height: calc(100% - 70px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.tag-option-list_dialog mat-list mat-list-item .mat-list-item-content {
  padding: 0;
  justify-content: space-between;
}
.tag-option-list_dialog mat-list mat-list-item:hover {
  cursor: pointer;
  background-color: #eee;
}
.tag-option-list_dialog mat-list mat-list-item.selected {
  background: #0071bb;
}
.tag-option-list_dialog mat-list mat-list-item.selected .list-item {
  color: #fff;
}
.tag-option-list_dialog mat-list mat-list-item.selected mat-icon {
  color: #fff;
}
.tag-option-list_dialog mat-list mat-list-item .rounded-rectangle {
  display: flex;
  width: 15%;
  justify-content: flex-end;
}
.tag-option-list_dialog mat-list mat-list-item .rounded-rectangle span {
  padding: 3px 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  background-color: #888;
}
.tag-option-list_dialog mat-list mat-list-item .visible {
  visibility: visible;
}
.tag-option-list_dialog mat-list mat-list-item .list-item {
  width: 80%;
  padding-left: 30px;
  word-break: break-word;
}
.tag-option-list_dialog mat-list mat-list-item mat-icon {
  font-size: 18px;
}
.tag-option-list_dialog mat-list mat-list-item mat-icon.bullet {
  font-weight: bold;
}

.vp-folder-option-list-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-folder-option-list-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-folder-option-list-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-folder-option-list-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (max-width : 599px) {
  .vp-folder-option-list-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}

.vp-location-option-list-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-location-option-list-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-location-option-list-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-location-option-list-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (max-width : 599px) {
  .vp-location-option-list-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}

.folder-option-list_dialog .mdl-textfield {
  width: 100%;
}
.folder-option-list_dialog mat-list {
  max-height: calc(100% - 70px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.folder-option-list_dialog mat-list mat-list-item .mat-list-item-content {
  justify-content: space-between;
  padding: 0 !important;
}
.folder-option-list_dialog mat-list mat-list-item:hover {
  cursor: pointer;
  background-color: #eee;
}
.folder-option-list_dialog mat-list mat-list-item.selected {
  background: #0071bb;
}
.folder-option-list_dialog mat-list mat-list-item.selected .list-item {
  color: #fff;
}
.folder-option-list_dialog mat-list mat-list-item.selected mat-icon {
  color: #fff;
}
.folder-option-list_dialog mat-list mat-list-item .rounded-rectangle {
  display: flex;
  width: 15%;
  justify-content: flex-end;
}
.folder-option-list_dialog mat-list mat-list-item .rounded-rectangle span {
  padding: 3px 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 20px;
  background-color: #888;
}
.folder-option-list_dialog mat-list mat-list-item .visible {
  visibility: visible;
}
.folder-option-list_dialog mat-list mat-list-item .list-item {
  width: 80%;
  padding-left: 30px;
  word-break: break-word;
}
.folder-option-list_dialog mat-list mat-list-item mat-icon {
  font-size: 18px;
}
.folder-option-list_dialog mat-list mat-list-item mat-icon.bullet {
  font-weight: bold;
}

.vp-users-management-dialog .option-list {
  height: 100%;
}
.vp-users-management-dialog .option-list mdl-list {
  max-height: 295px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 60px;
}
.vp-users-management-dialog .option-list mdl-list mdl-icon {
  color: #888888;
  font-size: 18px;
  vertical-align: middle;
}
@media only screen and (max-width : 1023px) {
  .vp-users-management-dialog .option-list mdl-list {
    max-height: calc(100% - 60px);
  }
}
.vp-users-management-dialog .option-list mdl-list mdl-list-item:hover {
  cursor: pointer;
  background: #eefafa;
}
.vp-users-management-dialog .option-list mdl-list mdl-list-item.collected-users {
  background-color: #eef3f8;
}
.vp-users-management-dialog .option-list mdl-list mdl-list-item.collected-users mdl-icon {
  color: #fff;
}
.vp-users-management-dialog .option-list .mdl-list__item:last-child {
  border: 0;
}
.vp-users-management-dialog .option-list .mdl-list__item {
  width: 100%;
  padding: 12px 0px;
}
.vp-users-management-dialog .option-list .mdl-list__item span {
  margin-left: 30px;
  font-size: 15px;
  width: 78%;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
@media only screen and (max-width : 1023px) {
  .vp-users-management-dialog .option-list .mdl-list__item span {
    font-size: 16px;
  }
}
.vp-users-management-dialog .option-list .mdl-list__item .list-span {
  word-break: break-all;
  word-wrap: break-word;
}
.vp-users-management-dialog .option-list .mdl-list__item:hover .more-options {
  display: block;
}
.vp-users-management-dialog .option-list .more-options {
  display: none;
}
.vp-users-management-dialog .option-list .hide-avatar {
  display: none;
}
.vp-users-management-dialog .option-list .task-done-avtar {
  display: block;
}
@media only screen and (min-width : 768px) {
  .vp-users-management-dialog .selected-list-header {
    position: fixed;
    left: 75px;
  }
}
.vp-users-management-dialog .desktop-header-btn {
  position: absolute;
  right: 5px;
}
.vp-users-management-dialog .desktop-header-btn button {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  min-width: 36px;
  line-height: 0;
  padding: 0;
}
.vp-users-management-dialog .desktop-header-btn button.select-all-btn {
  position: relative;
  text-align: center;
}
@media only screen and (max-width : 1023px) {
  .vp-users-management-dialog .desktop-header-btn button.select-all-btn {
    display: none;
  }
}
@media only screen and (max-width : 1023px) {
  .vp-users-management-dialog .desktop-header-btn button.archive-btn {
    display: none;
  }
}
.vp-users-management-dialog .desktop-clear-btn {
  position: absolute;
  left: 2px;
  cursor: initial;
}
.vp-users-management-dialog .desktop-clear-btn button {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  min-width: 36px;
  line-height: 0;
  padding: 0;
}
@media only screen and (max-width : 1023px) {
  .vp-users-management-dialog .desktop-clear-btn {
    display: none;
  }
}
.vp-users-management-dialog .archive-user-menu-popover {
  border-radius: 2.6px;
  box-shadow: 0px 1px 13px 0 rgba(0, 0, 0, 0.5);
}
.vp-users-management-dialog .archive-user-menu-popover mdl-list-item {
  height: 20px;
}
.vp-users-management-dialog .archive-user-menu-popover mdl-list-item:hover {
  cursor: pointer;
  background: #F8F8F8;
}
.vp-users-management-dialog .archive-user-menu-popover mdl-icon {
  margin-right: 15px;
  color: #000000;
  font-size: 20px;
}

.vp-archive-users-dialog .option-list {
  height: 100%;
}
@media only screen and (max-width : 1023px) {
  .vp-archive-users-dialog .option-list .filter-search-textfield {
    display: none;
  }
}
.vp-archive-users-dialog .option-list.searchmode {
  margin-top: 50px;
}
.vp-archive-users-dialog .option-list mdl-list {
  max-height: 295px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vp-archive-users-dialog .option-list mdl-list mdl-icon {
  color: #888888;
  font-size: 18px;
  vertical-align: middle;
}
@media only screen and (max-width : 1023px) {
  .vp-archive-users-dialog .option-list mdl-list {
    max-height: calc(100% - 60px);
  }
}
.vp-archive-users-dialog .option-list mdl-list mdl-list-item:hover {
  cursor: pointer;
  background: #eefafa;
}
.vp-archive-users-dialog .option-list .mdl-list__item:last-child {
  border: 0;
}
.vp-archive-users-dialog .option-list .mdl-list__item {
  width: 100%;
  padding: 12px 0px;
}
.vp-archive-users-dialog .option-list .mdl-list__item span {
  width: 80%;
  margin-left: 30px;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
@media only screen and (max-width : 1023px) {
  .vp-archive-users-dialog .option-list .mdl-list__item span {
    font-size: 16px;
  }
}
.vp-archive-users-dialog .option-list .mdl-list__item .list-span {
  word-break: break-all;
  word-wrap: break-word;
}

.vp-invite-users-dialog .mat-dialog__title {
  padding: 20px 18px 0px 18px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-invite-users-dialog .mat-dialog__header-body {
  padding: 20px 18px 0px 18px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-invite-users-dialog .mat-dialog__content-block {
  padding: 0 18px;
  margin-top: 20px;
}
.vp-invite-users-dialog .mat-dialog__content-block .email_add_div {
  width: 100%;
  resize: none;
  border: 0;
  font-size: 16px;
  outline: none;
  word-break: break-all;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.vp-invite-users-dialog .mat-dialog__content-block .email_add_div:focus {
  border-bottom: 1px solid #2d7bbe;
  outline: none;
}
.vp-invite-users-dialog .mat-dialog__actions-block {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.vp-invite-users-dialog .mat-dialog__actions-block button {
  font-size: 16px;
  font-weight: 600;
  color: #0071bb;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  box-shadow: none;
  margin-right: 0px;
}
.vp-invite-users-dialog .mail-line-1 {
  height: 26px;
}
.vp-invite-users-dialog .mail-line-2 {
  height: 44px;
}
.vp-invite-users-dialog .mail-line-3 {
  height: 65px;
}
.vp-invite-users-dialog .mail-line-4 {
  height: 84px;
}
@-moz-document url-prefix() {
  .vp-invite-users-dialog .mail-line-1 {
    height: 28px;
  }
  .vp-invite-users-dialog .mail-line-2 {
    height: 49px;
  }
  .vp-invite-users-dialog .mail-line-3 {
    height: 70px;
  }
  .vp-invite-users-dialog .mail-line-4 {
    height: 92px;
  }
}

.team-user-item-menu .mat-menu-item mdl-icon {
  font-size: 16px;
  vertical-align: middle;
}
.team-user-item-menu .mat-menu-item span {
  margin-left: 10px;
  font-size: 14px;
}

.cdk-overlay-pane .collapseMenu {
  position: relative;
  left: 70px;
  max-width: 200px;
  max-height: 300px;
}
.cdk-overlay-pane .collapseMenu .mat-menu-item {
  line-height: 36px;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 100%;
}
.cdk-overlay-pane .collapseMenu .mat-menu-item .item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.cdk-overlay-pane .collapseMenu .mat-menu-item .mat-icon {
  margin-right: 0px;
  font-size: 18px;
}
.cdk-overlay-pane .collapseMenu .header-menu-item {
  font-size: 12px;
  color: #888888;
  font-weight: bold;
  text-transform: uppercase;
}
.cdk-overlay-pane .collapseMenu .rounded-rectangle {
  display: grid;
  width: 25px;
  height: 25px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  justify-content: center;
  align-content: center;
  align-self: center;
}
.cdk-overlay-pane .collapseMenu .separate-bar {
  height: 1px;
  background-color: #eeeeee;
  margin: 0 16px;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.task-detail-info {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: #ECF6FE;
  box-shadow: 0 2px 4px 0 rgba(7, 29, 51, 0.2), 0 0 2px 0 rgba(7, 29, 51, 0.1);
}
@media only screen and (max-width : 1023px) {
  .task-detail-info {
    background-color: #fff;
  }
}
.task-detail-info .statistic-header {
  height: 89px;
  background-color: #337CBD;
}
.task-detail-info .statistic-header .statistics-header-text {
  display: flex;
  height: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  color: #fff;
  letter-spacing: -0.2px;
  word-break: break-all;
  justify-content: center;
  align-items: center;
}
.task-detail-info .multi-select-task-header {
  height: 89px;
  background-color: #0071bb;
}
.task-detail-info .multi-select-task-header .header-info-div {
  padding-top: 15px;
}
.task-detail-info .multi-select-task-header .icons {
  width: 24px;
  height: 26px;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
  color: #fff;
  text-align: left;
  letter-spacing: -0.3px;
}
.task-detail-info .multi-select-task-header .sub-header-text {
  height: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3.57;
  color: #fff;
  text-align: center;
  letter-spacing: normal;
}
.task-detail-info .multi-select-task-header .align-item-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.task-detail-info .header {
  height: auto;
  background-color: #0071bb;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .header {
    width: 100%;
    z-index: 4;
  }
}
.task-detail-info .header .detail-header {
  height: 100%;
  color: #fff;
  word-break: break-all;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
}
.task-detail-info .header .detail-header .buttons-header {
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
}
.task-detail-info .header .detail-header .buttons-header .back-buttons {
  margin-left: 10px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .header .detail-header .buttons-header .back-buttons {
    margin-left: 0px;
  }
}
.task-detail-info .header .detail-header .buttons-header .back-buttons .mat-button {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  min-width: 36px;
  line-height: 0;
  padding: 0;
}
.task-detail-info .header .detail-header .buttons-header .opts-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.task-detail-info .header .detail-header .buttons-header .edit-task-header {
  font-size: 15px;
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 26px;
  font-weight: bold;
}
.task-detail-info .header .detail-header .buttons-header .more-button mdl-icon {
  cursor: pointer;
}
.task-detail-info .header .detail-header .buttons-header .more-button svg {
  cursor: pointer;
}
.task-detail-info .header .detail-header .buttons-header .more-button .mat-button {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  min-width: 36px;
  line-height: 0;
  padding: 0;
}
.task-detail-info .header .detail-header .buttons-header .title {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  max-height: 16px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  margin-left: 13px;
}
.task-detail-info .header .detail-header .buttons-header .fixed-subject-info {
  width: calc(100% - 72px);
  display: flex;
  align-items: center;
}
.task-detail-info .header .detail-header .subject-header {
  display: flex;
  width: 100%;
  word-break: break-word;
  padding-bottom: 10px;
}
.task-detail-info .header .detail-header .subject-header .assigned-icon {
  margin-left: 14px;
}
.task-detail-info .header .detail-header .subject-header .text-header {
  font-size: 15px;
  margin-left: 15px;
  margin-right: 14px;
  width: 100%;
  height: 100%;
}
.task-detail-info .header .detail-header .subject-header .text-header .property-text-header {
  width: 75%;
}
.task-detail-info .header .detail-header .subject-header .text-header .property-text mdl-icon {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .subject-header .text-header .property-text mat-icon {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .subject-header .text-header .property-text svg g {
  fill: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .subject-header .text-header .property-text svg g path {
  fill: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .subject-header .text-header .property-text span {
  margin-left: 15px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .subject-header .subject-textfield {
  width: 100%;
  margin-left: 22px;
  margin-right: 22px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .header .detail-header .subject-header .subject-textfield {
    margin-left: 14px;
    margin-right: 14px;
  }
}
.task-detail-info .header .detail-header .subject-header .subject-textfield .mat-form-field {
  width: 100%;
}
.task-detail-info .header .detail-header .subject-header .subject-textfield .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #fff;
}
.task-detail-info .header .detail-header .subject-header .subject-textfield .mat-input-element {
  caret-color: #fff;
  font-family: "Roboto";
  font-size: 16px;
}
.task-detail-info .header .detail-header mdl-icon {
  color: #fff;
  font-size: 20px;
}
.task-detail-info .header .detail-header mdl-icon:hover {
  cursor: pointer;
}
.task-detail-info .header .detail-header mat-icon {
  color: #fff;
  font-size: 20px;
  padding: 4px;
}
.task-detail-info .header .detail-header mat-icon:hover {
  cursor: pointer;
}
.task-detail-info .header .detail-header svg g {
  fill: #fff;
}
.task-detail-info .header .detail-header .mat-button {
  min-width: 50px;
  padding: 0 14px;
  background: transparent;
}
.task-detail-info .header .detail-header .desktop-detail-header {
  height: 100%;
  padding: 10px 14px 5px 14px;
}
.task-detail-info .header .detail-header .desktop-detail-header .subject-title-header {
  padding: 5px 8px;
  display: flex;
  align-items: center;
}
.task-detail-info .header .detail-header .desktop-detail-header .subject-title-header mdl-icon {
  font-size: 17px;
}
.task-detail-info .header .detail-header .desktop-detail-header .subject-title-header mat-icon {
  font-size: 17px;
}
.task-detail-info .header .detail-header .desktop-detail-header .subject-title-header .title {
  font-size: 17px;
  margin-left: 26px;
  color: #ffffff;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-detail-header {
  display: flex;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header {
  width: 75%;
  padding: 0px 4px;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .assignto-header {
  display: flex;
  align-items: center;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .assignto-header vp-avatar .avatar.list-avatar {
  width: 30px;
  height: 30px;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .assignto-header vp-avatar .avatar.list-avatar .profile-avtar {
  font-size: 14px;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .assignto-header vp-avatar .avatar.list-avatar .mat-icon {
  font-size: 18px;
  line-height: 30px;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .priority-header mdl-icon {
  font-size: 13px;
  margin-left: 8px;
  margin-right: 8px;
  color: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .priority-header svg {
  font-size: 13px;
  margin-left: 8px;
  margin-right: 8px;
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .priority-header svg g {
  fill: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header .priority-header .mat-icon {
  font-size: 13px;
  margin-left: 8px;
  margin-right: 8px;
  color: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .desktop-detail-header .property-header span {
  font-size: 13px;
  margin-left: 18px;
  color: rgba(255, 255, 255, 0.5);
}
.task-detail-info .header .detail-header .edit-button-header {
  width: 25%;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -34px;
}
.task-detail-info .header .detail-header .edit-button-header .edit-button {
  position: relative;
  color: #fff;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
  margin: 0px;
}
.task-detail-info .header .detail-header .edit-button-header .edit-button mdl-icon {
  color: #fff;
  font-size: 24px;
}
.task-detail-info .separater-expand-div {
  display: flex;
  height: 34px;
  background-color: #e0e6eb;
  padding-left: 22px;
  padding-right: 22px;
  margin-top: 2px;
  margin-bottom: 2px;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .separater-expand-div {
    background-color: #f0f0f0;
    padding-left: 14px;
    padding-right: 14px;
  }
}
.task-detail-info .separater-expand-div .item-title {
  font-size: 16px;
  text-align: left;
  color: #000000;
  margin-left: 27px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .separater-expand-div .item-title {
    margin-left: 17px;
  }
}
.task-detail-info .separater-expand-div .item-count {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-left: 10px;
}
.task-detail-info .separater-expand-div .item-icon {
  display: flex;
  justify-content: flex-start;
}
.task-detail-info .separater-expand-div .item-icon mdl-icon {
  color: #000;
}
.task-detail-info .multi-select-body {
  height: calc(100% - 90px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .multi-select-body {
    margin-top: 45px;
    height: calc(100% - 90px);
  }
}
.task-detail-info .multi-select-body .multiple-select-tasks {
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  color: #888;
  text-align: left;
  letter-spacing: normal;
  word-break: break-all;
  width: 100%;
  padding: 10px 8px;
}
.task-detail-info .multi-select-body .label-view-all {
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #317bbc;
  width: 100%;
  cursor: pointer;
  float: right;
}
.task-detail-info .detail-body {
  background-color: #eef3f8;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-body {
    background-color: #fff;
  }
}
.task-detail-info .detail-body .label-info {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  color: #606B76;
  text-align: left;
  letter-spacing: -0.1px;
}
.task-detail-info .detail-body .statistic-detail-info {
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  color: #000;
  text-align: left;
  letter-spacing: -0.1px;
}
.task-detail-info .detail-body .overdue-info {
  color: #FF331C;
}
.task-detail-info .detail-body .completed-info {
  color: #27AB7F;
}
.task-detail-info .detail-body .autocomplete {
  margin-top: -22px;
}
.task-detail-info .detail-body .autocomplete .datepicker__calendar {
  margin: -50% 0 0 -75%;
}
.task-detail-info .detail-body mdl-textfield {
  padding: 0;
}
.task-detail-info .detail-body .autocomplete__list {
  max-height: 120px;
  margin-top: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
}
.task-detail-info .detail-body .autocomplete__list div {
  padding: 10px 15px;
  cursor: pointer;
}
.task-detail-info .detail-body .autocomplete__list .active {
  background-color: #eefafa;
}
.task-detail-info .padding-10 {
  padding: 10px;
}
.task-detail-info .padding-15 {
  padding: 15px;
}
.task-detail-info .padding-14 {
  padding: 14px;
}
.task-detail-info .padding-left-right-15 {
  padding: 0 15px 0 15px;
}
.task-detail-info .align-right {
  float: right;
}
.task-detail-info .cursor-pointer {
  cursor: pointer;
}
.task-detail-info .margin-left-30 {
  margin-left: 30px;
}
.task-detail-info #description {
  width: 100%;
  margin: 23px;
  word-wrap: break-word;
  word-break: break-word;
  background-size: 100%;
  background-position: center;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info #description {
    margin: 14px;
  }
}
.task-detail-info #description p {
  margin-bottom: 0;
}
.task-detail-info #description ul, .task-detail-info #description ol {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin-bottom: 0;
  margin-top: 0;
}
.task-detail-info #description a {
  color: #0071bb;
}
.task-detail-info .desc-read-more {
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 212px;
}
.task-detail-info .desc-expand-div {
  text-align: right;
  color: #29b6f6;
  padding: 14px;
  width: 100%;
  font-size: 14px;
}
.task-detail-info .desc-expand-div span {
  cursor: pointer;
}
.task-detail-info .task-toolbar #text-editor {
  margin-top: 0px;
  padding: 23px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .task-toolbar #text-editor {
    padding: 14px;
  }
}
.task-detail-info .detail__toolbar {
  width: 100%;
}
.task-detail-info .attachAddBtn {
  margin-left: 5px;
  color: #0071bb;
  cursor: pointer;
}
.task-detail-info .attachmentListView {
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 37px;
  padding-left: 8px;
  align-items: center;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .attachmentListView {
    padding-left: 0px;
    margin-left: 30px;
  }
}
.task-detail-info .attachmentListView.isEditMode {
  margin-left: 0px;
  padding-left: 8px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .attachmentListView.isEditMode {
    padding-left: 0px;
  }
}
.task-detail-info .attachmentListView .filename {
  width: 95%;
  word-break: break-all;
  margin-left: 15px;
}
.task-detail-info .attachmentListView .filename span {
  cursor: pointer;
}
.task-detail-info .attachmentListView .filename-detail {
  word-break: break-all;
  width: auto;
  margin-right: 10px;
  max-width: 88%;
  cursor: pointer;
  margin-left: 14px;
}
.task-detail-info .attachmentListView .clearBtn {
  width: 5%;
  cursor: pointer;
}
.task-detail-info .attachmentListView .thumbnail {
  width: 6%;
  cursor: pointer;
  opacity: 0.7;
}
.task-detail-info .attachmentListView .thumbnail img {
  height: 20px;
  width: 20px;
  object-fit: cover;
}
.task-detail-info .attachmentListView .download-icon {
  width: 6%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.task-detail-info .attachmentListView .download-icon mdl-icon {
  cursor: pointer;
}
.task-detail-info .add-attachment-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.task-detail-info .add-attachment-info mdl-icon {
  color: #0071bb;
  margin-right: 15px;
}
.task-detail-info .add-attachment-info .label-text {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  color: #0071bb;
  text-align: left;
  letter-spacing: -0.1px;
}
.task-detail-info .watcherListView {
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 37px;
  padding-left: 8px;
  align-items: center;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .watcherListView {
    padding-left: 0px;
    margin-left: 30px;
  }
}
.task-detail-info .watcherListView.isEditMode {
  margin-left: 0px;
  padding-left: 8px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .watcherListView.isEditMode {
    padding-left: 0px;
  }
}
.task-detail-info .watcherListView vp-avatar .avatar.list-avatar {
  width: 32px;
  height: 32px;
}
.task-detail-info .watcherListView vp-avatar .avatar.list-avatar .profile-avtar {
  font-size: 14px;
}
.task-detail-info .watcherListView .name {
  width: 87%;
  word-break: break-all;
  margin-left: 15px;
}
.task-detail-info .watcherListView .name span {
  cursor: pointer;
}
.task-detail-info .watcherListView .name-detail {
  word-break: break-all;
  width: auto;
  margin-right: 10px;
  max-width: 88%;
  margin-left: 15px;
  cursor: pointer;
}
.task-detail-info .watcherListView .clearBtn {
  width: 5%;
  cursor: pointer;
}
@media (min-width: 1024px) and (max-width: 1130px) {
  .task-detail-info .de-german-read .table-row .label-table-cell, .task-detail-info .en-read .table-row .label-table-cell {
    width: 100%;
  }
}
@media (min-width: 1130px) and (max-width: 1280px) {
  .task-detail-info .de-german-read .table-row .label-table-cell, .task-detail-info .en-read .table-row .label-table-cell {
    width: 65%;
  }
}
@media (min-width: 1024px) and (max-width: 1130px) {
  .task-detail-info .de-german-edit .table-row .label-table-cell {
    width: 35%;
    padding: 8px 0px;
    max-width: calc(16ch + 24px);
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
@media (min-width: 1131px) and (max-width: 1280px) {
  .task-detail-info .de-german-edit .table-row .label-table-cell {
    width: 36%;
  }
}
@media (min-width: 1024px) and (max-width: 1130px) {
  .task-detail-info .de-german-edit .table-row .value-table-cell {
    width: 110%;
    padding: 8px 0px;
  }
}
.task-detail-info .detail-info-table {
  width: 100%;
}
.task-detail-info .detail-info-table .table-row {
  width: 100%;
  display: flex;
  align-items: center;
}
.task-detail-info .detail-info-table .table-row-with-padding {
  padding: 0 15px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .table-row-with-padding {
    padding: 0 14px;
  }
}
.task-detail-info .detail-info-table .table-cell {
  width: 50%;
  padding: 10px 8px;
  vertical-align: middle;
}
.task-detail-info .detail-info-table .label-table-cell {
  min-width: 156px;
  width: 40%;
  padding: 8px 8px;
  vertical-align: middle;
  display: flex;
  align-items: center;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell {
    padding: 8px 0px;
  }
}
.task-detail-info .detail-info-table .label-table-cell mdl-icon {
  margin-right: 25px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell mdl-icon {
    margin-right: 14px;
  }
}
.task-detail-info .detail-info-table .label-table-cell mat-icon {
  margin-right: 25px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell mat-icon {
    margin-right: 14px;
  }
}
.task-detail-info .detail-info-table .label-table-cell .icon-container-block {
  margin-right: 25px;
  width: 24px;
  height: 24px;
  padding: 4px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell .icon-container-block {
    margin-right: 14px;
  }
}
@media (min-width: 1130px) and (max-width: 1280px) {
  .task-detail-info .detail-info-table .label-table-cell {
    width: 33%;
    padding: 8px 4px;
  }
}
@media (min-width: 1024px) and (max-width: 1130px) {
  .task-detail-info .detail-info-table .label-table-cell {
    width: 28%;
    padding: 8px 2px;
  }
}
.task-detail-info .detail-info-table .label-table-cell-row {
  padding: 10px 8px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell-row {
    padding: 8px 0px;
  }
}
.task-detail-info .detail-info-table .label-table-cell-row mdl-icon {
  margin-right: 20px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell-row mdl-icon {
    margin-right: 14px;
  }
}
.task-detail-info .detail-info-table .label-table-cell-row mat-icon {
  margin-right: 20px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell-row mat-icon {
    margin-right: 14px;
  }
}
.task-detail-info .detail-info-table .label-table-cell-row svg {
  margin-right: 25px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .label-table-cell-row svg {
    margin-right: 14px;
  }
}
.task-detail-info .detail-info-table .value-table-cell {
  vertical-align: middle;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-info-table .value-table-cell {
    padding: 8px 0px;
  }
}
.task-detail-info .detail-info-table .value-table-cell .list-span {
  word-break: break-all;
  word-wrap: break-word;
}
@media (min-width: 1280px) {
  .task-detail-info .detail-info-table .value-table-cell {
    width: 70%;
    padding: 8px 8px;
  }
}
@media (min-width: 1130px) and (max-width: 1280px) {
  .task-detail-info .detail-info-table .value-table-cell {
    width: 80%;
    padding: 8px 4px;
  }
}
@media (min-width: 1024px) and (max-width: 1130px) {
  .task-detail-info .detail-info-table .value-table-cell {
    width: 100%;
    padding: 8px 2px;
  }
}
.task-detail-info .detail-info-table .pick-date-span {
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  text-transform: initial;
  letter-spacing: normal;
  color: #29b6f6;
}
.task-detail-info .detail-info-table .pick-date-icon {
  float: right;
  font-size: 18px;
  line-height: inherit;
}
.task-detail-info .detail-info-table .tag-icon-div {
  width: 36px;
}
.task-detail-info .detail-info-table .tag-value-div {
  width: 100%;
}
.task-detail-info mdl-icon {
  font-size: 16px;
  color: #888;
  vertical-align: middle;
}
.task-detail-info mat-icon {
  padding: 4px;
  font-size: 16px;
  color: #888;
  vertical-align: middle;
}
.task-detail-info .detail-label-text {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  color: #888;
  text-align: left;
  letter-spacing: -0.1px;
}
.task-detail-info .separate-bar {
  height: 1px;
  background-color: #cccccc;
  opacity: 0.5;
}
.task-detail-info .margin-left-right-15 {
  margin-right: 15px;
  margin-left: 15px;
}
.task-detail-info .margin-top-bottom-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.task-detail-info .edit-task-date-input-field {
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
}
.task-detail-info .edit-task-date-mat-icon {
  vertical-align: middle;
  cursor: pointer;
  opacity: 0.6;
  float: right;
}
.task-detail-info .comment_section {
  padding: 14px;
}
.task-detail-info .comment_section--header {
  padding: 10px 8px;
  font-size: 18px;
}
.task-detail-info .comment_section .comment_detail {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
}
.task-detail-info .comment_section .comment_detail--avatar {
  width: 41px;
  height: 41px;
  font-size: 41px;
}
.task-detail-info .comment_section .comment_detail--body {
  width: calc(100% - 72px);
  margin-left: 15px;
  position: relative;
}
.task-detail-info .comment_section .comment_detail--body__meta--date {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  color: #7b7b7b;
  letter-spacing: normal;
}
.task-detail-info .comment_section .comment_detail--body__meta--username {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
}
.task-detail-info .comment_section .comment_detail--body__meta--separate {
  padding-right: 2px;
  padding-left: 2px;
  font-weight: bold;
}
.task-detail-info .comment_section .comment_detail--body__text {
  padding-top: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  word-wrap: break-word;
}
.task-detail-info .comment_section .comment_detail--body__text p {
  margin-bottom: 0;
}
.task-detail-info .comment_section .comment_detail--body__edited span {
  font-style: italic;
  color: #888888;
}
.task-detail-info .comment_section .comment_detail--edit__button {
  width: 30px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}
.task-detail-info .comment_section .comment_detail--edit__button mdl-icon {
  vertical-align: top;
  cursor: pointer;
}
.task-detail-info .comment_section .no-comments {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
}
.task-detail-info .comment_section a {
  color: #0071bb;
}
.task-detail-info .add-comment-wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 14px;
}
.task-detail-info .add-comment-wrapper .add-comment {
  display: flex;
  padding-left: 14px;
  padding-right: 14px;
  background-color: #fff;
  align-items: center;
  border-radius: 20.5px;
  box-shadow: 0.5px 0.9px 3px 0 rgba(0, 0, 0, 0.31);
  background-color: #ffffff;
}
.task-detail-info .add-comment-wrapper .add-comment--textbox {
  flex-grow: 1;
  width: 100%;
}
.task-detail-info .add-comment-wrapper .add-comment--btn {
  cursor: pointer;
  color: #29b6f6;
  font-size: 28px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .add-comment-wrapper .add-comment--btn {
    font-size: 24px;
  }
}
.task-detail-info .add-comment-wrapper .add-comment textarea {
  width: 97%;
}
.task-detail-info .add-comment-wrapper .add-comment .comment-input {
  display: block;
  width: 98%;
  height: 26px;
  padding: 2px 0;
  margin: 10px 0;
  font-size: 16px;
  color: inherit;
  text-align: left;
  resize: none;
  background: none;
  border: 0;
  outline: 0;
}
.task-detail-info .add-comment-wrapper .add-comment .comment-line-1 {
  height: 26px;
}
.task-detail-info .add-comment-wrapper .add-comment .comment-line-2 {
  height: 44px;
}
.task-detail-info .add-comment-wrapper .add-comment .comment-line-3 {
  height: 65px;
}
.task-detail-info .add-comment-wrapper .add-comment .comment-line-4 {
  height: 84px;
}
@-moz-document url-prefix() {
  .task-detail-info .add-comment-wrapper .add-comment .comment-line-1 {
    height: 28px;
  }
  .task-detail-info .add-comment-wrapper .add-comment .comment-line-2 {
    height: 49px;
  }
  .task-detail-info .add-comment-wrapper .add-comment .comment-line-3 {
    height: 70px;
  }
  .task-detail-info .add-comment-wrapper .add-comment .comment-line-4 {
    height: 92px;
  }
}
.task-detail-info .status__inprogress {
  color: #2d7bbe;
}
.task-detail-info .status__closed {
  color: #00a651;
}
.task-detail-info .status__new {
  color: #888;
}
.task-detail-info .apply-changes-fixed {
  position: relative;
  width: 100%;
  margin-top: 20px;
  outline: none;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .apply-changes-fixed {
    position: fixed;
    left: 0;
    z-index: 1;
    width: 100%;
    margin-left: 0;
  }
}
.task-detail-info .apply-changes-fixed .mdl-button {
  height: 40px;
}
.task-detail-info .apply-changes {
  width: 60%;
  margin-right: 20%;
  margin-left: 20%;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 20px;
  outline: none;
}
.task-detail-info .apply-changes.disabled {
  background-color: #ababab;
}
.task-detail-info .tags-list-view {
  width: 100%;
}
.task-detail-info .tags-list-view .detail-input-tag {
  display: inline-table;
  padding: 0 5px;
  height: 14px;
  border-radius: 3px;
  background-color: #a2aab3;
  line-height: 15px;
  font-size: 10px;
  white-space: initial;
  border: 0;
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: auto;
  max-width: 100%;
  word-break: break-word;
  margin-right: 3px;
}
.task-detail-info .tags-list-view .detail-input-tag i {
  margin-left: 3px;
  color: #317bbc;
}
.task-detail-info .tags-list-view .view-all-text {
  margin-left: 10px;
}
.task-detail-info .tags-list-view .view-all-text mdl-icon {
  vertical-align: middle;
  cursor: pointer;
}
.task-detail-info .tags-list-view .view-less-text {
  font-size: 12px;
  color: #29b6f6;
  cursor: pointer;
  margin-left: 10px;
}
.task-detail-info .tags-list-view .edit-input-tag {
  display: inline-table;
  padding: 0 5px;
  height: 14px;
  border-radius: 3px;
  background-color: #a2aab3;
  line-height: 15px;
  font-size: 12px;
  white-space: initial;
  border: 0;
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: auto;
  max-width: 100%;
  word-break: break-word;
  margin-right: 3px;
}
.task-detail-info .tags-list-view .edit-input-tag i {
  margin-left: 3px;
  color: #ffffff;
  cursor: pointer;
  vertical-align: middle;
  font-size: 10px;
  line-height: 15px;
  float: right;
}
.task-detail-info .tags-list-view .add-tag-div {
  display: flex;
  width: 100%;
}
.task-detail-info .tags-list-view .tag-msg-span {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #888888;
  margin: 16px 8px 0 8px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .tags-list-view .tag-msg-span {
    margin: 10px 0 5px 0px;
  }
}
.task-detail-info .tags-list-view .add-tag-input {
  width: 100%;
  height: 26px;
  padding: 2px 0;
  margin: 16px 8px 0 8px;
  font-size: 14px;
  color: inherit;
  text-align: left;
  resize: none;
  background: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  outline: 0;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .tags-list-view .add-tag-input {
    margin: 10px 0 5px 0px;
  }
}
.task-detail-info .tags-list-view .add-tag-check {
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-color: #0071bb;
  color: #ffffff;
  font-size: 24px;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.task-detail-info .tags-list-view .add-tag-check mat-icon {
  color: #fff;
  font-size: 18px;
}
.task-detail-info .tags-list-view .add-tag-check .mat-button-wrapper {
  padding: 0;
}
.task-detail-info .tags-list-view .add-tag-check .mat-button-wrapper .check-icon-tag {
  color: #fff;
  padding: 0 !important;
}
.task-detail-info .filter-search-textfield {
  width: 94%;
  margin: 0 3% 0 3%;
}
.task-detail-info .mdl-list__item {
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;
  padding: 8px;
  min-height: 35px;
  font-size: 14px;
}
.task-detail-info .mdl-list__item:hover {
  background: #eefafa;
}
.task-detail-info .detail-add-fields {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #317bbc;
  margin: 10px 23px 30px 23px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .detail-add-fields {
    margin: 10px 14px 30px 14px;
  }
}
.task-detail-info .detail-add-fields span {
  cursor: pointer;
  text-transform: uppercase;
}
.task-detail-info .edit-comment .edit-comment-input {
  display: block;
  width: 100%;
  height: 26px;
  padding: 2px 0;
  font-size: 14px;
  color: inherit;
  text-align: left;
  resize: none;
  background: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  outline: 0;
}
.task-detail-info .edit-comment .edit-comment-input:focus {
  border-bottom: 1px solid #2d7bbe;
}
.task-detail-info .edit-comment .comment-line-1 {
  height: 22px;
}
.task-detail-info .edit-comment .comment-line-2 {
  height: 40px;
}
.task-detail-info .edit-comment .comment-line-3 {
  height: 58px;
}
.task-detail-info .edit-comment .comment-line-4 {
  height: 76px;
}
@-moz-document url-prefix() {
  .task-detail-info .edit-comment .comment-line-1 {
    height: 24px;
  }
  .task-detail-info .edit-comment .comment-line-2 {
    height: 42px;
  }
  .task-detail-info .edit-comment .comment-line-3 {
    height: 60px;
  }
  .task-detail-info .edit-comment .comment-line-4 {
    height: 78px;
  }
}
.task-detail-info .edit-comment .edit-comment-buttons {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.1px;
  text-align: left;
  float: right;
  margin-top: 10px;
}
.task-detail-info .edit-comment .edit-comment-buttons span {
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 15px;
}
.task-detail-info .edit-comment .edit-comment-buttons .cancel-btn {
  color: #888888;
}
.task-detail-info .edit-comment .edit-comment-buttons .save-btn {
  color: #317bbc;
}
.task-detail-info .task__detail-footer {
  height: 52px;
  border-top: 1px solid #e3e7eb;
  box-sizing: border-box;
  background: #fff;
  bottom: 0px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 12px;
  padding: 0 16px;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .task__detail-footer {
    position: sticky;
  }
}
.task-detail-info .task__detail-footer button {
  font-family: Manrope;
  font-size: 12px;
  line-height: 30px;
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  text-transform: uppercase;
  border: solid 1px transparent;
  border-radius: 4px;
  cursor: pointer;
}
.task-detail-info .task__detail-footer button.negative {
  color: #606b76;
  border-color: #8b96a0;
  background-color: #fff;
  padding: 0 12px;
}
.task-detail-info .task__detail-footer button.primary {
  background-color: #337cbd;
  border-color: #337cbd;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(7, 29, 51, 0.2), 0 0 2px 0 rgba(7, 29, 51, 0.1);
  padding: 0 24px;
}
.task-detail-info .task__detail-footer button.primary:disabled {
  border-color: #A8B0B9;
  background-color: #A8B0B9;
  color: #fff;
  cursor: unset;
}
.task-detail-info .form-field {
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}
@media only screen and (max-width : 1023px) {
  .task-detail-info .form-field {
    display: block;
  }
}
.task-detail-info .form-field .mat-form-field-infix {
  border-top: 0;
}
.task-detail-info .form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}
.task-detail-info .form-field .mat-form-field-underline {
  height: 0;
  bottom: 0;
}
.task-detail-info .external-form-field {
  width: 100%;
}
.task-detail-info .external-form-field .mat-form-field-underline {
  background-color: #e4e4e4;
}
.task-detail-info .external-form-field .mat-form-field-infix {
  border-top: 0px;
}
.task-detail-info .show-more-div {
  text-align: center;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}
.task-detail-info .show-more-div span {
  font-size: 14px;
  color: #29b6f6;
}
.task-detail-info .show-more-div mdl-icon {
  font-size: 14px;
  color: #29b6f6;
}
.task-detail-info .mdl-selection-option .mdl-list__item.is-active {
  color: #000000;
  background-color: #f0f0f0;
}
.task-detail-info .mdl-selection-option-only-background .mdl-list__item.is-active {
  background-color: #f0f0f0;
}
.task-detail-info a {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #0071bb;
  text-decoration: none;
}
.task-detail-info .word-break {
  word-break: break-word;
}
.task-detail-info .timelog-history-label {
  margin-left: 10px;
  font-size: 14px;
  color: #29b6f6;
  cursor: pointer;
}
.task-detail-info .log-time-label {
  margin-left: 10px;
  font-size: 14px;
  color: #0071bb;
  cursor: pointer;
}

.cdk-overlay-pane .mat-autocomplete-panel {
  max-height: 200px;
}
@media only screen and (max-width : 1023px) {
  .cdk-overlay-pane .mat-autocomplete-panel {
    max-height: 150px;
  }
}
.cdk-overlay-pane .assignee-list {
  min-width: 120%;
  right: 20%;
}
.cdk-overlay-pane .project-list {
  min-width: 120%;
  right: 20%;
}
.cdk-overlay-pane .tag-list {
  max-height: 120px;
  min-width: 50%;
}
.cdk-overlay-pane .mat-option {
  font-size: 14px;
  white-space: unset;
}
.cdk-overlay-pane .option-list-item .mat-option-text {
  align-items: center;
  display: flex;
  height: 4em;
  line-height: 1em;
  word-break: break-all;
  word-wrap: break-word;
}
.cdk-overlay-pane .option-list-item .mat-option-text .assignee-text {
  padding-left: 12px;
  width: 80%;
  word-wrap: break-word;
}
.cdk-overlay-pane .option-list-item .mat-option-text .list-span {
  word-break: break-all;
  word-wrap: break-word;
}

.edit-task-date-matmenu .edit-task-date-matmenu-item {
  font-size: 14px;
  height: 40px;
}
.edit-task-date-matmenu .edit-task-date-matmenu-item span {
  float: right;
  color: #888888;
  margin-left: 15px;
}
.edit-task-date-matmenu .edit-task-date-matmenu-item mdl-icon {
  float: right;
  color: #888888;
  font-size: 18px;
  line-height: inherit;
}
.edit-task-date-matmenu .pick-date-icon {
  font-size: 14px;
  height: 40px;
  color: #0071bb;
}
.edit-task-date-matmenu .pick-date-icon span {
  color: #0071bb;
}
.edit-task-date-matmenu .pick-date-icon mdl-icon {
  float: right;
  color: #0071bb;
  font-size: 18px;
  line-height: inherit;
}

.flex {
  display: flex;
}

.fixed-header {
  position: fixed;
  top: 0;
  height: 50px;
  transition: all 0.4s ease;
}

.none {
  display: none !important;
}

.task-detail-header-menu-popover mdl-list-item {
  height: 20px;
}
.task-detail-header-menu-popover mdl-list-item:hover {
  cursor: pointer;
  background: #F8F8F8;
}
.task-detail-header-menu-popover mdl-icon {
  margin-right: 15px;
  color: #000000;
  font-size: 20px;
}
.task-detail-header-menu-popover mat-icon {
  margin-right: 15px;
  color: #000000;
  font-size: 20px;
}
.task-detail-header-menu-popover .icon-container-block {
  width: 24px;
  height: 24px;
  padding: 4px;
  margin-right: 15px;
}

.task-time-input {
  width: 40px;
  height: 30px;
  background-color: #eef3f8;
  font-size: 14px;
  text-align: center;
  border: none;
  outline: none;
  border-bottom: 1px solid #e4e4e4;
}
@media only screen and (max-width : 1023px) {
  .task-time-input {
    background-color: #fff;
  }
}

.highlight-header {
  animation: pulse 1s infinite;
  border-radius: 5px;
  animation-name: highlight-detail-header;
}

.highlight {
  animation: pulse 1s infinite;
  border-radius: 5px;
  animation-name: none;
}

@keyframes highlight-detail-header {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
  }
}
@keyframes highlight-priority-high {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 184, 212, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 184, 212, 0);
  }
}
@keyframes highlight-priority-immediate {
  0% {
    box-shadow: 0 0 0 0px rgba(213, 0, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(213, 0, 0, 0);
  }
}
@keyframes highlight-priority-urgent {
  0% {
    box-shadow: 0 0 0 0px rgba(98, 0, 234, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(98, 0, 234, 0);
  }
}
@keyframes highlight-priority-normal {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 113, 187, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 113, 187, 0);
  }
}
@keyframes highlight-priority-low {
  0% {
    box-shadow: 0 0 0 0px rgba(96, 125, 139, 0.7);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(96, 125, 139, 0);
  }
}
.task-completed-icon {
  position: relative;
  height: 0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  bottom: -90px;
  right: 15px;
}
.task-completed-icon img {
  width: 80px;
  height: 80px;
}

.mat-select-dropdown-below {
  margin-top: 32px;
}

vnc-datepicker div input {
  width: 100% !important;
}

.value-table-cell vnc-datepicker div ngx-daterangepicker-material .md-drppicker {
  left: 0 !important;
  height: 340px;
  width: 100% !important;
  overflow: hidden;
}
.value-table-cell vnc-datepicker div ngx-daterangepicker-material .md-drppicker .calendar {
  max-width: unset;
  min-width: 100%;
}

.vnc-single-date-picker .date-select-icon {
  right: 10px !important;
}

.vnc-single-date-picker .md-drppicker .calendar .calendar-table td.active.start-date,
.vnc-single-date-picker .md-drppicker .calendar .calendar-table td.active.end-date {
  background-color: transparent !important;
}

.vnc-single-date-picker .md-drppicker .buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 36px !important;
  background-color: #FFFFFF;
  box-shadow: inset 0 1px 0 0 #e3e7eb;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
}
.vnc-single-date-picker .md-drppicker .buttons .buttons_input {
  display: flex !important;
  justify-content: flex-end !important;
  flex-direction: row !important;
  padding-right: 12px;
  flex-grow: 1;
}
.vnc-single-date-picker .md-drppicker .buttons .buttons_input button.btn {
  padding: 0 !important;
  min-width: 96px !important;
  height: 24px !important;
  border-radius: 4px !important;
  margin-left: 12px !important;
  background-color: #337cbd !important;
  box-shadow: 0 2px 4px 0 rgba(7, 29, 51, 0.2), 0 0 2px 0 rgba(7, 29, 51, 0.1) !important;
  font-family: "Manrope" !important;
  font-size: 12px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  vertical-align: middle !important;
  line-height: 14px !important;
  border: none !important;
}
.vnc-single-date-picker .md-drppicker .buttons .buttons_input button.btn.btn-default {
  background-color: #ffffff !important;
  color: #337cbd !important;
  box-shadow: none !important;
  min-width: 80px !important;
  border-radius: 4px !important;
  border: none !important;
}

.assignee-list-block {
  max-height: 350px !important;
  width: 100%;
}
.assignee-list-block .mat-menu-content {
  margin: 0 !important;
  padding: 8px !important;
}
.assignee-list-block .mat-menu-content vnc-input .vnc-input-component {
  width: 250px;
}
.assignee-list-block .mat-menu-content vnc-input .vnc-input-component .vnc-form {
  margin: 0 !important;
}

.list-sec-assignee {
  overflow: auto;
  height: 250px;
}
.list-sec-assignee .assignee-list-item-d {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 250px;
  padding: 0 !important;
}
.list-sec-assignee .assignee-list-item-d .label-avatar {
  display: flex !important;
  align-items: center;
  gap: 4px;
}
.list-sec-assignee .assignee-list-item-d vp-avatar .list-avatar {
  width: 28px !important;
  height: 28px !important;
}
.list-sec-assignee .assignee-list-item-d vp-avatar .list-avatar img {
  display: flex !important;
}
.list-sec-assignee .assignee-list-item-d:hover {
  background-color: #f7f8fa;
}

cdk-virtual-scroll-viewport {
  height: 273px;
}
cdk-virtual-scroll-viewport .assignee-list-item-d .label-avatar {
  display: flex;
  align-items: center;
  height: 48px;
  gap: 4px;
}
cdk-virtual-scroll-viewport .assignee-list-item-d .label-avatar vp-avatar .list-avatar {
  height: 28px;
  width: 28px;
}
cdk-virtual-scroll-viewport .assignee-list-item-d svg.check-icon {
  position: absolute;
  top: 13px;
  right: 0;
}

.buttons-block-assignee {
  display: flex;
  justify-content: end;
}
.buttons-block-assignee div {
  display: flex;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-task-confirm-dialog .task__dialog {
  position: relative;
  width: 400px;
}
@media only screen and (max-width : 599px) {
  .vp-task-confirm-dialog .task__dialog {
    height: 100%;
    width: 100%;
  }
}
.vp-task-confirm-dialog .task__dialog-header {
  padding-left: 24px;
  border-bottom: none;
}
.vp-task-confirm-dialog .task__dialog-header .header_lbl {
  justify-content: left;
}
@media only screen and (max-width : 599px) {
  .vp-task-confirm-dialog .task__dialog-header .header_lbl {
    margin-left: 0px;
    color: #000;
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-confirm-dialog .task__dialog-header {
    background: #fff;
  }
}
.vp-task-confirm-dialog .task__dialog-body {
  padding: 16px 24px;
  height: auto;
}
.vp-task-confirm-dialog .task__dialog-body .content span {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: Source Sans Pro;
  line-height: 20px;
}
.vp-task-confirm-dialog .task__dialog-footer {
  border-top: none;
}
.vp-task-confirm-dialog .task__dialog-footer .buttons-div {
  padding-right: 8px;
}
.vp-task-confirm-dialog .task__dialog-footer button {
  font-family: Manrope;
  cursor: pointer;
  color: #317bbc;
  font-size: 16px;
}
.vp-task-confirm-dialog .task__dialog-footer button.disabled {
  color: #888;
}
.vp-task-confirm-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
}
@media only screen and (max-width : 599px) {
  .vp-task-confirm-dialog .mat-dialog-container {
    margin: 0 5% 0 5%;
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vnctasksettingsdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctasksettingsdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctasksettingsdialog {
    width: 100%;
    height: 100%;
  }
}
.vnctasksettingsdialog .task__dialog-body {
  padding: 0 14px 14px;
}
.vnctasksettingsdialog mat-list mat-list-item .mat-list-item-content {
  padding: 0 !important;
}
.vnctasksettingsdialog mat-list mat-list-item:hover {
  cursor: pointer;
  background-color: #eee;
}
.vnctasksettingsdialog mat-list mat-list-item span {
  padding-left: 30px;
}
.vnctasksettingsdialog mat-list mat-icon {
  font-size: 18px;
}
.vnctasksettingsdialog mat-list mat-icon.bullet {
  font-weight: bold;
}
.vnctasksettingsdialog .legalnotice-action-list {
  padding: 8px 0;
}
.vnctasksettingsdialog .legalnotice-action-list .terms-action-div {
  padding: 15px 0;
}
.vnctasksettingsdialog .legalnotice-action-list .terms-action-div mdl-icon {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #000000;
  margin-right: 30px;
}
.vnctasksettingsdialog .legalnotice-action-list .terms-action-div svg {
  margin-right: 30px;
}
.vnctasksettingsdialog .legalnotice-action-list .terms-action-div svg g {
  fill: #000000;
}
.vnctasksettingsdialog .legalnotice-action-list .terms-action-div a {
  font-size: 16px;
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
}
.vnctasksettingsdialog .none-decoration {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
}

.vnctaskservicedialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskservicedialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskservicedialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskservicedialog .task__dialog-body {
  padding: 0 14px 14px;
}
.vnctaskservicedialog .content {
  margin-top: 15px;
}
.vnctaskservicedialog .content p {
  font-size: 15px;
}
.vnctaskservicedialog p.button {
  margin: 10% 0 14px;
  text-align: center;
}
.vnctaskservicedialog p.button a {
  padding: 10px 22px 12px;
  margin-top: 6%;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  background-color: #0071bb;
  border: 0;
  border-radius: 27px;
  outline: none;
  text-decoration: none;
}
.vnctaskservicedialog p.linkitem {
  margin-top: 3%;
  margin-bottom: 20px;
  text-align: center;
}
.vnctaskservicedialog p.linkitem a {
  font-family: "Roboto";
  font-weight: 100;
  color: #000;
}
.vnctaskservicedialog p.left {
  text-align: left;
  line-height: 21px;
}

.vnctaskhelpfaqdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskhelpfaqdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskhelpfaqdialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskhelpfaqdialog .task__dialog-body {
  padding: 0 14px 14px;
}
.vnctaskhelpfaqdialog div.content {
  margin-top: 3%;
}
.vnctaskhelpfaqdialog div.content p.heading {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.vnctaskhelpfaqdialog .user-manual {
  word-break: break-all;
}

.vnctaskchangelogdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskchangelogdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskchangelogdialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskchangelogdialog .content {
  max-height: 300px;
  padding: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-family: Roboto;
  font-weight: 400;
  color: #000;
  text-align: left;
  word-break: break-word;
  word-wrap: break-word;
}
.vnctaskchangelogdialog .content a {
  font-size: 14px;
  font-weight: 400;
  color: #0070bc;
  text-decoration: none;
}

.vnctaskgeneralsettingsdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskgeneralsettingsdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskgeneralsettingsdialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskgeneralsettingsdialog .task__dialog-body {
  padding: 0px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .language-form-style {
  width: 100%;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .language-form-style label {
  font-size: 14px;
  color: #a3a3a3;
}
@media only screen and (min-width : 768px) {
  .vnctaskgeneralsettingsdialog .task__dialog-body {
    max-height: 400px;
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskgeneralsettingsdialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskgeneralsettingsdialog .task__dialog-body.save-mode {
    height: calc(100% - 93px);
  }
}
.vnctaskgeneralsettingsdialog .task__dialog-body label {
  font-size: 11px;
  color: #a3a3a3;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .mdl-textfield {
  margin: -14px 0 0;
  width: 100%;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .row {
  margin: 18px 0 0;
  padding: 0 14px 14px;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .mat-list-item:hover {
  background: #f8f8f8;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .deactivate-div {
  padding-top: 10px;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .deactivate-div span {
  font-family: Roboto;
  font-size: 16.5px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff0000;
  cursor: pointer;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .submit-button {
  font-weight: 600;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .mat-dialog__actions-block {
  padding: 0px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.vnctaskgeneralsettingsdialog .task__dialog-body .mat-dialog__actions-block button {
  font-size: 16px;
  font-weight: 600;
  color: #317bbc;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.vnctaskgeneralsettingsdialog .mdl-selection-option .mdl-list__item.is-active {
  color: #000000;
  background-color: #f0f0f0;
}
.vnctaskgeneralsettingsdialog .mdl-list__item {
  padding: 10px 0px 10px 0px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vnctaskgeneralsettingsdialog .mdl-list__item .mdl-list__item-primary-content {
  order: 0;
  flex-grow: 2;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vnctaskgeneralsettingsdialog .mdl-list__item .mdl-list__item-primary-content .heading {
  font-size: 16.5px;
}
.vnctaskgeneralsettingsdialog .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-sub-title {
  color: #888;
  margin: 5px 0px 0px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
}
.vnctaskgeneralsettingsdialog .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-sub-title span {
  display: block;
  margin: 5px 0px;
}
.vnctaskgeneralsettingsdialog .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-sub-title .heading {
  color: #000000;
}
.vnctaskgeneralsettingsdialog .mat-list .setting-action-switch {
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vnctaskgeneralsettingsdialog .mat-list .setting-action-switch .mat-list-item-content {
  justify-content: space-between;
  padding: 0;
}
.vnctaskgeneralsettingsdialog .mat-list .setting-action-switch .mat-list-item-content .action-style {
  margin-right: 14px;
}
.vnctaskgeneralsettingsdialog .action-style mdl-switch {
  z-index: 0;
}
.vnctaskgeneralsettingsdialog .action-style .mdl-switch.is-checked .mdl-switch__track {
  background: #0071bb;
  opacity: 0.5;
}
.vnctaskgeneralsettingsdialog .action-style .mdl-switch.is-checked .mdl-switch__thumb {
  background: #0071bb;
}
.vnctaskgeneralsettingsdialog .action-style .mdl-switch.is-upgraded {
  padding-left: 40px;
}
.vnctaskgeneralsettingsdialog .notification-header {
  font-size: 16.5px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.94;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  text-transform: uppercase;
}
.vnctaskgeneralsettingsdialog .divider {
  background-color: #a2aab3;
  height: 1px;
}
.vnctaskgeneralsettingsdialog .mdl-popover .mdl-list__item {
  padding: 10px;
}
.vnctaskgeneralsettingsdialog .start-authenticator-app {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #317bbc;
  cursor: pointer;
}

.vp-apps-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-apps-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-apps-dialog {
    width: 100%;
    height: 100%;
  }
}

.vnctaskaboutdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskaboutdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskaboutdialog {
    width: 100%;
    height: 100%;
  }
}

.help-dialog {
  margin-top: 15px;
}
.help-dialog .section_holder {
  position: relative;
  max-height: calc(100vh - 50px);
  overflow-x: hidden;
  overflow-y: auto;
}
.help-dialog .section_holder .section {
  position: relative;
  padding-bottom: 25px;
}
.help-dialog .section_holder .section h4 {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.help-dialog .section_holder .section p {
  padding-top: 7px;
  margin: 0;
  line-height: 22px;
}
.help-dialog .section_holder .section p a {
  font-size: 12px;
  color: #0071bb;
}
.help-dialog .section_holder .section p.t-center {
  text-align: center;
}
.help-dialog .section_holder .section p.t-center span {
  cursor: pointer;
}
.help-dialog .section_holder .section p.task_about_title {
  vertical-align: middle;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
.help-dialog .section_holder .section p.task_about_title span {
  font-weight: 700;
  font-family: "Montserrat";
}
.help-dialog .section_holder .section .p-b-10 {
  padding-bottom: 10px;
}
.help-dialog .section_holder .section .p-t-0 {
  padding-top: 0;
}
.help-dialog .section_holder .section .bullet {
  padding: 8px 10px;
  font-weight: 600;
  color: #FFF;
  text-transform: uppercase;
  background-color: #317bbc;
  border-radius: 16px;
}
.help-dialog .section_holder .section .t-center {
  font-size: 22px;
  text-align: center;
}
.help-dialog .section_holder .section .t-center span {
  font-weight: bold;
}
.help-dialog .section_holder .section .paragraph {
  font-size: 12px;
  color: #777;
  text-align: justify;
}
.help-dialog .section_holder .section .\--version {
  font-size: 14px;
  color: #818181;
}
.help-dialog .section_holder .\--img {
  padding-bottom: 10px;
  text-align: center;
}
.help-dialog .section_holder .\--img img {
  height: 103.5px;
}
.help-dialog .section_holder .changelog {
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0071bb;
}
.help-dialog .section_holder .changelog a {
  font-size: 14px;
  font-weight: 400;
  color: #0070bc;
  text-decoration: none;
}
.help-dialog .dialog_close_right {
  position: absolute;
  top: 0;
  right: 0;
  width: 56px;
  height: auto;
  margin: auto;
  color: #FFF;
  cursor: pointer;
  background-color: #0079a6;
}

.link a {
  color: #0071bb;
  word-break: break-word;
}

@media only screen and (max-width : 1023px) {
  .task_dialog-desktop-save {
    display: none;
  }
}
.vp-task-deactivate-feedback-dialog {
  max-width: 767px;
}
.vp-task-deactivate-feedback-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-task-deactivate-feedback-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-deactivate-feedback-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-task-deactivate-feedback-dialog .task__dialog-body {
  padding: 14px 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 93px);
}
@media only screen and (min-width : 1024px) {
  .vp-task-deactivate-feedback-dialog .task__dialog-body {
    height: 400px;
  }
}
.vp-task-deactivate-feedback-dialog .mdl-list {
  padding: 0px;
}
.vp-task-deactivate-feedback-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-task-deactivate-feedback-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-task-deactivate-feedback-dialog .mdl-list__item:last-child {
  box-shadow: none;
}
.vp-task-deactivate-feedback-dialog mdl-list-item-primary-content mdl-icon {
  color: #0071bb;
}
.vp-task-deactivate-feedback-dialog mdl-list-item-primary-content span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vp-task-deactivate-feedback-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-task-deactivate-feedback-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-task-deactivate-feedback-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-task-deactivate-feedback-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-task-deactivate-feedback-dialog .message-header {
  padding: 14px;
}
.vp-task-deactivate-feedback-dialog .message-header span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-task-deactivate-feedback-dialog textarea {
  width: 100%;
  font-size: 14px;
  border: none;
  border: 1px solid #888;
}

.vp-task-deactivate-confirmation-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-task-deactivate-confirmation-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-deactivate-confirmation-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-task-deactivate-confirmation-dialog .task__dialog-body {
  padding: 14px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 93px);
}
@media only screen and (min-width : 1024px) {
  .vp-task-deactivate-confirmation-dialog .task__dialog-body {
    height: 400px;
  }
}
@media only screen and (min-width : 768px) {
  .vp-task-deactivate-confirmation-dialog .confirm-password-form {
    padding-bottom: 44px;
  }
}
.vp-task-deactivate-confirmation-dialog .confirm-password-form .mat-form-field {
  width: 100%;
  padding-bottom: 15px;
}
.vp-task-deactivate-confirmation-dialog .confirm-password-form .mat-hint {
  font-size: 12px;
}
.vp-task-deactivate-confirmation-dialog .confirm-password-form .mat-error {
  font-size: 12px;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87) !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vnctask-bulk-edit-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctask-bulk-edit-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctask-bulk-edit-dialog {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (min-width : 600px) {
  .vnctask-bulk-edit-dialog {
    min-height: 400px;
  }
}
.vnctask-bulk-edit-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (max-width : 599px) {
  .vnctask-bulk-edit-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}
@media only screen and (max-width : 599px) {
  .vnctask-bulk-edit-dialog .task__dialog-body.save-mode {
    height: calc(100% - 93px);
  }
}
.vnctask-bulk-edit-dialog .option-list {
  height: 100%;
}
.vnctask-bulk-edit-dialog .option-list mdl-list {
  max-height: 295px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vnctask-bulk-edit-dialog .option-list mdl-list mdl-icon {
  color: #888888;
  font-size: 18px;
  vertical-align: middle;
}
.vnctask-bulk-edit-dialog .option-list mdl-list mat-icon {
  color: #888888;
  font-size: 18px;
  padding: 4px;
}
@media only screen and (max-width : 1023px) {
  .vnctask-bulk-edit-dialog .option-list mdl-list {
    max-height: calc(100% - 70px);
  }
}
.vnctask-bulk-edit-dialog .option-list mdl-list mdl-list-item:hover {
  cursor: pointer;
  background: #eefafa;
}
.vnctask-bulk-edit-dialog .option-list mat-list {
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vnctask-bulk-edit-dialog .option-list mat-list mat-icon {
  color: #888888;
  font-size: 18px;
  padding: 4px;
}
@media only screen and (max-width : 1023px) {
  .vnctask-bulk-edit-dialog .option-list mat-list {
    max-height: calc(100% - 70px);
  }
}
.vnctask-bulk-edit-dialog .option-list mat-list mat-list-item {
  height: 60px !important;
}
.vnctask-bulk-edit-dialog .option-list mat-list mat-list-item:hover {
  cursor: pointer;
  background: #eefafa;
}
.vnctask-bulk-edit-dialog .option-list .mdl-list__item:last-child {
  border: 0;
}
.vnctask-bulk-edit-dialog .option-list .mdl-list__item {
  width: 100%;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.vnctask-bulk-edit-dialog .option-list .mdl-list__item span {
  margin-left: 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vnctask-bulk-edit-dialog .option-list .mdl-list__item .list-span {
  word-break: break-all;
  word-wrap: break-word;
}
.vnctask-bulk-edit-dialog .option-list .mat-list-item {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.vnctask-bulk-edit-dialog .option-list .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}
.vnctask-bulk-edit-dialog .option-list .mat-list-item span {
  margin-left: 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vnctask-bulk-edit-dialog .option-list .mat-list-item .list-span {
  word-break: break-all;
  word-wrap: break-word;
}
.vnctask-bulk-edit-dialog .option-list .mat-list-item:last-child {
  border: 0;
}
.vnctask-bulk-edit-dialog .assign-option-list mdl-list {
  min-height: 295px;
}
@media only screen and (max-width : 1023px) {
  .vnctask-bulk-edit-dialog .assign-option-list mdl-list {
    max-height: calc(100% - 95px);
  }
}
.vnctask-bulk-edit-dialog .assign-option-list .invite-external-label {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0071bb;
}
.vnctask-bulk-edit-dialog .assign-option-list mat-form-field {
  width: 100%;
  height: 95px;
}
.vnctask-bulk-edit-dialog .assign-option-list .mat-hint {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #888888;
}
.vnctask-bulk-edit-dialog.active {
  flex-grow: 1;
  width: 100%;
}
.vnctask-bulk-edit-dialog.task__bulk_edit__dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 350px;
  min-width: 0;
  max-width: 100%;
  min-height: 0;
  max-height: none;
  overflow: initial;
  background: #fff;
  transform: translateX(-50%) translateY(-50%);
}
.vnctask-bulk-edit-dialog .filter-search-textfield {
  width: 100%;
}
.vnctask-bulk-edit-dialog .filter-search-textfield .is-focused {
  padding: 20px 0;
}
.vnctask-bulk-edit-dialog .date-option-list {
  height: 100%;
}
.vnctask-bulk-edit-dialog .date-option-list mdl-list {
  height: auto;
  padding: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vnctask-bulk-edit-dialog .date-option-list mdl-list mdl-list-item:hover {
  cursor: pointer;
  background: #eefafa;
}
.vnctask-bulk-edit-dialog .date-option-list .mat-list-item {
  height: 60px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.vnctask-bulk-edit-dialog .date-option-list .mat-list-item .mat-list-item-content {
  padding: 0 !important;
}
.vnctask-bulk-edit-dialog .date-option-list .mat-list-item .mat-list-item-content .mat-icon {
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
  margin-right: 20px !important;
}
.vnctask-bulk-edit-dialog .date-option-list .mdl-list__item {
  width: 100%;
  padding: 12px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.vnctask-bulk-edit-dialog .date-option-list .mdl-list__item span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vnctask-bulk-edit-dialog .date-option-list .mdl-list__item mdl-icon {
  font-size: 18px;
  color: #888888;
  vertical-align: middle;
}
.vnctask-bulk-edit-dialog .date-option-list .mdl-list__item div {
  width: 100%;
  margin-left: 30px;
}
.vnctask-bulk-edit-dialog .date-option-list .mdl-list__item div .no-date {
  margin-left: 22px;
}
.vnctask-bulk-edit-dialog .date-option-list .align-right {
  float: right;
  opacity: 0.5;
  font-size: 14px;
}
.vnctask-bulk-edit-dialog .date-option-list .footer-div {
  height: 50px;
  padding-top: 7px;
  justify-content: center;
  align-items: center;
}
.vnctask-bulk-edit-dialog .date-option-list .footer-div .separate-bar {
  height: 1px;
  margin-bottom: 6px;
  background-color: #b6b6b6;
}
.vnctask-bulk-edit-dialog .date-option-list .date-picker-button {
  height: 36px;
  color: #29b6f6;
  text-transform: capitalize;
  border-radius: 20px;
  padding: 0px;
}
.vnctask-bulk-edit-dialog .date-option-list .date-picker-button:hover {
  background-color: #fff;
}
.vnctask-bulk-edit-dialog .date-option-list .date-picker-button mdl-icon {
  font-size: 18px;
  vertical-align: middle;
}
.vnctask-bulk-edit-dialog .date-option-list .date-picker-button span {
  margin-left: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  text-transform: initial;
  letter-spacing: normal;
  margin-left: 27px;
}
.vnctask-bulk-edit-dialog .date-option-list material-datepicker .datepicker__input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.vnctask-bulk-edit-dialog .date-option-list material-datepicker .datepicker__calendar {
  width: 19.5em;
  height: 20.5em;
  margin: -25.5em 0 1em 4em;
}
.vnctask-bulk-edit-dialog .date-option-list material-datepicker .datepicker__calendar__cancel {
  position: relative;
  left: 0;
  width: 100%;
  padding-right: 15px;
  margin-top: 10px;
  text-align: right;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vnc-authentication {
  background: no-repeat center center;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0px;
  z-index: 1;
}
.vnc-authentication *, .vnc-authentication *:before, .vnc-authentication *:after {
  box-sizing: border-box;
}
.vnc-authentication h4.vnctalk-heading {
  text-align: center;
  color: white;
  margin-top: 80px;
}
.vnc-authentication form {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.27);
  margin: 30px 12px;
  margin-bottom: 130px;
  padding: 0 32px;
  border-radius: 12px;
}
.vnc-authentication form .form-control input {
  font-size: 16px;
  font-family: Roboto;
  height: 46px;
  border: 1px solid #dadee2;
  border-radius: 6px;
  padding: 0 16px;
}
.vnc-authentication form .form-control input:focus {
  border-bottom: 2px solid #388eda;
}
.vnc-authentication button.login-server {
  background-color: #27ab7f;
  color: white;
  width: 100%;
  border: none;
  padding: 10px;
  margin-top: 56px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  -webkit-appearance: none;
  height: 64px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(7, 29, 51, 0.2), 0 0 2px 0 rgba(7, 29, 51, 0.1);
}
.vnc-authentication button.login-server:hover, .vnc-authentication button.login-server:focus {
  outline: 0;
}
.vnc-authentication button.login-server[disabled] {
  opacity: 0.5;
}
.vnc-authentication .separator {
  margin-top: 40px;
  margin-bottom: 40px;
}
.vnc-authentication .form-control input {
  color: #000000;
  background-color: white;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e4e7e9;
  -webkit-appearance: none;
  line-height: 24px;
}
.vnc-authentication .form-control input:hover, .vnc-authentication .form-control input:focus {
  outline: 0;
}
.vnc-authentication h5 {
  color: #131313;
  font-size: 18px;
  margin-top: 32px;
  margin-bottom: 32px;
  font-family: Manrope;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}
.vnc-authentication .login-demo-server {
  text-align: center;
}
.vnc-authentication .login-demo-server a {
  color: #0071bb;
}

.back-to-login {
  height: 64px;
  border-top: 1px solid #e3e7eb;
  margin-top: 32px;
  padding: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.back-to-login button {
  background: transparent;
  border: none;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #337cbd;
  line-height: 1.71;
  letter-spacing: normal;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
@media only screen and (min-width : 600px) {
  .vp-task-datepicker-dialog {
    width: 350px;
  }
}
.vp-task-datepicker-dialog .task__dialog-body {
  padding: 0px;
  height: auto;
}
.vp-task-datepicker-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-task-datepicker-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.vp-task-datepicker-dialog .date-picker-footer {
  height: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vp-task-datepicker-dialog .date-picker-footer span {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: left;
  color: #0071bb;
  cursor: pointer;
  margin-right: 20px;
  text-transform: uppercase;
}
.vp-task-datepicker-dialog .owl-dt-inline {
  width: 100%;
}
.vp-task-datepicker-dialog .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #29b6f6;
}
.vp-task-datepicker-dialog .owl-dt-inline-container, .vp-task-datepicker-dialog .owl-dt-popup-container {
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.vp-task-datepicker-dialog .owl-dt-inline-container .owl-dt-calendar, .vp-task-datepicker-dialog .owl-dt-popup-container .owl-dt-calendar {
  height: auto;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.advance-search-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .advance-search-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .advance-search-dialog {
    width: 100%;
    height: 100%;
  }
}
.advance-search-dialog .task__dialog-body {
  padding: 0px;
}
@media only screen and (min-width : 1024px) {
  .advance-search-dialog .task__dialog-body {
    height: 420px;
  }
}
@media only screen and (max-width : 599px) {
  .advance-search-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}
.advance-search-dialog .left-button {
  right: 0;
  left: 0;
  padding: 0 16px;
}
.advance-search-dialog .advance-search-list {
  height: 420px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width : 1023px) {
  .advance-search-dialog .advance-search-list {
    height: 100%;
  }
}
.advance-search-dialog .advance-search-list .mat-list-item {
  height: 60px !important;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.advance-search-dialog .advance-search-list .action-switch .mat-list-item-content {
  justify-content: space-between;
}
.advance-search-dialog .changed {
  height: calc(100% - 40px);
}
@media screen and (min-width: 767px) {
  .advance-search-dialog .changed {
    height: 360px;
  }
}
.advance-search-dialog mdl-switch {
  z-index: 0;
}
.advance-search-dialog .mdl-switch.is-checked .mdl-switch__track {
  background: #0071bb;
  opacity: 0.5;
}
.advance-search-dialog .mdl-switch.is-checked .mdl-switch__thumb {
  background: #0071bb;
}
.advance-search-dialog .mdl-switch.is-upgraded {
  padding-left: 40px;
}
.advance-search-dialog .mdl-list__item {
  padding: 12px 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.advance-search-dialog .mdl-list__item .mdl-list__item-primary-content {
  order: 0;
  flex-grow: 2;
  text-decoration: none;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.advance-search-dialog .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-sub-title {
  color: #888;
  margin: 5px 0px 0px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
}
.advance-search-dialog .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-sub-title span {
  display: block;
  margin: 5px 0px;
}
.advance-search-dialog .mdl-list__item .mdl-list__item-primary-content .mdl-list__item-sub-title .heading {
  color: #000000;
}

.search-dialog .action-buttons {
  display: none;
}

.task-search-header {
  position: fixed;
  background: white;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
  height: 50px;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100000;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
}
.task-search-header .mat-button {
  margin: 0;
  padding: 0;
  min-width: 24px;
}
.task-search-header .mat-button.search-back-button {
  margin-left: -5px;
  margin-right: 10px;
}
.task-search-header .mat-button mdl-icon {
  color: #000000;
  font-size: 20px;
  width: 20px;
}
.task-search-header .icon-button {
  padding: 0;
  margin-left: 20px;
  min-width: 24px;
  background-color: #ffffff;
  border: none;
  color: #000000;
  font-size: 1.4rem;
}
.task-search-header .input-search {
  background: transparent;
  outline: 0;
  border: 0;
  height: 26px;
  font-family: Roboto;
  font-size: 16px;
  width: 100%;
  text-align: left;
  color: #000000;
  padding-left: 20px;
}
.task-search-header .input-search::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .task-search-header .input-search::-webkit-input-placeholder {
    color: #888888;
  }
}
.task-search-header .input-search::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .task-search-header .input-search::-moz-placeholder {
    color: #888888;
  }
}
.task-search-header .input-search:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .task-search-header .input-search:-ms-input-placeholder {
    color: #888888;
  }
}
.task-search-header .input-search:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}
@media screen and (max-width: 767px) {
  .task-search-header .input-search:-moz-placeholder {
    color: #888888;
  }
}

.advance-search-option-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .advance-search-option-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .advance-search-option-dialog {
    width: 100%;
    height: 100%;
  }
}

.sub-title {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.mdl-layout__drawer-button {
  display: none;
  color: #fff;
}

.mdl-layout__header-row {
  height: 48px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media only screen and (min-width : 768px) and (max-width : 1023px) {
  .header-drop-down {
    padding-right: 10px;
  }
}
.header-drop-down {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}
.header-drop-down:hover .drop-down-holder {
  display: block;
}
.header-drop-down .nav {
  width: 100%;
  padding: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
}
.header-drop-down .nav li {
  display: inline-block;
  width: 100%;
  padding: 0 0 0 10px;
  text-align: right;
  cursor: pointer;
  background: #fff;
}
.header-drop-down .nav li div {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 25px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  text-align: left;
}
.header-drop-down .nav li:nth-last-child(1) {
  padding-bottom: 25px;
}
.header-drop-down .hide {
  display: none;
}
.header-drop-down .name-div {
  position: absolute;
  left: 0;
  width: 80%;
  height: 100%;
}
.header-drop-down .name-div p {
  padding: 19px 11px 0 0;
  font-size: 14px;
  font-weight: 600;
  font-stretch: narrower;
  text-align: right;
  letter-spacing: 0.11em;
}
.header-drop-down .header-username {
  width: auto;
  padding: 0 10px;
  overflow: hidden;
  font-size: 18px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header-drop-down .avatar-div {
  display: inline-block;
  margin-right: 0;
  vertical-align: middle;
}
.header-drop-down .avatar-div.non-user-image mdl-icon {
  font-size: 28Spx;
}
.header-drop-down .avatar-div img {
  width: 46px;
  height: 46px;
  vertical-align: middle;
  border-radius: 50%;
}
.header-drop-down .drop-down-holder {
  position: relative;
  display: none;
  margin-top: 60px;
  background: #fff;
}
.header-drop-down .drop-down-holder .drop-item {
  position: relative;
  width: 100%;
  padding: 6px 9px;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  text-align: right;
}

.search-box-holder__options {
  display: flex;
  height: 36px;
}
.search-box-holder__options > button.mat-button, .search-box-holder__options > button.mat-button:focus {
  min-width: 20px;
  height: 38px;
  padding: 0;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  color: #686868;
  text-align: left;
  letter-spacing: normal;
  background: #fff;
  border-radius: 0;
}

.search-box-holder {
  display: flex;
  align-items: center;
}
.search-box-holder .container-4 {
  display: flex;
  width: 350px;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
}
.search-box-holder .container-4 input#search {
  float: left;
  width: 100%;
  height: 36px;
  padding: 0px 10px;
  font-size: 10pt;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.07;
  color: #acacac;
  letter-spacing: normal;
  background: #fff;
  border: 0;
  outline: none;
}
.search-box-holder .container-4 input#search:-moz-placeholder,
.search-box-holder .container-4 input#search:-ms-input-placeholder,
.search-box-holder .container-4 input#search::-moz-placeholder,
.search-box-holder .container-4 input#search::-webkit-input-placeholder {
  color: #BBB;
}

vp-header .switch-app {
  z-index: 1001;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
vp-header .switch-app .switch-button {
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  padding: 20px 10px;
  cursor: pointer;
}
vp-header .switch-app .switch-button--cordova > mdl-icon {
  line-height: 40px;
  margin-left: 14px;
}
vp-header .switch-app .switch-button--cordova > mat-icon {
  line-height: 40px;
  margin-left: 14px;
}
@media (max-width: 1023px) {
  vp-header .switch-app .switch-button-app {
    display: none;
  }
}
vp-header .switch-app .switch-button-sidebar {
  cursor: pointer;
  color: rgb(255, 255, 255);
}
@media (min-width: 1024px) {
  vp-header .switch-app .switch-button-sidebar {
    display: none;
  }
}
vp-header .pointer {
  cursor: pointer;
}
vp-header .advance-tune-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  align-items: center;
  background-color: #5a95c9;
  font-size: 1.28rem;
  font-weight: 400;
  line-height: 1.58;
  letter-spacing: normal;
  color: #fff;
  width: 36px;
  height: 36px;
  padding: 0;
  border: none;
  outline: 0;
  cursor: pointer;
}
vp-header .advance-tune-button mdl-icon {
  vertical-align: middle;
}
vp-header .advance-tune-button svg g {
  fill: #fff;
  vertical-align: middle;
}
@media only screen and (min-width : 600px) {
  vp-header .pointer--padding-left {
    padding-left: 5px;
  }
}
vp-header .hide-desktop {
  display: none;
}
vp-header .separator {
  width: 1px;
  height: 30px;
  margin: 0 10px;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width : 599px) {
  vp-header .separator {
    display: none;
  }
}
vp-header .app-logo {
  margin-left: 10px;
}
vp-header .app-logo img {
  height: 50px;
}
@media only screen and (max-width : 599px) {
  vp-header .app-logo {
    display: none;
  }
}
vp-header .app-title {
  display: inline-flex;
  vertical-align: middle;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.45;
  letter-spacing: normal;
  color: #ffffff;
  padding: 8px 0;
}
vp-header .app-title span:nth-child(1) {
  font-family: "Montserrat";
  font-weight: 700;
}
vp-header .app-title span:nth-child(2) {
  font-family: "Montserrat";
  font-weight: 400;
}
vp-header .float-right {
  float: right;
}
vp-header .avatar-div .mdl-badge[data-badge]::after {
  top: 29px;
  right: 1px;
  width: 12px;
  height: 12px;
  background-color: #00ee00;
  border: 1px #fff solid;
}
vp-header .mdl-badge {
  margin-right: 0;
}
vp-header .overlay {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: transparent;
}
vp-header .header-menu-btn {
  position: relative;
  height: 100%;
}
vp-header .header-menu-btn > button {
  min-width: 41px;
  height: 100%;
  padding: 4px;
  color: #fff;
}
vp-header .settings {
  right: 5px;
}
@media only screen and (min-width : 768px) and (max-width : 1279px) {
  vp-header .search-box-holder .container-4 {
    width: 250px;
  }
}
@media only screen and (min-width : 600px) and (max-width : 767px) {
  vp-header .search-box-holder .container-4 {
    width: 200px;
  }
}
vp-header .mobile-search {
  position: relative;
  display: flex;
  min-height: 42px;
  background: #fff;
}
vp-header .mobile-search__input {
  width: 100%;
  padding-left: 10px;
  font-size: 14px;
  border: 0;
  border-radius: 0;
  outline: 0;
}
vp-header .mobile-search__icon {
  padding: 5px;
  color: #777;
  background: #fff;
  border: 0;
  border-radius: 0;
  outline: 0;
}
vp-header .mobile-search__icon img {
  width: 27px;
  border: 0;
  border-radius: 0;
}
vp-header .app-loader {
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 5px;
  padding-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background: #f5f5f5;
  justify-content: flex-start;
}
vp-header .app-loader .skipper {
  padding-right: 50px;
}

.header__blue {
  background-color: #317bbc;
  justify-content: center;
  -webkit-box-pack: center;
  min-height: 50px;
}

.drop-menu-nav__item {
  display: inline-flex;
  min-width: 125px;
  line-height: initial;
  vertical-align: middle;
  align-items: center;
}
.drop-menu-nav__item vp-content-type-icon {
  margin-right: 12px;
}
.drop-menu-nav__item mdl-icon {
  margin-right: 10px;
}

@media only screen and (min-width : 600px) {
  vp-header .app-loader,
  vp-header .mobile-dropdown,
  vp-header .mobile-search {
    display: none;
  }
}
@media only screen and (max-width : 767px) {
  vp-header .filter-bar {
    display: none;
  }
}
@media only screen and (max-width : 599px) {
  vp-header .search-box-holder {
    display: none;
  }
  vp-header .app-logo {
    width: 100px;
  }
  vp-header .app-logo img {
    width: 100%;
    height: 100%;
  }
  vp-header .app-title {
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: 600;
    margin-left: 24px;
  }
  .drop-menu-nav .mdl-menu__outline {
    border-radius: 3px;
    -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.35);
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.35);
  }
  .drop-menu-nav .mdl-menu__container {
    top: 57px !important;
    right: 5px !important;
  }
}
@media only screen and (min-width : 1024px) {
  .item-sm-visibility {
    display: none;
  }
}

@media only screen and (max-width : 1023px) {
  .item-gt-visibility {
    display: none;
  }
}

.breadcrumbs-container {
  position: relative;
  display: flex;
  padding: 5px 10px;
  font-size: 15px;
  background-color: #fff;
  box-shadow: 0 0 3.5px 0 rgba(0, 0, 0, 0.27);
}
@media only screen and (max-width : 1023px) {
  .breadcrumbs-container {
    display: none;
  }
}
.breadcrumbs-container .breadcrumbs {
  display: flex;
  flex: 1;
  width: 100%;
  height: 20px;
  padding-right: 5px;
  align-items: center;
}
.breadcrumbs-container .breadcrumbs .bread-item {
  overflow: hidden;
  color: #317bbc;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;
}
.breadcrumbs-container .breadcrumbs .bread-item mdl-icon {
  font-size: 20px;
  color: #666;
}
.breadcrumbs-container .refresh-holder {
  display: flex;
  height: 20px;
  align-items: center;
}
.breadcrumbs-container .refresh-holder mdl-icon {
  font-size: 18px;
}
.breadcrumbs-container .refresh-holder span {
  color: #666;
  cursor: pointer;
}
.breadcrumbs-container .refresh-holder span.icon {
  display: inline-flex;
}
.breadcrumbs-container .refresh-holder span.text {
  padding-left: 5px;
  font-size: 14px;
}

@media only screen and (max-width : 1023px) {
  .hide-container-view {
    display: none;
  }
}

.header-menu .mat-menu-item mdl-icon {
  font-size: 18px;
  vertical-align: middle;
}
.header-menu .mat-menu-item span {
  margin-left: 10px;
  font-size: 14px;
}

.search-bar {
  align-items: center;
  position: absolute;
  display: flex;
  flex-shrink: 1;
  height: 36px;
  padding: 0 12px;
  border-radius: 8px;
  width: 240px;
  right: 96px;
  background-color: var(--vnc-search-bar-bg-color, #285f8f);
  margin: 6px 0;
}
.search-bar input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 0 80px 0 8px;
  position: relative;
  border-radius: 8px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.5019607843);
  line-height: 24px;
  font-weight: 400;
  left: 16px;
  height: 32px;
  font-stretch: normal;
  font-style: normal;
  margin-top: 0;
  caret-color: #95a3f3;
}
.search-bar #clearSearchBtn {
  width: 16px;
  position: absolute;
  display: flex;
  right: 40px;
  min-width: 24px;
  padding: 0;
}
.search-bar #option-selected-btn {
  width: 16px;
  position: absolute;
  display: flex;
  right: 12px;
  min-width: 24px;
  padding: 0;
}
.search-bar #searchBtn {
  border-radius: 0;
  padding: 0;
  margin: 0;
  min-height: 100%;
  color: gray;
  height: 32px;
  width: 20px;
  position: absolute;
  left: 15px;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vnc-search-bar--active {
  width: calc(100% - 434px);
  transition: background-color var(--transition-duration, var(--transition-duration-default)) linear;
}

.header-end {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  right: 0;
  height: 48px;
}
.header-end .sidebar-toggle-button {
  margin-left: 6px;
  cursor: pointer;
}
.header-end .sidebar-toggle-button.active svg path {
  fill: #fff;
}
.header-end .sidebar-toggle-button:hover svg path {
  fill: #fff;
}
.header-end .end-icons {
  width: 100%;
  display: none;
  align-items: center;
  margin-left: 16px;
}
.header-end .avatar-div {
  margin: 0px 16px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-end .avatar-div vp-avatar .list-avatar {
  height: 32px;
  width: 32px;
}

.profile-logout-menu {
  align-items: center;
  display: flex;
  padding: 0;
  width: 280px;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2), 0 4px 12px 0 rgba(8, 45, 61, 0.1);
}
.profile-logout-menu .mat-menu-content {
  width: 100%;
}
.profile-logout-menu.sub-header {
  width: 220px;
}
.profile-logout-menu .mat-menu-item {
  align-items: center;
  display: flex;
  padding: 0;
  width: 100%;
  padding: 0 12px;
  height: 36px;
  line-height: 36px;
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.14px;
  color: #131313;
  background-color: #ffffff;
}
.profile-logout-menu .mat-menu-item.header-help-btn .mat-menu-submenu-icon {
  display: none;
}
.profile-logout-menu .mat-menu-item.header-help-btn .btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  font-size: 14px;
  line-height: 20px;
}
.profile-logout-menu .mat-menu-item span {
  margin-left: 8px;
  font-family: "Source Sans Pro";
}
.profile-logout-menu .mat-menu-item .header-menu-icons {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #8b96a0;
}
.profile-logout-menu .mat-menu-item .header-menu-icons svg {
  width: 18px;
  height: 18px;
}
.profile-logout-menu .mat-menu-item vp-avatar .list-avatar {
  margin: 0 8px 0 12px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
}
.profile-logout-menu .mat-menu-item vnc-icon-container {
  margin-right: 8px;
  padding: 6px 0 6px 12px;
}
.profile-logout-menu .mat-menu-item:hover {
  background-color: #f7f8fa;
}
.profile-logout-menu .profile-menu {
  align-items: center;
  display: flex;
  padding: 0;
  grid-gap: 12px;
  height: 64px;
  padding: 8px 0px 8px 14px;
}
.profile-logout-menu .profile-menu .vnc-avatar {
  height: 48px;
  width: 48px;
}
.profile-logout-menu .profile-text {
  display: inline-block;
  position: relative;
  vertical-align: top;
  cursor: pointer;
}
.profile-logout-menu .profile-text #uname {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #000;
  overflow-wrap: anywhere;
}
.profile-logout-menu .profile-text #uemail {
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 14px;
  letter-spacing: normal;
  color: #7a858f;
  overflow-wrap: anywhere;
}
.profile-logout-menu .logout-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: unset;
  height: 40px;
}
.profile-logout-menu .logout-btn #btn-log {
  width: 72px;
  height: 24px;
  border: none;
  margin: 0 16px 0 0;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
}
.profile-logout-menu .logout-btn #btn-log:hover {
  background-color: #f2f9fe;
}
.profile-logout-menu .logout-btn #btn-log #txt {
  width: 52px;
  height: 24px;
  font-family: Manrope;
  font-size: 12px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  text-transform: uppercase;
  letter-spacing: normal;
  text-align: center;
  color: #337cbd;
  margin: 0;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.task-compose-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .task-compose-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .task-compose-dialog {
    width: 100%;
    height: 100%;
  }
  .task-compose-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
  .task-compose-dialog .task__dialog-body.save_mode {
    height: calc(100% - 93px);
  }
}

.add-task-options {
  min-height: 450px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (min-width : 600px) {
  .add-task-options {
    max-height: 80vh;
  }
}

.add-task-margin-bottom {
  margin-bottom: 50px;
}

.add-task-estimate-time {
  text-align: center;
}
.add-task-estimate-time .estimate-save-close {
  font-size: 16px;
  color: #888888;
  height: 40px;
  padding: 0px 4px 10px 10px;
  text-align: left;
}
.add-task-estimate-time .estimate-save-close .icons-div {
  float: right;
  font-size: 24px;
  margin-right: 7px;
}
.add-task-estimate-time .estimate-save-close .icons-div mdl-icon {
  font-size: 24px;
  vertical-align: middle;
  color: #888888;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.add-task-estimate-time .estimate-save-close .icons-div .icon-check {
  color: #0071bb;
}
.add-task-estimate-time span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  text-transform: capitalize;
}
.add-task-estimate-time input {
  width: 63px;
  height: 75px;
  border-radius: 7px;
  background-color: #f0f0f0;
  color: #000;
  font-size: 30px;
  text-align: center;
  border: none;
  margin-right: 5px;
}
.add-task-estimate-time input[type=number]::-webkit-inner-spin-button,
.add-task-estimate-time input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.add-task-estimate-time input[type=number] {
  -moz-appearance: textfield;
}
.add-task-estimate-time .estimate-time-label {
  display: flex;
  align-content: center;
  justify-content: center;
}
.add-task-estimate-time .estimate-time-label div {
  width: 60px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #888888;
  margin-right: 5px;
}

@media only screen and (max-width : 1023px) {
  .add-task-options {
    height: calc(100% - 104px);
    min-height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .add-task-options .task-toolbar-buttons {
    position: relative;
    display: block;
  }
  .save-with-toolbar {
    display: none;
  }
  .task-toolbar .task-toolbar-buttons.toolbar-display {
    display: block;
  }
}
.add-description-dialog {
  height: 100%;
}
.add-description-dialog .description-save-close {
  font-size: 14px;
  color: #000000;
  height: 40px;
  padding: 10px 0px 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.add-description-dialog .description-save-close .icons-div {
  float: right;
  margin-right: 7px;
}
.add-description-dialog .description-save-close .icons-div mdl-icon {
  font-size: 24px;
  vertical-align: middle;
  color: #888888;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.add-description-dialog .description-save-close .icons-div .icon-check {
  color: #0071bb;
}
.add-description-dialog .description-save-close span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  text-transform: capitalize;
}
.add-description-dialog .task-toolbar {
  height: 100%;
}
.add-description-dialog .task-toolbar #text-editor {
  margin-top: 0px;
  height: 100%;
  overflow-y: auto;
}
.add-description-dialog .detail__toolbar {
  height: calc(100% - 40px);
}

.add-assignee-user-dialog {
  height: 100%;
}
.add-assignee-user-dialog .external-user-list {
  height: calc(100% - 120px);
}
.add-assignee-user-dialog .assignee-user-save-close {
  font-size: 14px;
  color: #000000;
  height: 40px;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.add-assignee-user-dialog .assignee-user-save-close .icons-div {
  float: right;
}
.add-assignee-user-dialog .assignee-user-save-close .icons-div mdl-icon {
  font-size: 24px;
  vertical-align: middle;
  color: #888888;
  margin-left: 10px;
  cursor: pointer;
}
.add-assignee-user-dialog .assignee-user-save-close .icons-div .icon-check {
  color: #0071bb;
}
.add-assignee-user-dialog .assignee-user-save-close span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  text-transform: capitalize;
}
.add-assignee-user-dialog .external-user-div {
  font-size: 14px;
  color: #000000;
  height: 80px;
  display: flex;
  align-items: center;
}
.add-assignee-user-dialog .external-user-div .user-icon {
  font-size: 18px;
  vertical-align: middle;
  color: #888888;
  margin-left: 15px;
}
.add-assignee-user-dialog .external-user-div .external-textfield {
  width: 100%;
  margin-left: 40px;
  height: 70px;
}
.add-assignee-user-dialog .external-user-div .external-textfield .mat-form-field {
  width: 100%;
}
.add-assignee-user-dialog .external-user-div .external-textfield .mat-hint {
  font-size: 14px;
}
.add-assignee-user-dialog .detail__toolbar {
  height: calc(100% - 40px);
}

.add-assignee-list-dialog {
  height: 100%;
}
.add-assignee-list-dialog .add-assignee-list-header span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  text-transform: capitalize;
}
.add-assignee-list-dialog .add-assignee-list-header .add-list-icon {
  font-size: 24px;
  color: #0071bb;
  float: right;
  vertical-align: middle;
  cursor: pointer;
}
.add-assignee-list-dialog .no-available-lists {
  padding-top: 20px;
  color: #000;
  font-size: 14px;
}
.add-assignee-list-dialog .folder-list {
  height: calc(100% - 21px);
}

.add-tags-dialog {
  height: 100%;
}
.add-tags-dialog .tag-header {
  font-size: 14px;
  color: #000000;
  height: 40px;
  padding: 10px 0px 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.add-tags-dialog .tag-header span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  text-transform: capitalize;
}
.add-tags-dialog .tag-header .icons-div {
  float: right;
  margin-right: 7px;
}
.add-tags-dialog .tag-header .icons-div mdl-icon {
  font-size: 24px;
  vertical-align: middle;
  color: #888888;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.add-tags-dialog .tag-header .icons-div .icon-check {
  color: #0071bb;
}
.add-tags-dialog .tags-list-view {
  width: 100%;
  padding: 20px 14px 14px 14px;
}
.add-tags-dialog .tags-list-view .add-tag-div {
  display: flex;
  width: 100%;
}
.add-tags-dialog .tags-list-view .add-tag-input {
  width: 90%;
  height: 26px;
  padding: 2px 0;
  margin: 16px 8px 0 8px;
  font-size: 14px;
  color: inherit;
  text-align: left;
  resize: none;
  background: none;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  outline: 0;
}
.add-tags-dialog .tags-list-view .add-tag-check {
  color: #0071bb;
  font-size: 24px;
  vertical-align: middle;
  cursor: pointer;
}
.add-tags-dialog .tags-list-view .filter-search-textfield {
  width: 94%;
  margin: 0 3% 0 0%;
}
.add-tags-dialog .tags-list-view .tag-msg-span {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #888888;
}
.add-tags-dialog .tags-list-view .tag-list-item {
  display: inline-table;
  padding: 0 5px;
  height: 14px;
  border-radius: 3px;
  background-color: #a2aab3;
  line-height: 15px;
  font-size: 12px;
  white-space: initial;
  border: 0;
  color: #ffffff;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  width: auto;
  max-width: 100%;
  word-break: break-word;
  margin-right: 3px;
}
.add-tags-dialog .tags-list-view .tag-list-item i {
  margin-left: 3px;
  color: #ffffff;
  cursor: pointer;
  vertical-align: middle;
  font-size: 10px;
  line-height: 15px;
  float: right;
}
.add-tags-dialog .detail__toolbar {
  height: calc(100% - 40px);
}

.add-watchers-list-dialog {
  height: 100%;
}
.add-watchers-list-dialog .add-watchers-list-header span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
  text-transform: capitalize;
}
.add-watchers-list-dialog .add-watchers-list-header .add-list-icon {
  font-size: 24px;
  color: #0071bb;
  float: right;
  vertical-align: middle;
  cursor: pointer;
}
.add-watchers-list-dialog .add-watchers-list-header .icons-div {
  float: right;
  margin-right: 7px;
}
.add-watchers-list-dialog .add-watchers-list-header .icons-div mdl-icon {
  font-size: 24px;
  vertical-align: middle;
  color: #888888;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}
.add-watchers-list-dialog .add-watchers-list-header .icons-div .icon-check {
  color: #0071bb;
}
.add-watchers-list-dialog .no-available-lists {
  padding-top: 20px;
  color: #000;
  font-size: 14px;
}
.add-watchers-list-dialog .folder-list {
  height: calc(100% - 21px);
}
.add-watchers-list-dialog .selected-list-wrapper {
  height: 95px;
  overflow: auto;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03);
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList {
  overflow-x: auto;
  display: flex;
  height: 95px;
  padding-right: 30px;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: hidden;
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item {
  max-width: 52px;
  width: auto;
  min-width: 52px;
  height: 80px;
  box-sizing: border-box;
  padding: 0;
  margin-right: 20px;
  box-shadow: none;
}
@media screen and (max-width: 767px) {
  .add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item {
    height: 90px;
  }
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item:last-child {
  min-width: 64px;
  max-width: 64px;
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list {
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 42px;
  max-width: 42px;
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .member-avatar {
  position: relative;
  margin-left: 0px;
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .member-avatar .selected-member-close-icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-color: #888888;
  border: solid 2px #f8f8f8;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: -1px;
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .member-avatar .selected-member-close-icon mdl-icon {
  font-size: 1rem;
  color: white;
}
.add-watchers-list-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .selected-member-name {
  font-size: 0.85rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #888888;
  margin-top: 10px;
  padding: 0;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}

.task-compose-dialog-new {
  width: 960px;
}
.task-compose-dialog-new mat-dialog-container {
  padding: 0;
  border-radius: 8px;
}
.task-compose-dialog-new .new-task-body {
  padding: 24px !important;
}
.task-compose-dialog-new .new-task-body vnc-input .vnc-input-component .vnc-form {
  margin: 8px 0 32px;
}
.task-compose-dialog-new .new-task-body .chip-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: solid 1px #dadee2;
  padding: 16px;
  min-height: 48px;
  height: auto;
  margin-bottom: 24px;
}
.task-compose-dialog-new .new-task-body .options-block-new {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.task-compose-dialog-new .new-task-body .options-block-new .option {
  width: auto;
  margin: 0;
}
.task-compose-dialog-new .new-task-body .options-block-new .option svg {
  margin-bottom: 5px;
}
.task-compose-dialog-new .new-task-body .options-block-new .option svg g {
  fill: #FFFFFF;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 132px;
  padding: 16px 24px 10px;
  border-radius: 8px;
  background-color: #fff;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block.custom-height {
  height: 155px;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .date-icon {
  width: 72px;
  height: 72px;
  margin: 0 0 9px;
  padding: 20px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .start-date-icon {
  background-color: #84c620;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .due-date-icon {
  background-color: #0f89f4;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .priority-icon {
  background-color: #46ba93;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .project-icon {
  background-color: #6358c7;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .user-icon {
  background-color: #fa864c;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .repeat-icon {
  background-color: #0f89f4;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .estimate-icon {
  background-color: #46ba93;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .tags-icon {
  background-color: #1ba3b8;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .description-icon {
  background-color: #0f89f4;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block .attachment-icon {
  background-color: #46ba93;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block span {
  font-family: Manrope;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  text-align: center;
}
.task-compose-dialog-new .new-task-body .options-block-new .icons-block:hover {
  background: #f5f8fa;
}

.disable-click {
  pointer-events: none;
  opacity: 0.7;
}

.disable-click-create {
  opacity: 0.7;
  cursor: not-allowed !important;
}

.drop-data-area {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  border-radius: 4px;
  border: dashed 2px #388eda;
  background-color: white;
}
.drop-data-area.show-drop-file {
  display: flex !important;
}

.hide {
  display: none;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.task-toolbar {
  position: relative;
}
.task-toolbar .mat-button {
  min-width: 36px;
  height: 42px;
}
.task-toolbar .mat-button:hover {
  background-color: #eef3f8;
}
@media only screen and (max-width : 1023px) {
  .task-toolbar .mat-button:hover {
    background-color: #dadada;
  }
}
.task-toolbar .task-toolbar-buttons {
  position: relative;
  background-color: #f1f1f1;
  width: 100%;
  height: 42px;
  display: block;
  border-top: 1px solid #cccccc;
}
.task-toolbar .material-icons {
  font-size: 20px;
  color: #797979;
}
.task-toolbar .material-icons.active {
  color: #0071bb;
}
.task-toolbar #text-editor {
  min-height: 60px;
  outline: none;
  word-wrap: break-word;
  border-bottom: 1px solid #cccccc;
  background-image: url("/assets/background-edit.jpg");
  background-size: 100%;
  background-position: center;
  padding: 10px;
  margin-top: -10px;
  -webkit-appearance: textarea;
  overflow: auto;
  resize: vertical;
}
@media only screen and (max-width : 1023px) {
  .task-toolbar #text-editor {
    min-height: 25px;
  }
}
.task-toolbar #text-editor:focus {
  border-bottom: 1px solid #0071bb;
}
.task-toolbar #text-editor:empty:not(:focus)::before {
  content: attr(title);
  display: block;
  padding: 5px;
  color: #888;
  opacity: 0.5;
}
.task-toolbar #text-editor p {
  margin-bottom: 0;
}
.task-toolbar #text-editor ul, .task-toolbar #text-editor ol {
  margin-top: 0;
  margin-bottom: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}
.task-toolbar .color-picker .arrow.arrow-top {
  left: 38%;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-task-image-cropper-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-task-image-cropper-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-image-cropper-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar {
  text-align: center;
  height: 18em;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .upload-text-button {
  cursor: pointer;
  border: 1px solid #000000;
  box-shadow: none;
  outline: none;
  border-radius: 20px;
  background: white;
  padding: 8px 45px 8px 45px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  margin-top: 25px;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .file-upload {
  margin-top: 55px;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-left-div {
  float: left;
  width: 50%;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-left-div mdl-icon {
  vertical-align: text-bottom;
  font-size: 20px;
  color: #0071bb;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-left-div svg g {
  fill: #0071bb;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-left-div svg g path {
  fill: #0071bb;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-left-div span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0071bb;
  cursor: pointer;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-right-div {
  float: right;
  width: 50%;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-right-div mdl-icon {
  vertical-align: text-bottom;
  font-size: 20px;
  color: #0071bb;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-right-div svg g {
  fill: #0071bb;
}
.vp-task-image-cropper-dialog .user-profile__upload-avatar .avatar-option-right-div span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0071bb;
  cursor: pointer;
}
.vp-task-image-cropper-dialog .save_crop_image_btn {
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline: none;
  border-radius: 20px;
  background: #0071bb;
  padding: 8px 45px 8px 45px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  bottom: 15px;
}
.vp-task-image-cropper-dialog .save_crop_image_btn:disabled {
  background: #888888;
}
.vp-task-image-cropper-dialog .task-dialog .task__dialog-body--pad {
  padding: 20px 15px;
}
.vp-task-image-cropper-dialog .task-dialog .task__dialog-body {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 200px);
  margin-top: 0;
}
.vp-task-image-cropper-dialog .task__dialog-body {
  padding: 24px 15px 24px 15px;
  font-size: 15px;
}
.vp-task-image-cropper-dialog .hide {
  display: none;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vnc_task_login_profile_dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnc_task_login_profile_dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnc_task_login_profile_dialog {
    width: 100%;
    height: 100%;
  }
}
.vnc_task_login_profile_dialog .uppercase {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  padding: 0 0 0 21%;
  align-items: baseline;
}
.vnc_task_login_profile_dialog .uppercase .material-icons {
  font-size: 16.5px;
}
.vnc_task_login_profile_dialog .task-profile {
  background: #F6F4FA;
  border-bottom: 1px solid #b7b7b7;
  padding-top: 18px;
  height: 230px;
  text-align: center;
  padding-bottom: 18px;
}
.vnc_task_login_profile_dialog .task-profile .uploadlbl {
  cursor: pointer;
  color: #0070bc;
  font-size: 14px;
  font-weight: 400;
}
.vnc_task_login_profile_dialog .task-profile img.avatar-img {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
}
.vnc_task_login_profile_dialog .task-profile .avatar-xlg {
  margin-top: 12px;
  margin-bottom: 12px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  font-size: 40px;
  line-height: 2;
}
.vnc_task_login_profile_dialog .task-profile .material-icons {
  font-size: 80px;
  text-align: center;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
}
.vnc_task_login_profile_dialog .task-profile .span-header {
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: -0.4px;
  margin: 11px 0 16px 0;
  cursor: pointer;
}
.vnc_task_login_profile_dialog .task-profile .camera-icon {
  cursor: pointer;
  margin-top: -33px;
  color: white;
  display: block;
  margin-bottom: 10px;
  font-size: 21px;
}
.vnc_task_login_profile_dialog .task-profile vp-avatar .avatar.list-avatar {
  border-radius: 50%;
  overflow: hidden;
  height: 80px;
  width: 80px;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 12px;
  cursor: pointer;
}
.vnc_task_login_profile_dialog .task-profile vp-avatar .avatar.list-avatar .profile-avtar {
  font-size: 30px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .detail_body_size {
  height: 350px;
  overflow: auto;
}
@media (max-width: 768px) {
  .vnc_task_login_profile_dialog .login_user_profile_dialog-body .detail_body_size {
    height: calc(100vh - 120px);
    overflow-x: hidden;
  }
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .profile_picture {
  text-align: center;
  background: #F6F4FA;
  padding-top: 10px;
  border-bottom: 1px solid #b7b7b7;
  height: auto;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .profile_picture .material-icons {
  font-size: 120px;
  text-align: center;
  display: block;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .profile_picture .display_name {
  font-size: 18px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .profile_picture .horizontal_line {
  background: #b7b7b7;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options {
  display: flex;
  border-bottom: 1px solid #b7b7b7;
  text-align: center;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .material-icons {
  display: block;
  font-size: 30px;
  color: #0071bb;
  padding-bottom: 12px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .material-icons.custom_color {
  color: #888888;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .label {
  font-size: 15px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .option {
  width: 20%;
  padding: 18px 0 20px 26px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .option button {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  width: 80px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .mdl-menu__container {
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.5);
  margin: 0 -100px 0 0;
}
@media (max-width: 768px) {
  .vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .mdl-menu__container {
    margin: 0px;
  }
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .opration_options .mdl-menu__container .menu_image {
  width: 26px;
  height: 26px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .task_details {
  padding: 0px 14px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .task_details .details_heading {
  font-size: 13px;
  text-align: left;
  color: #6f6f6f;
  line-height: 1.15;
  margin: 4% 0 5% 0%;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .task_details .details_info {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .task_details .details_info mdl-icon {
  vertical-align: middle;
  font-size: 20px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .task_details .details_info mat-icon {
  vertical-align: middle;
  font-size: 20px;
}
.vnc_task_login_profile_dialog .login_user_profile_dialog-body .task_details .details_info .text_info {
  padding-left: 15px;
  cursor: pointer;
}
.vnc_task_login_profile_dialog .separator-div {
  height: 1px;
  margin-left: 35px;
  background-color: #e1e1e1;
}
.vnc_task_login_profile_dialog .separator-last-div {
  height: 1px;
  margin-bottom: 10px;
  background-color: #b7b7b7;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.avatar {
  background-color: #0071bb;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}

.avatar-img {
  height: 50px;
  width: 50px;
  display: inline-block;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  padding: 0px;
  background-size: cover;
  background-position: center;
}

.avatar-xs {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.avatar-xs.avatar {
  font-size: 15px;
}

.avatar-sm {
  width: 42px;
  height: 42px;
  line-height: 42px;
}

.avatar-md {
  min-width: 50px;
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.avatar-select-md {
  min-width: 50px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #29b6f6;
}

.avatar-lg {
  width: 58px;
  height: 58px;
  line-height: 58px;
}

.avatar-xlg {
  width: 78px;
  height: 78px;
  font-size: 34px;
  line-height: 78px;
}

.avatar-name {
  vertical-align: middle;
  padding-left: 5px;
}

.layout-align-center-center {
  align-items: center;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vnctaskaddfielddialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskaddfielddialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskaddfielddialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskaddfielddialog .task__dialog-body {
  padding: 0px 15px;
}
.vnctaskaddfielddialog mat-list {
  padding-bottom: 8px;
}
.vnctaskaddfielddialog mat-list mat-list-item {
  padding: 14px 0px;
}
.vnctaskaddfielddialog mat-list mat-list-item .mat-list-item-content {
  padding: 0 !important;
}
.vnctaskaddfielddialog mat-list mat-list-item:hover {
  cursor: pointer;
  background-color: #eee;
}
.vnctaskaddfielddialog mat-list mat-list-item mdl-icon {
  font-size: 18px;
}
.vnctaskaddfielddialog mat-list mat-list-item mat-icon {
  padding: 4px;
  height: 24px !important;
  width: 24px !important;
}
.vnctaskaddfielddialog mat-list mat-list-item span {
  padding-left: 30px;
}
.vnctaskaddfielddialog mat-list mat-list-item .icon-container-block {
  padding: 4px;
  height: 24px;
  width: 24px;
}
.vnctaskaddfielddialog mat-list mdl-icon.bullet {
  font-weight: bold;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vnctaskaddattachmentdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskaddattachmentdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskaddattachmentdialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskaddattachmentdialog .task__dialog-body {
  padding: 0px 15px;
}
.vnctaskaddattachmentdialog .task-attachment-list {
  height: 295px;
  overflow-y: auto;
}
@media only screen and (max-width : 1023px) {
  .vnctaskaddattachmentdialog .task-attachment-list {
    height: 100%;
  }
}
.vnctaskaddattachmentdialog .task-attachment-item {
  display: flex;
  height: 50px;
  align-items: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding: 10px 0px;
  word-break: break-all;
}
.vnctaskaddattachmentdialog .task-attachment-item .thumbnail img {
  height: 38px;
  width: 38px;
  object-fit: cover;
}
.vnctaskaddattachmentdialog .task-attachment-item .filename {
  width: 87%;
  padding-left: 10px;
  word-break: break-word;
}
.vnctaskaddattachmentdialog .task-attachment-item .delete mdl-icon {
  font-size: 18px;
  color: #888888;
  float: right;
  cursor: pointer;
}
.vnctaskaddattachmentdialog .add-attachment-footer {
  height: 44px;
  background: #0071bb;
  bottom: 0px;
  position: fixed;
  width: 100%;
  align-items: center;
  display: flex;
}
.vnctaskaddattachmentdialog .add-attachment-footer span {
  font-size: 12px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  padding-left: 10px;
}
.vnctaskaddattachmentdialog .task-save-attachment-btn {
  position: absolute;
  bottom: 10px;
  z-index: 2;
  right: 7px;
  background-color: #29b6f6;
}
.vnctaskaddattachmentdialog .task-save-attachment-btn:hover {
  background-color: #29b6f6;
}

.vnctaskattachmentoptiondialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskattachmentoptiondialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.vnctaskattachmentoptiondialog .task__dialog-body {
  padding: 0px 15px;
}
.vnctaskattachmentoptiondialog mat-list mat-list-item:hover {
  cursor: pointer;
  background-color: #eee;
}
.vnctaskattachmentoptiondialog mat-list mat-list-item mat-icon {
  font-size: 18px;
}
.vnctaskattachmentoptiondialog mat-list mat-list-item span {
  margin-left: 15px;
}
.vnctaskattachmentoptiondialog mat-list mdl-icon.bullet {
  font-weight: bold;
}
.vnctaskattachmentoptiondialog .hide {
  display: none;
}

#taskAttachmentDialog {
  height: 100%;
}

.vnctaskrecordvoicemsgdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskrecordvoicemsgdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskrecordvoicemsgdialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskrecordvoicemsgdialog #taskRecordVoiceDialog {
  height: 100%;
}
.vnctaskrecordvoicemsgdialog .hide {
  display: none;
}
.vnctaskrecordvoicemsgdialog .task-record-stop-btn button, .vnctaskrecordvoicemsgdialog .task-record-mic-btn button {
  height: 54px !important;
  width: 54px !important;
  background-color: red !important;
  margin: 5px;
}
.vnctaskrecordvoicemsgdialog .voice-recording {
  display: flex;
  justify-content: space-between;
  padding: 0 75px 0 75px;
  align-items: center;
  position: relative;
}
.vnctaskrecordvoicemsgdialog .recording-time {
  text-align: center;
  height: 268px;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
}
.vnctaskrecordvoicemsgdialog .recording-time span {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.15;
  letter-spacing: normal;
  color: #000000;
}
.vnctaskrecordvoicemsgdialog .tap-to-start {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  text-align: center;
  height: 268px;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  display: flex;
}
.vnctaskrecordvoicemsgdialog .pauseVoiceRecBtn, .vnctaskrecordvoicemsgdialog .resumeVoiceRecBtn, .vnctaskrecordvoicemsgdialog .stopVoiceRecBtn {
  cursor: pointer;
}
.vnctaskrecordvoicemsgdialog .start-voice-recording {
  justify-content: center;
}
@media only screen and (max-width : 1023px) {
  .vnctaskrecordvoicemsgdialog .voice-recording {
    display: flex;
    justify-content: space-between;
    padding: 0 40px 0 40px;
    align-items: center;
    width: 100%;
  }
  .vnctaskrecordvoicemsgdialog .start-voice-recording {
    justify-content: center;
  }
  .vnctaskrecordvoicemsgdialog .tap-to-start {
    height: calc(100% - 70px);
  }
  .vnctaskrecordvoicemsgdialog .recording-time {
    height: calc(100% - 70px);
  }
}

.vnctaskaddreminderdialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vnctaskaddreminderdialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vnctaskaddreminderdialog {
    width: 100%;
    height: 100%;
  }
}
.vnctaskaddreminderdialog .task__dialog-body {
  padding: 0px 15px;
}
.vnctaskaddreminderdialog .mdl-list__item {
  padding: 14px 0px;
}
.vnctaskaddreminderdialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 22px;
}
.vnctaskaddreminderdialog .reminder-option {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
  height: 55px;
}
.vnctaskaddreminderdialog .reminder-option input {
  padding-right: 5px;
  height: 18px;
  width: 18px;
  line-height: 18px;
}
.vnctaskaddreminderdialog .reminder-option span {
  margin-left: 15px;
}
.vnctaskaddreminderdialog .reminder-time {
  width: 40%;
  height: 30px;
}
.vnctaskaddreminderdialog .reminder-date {
  width: 50%;
  height: 30px;
}
.vnctaskaddreminderdialog .custom-date-div {
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
}
.vnctaskaddreminderdialog .custom-date-div input {
  border: none;
  border-bottom: 1px solid #888;
  outline: none;
}
.vnctaskaddreminderdialog .custom-date-div input:focus {
  border-bottom: 1px solid #0071bb;
}
.vnctaskaddreminderdialog .reminder-div {
  justify-content: space-between;
  display: flex;
  width: 300px;
  margin-left: 28px;
}
.vnctaskaddreminderdialog .reminder-date-icon {
  opacity: 0.5;
  font-size: 18px;
  vertical-align: middle;
}
@media only screen and (max-width : 1023px) {
  .vnctaskaddreminderdialog .voice-recording {
    display: flex;
    justify-content: space-between;
    padding: 0 40px 0 40px;
    align-items: center;
    width: 100%;
  }
  .vnctaskaddreminderdialog .start-voice-recording {
    justify-content: center;
  }
  .vnctaskaddreminderdialog .tap-to-start {
    height: calc(100% - 70px);
  }
  .vnctaskaddreminderdialog .recording-time {
    height: calc(100% - 70px);
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 11111111;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.288);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.owl-dialog-container {
  position: relative;
  pointer-events: auto;
  box-sizing: border-box;
  display: block;
  padding: 1.5em;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: auto;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 100%;
  outline: none;
}

.owl-dt-container,
.owl-dt-container * {
  box-sizing: border-box;
}

.owl-dt-container {
  display: block;
  font-size: 1rem;
  background: #ffffff;
  pointer-events: auto;
  z-index: 1000;
}

.owl-dt-container-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.owl-dt-container-row:last-child {
  border-bottom: none;
}

.owl-dt-calendar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: flex;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: 0.5em;
  color: #000000;
}
.owl-dt-calendar-control .owl-dt-calendar-control-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button {
  padding: 0 0.8em;
}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.owl-dt-calendar-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0.5em 0.5em;
  outline: 0;
}

.owl-dt-calendar-view {
  display: block;
  flex: 1 1 auto;
}

.owl-dt-calendar-multi-year-view {
  display: flex;
  align-items: center;
}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table {
  width: calc(100% - 3em);
}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th {
  padding-bottom: 0.25em;
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.owl-dt-calendar-table .owl-dt-calendar-header {
  color: rgba(0, 0, 0, 0.4);
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th {
  font-size: 0.7em;
  font-weight: 400;
  text-align: center;
  padding-bottom: 1em;
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider {
  position: relative;
  height: 1px;
  padding-bottom: 0.5em;
}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
  content: "";
  position: absolute;
  top: 0;
  left: -0.5em;
  right: -0.5em;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
}
.owl-dt-calendar-table .owl-dt-calendar-cell {
  position: relative;
  height: 0;
  line-height: 0;
  text-align: center;
  outline: 0;
  color: rgba(0, 0, 0, 0.85);
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  position: absolute;
  top: 5%;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  font-size: 0.8em;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 999px;
  color: inherit;
  cursor: pointer;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-out {
  opacity: 0.2;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgba(0, 0, 0, 0.4);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: hsla(0deg, 0%, 100%, 0.85);
  background-color: #3f51b5;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today {
  box-shadow: inset 0 0 0 1px hsla(0deg, 0%, 100%, 0.85);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled {
  cursor: default;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  color: rgba(0, 0, 0, 0.4);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
  opacity: 0.4;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: rgba(0, 0, 0, 0.2);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: rgba(0, 0, 0, 0.04);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range {
  background: rgba(63, 81, 181, 0.2);
}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from {
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to {
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

.owl-dt-timer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 7em;
  padding: 0.5em;
  outline: none;
}

.owl-dt-timer-box {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 100%;
}

.owl-dt-timer-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.2em 0;
}
.owl-dt-timer-content .owl-dt-timer-input {
  display: block;
  width: 2em;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  outline: medium none;
  font-size: 1.2em;
  padding: 0.2em;
}

.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: 0.6em;
  height: 100%;
  left: -0.3em;
}
.owl-dt-timer-divider:before, .owl-dt-timer-divider:after {
  content: "";
  display: inline-block;
  width: 0.35em;
  height: 0.35em;
  position: absolute;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%);
  background-color: currentColor;
}
.owl-dt-timer-divider:before {
  top: 35%;
}
.owl-dt-timer-divider:after {
  bottom: 35%;
}

.owl-dt-control-button {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background-color: transparent;
  font-size: 1em;
  color: inherit;
}
.owl-dt-control-button .owl-dt-control-button-content {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.owl-dt-control-period-button .owl-dt-control-button-content {
  height: 1.5em;
  padding: 0 0.5em;
  border-radius: 3px;
  transition: background-color 100ms linear;
}
.owl-dt-control-period-button:hover > .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.12);
}
.owl-dt-control-period-button .owl-dt-control-button-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin: 0.1em;
  transition: transform 200ms ease;
}

.owl-dt-control-arrow-button .owl-dt-control-button-content {
  padding: 0;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
}
.owl-dt-control-arrow-button[disabled] {
  color: rgba(0, 0, 0, 0.4);
  cursor: default;
}
.owl-dt-control-arrow-button svg {
  width: 50%;
  height: 50%;
  fill: currentColor;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 17em;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-inline-container .owl-dt-timer,
.owl-dt-popup-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-timer {
  width: 100%;
}
.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
  height: 20.25em;
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;
}
.owl-dt-dialog-container .owl-dt-calendar {
  min-width: 250px;
  min-height: 330px;
  max-width: 750px;
  max-height: 750px;
}
.owl-dt-dialog-container .owl-dt-timer {
  min-width: 250px;
  max-width: 750px;
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 58vh;
    height: 62vh;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 58vh;
  }
}
@media all and (orientation: portrait) {
  .owl-dt-dialog-container .owl-dt-calendar {
    width: 80vw;
    height: 80vw;
  }
  .owl-dt-dialog-container .owl-dt-timer {
    width: 80vw;
  }
}
.owl-dt-container-buttons {
  display: flex;
  width: 100%;
  height: 2em;
  color: #3f51b5;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
  border-radius: 0;
}
.owl-dt-container-control-button .owl-dt-control-button-content {
  height: 100%;
  width: 100%;
  transition: background-color 100ms linear;
}
.owl-dt-container-control-button:hover .owl-dt-control-button-content {
  background-color: rgba(0, 0, 0, 0.1);
}

.owl-dt-container-info {
  padding: 0 0.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.owl-dt-container-info .owl-dt-container-range {
  outline: none;
}
.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 0;
  font-size: 0.8em;
}
.owl-dt-container-info .owl-dt-container-range:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.owl-dt-container-info .owl-dt-container-info-active {
  color: #3f51b5;
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

.owl-dt-timer-hour12 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3f51b5;
}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box {
  border: 1px solid currentColor;
  border-radius: 2px;
  transition: background 200ms ease;
}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content {
  width: 100%;
  height: 100%;
  padding: 0.5em;
}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content, .owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content {
  background: #3f51b5;
  color: #ffffff;
}

.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out {
  visibility: hidden;
  cursor: default;
}

.owl-dt-inline {
  display: inline-block;
}

.owl-dt-control {
  outline: none;
  cursor: pointer;
}
.owl-dt-control .owl-dt-control-content {
  outline: none;
}
.owl-dt-control:focus > .owl-dt-control-content {
  background-color: rgba(0, 0, 0, 0.12);
}
.owl-dt-control:not(:-moz-focusring):focus > .owl-dt-control-content {
  box-shadow: none;
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.cdk-overlay-backdrop.vncMail-form-backdrop {
  background: rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 767px) {
  .cdk-overlay-backdrop.vncMail-form-backdrop.mail-logout-backdrop {
    background: rgba(0, 0, 0, 0.7);
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-select-apps-dialog {
  max-width: 767px;
  height: 100%;
}
.vp-select-apps-dialog .task__dialog-body {
  padding: 0px;
}
@media only screen and (min-width : 768px) {
  .vp-select-apps-dialog .task__dialog-body {
    height: 420px;
  }
}
.vp-select-apps-dialog .task__dialog-body .task__dialog-footer vnc-button {
  margin: 4px 10px;
}
.vp-select-apps-dialog .task__dialog-body .task__dialog-footer .disabled-save-app .vnc-round-button {
  background: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.vp-select-apps-dialog .task__dialog-body .task__dialog-footer .disabled-save-app .vnc-round-button .mat-icon {
  color: rgba(0, 0, 0, 0.26);
}
.vp-select-apps-dialog .task__dialog-body .task__dialog-footer .active-save-app .vnc-round-button {
  background: rgba(158, 158, 158, 0.2);
  cursor: pointer;
}
.vp-select-apps-dialog .task__dialog-body .task__dialog-footer .active-save-app .vnc-round-button .mat-icon {
  color: #737373;
}
.vp-select-apps-dialog .vnc-icon {
  height: 32px;
  width: 32px;
}
.vp-select-apps-dialog .changed {
  height: calc(100% - 60px);
}
@media screen and (min-width: 767px) {
  .vp-select-apps-dialog .changed {
    height: 360px;
  }
}
@media screen and (max-width: 767px) {
  .vp-select-apps-dialog mdl-list {
    height: 89vh;
    overflow-y: scroll;
  }
}
@media screen and (min-width: 767px) {
  .vp-select-apps-dialog mdl-list {
    height: 400px;
    overflow-y: auto;
  }
}
.vp-select-apps-dialog h5 {
  margin: 5px;
  margin-left: 16px;
  margin-bottom: 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-select-apps-dialog .mdl-list {
  padding: 0px;
}
.vp-select-apps-dialog vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
}
.vp-select-apps-dialog .mdl-list__item {
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  padding: 14px;
}
.vp-select-apps-dialog .mdl-list__item .mdl-list__item-primary-content {
  font-size: 1.14rem;
  font-weight: 400;
}
.vp-select-apps-dialog .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox__box-outline {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid #d8d8d8;
}
.vp-select-apps-dialog .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background-color: #29b6f6;
}
.vp-select-apps-dialog .mdl-list__item .mdl-checkbox.is-checked .mdl-checkbox__box-outline, .vp-select-apps-dialog .mdl-list__item .mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}
.vp-select-apps-dialog .mdl-list__item .mdl-checkbox.is-upgraded {
  padding-left: 20px;
}
.vp-select-apps-dialog .mat-list-item-content {
  justify-content: space-between;
}
.vp-select-apps-dialog .mat-list-item-content .list-label {
  display: flex;
}
.vp-select-apps-dialog .mat-list-item {
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-select-users-dialog {
  max-width: 767px;
  height: 100%;
}
@media only screen and (min-width : 1024px) {
  .vp-select-users-dialog {
    height: 470px;
    width: 450px;
  }
}
.vp-select-users-dialog .task__dialog-body {
  padding: 0 0 14px;
  overflow: auto;
}
@media only screen and (min-width : 768px) {
  .vp-select-users-dialog .task__dialog-body {
    height: 370px;
  }
}
@media only screen and (max-width : 1023px) {
  .vp-select-users-dialog .task__dialog-body {
    height: calc(100% - 100px);
    max-height: calc(100% - 100px);
  }
}
.vp-select-users-dialog .mdl-list {
  padding: 0px;
}
.vp-select-users-dialog .vnc-icon {
  height: 32px;
  width: 32px;
}
.vp-select-users-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-select-users-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-select-users-dialog .selected-users {
  height: 100%;
  padding-bottom: 8px;
}
.vp-select-users-dialog .selected-users .option-list {
  height: 100%;
}
@media screen and (max-width: 767px) {
  .vp-select-users-dialog .selected-users mat-list {
    height: calc(100% - 170px);
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (min-width: 767px) {
  .vp-select-users-dialog .selected-users mat-list {
    height: 235px;
  }
}
.vp-select-users-dialog .selected-users .changed {
  height: calc(100% - 200px);
}
@media screen and (min-width: 767px) {
  .vp-select-users-dialog .selected-users .changed {
    height: 175px;
  }
}
.vp-select-users-dialog .selected-users .filter-search-textfield {
  width: calc(100% - 28px);
  margin: 0 14px;
}
.vp-select-users-dialog .selected-users .mdl-list__item .mdl-list__item-primary-content {
  font-size: 1.14rem;
  font-weight: 400;
}
.vp-select-users-dialog .selected-users .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox__box-outline {
  border-radius: 50%;
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-users-dialog .selected-users .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background-color: #29b6f6;
}
.vp-select-users-dialog .selected-users .mdl-list__item .mdl-checkbox.is-checked .mdl-checkbox__box-outline, .vp-select-users-dialog .selected-users .mdl-list__item .mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}
.vp-select-users-dialog .selected-users .mdl-list__item {
  width: 100%;
}
.vp-select-users-dialog .selected-users .mdl-list__item.user-list-item {
  padding: 10px 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  border: none;
}
.vp-select-users-dialog .selected-users .mdl-list__item mdl-list-item-primary-content span {
  margin-left: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vp-select-users-dialog .selected-users .mdl-list__item mdl-list-item-secondary-action mdl-checkbox {
  z-index: 0;
}
.vp-select-users-dialog .selected-users .mdl-list__item mdl-list-item-secondary-action .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-users-dialog .selected-users .mdl-list__item mdl-list-item-secondary-action .mdl-radio__inner-circle {
  top: 3px;
  left: 4px;
}
.vp-select-users-dialog .selected-users .mat-list-item {
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
  padding: 0 14px !important;
}
.vp-select-users-dialog .selected-users .mat-list-item-content {
  justify-content: space-between;
}
.vp-select-users-dialog .selected-users .mat-list-item-content .list-lable {
  display: flex;
  align-items: center;
  gap: 10px;
}
.vp-select-users-dialog .selected-users .mat-list-item-content .list-action {
  padding-right: 10px;
}
.vp-select-users-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-users-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-users-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-users-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-select-users-dialog vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
}
.vp-select-users-dialog .select-user-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-select-users-dialog .select-user-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-select-users-dialog .select-user-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-select-users-dialog .select-user-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-select-users-dialog .select-user-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-select-users-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-select-users-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-select-users-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-select-users-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
@media only screen and (max-width : 1023px) {
  .vp-select-users-dialog .task__dialog-desktop-close-button {
    display: none;
  }
}
@media only screen and (min-width : 1024px) {
  .vp-select-users-dialog .task__dialog-mobile-back-button {
    display: none;
  }
}
.vp-select-users-dialog .mat-list .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-select-projects-dialog {
  max-width: 767px;
  height: 100%;
}
@media only screen and (min-width : 1024px) {
  .vp-select-projects-dialog {
    height: 470px;
    width: 450px;
  }
}
.vp-select-projects-dialog .task__dialog-body {
  padding: 0 0 14px;
  overflow: auto;
}
@media only screen and (min-width : 768px) {
  .vp-select-projects-dialog .task__dialog-body {
    height: 370px;
  }
}
.vp-select-projects-dialog .mat-list {
  padding: 0px;
}
.vp-select-projects-dialog .mat-list-item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-select-projects-dialog .mat-list-item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-select-projects-dialog .selected-projects {
  height: 100%;
}
.vp-select-projects-dialog .selected-projects .option-list {
  height: 100%;
}
.vp-select-projects-dialog .selected-projects mat-list {
  padding-left: 62px;
}
@media screen and (max-width: 767px) {
  .vp-select-projects-dialog .selected-projects mat-list {
    height: calc(100% - 170px);
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (min-width: 767px) {
  .vp-select-projects-dialog .selected-projects mat-list {
    height: 235px;
  }
}
.vp-select-projects-dialog .selected-projects .changed {
  height: auto;
  padding-bottom: 55px;
}
@media screen and (min-width: 767px) {
  .vp-select-projects-dialog .selected-projects .changed {
    height: 180px;
  }
}
.vp-select-projects-dialog .selected-projects .filter-search-textfield {
  width: calc(100% - 28px);
  margin: 0 14px;
}
.vp-select-projects-dialog .selected-projects .mat-list-item {
  border-bottom: 1px solid rgb(228, 227, 227);
}
.vp-select-projects-dialog .selected-projects .mat-list-item .mdl-list__item-primary-content {
  font-size: 1.14rem;
  font-weight: 400;
}
.vp-select-projects-dialog .selected-projects .mat-list-item .mdl-list__item-secondary-action .mdl-checkbox__box-outline {
  border-radius: 50%;
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-projects-dialog .selected-projects .mat-list-item .mdl-list__item-secondary-action .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background-color: #29b6f6;
}
.vp-select-projects-dialog .selected-projects .mat-list-item .mdl-checkbox.is-checked .mdl-checkbox__box-outline, .vp-select-projects-dialog .selected-projects .mat-list-item .mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}
.vp-select-projects-dialog .selected-projects .mat-list-item {
  width: 100%;
  border-bottom: 1px solid #ebebeb;
}
.vp-select-projects-dialog .selected-projects .mat-list-item.project-list-item {
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  border: none;
}
.vp-select-projects-dialog .selected-projects .mat-list-item .mat-list-item-content {
  padding: 0 !important;
  justify-content: space-between;
}
.vp-select-projects-dialog .selected-projects .mat-list-item .mat-list-item-content .list-action-style {
  padding-right: 10px;
}
.vp-select-projects-dialog .selected-projects .mat-list-item mdl-list-item-primary-content mdl-icon {
  color: #29b6f6;
  font-size: 18px;
}
.vp-select-projects-dialog .selected-projects .mat-list-item mdl-list-item-primary-content span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vp-select-projects-dialog .selected-projects .mat-list-item mdl-list-item-secondary-action mdl-checkbox {
  z-index: 0;
}
.vp-select-projects-dialog .selected-projects .mat-list-item mdl-list-item-secondary-action .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-projects-dialog .selected-projects .mat-list-item mdl-list-item-secondary-action .mdl-radio__inner-circle {
  top: 3px;
  left: 4px;
}
.vp-select-projects-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-projects-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-projects-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-projects-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-select-projects-dialog vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
}
.vp-select-projects-dialog .select-project-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-select-projects-dialog .select-project-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-select-projects-dialog .select-project-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-select-projects-dialog .select-project-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-select-projects-dialog .select-project-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-select-projects-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-select-projects-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-select-projects-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-select-projects-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
@media only screen and (max-width : 1023px) {
  .vp-select-projects-dialog .task__dialog-desktop-close-button {
    display: none;
  }
}
@media only screen and (min-width : 1024px) {
  .vp-select-projects-dialog .task__dialog-mobile-back-button {
    display: none;
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-select-tags-dialog {
  max-width: 767px;
  height: 100%;
}
@media only screen and (min-width : 1024px) {
  .vp-select-tags-dialog {
    height: 470px;
    width: 450px;
  }
}
.vp-select-tags-dialog .task__dialog-body {
  padding: 0 0 14px;
  overflow: auto;
}
@media only screen and (min-width : 768px) {
  .vp-select-tags-dialog .task__dialog-body {
    height: 370px;
  }
}
.vp-select-tags-dialog .mdl-list {
  padding: 0px;
}
.vp-select-tags-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-select-tags-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-select-tags-dialog .selected-tags {
  height: 100%;
}
.vp-select-tags-dialog .selected-tags .option-list {
  height: 100%;
}
.vp-select-tags-dialog .selected-tags mat-list {
  padding-left: 62px;
}
@media screen and (max-width: 767px) {
  .vp-select-tags-dialog .selected-tags mat-list {
    height: calc(100% - 220px);
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (min-width: 767px) {
  .vp-select-tags-dialog .selected-tags mat-list {
    height: 170px;
  }
}
.vp-select-tags-dialog .selected-tags mat-list .mat-list-item-content {
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
  justify-content: space-between;
}
.vp-select-tags-dialog .selected-tags mat-list .mat-list-item-content .list-action {
  padding-right: 10px;
}
.vp-select-tags-dialog .selected-tags .changed {
  height: auto;
  padding-bottom: 40px;
}
@media screen and (min-width: 767px) {
  .vp-select-tags-dialog .selected-tags .changed {
    height: 180px;
  }
}
.vp-select-tags-dialog .selected-tags .filter-search-textfield {
  width: calc(100% - 28px);
  margin: 0 14px;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item .mdl-list__item-primary-content {
  font-size: 1.14rem;
  font-weight: 400;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox__box-outline {
  border-radius: 50%;
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background-color: #29b6f6;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item .mdl-checkbox.is-checked .mdl-checkbox__box-outline, .vp-select-tags-dialog .selected-tags .mdl-list__item .mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item {
  width: 100%;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item.tag-list-item {
  padding: 14px 14px 14px 0px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  border: none;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item mdl-list-item-primary-content mdl-icon {
  color: #29b6f6;
  font-size: 18px;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item mdl-list-item-primary-content span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item mdl-list-item-secondary-action mdl-checkbox {
  z-index: 0;
}
.vp-select-tags-dialog .selected-tags .mdl-list__item mdl-list-item-secondary-action .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-tags-dialog .selected-tags .mdl-list__item mdl-list-item-secondary-action .mdl-radio__inner-circle {
  top: 3px;
  left: 4px;
}
.vp-select-tags-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-tags-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-tags-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-tags-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-select-tags-dialog vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
}
.vp-select-tags-dialog .select-tags-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-select-tags-dialog .select-tags-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-select-tags-dialog .select-tags-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-select-tags-dialog .select-tags-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-select-tags-dialog .select-tags-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-select-tags-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-select-tags-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-select-tags-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-select-tags-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
@media only screen and (max-width : 1023px) {
  .vp-select-tags-dialog .task__dialog-desktop-close-button {
    display: none;
  }
}
@media only screen and (min-width : 1024px) {
  .vp-select-tags-dialog .task__dialog-mobile-back-button {
    display: none;
  }
}
.vp-select-tags-dialog .mat-list .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-search-through-dialog {
  max-width: 767px;
  height: 100%;
}
.vp-search-through-dialog .task__dialog-body {
  padding: 14px 0;
}
@media only screen and (min-width : 768px) {
  .vp-search-through-dialog .task__dialog-body {
    height: 370px;
  }
}
.vp-search-through-dialog .mdl-list {
  padding: 0px;
}
.vp-search-through-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-search-through-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 22px;
}
.vp-search-through-dialog .selected-files {
  height: 100%;
  padding-left: 62px;
}
.vp-search-through-dialog .selected-files mdl-list {
  padding: 0px 0px 14px 62px;
}
@media screen and (max-width: 767px) {
  .vp-search-through-dialog .selected-files mdl-list {
    height: calc(100% - 145px);
    overflow-y: scroll;
  }
}
@media screen and (min-width: 767px) {
  .vp-search-through-dialog .selected-files mdl-list {
    height: 235px;
    overflow-y: auto;
    padding-bottom: 55px;
  }
}
.vp-search-through-dialog .selected-files .mdl-list__item {
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-search-through-dialog .selected-files .mdl-list__item .mdl-list__item-primary-content {
  font-size: 1.14rem;
  font-weight: 400;
}
.vp-search-through-dialog .selected-files .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox__box-outline {
  border-radius: 50%;
  border: 2px solid #d8d8d8;
}
.vp-search-through-dialog .selected-files .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background-color: #29b6f6;
}
.vp-search-through-dialog .selected-files .mdl-list__item .mdl-checkbox.is-checked .mdl-checkbox__box-outline, .vp-search-through-dialog .selected-files .mdl-list__item .mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}
.vp-search-through-dialog .selected-files .mdl-list__item {
  width: 100%;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-search-through-dialog .selected-files .mdl-list__item.list-item {
  padding: 14px 14px 14px 0;
}
.vp-search-through-dialog .selected-files .mdl-list__item mdl-list-item-primary-content mdl-icon {
  color: #29b6f6;
}
.vp-search-through-dialog .selected-files .mdl-list__item mdl-list-item-primary-content span {
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vp-search-through-dialog .selected-files .mdl-list__item mdl-list-item-secondary-action mdl-checkbox {
  z-index: 0;
}
.vp-search-through-dialog .selected-files .mdl-list__item mdl-list-item-secondary-action .mdl-checkbox .mdl-checkbox__box-outline, .vp-search-through-dialog .selected-files .mdl-list__item mdl-list-item-secondary-action .mdl-radio__inner-circle {
  top: 3px;
  left: 4px;
}
.vp-search-through-dialog .selected-files .mat-list-item {
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-search-through-dialog .selected-files .mat-list-item-content {
  justify-content: space-between;
}
.vp-search-through-dialog .selected-files .mat-list-item-content .list-action-style {
  padding-right: 10px;
}
.vp-search-through-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-search-through-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-search-through-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-search-through-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-search-through-dialog vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
}
.vp-search-through-dialog .search-through-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-search-through-dialog .search-through-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-search-through-dialog .search-through-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-search-through-dialog .search-through-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-search-through-dialog .search-through-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-search-through-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-search-through-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-search-through-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-search-through-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
.vp-search-through-dialog .mat-list .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-time-range-dialog {
  max-width: 767px;
  height: 100%;
}
.vp-time-range-dialog .task__dialog-body {
  padding: 14px 0;
}
@media only screen and (min-width : 768px) {
  .vp-time-range-dialog .task__dialog-body {
    height: 370px;
  }
}
.vp-time-range-dialog .mdl-list {
  padding: 0px;
}
.vp-time-range-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-time-range-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-time-range-dialog .mdl-list__item:last-child {
  box-shadow: none;
}
.vp-time-range-dialog mdl-list-item-primary-content mdl-icon {
  color: #0071bb;
}
.vp-time-range-dialog mdl-list-item-primary-content span {
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vp-time-range-dialog .select-date {
  margin-left: 48px;
  display: flex;
}
.vp-time-range-dialog .select-date input {
  border: none;
  border-bottom: 1px solid #888;
  outline: none;
  height: 30px;
  width: 110px;
  font-size: 14px;
}
.vp-time-range-dialog .select-date span {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.1px;
  text-align: left;
  color: #888888;
}
.vp-time-range-dialog .select-date .date-option {
  display: grid;
  margin-right: 10px;
}
.vp-time-range-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-time-range-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-time-range-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-time-range-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-time-range-dialog .time-range-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-time-range-dialog .time-range-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-time-range-dialog .time-range-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-time-range-dialog .time-range-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-time-range-dialog .time-range-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-time-range-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-time-range-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-time-range-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-time-range-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
.vp-time-range-dialog .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-duedate-range-dialog {
  max-width: 767px;
  height: 100%;
}
.vp-duedate-range-dialog .task__dialog-body {
  padding: 14px 0;
}
@media only screen and (min-width : 768px) {
  .vp-duedate-range-dialog .task__dialog-body {
    height: 370px;
  }
}
.vp-duedate-range-dialog .mdl-list {
  padding: 0px;
}
.vp-duedate-range-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-duedate-range-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-duedate-range-dialog .mdl-list__item:last-child {
  box-shadow: none;
}
.vp-duedate-range-dialog mdl-list-item-primary-content mdl-icon {
  color: #0071bb;
}
.vp-duedate-range-dialog mdl-list-item-primary-content span {
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
.vp-duedate-range-dialog .select-date {
  margin-left: 48px;
  display: flex;
}
.vp-duedate-range-dialog .select-date input {
  border: none;
  border-bottom: 1px solid #888;
  outline: none;
  height: 30px;
  width: 110px;
  font-size: 14px;
}
.vp-duedate-range-dialog .select-date span {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.1px;
  text-align: left;
  color: #888888;
}
.vp-duedate-range-dialog .select-date .date-option {
  display: grid;
  margin-right: 10px;
}
.vp-duedate-range-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-duedate-range-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-duedate-range-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-duedate-range-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-duedate-range-dialog .due-date-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-duedate-range-dialog .due-date-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-duedate-range-dialog .due-date-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-duedate-range-dialog .due-date-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-duedate-range-dialog .due-date-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-duedate-range-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-duedate-range-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-duedate-range-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-duedate-range-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
.vp-duedate-range-dialog .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-task-notification-content-wrapper {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  min-height: 174px;
  border-radius: 4px;
  background-color: #dff1fa;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}
.vp-task-notification-content-wrapper.vp-task-notification-default {
  max-height: 220px;
}
.vp-task-notification-content-wrapper.vp-task-notification-default .vp-task-notification-title {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  color: #ffffff;
  padding: 0 20px;
  height: 44px;
  background-color: #317bbc;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  align-items: center;
  display: flex;
}
.vp-task-notification-content-wrapper.vp-task-notification-default .vp-task-notification-title mdl-icon {
  font-size: 16px;
  margin-right: 10px;
}
.vp-task-notification-content-wrapper.vp-task-notification-default .vp-task-notification-content {
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding: 10px 20px;
}
.vp-task-notification-content-wrapper.vp-task-notification-default .vp-task-notification-content h6 {
  margin-top: 10px;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 68px;
  word-break: break-word;
  max-width: 200px;
}
.vp-task-notification-content-wrapper .vp-task-notification-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.vp-task-notification-content-wrapper .vp-task-notification-actions .notification-button button {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0071bb;
}
.vp-task-notification-content-wrapper .vp-task-notification-actions .notification-button button:hover {
  background-color: rgba(158, 158, 158, 0.2);
}
.vp-task-notification-content-wrapper ul.reminder-tasks-list-notification {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow: auto;
  margin-top: 10px;
}
.vp-task-notification-content-wrapper ul.reminder-tasks-list-notification li {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tasks-dialog {
  height: 313px;
  border-radius: 2.6px;
  background-color: #ffffff;
  box-shadow: 0px 1px 13px 0 rgba(0, 0, 0, 0.5);
  padding: 20px;
}
.tasks-dialog-header {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.tasks-dialog-content {
  height: calc(100% - 80px);
  overflow-y: auto;
}
.tasks-dialog-content .tasks-list {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 15px;
}
.tasks-dialog-content .tasks-list li {
  margin-bottom: 10px;
  cursor: pointer;
}
.tasks-dialog-content .tasks-list li span {
  float: right;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: right;
  color: #ff0000;
}
.tasks-dialog-actions {
  height: 70px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.tasks-dialog-actions button {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0071bb;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.preview-image-dialog-component,
.media-preview-dialog-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}
.preview-image-dialog-component.preview-image-dialog-desktop, .preview-image-dialog-component.media-preview-dialog-desktop,
.media-preview-dialog-component.preview-image-dialog-desktop,
.media-preview-dialog-component.media-preview-dialog-desktop {
  max-width: 900px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  justify-content: center;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper {
  display: flex;
  height: auto;
  width: 100%;
  align-items: flex-start;
  align-content: center;
  flex-direction: column;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper img, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper img,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper img,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper img {
  max-width: 900px;
  height: auto;
  max-height: calc(100vh - 280px);
  margin: 0 auto;
  box-shadow: 0px 2px 13px 0 rgba(0, 0, 0, 0.71);
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media {
  max-width: 900px;
  height: auto;
  max-height: calc(100vh - 280px);
  margin: 0 auto;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media img, .preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media video, .preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media div.audio, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media img, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media video, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media div.audio,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media img,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media video,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media div.audio,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media img,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media video,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media div.audio {
  box-shadow: 0px 2px 13px 0 rgba(0, 0, 0, 0.71);
  max-width: 900px;
  height: auto;
  max-height: calc(100vh - 280px);
  margin: 0 auto;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media div, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media div,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media div,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media div {
  box-shadow: none;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media div img, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media div img,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media div img,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media div img {
  box-shadow: none;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .filename, .preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media-title, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .filename, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media-title,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .filename,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media-title,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .filename,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media-title {
  position: relative;
  bottom: 0;
  width: calc(100% - 100px);
  height: 41px;
  background-color: transparent;
  color: white;
  font-family: Roboto;
  font-size: 1.21rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.3;
  letter-spacing: normal;
  text-align: left;
  color: white;
  line-height: 40px;
  text-transform: none;
  padding: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .timeago, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .timeago,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .timeago,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .timeago {
  font-size: 1rem;
  line-height: normal;
  color: #b5b5b5;
  display: block;
  margin-top: -10px;
}
.preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .media-information, .preview-image-dialog-component.preview-image-dialog-desktop .image-wrapper .image-header, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .media-information, .preview-image-dialog-component.media-preview-dialog-desktop .image-wrapper .image-header,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .media-information,
.media-preview-dialog-component.preview-image-dialog-desktop .image-wrapper .image-header,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .media-information,
.media-preview-dialog-component.media-preview-dialog-desktop .image-wrapper .image-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.preview-image-dialog-component.preview-image-dialog-mobile .mdl-dialog__header,
.media-preview-dialog-component.preview-image-dialog-mobile .mdl-dialog__header {
  height: 52px;
  background-color: rgba(0, 0, 0, 0.6);
  min-width: 100vw;
  z-index: 999;
  justify-content: space-between;
}
.preview-image-dialog-component.preview-image-dialog-mobile .mat-dialog__title,
.media-preview-dialog-component.preview-image-dialog-mobile .mat-dialog__title {
  display: inline-block;
  text-transform: none;
  max-width: calc(100vw - 220px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
}
.preview-image-dialog-component.preview-image-dialog-mobile .image-wrapper,
.media-preview-dialog-component.preview-image-dialog-mobile .image-wrapper {
  display: flex;
  height: 100%;
  width: 100vw;
  justify-content: center;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  position: absolute;
  top: 0;
}
.preview-image-dialog-component.preview-image-dialog-mobile .image-wrapper img,
.media-preview-dialog-component.preview-image-dialog-mobile .image-wrapper img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.preview-image-dialog-component #closePreviewDialog mat-icon,
.media-preview-dialog-component #closePreviewDialog mat-icon {
  font-size: 1.6rem;
  color: white;
}
.preview-image-dialog-component #closePreviewDialog:hover,
.media-preview-dialog-component #closePreviewDialog:hover {
  background-color: rgba(158, 158, 158, 0.2);
}
.preview-image-dialog-component .image-actions,
.media-preview-dialog-component .image-actions {
  display: flex;
  height: 32px;
}
.preview-image-dialog-component .image-actions button,
.media-preview-dialog-component .image-actions button {
  height: 32px;
  padding: 0;
  min-width: 36px;
}
@media screen and (max-width: 767px) {
  .preview-image-dialog-component .image-actions button:hover, .preview-image-dialog-component .image-actions button:active,
  .media-preview-dialog-component .image-actions button:hover,
  .media-preview-dialog-component .image-actions button:active {
    background-color: rgba(158, 158, 158, 0.2);
  }
}
.preview-image-dialog-component .image-actions #downloadAttachmentSingle,
.media-preview-dialog-component .image-actions #downloadAttachmentSingle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
}
.preview-image-dialog-component .image-actions mdl-icon,
.media-preview-dialog-component .image-actions mdl-icon {
  font-size: 1.6rem;
  color: white;
}
.preview-image-dialog-component .image-actions mat-icon,
.media-preview-dialog-component .image-actions mat-icon {
  font-size: 1.6rem;
  color: white;
}
.preview-image-dialog-component .filename,
.media-preview-dialog-component .filename {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 41px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-family: Roboto;
  font-size: 1.07rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.3;
  letter-spacing: normal;
  text-align: center;
  color: white;
  line-height: 40px;
  text-transform: none;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-wrap: break-word;
  word-break: break-word;
}

.vp-confirmation-wrapper .mat-dialog__title {
  padding-top: 13px;
  font-family: Montserrat;
  font-size: 1.28rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(0, 0, 0);
}
.vp-confirmation-wrapper .mat-dialog__content-block {
  padding: 0 25px;
  font-family: Roboto;
  font-size: 1.14rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(0, 0, 0);
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
}
.vp-confirmation-wrapper .mat-dialog__actions-block {
  padding: 5px 12px;
  border-top: none;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.vp-confirmation-wrapper .mat-dialog__actions-block button {
  font-family: Montserrat;
  font-size: 1.14rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #29b6f6;
}
.vp-confirmation-wrapper.delete-group-component .mat-dialog__content-block strong {
  word-break: break-word;
  word-wrap: break-word;
}
.vp-confirmation-wrapper.delete-group-component .mat-dialog__content-block button {
  color: #0071bb;
}

.media-image-preview {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: rgb(0, 0, 0);
}
.media-image-preview__header {
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  top: 0;
  left: 0;
  height: 60px;
}
.media-image-preview__header .back-to-media, .media-image-preview__header .more-options {
  width: 42px;
  min-width: 36px;
  line-height: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-image-preview__header .back-to-media button > mdl-icon, .media-image-preview__header .back-to-media mdl-icon, .media-image-preview__header .more-options button > mdl-icon, .media-image-preview__header .more-options mdl-icon {
  color: white;
  height: 80%;
}
.media-image-preview__header #media-menu button {
  padding: 0;
  min-width: 0;
}
.media-image-preview__header .menu-item {
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
}
.media-image-preview__header .menu-item mdl-icon {
  color: #000000;
  font-size: 18px;
  padding-top: 4px;
}
.media-image-preview__header .image-file-name {
  font-size: 1.07rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.3;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  overflow: hidden;
  padding-left: 26px;
}
.media-image-preview__header .image-file-name p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.media-image-preview__header span {
  flex: 1 1 auto;
}
.media-image-preview__body {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-image-preview__body img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.media-image-preview__footer {
  position: absolute;
  z-index: 1;
  padding-left: 14px;
  padding-bottom: 5px;
  padding-top: 8px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.media-image-preview__footer .sender-name p {
  width: 95%;
  margin: 0;
  padding-bottom: 4px;
  font-size: 1.07rem;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.media-image-preview__footer .sent-time p {
  width: 95%;
  margin: 0;
  font-size: 1rem;
  color: #a9a9a9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-audio-preview {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: rgb(0, 0, 0);
}
.media-audio-preview__header {
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  top: 0;
  left: 0;
  height: 60px;
}
.media-audio-preview__header .back-to-media, .media-audio-preview__header .more-options {
  width: 42px;
  min-width: 36px;
  line-height: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-audio-preview__header .back-to-media button > mdl-icon, .media-audio-preview__header .back-to-media mdl-icon, .media-audio-preview__header .more-options button > mdl-icon, .media-audio-preview__header .more-options mdl-icon {
  color: white;
  height: 80%;
}
.media-audio-preview__header #media-menu button {
  padding: 0;
  min-width: 0;
}
.media-audio-preview__header .audio-file-name {
  font-size: 1.07rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.3;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding-left: 26px;
}
.media-audio-preview__header .audio-file-name p {
  margin: 0;
}
.media-audio-preview__header span {
  flex: 1 1 auto;
}
.media-audio-preview__body {
  position: absolute;
  top: 10%;
  bottom: 10%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-audio-preview__body mdl-icon {
  color: white;
  font-size: 14.28rem;
}
.media-audio-preview__audio {
  width: 100%;
  position: absolute;
  bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-video-preview {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  background: rgb(0, 0, 0);
}
.media-video-preview__header {
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  top: 0;
  left: 0;
  height: 60px;
}
.media-video-preview__header .back-to-media, .media-video-preview__header .more-options {
  width: 42px;
  min-width: 36px;
  line-height: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-video-preview__header .back-to-media button > mdl-icon, .media-video-preview__header .back-to-media mdl-icon, .media-video-preview__header .more-options button > mdl-icon, .media-video-preview__header .more-options mdl-icon {
  color: white;
  height: 80%;
}
.media-video-preview__header #media-menu button {
  padding: 0;
  min-width: 0;
}
.media-video-preview__header .video-file-name {
  font-size: 1.07rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 4.3;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding-left: 26px;
}
.media-video-preview__header .video-file-name p {
  margin: 0;
}
.media-video-preview__header span {
  flex: 1 1 auto;
}
.media-video-preview__body {
  position: absolute;
  top: 10%;
  bottom: 10%;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-video-preview__body video {
  height: 100%;
  width: 100%;
}

.options-menu .menu-item {
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  align-content: center;
}
.options-menu .menu-item mat-icon {
  height: 20px !important;
  width: 20px !important;
  color: #000000 !important;
}
.options-menu .menu-item mdl-icon {
  color: #000000;
  font-size: 1.28rem;
  padding-top: 4px;
}
.options-menu .menu-item p {
  padding-left: 12px;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.registration {
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.registration .registration-form {
  max-width: calc(100% - 40px);
  background: #FFF;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px;
  -moz-box-shadow: 0 0 3px 0 #8c8c8c;
  -webkit-box-shadow: 0 0 3px 0 #8c8c8c;
  box-shadow: 0 0 1px 0 #8c8c8c;
  min-height: 280px;
  padding-top: 20px;
  box-sizing: border-box;
  float: none;
  margin: 20px;
}
@media only screen and (min-width : 768px) {
  .registration .registration-form {
    max-width: 450px;
  }
}
.registration .registration-form .title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}
.registration .registration-form .mat-form-field {
  width: 100%;
  padding-bottom: 15px;
}
.registration .registration-form .login-button {
  text-align: center;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  margin-top: 35px;
}
.registration .registration-form button {
  width: 100%;
  background: #09c856;
  border: 1px solid #09c856;
  cursor: pointer;
  margin: 0 auto;
  height: 47px;
  float: none;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}
.registration .registration-form .mat-hint {
  font-size: 12px;
}
.registration .registration-form .mat-error {
  font-size: 12px;
}
.registration .registration-form .policy-privacy-link {
  color: #0070bc;
  text-decoration: none;
}
.registration .registration-form .clear-form {
  padding-top: 20px;
}
.registration .registration-form .clear-form span {
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #317bbc;
  cursor: pointer;
  text-transform: uppercase;
}
.registration .app_footer-title {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 20px;
  padding-top: 20px;
  cursor: pointer;
}
.registration .registration-activation {
  height: 100%;
  padding: 25px;
  width: 100%;
}
@media only screen and (min-width : 768px) {
  .registration .registration-activation {
    width: 450px;
  }
}
.registration .registration-activation .title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 40px;
  text-transform: uppercase;
}
.registration .registration-activation .message-body {
  padding-top: 50px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.registration .registration-activation .message-body .activation-message {
  color: #ffffff;
}
.registration .registration-activation .message-body .activation-note {
  color: #b8b8b8;
}
.registration .registration-activation .message-body div {
  padding-bottom: 30px;
}
.registration .registration-activation .login-button {
  text-align: center;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
}
@media only screen and (min-width : 768px) {
  .registration .registration-activation .login-button {
    position: inherit;
    margin-top: 40px;
  }
}
.registration .registration-activation .login-button button {
  width: calc(100% - 80px);
  background: #09c856;
  border: 1px solid #09c856;
  cursor: pointer;
  margin: 0 auto;
  height: 47px;
  float: none;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.registration .registration-activation .resend-link {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
@media only screen and (min-width : 768px) {
  .registration .registration-activation .resend-link {
    position: inherit;
    margin-top: 40px;
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.forgotpassword {
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.forgotpassword .forgotpassword-form {
  max-width: calc(100% - 40px);
  background: #FFF;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px;
  -moz-box-shadow: 0 0 3px 0 #8c8c8c;
  -webkit-box-shadow: 0 0 3px 0 #8c8c8c;
  box-shadow: 0 0 1px 0 #8c8c8c;
  min-height: 280px;
  padding-top: 20px;
  box-sizing: border-box;
  float: none;
  margin: 40% 20px 20px;
}
@media only screen and (min-width : 768px) {
  .forgotpassword .forgotpassword-form {
    max-width: 450px;
    margin: 10% 0 0;
  }
}
.forgotpassword .forgotpassword-form .title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}
.forgotpassword .forgotpassword-form .enter-email-info {
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.forgotpassword .forgotpassword-form .mat-form-field {
  width: 100%;
  padding-bottom: 15px;
}
.forgotpassword .forgotpassword-form .login-button {
  text-align: center;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  margin-top: 35px;
}
.forgotpassword .forgotpassword-form button {
  width: 100%;
  background: #09c856;
  border: 1px solid #09c856;
  cursor: pointer;
  margin: 0 auto;
  height: 47px;
  float: none;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}
.forgotpassword .forgotpassword-form .mat-hint {
  font-size: 12px;
}
.forgotpassword .forgotpassword-form .mat-error {
  font-size: 12px;
}
.forgotpassword .app_footer-title {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 20px;
  padding-top: 25px;
  cursor: pointer;
}
.forgotpassword .forgotpassword-info {
  height: 100%;
  padding: 25px;
}
@media only screen and (min-width : 768px) {
  .forgotpassword .forgotpassword-info {
    max-width: 450px;
    margin: 5% 0 0;
    height: auto;
  }
}
.forgotpassword .forgotpassword-info .title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 40px;
  text-transform: uppercase;
}
.forgotpassword .forgotpassword-info .message-body {
  padding-top: 50px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}
.forgotpassword .forgotpassword-info .message-body .info-message {
  color: #ffffff;
}
.forgotpassword .forgotpassword-info .login-button {
  text-align: center;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
}
@media only screen and (min-width : 768px) {
  .forgotpassword .forgotpassword-info .login-button {
    position: inherit;
    margin-top: 40px;
  }
}
.forgotpassword .forgotpassword-info .login-button button {
  width: calc(100% - 80px);
  background: #09c856;
  border: 1px solid #09c856;
  cursor: pointer;
  margin: 0 auto;
  height: 47px;
  float: none;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  font-size: 16px;
  text-transform: capitalize;
  color: #fff;
  font-weight: bold;
}
.forgotpassword .forgotpassword-info .resend-link {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
@media only screen and (min-width : 768px) {
  .forgotpassword .forgotpassword-info .resend-link {
    position: inherit;
    margin-top: 40px;
    bottom: 0px;
  }
}

.passwordreset {
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  text-align: -webkit-center;
  text-align: -moz-center;
}
.passwordreset .passwordreset-form {
  max-width: calc(100% - 40px);
  background: #FFF;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px;
  -moz-box-shadow: 0 0 3px 0 #8c8c8c;
  -webkit-box-shadow: 0 0 3px 0 #8c8c8c;
  box-shadow: 0 0 1px 0 #8c8c8c;
  min-height: 280px;
  padding-top: 20px;
  box-sizing: border-box;
  float: none;
  margin: 40% 20px 20px;
}
@media only screen and (min-width : 768px) {
  .passwordreset .passwordreset-form {
    max-width: 450px;
    margin: 10% 0 0;
  }
}
.passwordreset .passwordreset-form .title {
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  text-transform: uppercase;
}
.passwordreset .passwordreset-form .mat-form-field {
  width: 100%;
  padding-bottom: 15px;
}
.passwordreset .passwordreset-form .login-button {
  text-align: center;
  overflow: hidden;
  width: 100%;
  border-radius: 5px;
  margin-top: 35px;
}
.passwordreset .passwordreset-form button {
  width: 100%;
  background: #09c856;
  border: 1px solid #09c856;
  cursor: pointer;
  margin: 0 auto;
  height: 47px;
  float: none;
  box-sizing: border-box;
  border-radius: 5px;
  -webkit-appearance: none;
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}
.passwordreset .passwordreset-form .mat-hint {
  font-size: 12px;
}
.passwordreset .passwordreset-form .mat-error {
  font-size: 12px;
}
.passwordreset .app_footer-title {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 20px;
  padding-top: 25px;
  cursor: pointer;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-task-change-password-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-task-change-password-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-change-password-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-task-change-password-dialog .task__dialog-body {
  padding: 0px 15px;
}
.vp-task-change-password-dialog .change-password-form .mat-form-field {
  width: 100%;
  padding-bottom: 15px;
}
.vp-task-change-password-dialog .change-password-form .mat-hint {
  font-size: 12px;
}
.vp-task-change-password-dialog .change-password-form .mat-error {
  font-size: 12px;
}
.vp-task-change-password-dialog .change-password-form .mat-icon {
  font-size: 18px;
  color: #888;
  cursor: pointer;
}
.vp-task-change-password-dialog .mat-dialog__title {
  padding: 20px 18px 0px 18px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-task-change-password-dialog .mat-dialog__actions-block {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.vp-task-change-password-dialog .mat-dialog__actions-block button {
  font-size: 16px;
  font-weight: 600;
  color: #0071bb;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  box-shadow: none;
  margin-right: 0px;
}
.vp-task-change-password-dialog .message-body {
  padding: 8px 8px 8px 8px;
  text-align: center;
}
@media only screen and (max-width : 1023px) {
  .vp-task-change-password-dialog .message-body {
    margin-top: 30px;
  }
}
.vp-task-change-password-dialog .message-body div {
  padding-bottom: 10px;
}
.vp-task-change-password-dialog .message-body .success-div {
  color: #21cd67;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-select-list-dialog {
  max-width: 767px;
  height: 100%;
}
@media only screen and (min-width : 768px) {
  .vp-select-list-dialog {
    height: 470px;
    width: 450px;
  }
}
.vp-select-list-dialog .task__dialog-body {
  padding: 0 0 14px;
  overflow: auto;
}
@media only screen and (min-width : 768px) {
  .vp-select-list-dialog .task__dialog-body {
    height: 370px;
  }
}
.vp-select-list-dialog .mdl-list {
  padding: 0px;
}
.vp-select-list-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-select-list-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-select-list-dialog .selected-lists .option-list {
  height: 100%;
}
.vp-select-list-dialog .selected-lists .mat-list {
  padding-left: 62px;
}
@media screen and (max-width: 767px) {
  .vp-select-list-dialog .selected-lists .mat-list {
    height: calc(100% - 220px);
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (min-width: 767px) {
  .vp-select-list-dialog .selected-lists .mat-list {
    height: 170px;
  }
}
.vp-select-list-dialog .selected-lists .mat-list .mat-list-item-content {
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
  justify-content: space-between;
}
.vp-select-list-dialog .selected-lists .mat-list .mat-list-item-content .list-action {
  padding-right: 10px;
}
.vp-select-list-dialog .selected-lists .changed {
  height: auto;
  padding-bottom: 40px;
}
@media screen and (min-width: 767px) {
  .vp-select-list-dialog .selected-lists .changed {
    height: 180px;
  }
}
.vp-select-list-dialog .selected-lists .filter-search-textfield {
  width: calc(100% - 28px);
  margin: 0 14px;
}
.vp-select-list-dialog .selected-lists .mdl-list__item .mdl-list__item-primary-content {
  font-size: 1.14rem;
  font-weight: 400;
}
.vp-select-list-dialog .selected-lists .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox__box-outline {
  border-radius: 50%;
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-list-dialog .selected-lists .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background-color: #29b6f6;
}
.vp-select-list-dialog .selected-lists .mdl-list__item .mdl-checkbox.is-checked .mdl-checkbox__box-outline, .vp-select-list-dialog .selected-lists .mdl-list__item .mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}
.vp-select-list-dialog .selected-lists .mdl-list__item {
  width: 100%;
}
.vp-select-list-dialog .selected-lists .mdl-list__item.tag-list-item {
  padding: 14px 14px 14px 0px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  border: none;
}
.vp-select-list-dialog .selected-lists .mdl-list__item mdl-list-item-primary-content mdl-icon {
  color: #29b6f6;
  font-size: 18px;
}
.vp-select-list-dialog .selected-lists .mdl-list__item mdl-list-item-primary-content span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.vp-select-list-dialog .selected-lists .mdl-list__item mdl-list-item-secondary-action mdl-checkbox {
  z-index: 0;
}
.vp-select-list-dialog .selected-lists .mdl-list__item mdl-list-item-secondary-action .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-list-dialog .selected-lists .mdl-list__item mdl-list-item-secondary-action .mdl-radio__inner-circle {
  top: 3px;
  left: 4px;
}
.vp-select-list-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-list-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-list-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-list-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-select-list-dialog vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
}
.vp-select-list-dialog .select-list-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-select-list-dialog .select-list-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-select-list-dialog .select-list-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-select-list-dialog .select-list-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-select-list-dialog .select-list-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-select-list-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-select-list-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-select-list-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-select-list-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
@media only screen and (max-width : 1023px) {
  .vp-select-list-dialog .task__dialog-desktop-close-button {
    display: none;
  }
}
@media only screen and (min-width : 1024px) {
  .vp-select-list-dialog .task__dialog-mobile-back-button {
    display: none;
  }
}
.vp-select-list-dialog .mat-list .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-create-list-dialog {
  min-width: 300px;
}
.vp-create-list-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-create-list-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.vp-create-list-dialog .mat-dialog__title {
  padding: 20px 18px 0px 18px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-create-list-dialog .mat-dialog__content-block {
  padding: 0 18px;
}
.vp-create-list-dialog .mat-dialog__content-block .mat-form-field {
  width: 100%;
}
.vp-create-list-dialog .mat-dialog__content-block .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0071bb;
}
.vp-create-list-dialog .mat-dialog__content-block input.mat-input-element {
  font-family: "Roboto";
  font-size: 16px;
  color: #000;
}
.vp-create-list-dialog .mat-dialog__content-block .mat-input-element {
  caret-color: #0071bb;
}
.vp-create-list-dialog .mat-dialog__actions-block {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.vp-create-list-dialog .mat-dialog__actions-block vnc-button {
  margin: 0px;
}
.vp-create-list-dialog .mat-dialog__actions-block vnc-button button {
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-size: 16px;
  font-weight: 600;
  color: #0071bb;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  box-shadow: none;
  margin-right: 0px;
}
.vp-create-list-dialog .mat-dialog__actions-block vnc-button button.disabled-button {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.2588235294);
  cursor: not-allowed;
}

.vp-folder-option-list-item-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-folder-option-list-item-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.vp-folder-option-list-item-dialog mat-list mat-list-item:hover {
  cursor: pointer;
  background-color: #eee;
}
.vp-folder-option-list-item-dialog mat-list mat-list-item mat-icon {
  font-size: 18px;
  padding: 4px;
}
.vp-folder-option-list-item-dialog mat-list mat-list-item span {
  margin-left: 15px;
}
.vp-folder-option-list-item-dialog mat-list mat-icon.bullet {
  font-weight: bold;
}
.vp-folder-option-list-item-dialog .mat-list-disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  touch-action: none;
  pointer-events: none;
}

.add_list-button.add-task-mobile-button.add-task-mobile-button {
  position: sticky;
  bottom: 18px;
  z-index: 2;
  float: right;
  margin-right: 14px;
}
.add_list-button.add-task-mobile-button.add-task-mobile-button button {
  background-color: #29b6f6 !important;
}
@media only screen and (min-width : 1024px) {
  .add_list-button.add-task-mobile-button.add-task-mobile-button {
    display: none;
  }
}
.add_list-button.add-task-mobile-button.add-button-with-icon {
  position: sticky;
  bottom: 18px;
  z-index: 2;
  float: right;
  margin-right: 14px;
  background-color: #29b6f6 !important;
}
@media only screen and (min-width : 1024px) {
  .add_list-button.add-task-mobile-button.add-button-with-icon {
    display: none;
  }
}

.vp-create-location-dialog {
  min-width: 300px;
}
.vp-create-location-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-create-location-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.vp-create-location-dialog .mat-dialog__title {
  padding: 20px 18px 0px 18px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-create-location-dialog .mat-dialog__content-block {
  padding: 0 18px;
}
.vp-create-location-dialog .mat-dialog__content-block .mat-form-field {
  width: 100%;
}
.vp-create-location-dialog .mat-dialog__content-block .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0071bb;
}
.vp-create-location-dialog .mat-dialog__content-block input.mat-input-element {
  font-family: "Roboto";
  font-size: 16px;
  color: #000;
}
.vp-create-location-dialog .mat-dialog__content-block .mat-input-element {
  caret-color: #0071bb;
}
.vp-create-location-dialog .mat-dialog__actions-block {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
.vp-create-location-dialog .mat-dialog__actions-block vnc-button {
  margin: 0px;
}
.vp-create-location-dialog .mat-dialog__actions-block vnc-button button {
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-size: 16px;
  font-weight: 600;
  color: #0071bb;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  box-shadow: none;
  margin-right: 0px;
}
.vp-create-location-dialog .mat-dialog__actions-block vnc-button button.disabled-button {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.2588235294);
  cursor: not-allowed;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-save-search-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-save-search-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.vp-save-search-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (max-width : 599px) {
  .vp-save-search-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}
.vp-save-search-dialog .task__dialog-body .mdl-textfield {
  width: 100%;
}
@media only screen and (max-width : 599px) {
  .vp-save-search-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-save-search-dialog .option-list {
  height: 100%;
}
.vp-save-search-dialog .option-list mat-list {
  max-height: 295px;
  min-height: 295px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vp-save-search-dialog .option-list mat-list mdl-icon {
  color: #888888;
  font-size: 18px;
  vertical-align: middle;
}
@media only screen and (max-width : 1023px) {
  .vp-save-search-dialog .option-list mat-list {
    max-height: calc(100% - 70px);
  }
}
.vp-save-search-dialog .option-list mat-list mat-list-item:hover {
  cursor: pointer;
  background: #eefafa;
}
.vp-save-search-dialog .option-list .mat-list-item:last-child {
  border: 0;
}
.vp-save-search-dialog .option-list .mat-list-item {
  width: 100%;
}
.vp-save-search-dialog .option-list .mat-list-item div {
  width: 100%;
}
.vp-save-search-dialog .option-list .mat-list-item .query-name {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.vp-save-search-dialog .option-list .mat-list-item .query-date {
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #888888;
  float: right;
  margin-top: 3px;
}
.vp-save-search-dialog .option-list .mat-list-item .query-result-count {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #888888;
}

.vp-save-query-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-save-query-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-select-location-dialog {
  max-width: 767px;
  height: 100%;
}
@media only screen and (min-width : 768px) {
  .vp-select-location-dialog {
    height: 470px;
    width: 450px;
  }
}
.vp-select-location-dialog .task__dialog-body {
  padding: 0 0 14px;
  overflow: auto;
}
@media only screen and (min-width : 768px) {
  .vp-select-location-dialog .task__dialog-body {
    height: 370px;
  }
}
.vp-select-location-dialog .mdl-list {
  padding: 0px;
}
.vp-select-location-dialog .mdl-list__item {
  padding: 14px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-select-location-dialog .mdl-list__item mdl-list-item-primary-content .option_fields_text {
  padding-left: 24px;
}
.vp-select-location-dialog .selected-lists .option-list {
  height: 100%;
}
.vp-select-location-dialog .selected-lists mat-list {
  padding-left: 62px;
}
@media screen and (max-width: 767px) {
  .vp-select-location-dialog .selected-lists mat-list {
    height: calc(100% - 220px);
    -webkit-overflow-scrolling: touch;
  }
}
@media screen and (min-width: 767px) {
  .vp-select-location-dialog .selected-lists mat-list {
    height: 170px;
  }
}
.vp-select-location-dialog .selected-lists mat-list .mat-list-item-content {
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
  justify-content: space-between;
}
.vp-select-location-dialog .selected-lists mat-list .mat-list-item-content .list-action {
  padding-right: 10px;
}
.vp-select-location-dialog .selected-lists .changed {
  height: auto;
  padding-bottom: 40px;
}
@media screen and (min-width: 767px) {
  .vp-select-location-dialog .selected-lists .changed {
    height: 180px;
  }
}
.vp-select-location-dialog .selected-lists .filter-search-textfield {
  width: calc(100% - 28px);
  margin: 0 14px;
}
.vp-select-location-dialog .selected-lists .mdl-list__item .mdl-list__item-primary-content {
  font-size: 1.14rem;
  font-weight: 400;
}
.vp-select-location-dialog .selected-lists .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox__box-outline {
  border-radius: 50%;
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-location-dialog .selected-lists .mdl-list__item .mdl-list__item-secondary-action .mdl-checkbox.is-checked .mdl-checkbox__tick-outline {
  background-color: #29b6f6;
}
.vp-select-location-dialog .selected-lists .mdl-list__item .mdl-checkbox.is-checked .mdl-checkbox__box-outline, .vp-select-location-dialog .selected-lists .mdl-list__item .mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}
.vp-select-location-dialog .selected-lists .mdl-list__item {
  width: 100%;
}
.vp-select-location-dialog .selected-lists .mdl-list__item.tag-list-item {
  padding: 14px 14px 14px 0px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  border: none;
}
.vp-select-location-dialog .selected-lists .mdl-list__item mdl-list-item-primary-content mdl-icon {
  color: #29b6f6;
  font-size: 18px;
}
.vp-select-location-dialog .selected-lists .mdl-list__item mdl-list-item-primary-content span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.vp-select-location-dialog .selected-lists .mdl-list__item mdl-list-item-secondary-action mdl-checkbox {
  z-index: 0;
}
.vp-select-location-dialog .selected-lists .mdl-list__item mdl-list-item-secondary-action .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-location-dialog .selected-lists .mdl-list__item mdl-list-item-secondary-action .mdl-radio__inner-circle {
  top: 3px;
  left: 4px;
}
.vp-select-location-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-location-dialog .mdl-radio__outer-circle {
  border: 2px solid #d8d8d8;
  width: 18px;
  height: 18px;
}
.vp-select-location-dialog .mdl-checkbox .mdl-checkbox__box-outline, .vp-select-location-dialog .mdl-radio__inner-circle {
  top: 9px;
  left: 5px;
}
.vp-select-location-dialog vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
}
.vp-select-location-dialog .select-location-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.vp-select-location-dialog .select-location-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.vp-select-location-dialog .select-location-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.vp-select-location-dialog .select-location-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.vp-select-location-dialog .select-location-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.vp-select-location-dialog .mat-radio-button.mat-accent .mat-radio-inner-circle,
.vp-select-location-dialog .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.vp-select-location-dialog .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.vp-select-location-dialog .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}
@media only screen and (max-width : 1023px) {
  .vp-select-location-dialog .task__dialog-desktop-close-button {
    display: none;
  }
}
@media only screen and (min-width : 1024px) {
  .vp-select-location-dialog .task__dialog-mobile-back-button {
    display: none;
  }
}
.vp-select-location-dialog .mat-list .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-timelog-history-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-timelog-history-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-timelog-history-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-timelog-history-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (min-width : 600px) {
  .vp-timelog-history-dialog .add_list-button {
    display: none;
  }
}
.vp-timelog-history-dialog mdl-list {
  height: 295px;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width : 1023px) {
  .vp-timelog-history-dialog mdl-list {
    height: 100%;
  }
}
.vp-timelog-history-dialog mdl-list mdl-list-item {
  padding: 0px;
  align-items: inherit;
}
.vp-timelog-history-dialog .timelog-history {
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
}
.vp-timelog-history-dialog .timelog-history--body {
  width: 85%;
  padding-left: 15px;
}
.vp-timelog-history-dialog .timelog-history--body__meta--date {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  color: #7b7b7b;
  letter-spacing: normal;
}
.vp-timelog-history-dialog .timelog-history--body__meta--username {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
}
.vp-timelog-history-dialog .timelog-history--body__hours {
  padding-top: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  word-wrap: break-word;
}
.vp-timelog-history-dialog .timelog-history--body__hours--label {
  color: #7b7b7b;
}
.vp-timelog-history-dialog .timelog-history--body__comments {
  padding-top: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  letter-spacing: normal;
  word-wrap: break-word;
}
.vp-timelog-history-dialog .timelog-history--body__comments p {
  margin-bottom: 0;
}

.vp-log-time-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-log-time-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-log-time-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-log-time-dialog .task__dialog-body {
  padding: 0px 15px;
}
.vp-log-time-dialog mdl-list {
  height: 275px;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width : 1023px) {
  .vp-log-time-dialog mdl-list {
    max-height: calc(100% - 60px);
  }
}
.vp-log-time-dialog mdl-list mdl-list-item {
  padding: 0px;
}
.vp-log-time-dialog .log-time .label-div {
  width: 30%;
}
.vp-log-time-dialog .log-time .value-div {
  width: 70%;
}
.vp-log-time-dialog .log-time .label-field {
  font-size: 14px;
  color: #888;
}
.vp-log-time-dialog .log-time .require-field {
  font-size: 14px;
  color: #ff0000;
}
.vp-log-time-dialog .log-time .spent-date {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #e4e4e4;
  font-size: 14px;
}
.vp-log-time-dialog .log-time .spent-time-input {
  width: 40px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  border: none;
  outline: none;
  border-bottom: 1px solid #e4e4e4;
}
.vp-log-time-dialog .mat-form-field {
  width: 100%;
  font-size: 14px;
}
.vp-log-time-dialog .mat-form-field .mat-form-field-infix {
  border-top: 0px;
  width: 100%;
}
.vp-log-time-dialog .mat-form-field .mat-form-field-underline {
  background-color: #e4e4e4;
}
.vp-log-time-dialog .mat-form-field textarea {
  min-height: 20px;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-task-watchers-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-task-watchers-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.vp-task-watchers-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (max-width : 599px) {
  .vp-task-watchers-dialog .task__dialog-body {
    height: calc(100% - 55px);
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-watchers-dialog .task__dialog-body.save-mode {
    height: calc(100% - 93px);
  }
}
@media only screen and (max-width : 599px) {
  .vp-task-watchers-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-task-watchers-dialog .watchers-body {
  height: 100%;
}
.vp-task-watchers-dialog .watchers-body .mdl-textfield {
  width: 100%;
}
.vp-task-watchers-dialog .option-list {
  max-height: 295px;
  min-height: 295px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vp-task-watchers-dialog .option-list.save-mode {
  max-height: 200px;
  min-height: 200px;
}
@media only screen and (max-width : 1023px) {
  .vp-task-watchers-dialog .option-list.save-mode {
    max-height: calc(100% - 165px);
  }
}
@media only screen and (max-width : 1023px) {
  .vp-task-watchers-dialog .option-list {
    max-height: calc(100% - 70px);
  }
}
.vp-task-watchers-dialog .option-list mat-list mat-icon {
  color: #888888;
  font-size: 18px;
  vertical-align: middle;
}
.vp-task-watchers-dialog .option-list mat-list mat-list-item:hover {
  cursor: pointer;
  background: #eefafa;
}
.vp-task-watchers-dialog .option-list .mdl-list-item:last-child {
  border: 0;
}
.vp-task-watchers-dialog .option-list .mat-list-item {
  width: 100%;
  height: 60px !important;
}
.vp-task-watchers-dialog .option-list .mat-list-item span {
  width: 80%;
  margin-left: 30px;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
@media only screen and (max-width : 1023px) {
  .vp-task-watchers-dialog .option-list .mat-list-item span {
    font-size: 16px;
  }
}
.vp-task-watchers-dialog .option-list .mat-list-item .list-span {
  word-break: break-all;
  word-wrap: break-word;
}
.vp-task-watchers-dialog .selected-list-wrapper {
  height: 95px;
  overflow: auto;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03);
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList {
  overflow-x: auto;
  display: flex;
  height: 95px;
  padding-right: 30px;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: hidden;
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item {
  max-width: 52px;
  width: auto;
  min-width: 52px;
  height: 80px;
  box-sizing: border-box;
  padding: 0;
  margin-right: 20px;
  box-shadow: none;
}
@media screen and (max-width: 767px) {
  .vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item {
    height: 90px;
  }
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item:last-child {
  min-width: 64px;
  max-width: 64px;
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list {
  flex-direction: column;
  justify-content: center;
  background-color: white;
  width: 42px;
  max-width: 42px;
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .member-avatar {
  position: relative;
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .member-avatar .selected-member-close-icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-color: #888888;
  border: solid 2px #f8f8f8;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: -1px;
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .member-avatar .selected-member-close-icon mat-icon {
  font-size: 1rem;
  color: white;
}
.vp-task-watchers-dialog .selected-list-wrapper .selectedMemberList mat-list-item .selected-watcher-list .selected-member-name {
  font-size: 0.85rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #888888;
  margin-top: 10px;
  padding: 0;
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
}
.vp-task-watchers-dialog .mat-list-item-content {
  padding: 0 !important;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.vp-description-diff-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .vp-description-diff-dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .vp-description-diff-dialog {
    width: 100%;
    height: 100%;
  }
}
.vp-description-diff-dialog .task__dialog-body {
  padding: 0px 15px;
}
@media only screen and (min-width : 768px) {
  .vp-description-diff-dialog .task__dialog-body {
    height: 350px;
  }
}
.vp-description-diff-dialog .diff-label {
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.vp-description-diff-dialog #descDiff {
  height: calc(100% - 41px);
  overflow: auto;
}
@media only screen and (min-width : 768px) {
  .vp-description-diff-dialog #descDiff {
    height: 285px;
    word-break: break-word;
  }
}
.vp-description-diff-dialog #descDiff ins {
  text-decoration: none;
  background-color: #c1ffc6;
}
.vp-description-diff-dialog #descDiff del {
  text-decoration: none;
  background-color: #ffb1b1;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
@media only screen and (max-width : 599px) {
  .task_tfa_settings_dialog {
    height: 100%;
    width: 100%;
  }
}
.task_tfa_settings_dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .task_tfa_settings_dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
.task_tfa_settings_dialog .task__dialog-body {
  overflow-x: hidden;
  font-size: 16px;
}
@media only screen and (min-width : 600px) {
  .task_tfa_settings_dialog .task__dialog-body {
    max-height: 500px;
  }
}
@media only screen and (max-width : 599px) {
  .task_tfa_settings_dialog .task__dialog-body {
    height: calc(100vh - 55px);
    padding: 0px;
  }
}
.task_tfa_settings_dialog .content {
  margin-top: 10px;
  width: 450px;
}
.task_tfa_settings_dialog .content .tfa__body__div {
  padding: 15px;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div {
  margin-top: 10px;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div div {
  display: flex;
  width: 100%;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div div .content-span {
  margin-left: 10px;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div div img {
  margin-left: 15px;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div input#otpCode {
  height: 24px;
  font-family: "Roboto";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  border: none;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
  color: #888888;
  width: 50%;
  margin-left: 23px;
  margin-top: 10px;
  outline: 0;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div input#otpCode.ng-invalid {
  border-color: #0071bb;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div .barcode_text {
  color: #0071bb;
  cursor: pointer;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div .margin-left-20 {
  margin-left: 20px;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div .margin-top-15 {
  margin-top: 15px;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div .detail_value {
  margin-left: 5px;
  font-weight: bold;
}
.task_tfa_settings_dialog .content .tfa__body__div .content-div .margin-bottom-15 {
  margin-bottom: 15px;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
vp-avatar {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
vp-avatar .avatar {
  text-transform: uppercase;
}
vp-avatar .avatar.profile {
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  width: 58px;
  height: 58px;
  font-size: 26px;
  text-align: center;
  display: inline-block;
  line-height: 58px;
  background-color: #ffc208;
}
vp-avatar .avatar.profile img {
  width: inherit;
  height: inherit;
  border-radius: 50%;
}
vp-avatar .avatar.profile-dialog {
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  width: 94px;
  height: 94px;
  font-size: 26px;
  text-align: center;
  display: inline-block;
  line-height: 94px;
  background-color: #ffc208;
}
vp-avatar .avatar.profile-dialog img {
  width: inherit;
  height: inherit;
  border-radius: 50%;
}
vp-avatar .avatar.list-avatar {
  border-radius: 50%;
  overflow: hidden;
  height: 42px;
  width: 42px;
  font-size: 20px;
  text-align: center;
  color: white;
  background-color: #ffc208;
}
vp-avatar .avatar.list-avatar img {
  width: inherit;
  height: inherit;
}
vp-avatar .avatar.list-avatar .profile-avtar {
  font-size: 16px;
  align-items: center;
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: center;
}
vp-avatar .avatar.list-avatar .mat-icon {
  line-height: 36px;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
.task__dialog {
  width: 450px;
  max-width: 100%;
  max-height: 100%;
  min-height: 0px;
  overflow: initial;
  border-radius: 10px;
  background: white;
  z-index: 2;
}
.task__dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}
@media only screen and (min-width : 600px) {
  .task__dialog .mat-dialog-container {
    border-radius: 8px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.4);
  }
}
@media only screen and (max-width : 599px) {
  .task__dialog {
    width: 100%;
    height: 100%;
  }
}
.task__dialog-header {
  justify-content: left;
  text-align: center;
  position: relative;
  z-index: 3;
  width: 100%;
  align-items: center;
  position: relative;
  display: flex;
  min-height: 48px;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
}
@media only screen and (max-width : 599px) {
  .task__dialog-header {
    background: #0071bb;
    color: white;
    border-bottom: none;
    justify-content: end;
  }
}
.task__dialog-header .mat-button {
  height: 36px;
  width: 36px;
  border-radius: 18px;
  min-width: 36px;
  line-height: 0;
  padding: 0;
}
.task__dialog-header .header_lbl {
  display: flex;
  font-size: 18px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Manrope";
  font-weight: bold;
  color: #000000;
  width: 100%;
}
@media only screen and (max-width : 599px) {
  .task__dialog-header .header_lbl {
    color: #fff;
    font-size: 18px;
    margin-left: 14px;
  }
}
@media only screen and (min-width : 600px) {
  .task__dialog-header .header_lbl {
    justify-content: center;
  }
}
.task__dialog-header .icon-text {
  display: flex;
  align-items: center;
}
.task__dialog-header .mobile-back-button {
  margin: 0 5px 0 5px;
}
@media only screen and (min-width : 600px) {
  .task__dialog-header .mobile-back-button {
    display: none;
  }
}
.task__dialog-header .desktop-close-button {
  margin: 0 10px 0 0;
  display: flex;
}
@media only screen and (max-width : 599px) {
  .task__dialog-header .desktop-close-button {
    display: none;
  }
}
.task__dialog-header .desktop-close {
  width: 20%;
  justify-content: right;
}
.task__dialog-header .desktop-close .mat-button {
  float: right;
}
.task__dialog-header .mobile-right-close {
  width: 20%;
  justify-content: right;
}
.task__dialog-header .mobile-right-close .mat-button {
  float: right;
  padding-right: 16px;
}
.task__dialog-header .mobile-right-close .mat-button .mat-icon {
  font-size: 20px;
  height: 20px;
  width: 20px;
}
.task__dialog-body {
  height: calc(100% - 55px);
  padding-left: 7px;
  overflow: auto;
  font-size: 16px;
}
@media only screen and (max-width : 599px) {
  .task__dialog-body {
    height: calc(100vh - 93px);
    padding-left: 0px;
  }
}
.task__dialog-body.folder-body {
  height: 100%;
}
.task__dialog-body .non-decoration {
  text-decoration: blink;
  display: flex;
  align-items: center;
  color: #000000;
  width: 100%;
  height: 100%;
  padding-left: 25px;
}
.task__dialog-body .mat-list-item {
  cursor: pointer;
}
.task__dialog-body .mat-list-item .mat-icon {
  height: 18px;
  width: 18px;
  font-size: 18px;
}
.task__dialog-body .folder-icon .mat-icon {
  font-size: 18px;
  line-height: 24px;
}
.task__dialog-body .folder-title {
  padding-left: 23px;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task__dialog-body .folder-expander {
  width: 20%;
}
.task__dialog-body .folder-expander .mat-icon {
  float: right;
  cursor: pointer;
}
.task__dialog-footer {
  height: 48px;
  display: flex;
  justify-content: flex-end;
}
@media only screen and (min-width : 600px) {
  .task__dialog-footer {
    border-top: 1px solid #e1e1e1;
  }
}
@media only screen and (max-width : 599px) {
  .task__dialog-footer {
    height: 44px;
  }
}
.task__dialog-footer .desktop-footer {
  display: flex;
  padding: 0 20px;
  align-items: center;
  gap: 12px;
}
@media only screen and (max-width : 599px) {
  .task__dialog-footer .desktop-footer {
    display: none;
  }
}
.task__dialog-footer .desktop-footer button {
  cursor: pointer;
  color: #317bbc;
  font-size: 16px;
}
.task__dialog-footer .desktop-footer button.disabled {
  color: #888;
}
.task__dialog-footer .desktop-footer .primary-btn {
  background-color: #337cbd;
  border-color: #337cbd;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgba(7, 29, 51, 0.2), 0 0 2px 0 rgba(7, 29, 51, 0.1);
  /* padding: 0 24px; */
  width: 96px;
  height: 32px;
  margin: 0;
  padding: 0 24px 0 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.task__dialog-footer .desktop-footer .secondary-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 32px;
  margin: 0;
  padding: 0 12px 0 13px;
  border-radius: 4px;
  border: solid 1px #8b96a0;
  color: #606b76;
}
.task__dialog-footer .mobile-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 34px;
  margin: 10px 0 0 0;
  align-items: flex-end;
  position: static;
  bottom: 0;
  background: #0071bb;
  align-items: center;
}
@media only screen and (min-width : 600px) {
  .task__dialog-footer .mobile-footer {
    display: none;
  }
}
.task__dialog-footer .mobile-footer .title {
  padding-left: 25px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.task__dialog-footer .mobile-footer .save_footer_btn {
  display: block;
}
.task__dialog-footer .mobile-footer .save_footer_btn button {
  width: 57px;
  height: 57px;
  min-width: 51px;
  right: 10px;
  bottom: 19px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: #29b6f6;
}
.task__dialog-footer .mobile-footer .save_footer_btn button .mat-icon {
  color: #fff;
  font-size: 30px;
  margin: 0 0 7px -4px;
}

.mdl-text-padding .mdl-textfield__input {
  padding-bottom: 10px;
}

.task-profile-avtar {
  display: inline-block;
  width: 75px;
  height: 75px;
  font-size: 26px;
  line-height: 75px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #0071bb;
  border-radius: 50px;
}
.task-profile-avtar .material-icons {
  font-size: 24px;
  color: #fff;
  vertical-align: middle;
}

.task-app-component .task-app-component-body {
  background-color: #e3e7eb;
}
@media screen and (max-width: 1023px) {
  .task-app-component .task-app-component-body {
    height: 100%;
  }
  .task-app-component .task-app-component-body .cdk-task-list {
    height: calc(100vh - 92px);
  }
}
@media screen and (min-width: 1023px) {
  .task-app-component .task-app-component-body {
    height: calc(100vh - 80px);
  }
  .task-app-component .task-app-component-body .cdk-task-list {
    height: calc(100vh - 190px);
    margin: 0 13px 0 13px;
  }
}

.task-icon-avtar {
  display: inline-block;
  width: 42px;
  height: 42px;
  font-size: 10px;
  line-height: 42px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  background-color: #29b6f6;
  border-radius: 50px;
}
.task-icon-avtar .material-icons {
  font-size: 24px;
  color: #fff;
  vertical-align: middle;
}

@media only screen and (min-width : 1024px) {
  .task-done-avtar {
    display: none;
  }
}

@media only screen and (max-width : 1023px) {
  .task-comment-avtar-device {
    display: none;
  }
}

.task-comment-avtar {
  width: 42px;
  height: 42px;
  overflow: hidden;
  border: #fff;
  border-radius: 50%;
  grid-area: 1/1/-1/2;
}
.task-comment-avtar.task-assigned-to {
  width: 40px;
  height: 40px;
  vertical-align: middle;
}
.task-comment-avtar img {
  width: 42px;
  height: 42px;
}

.assigned-profile {
  display: flex;
  align-items: center;
}
.assigned-profile .username-place {
  padding-left: 8px;
}

.task__add-button.add-task-mobile-button {
  position: sticky;
  bottom: 18px;
  z-index: 2;
  float: right;
  margin-right: 14px;
}
.task__add-button.add-task-mobile-button button {
  background-color: #29b6f6 !important;
}
@media only screen and (max-width : 1023px) {
  .task__add-button.add-task-mobile-button.detailview {
    right: 8px;
  }
}
@media only screen and (min-width : 1024px) {
  .task__add-button.add-task-mobile-button {
    display: none;
  }
}

.notask-container {
  text-align: -webkit-center;
}
.notask-container mat-icon {
  font-size: 75px;
  height: 75px;
  width: 75px;
  color: #8B96A0;
}
@media only screen and (max-width : 1023px) {
  .notask-container mat-icon {
    font-size: 60px;
    height: 60px;
    width: 60px;
  }
}

.task-reminder-item {
  display: flex;
  padding: 18px 15px;
  color: #000;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
}
.task-reminder-item .task-subject {
  width: 70%;
  word-break: break-all;
}
.task-reminder-item .task-due {
  width: 30%;
  text-align: right;
  align-self: right;
}

.task-reminder-item:hover {
  background-color: #eefafa;
}

.task-reminder-task {
  max-height: 200px;
  margin-top: 2px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width : 1023px) {
  .task-reminder-task {
    height: calc(100vh - 105px);
    max-height: calc(100vh - 105px);
  }
}

.task-reminder-subject {
  display: flex;
  padding: 10px 33px 10px 14px;
  font-size: 16px;
  color: #000;
  background-color: #f6f6f6;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  justify-content: space-between;
  align-items: center;
}

/*.task__dialog.open {
  display: block;
}

.task__dialog-body {
  padding: 14px;
  @media only screen and (max-width : 1023px) {
    height: 100%;
  }
}*/
.uppercase {
  text-transform: uppercase;
}

.task-search-result-panel {
  padding: 14px;
  color: #888888;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.task-search-result-panel .search-result {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding-left: 12px;
}
.task-search-result-panel .search-header-options {
  margin-right: 6px;
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
}
.task-search-result-panel .search-header-options div {
  align-items: center;
  display: flex;
}
.task-search-result-panel .search-header-options div span {
  margin-left: 10px;
}
.task-search-result-panel .search-header-options .vnc-icon {
  height: 24px;
  width: 24px;
}
.task-search-result-panel .search-header-options .search-header-options-save {
  cursor: pointer;
  color: #0071bb;
}
@media only screen and (max-width : 1023px) {
  .task-search-result-panel .search-header-options {
    padding-left: 8px;
  }
  .task-search-result-panel .search-header-options button {
    display: none;
  }
  .task-search-result-panel .search-result {
    padding-left: 8px;
  }
}

.task-list-view {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(7, 29, 51, 0.2), 0 0 2px 0 rgba(7, 29, 51, 0.1);
}
.task-list-view.task-detail {
  width: 62%;
}
.task-list-view.task-detail.is-right-sidebar-expanded {
  width: calc(62% - 194px);
}
@media screen and (max-width: 1479px) {
  .task-list-view.task-detail.is-right-sidebar-expanded {
    width: calc(62% - 169px);
  }
}
@media screen and (max-width: 1023px) {
  .task-list-view.task-detail.is-right-sidebar-expanded {
    width: 0;
  }
}
@media only screen and (max-width : 1023px) {
  .task-list-view.task-detail {
    flex-direction: column;
    width: 0;
    visibility: hidden;
  }
}
.task-list-view.task-list {
  width: 62%;
}
.task-list-view.task-list.is-right-sidebar-expanded {
  width: calc(62% - 194px);
}
@media screen and (max-width: 1479px) {
  .task-list-view.task-list.is-right-sidebar-expanded {
    width: calc(62% - 169px);
  }
}
@media screen and (max-width: 1023px) {
  .task-list-view.task-list.is-right-sidebar-expanded {
    width: 0;
  }
}
@media only screen and (max-width : 1023px) {
  .task-list-view.task-list {
    width: 100%;
  }
}

.task-container {
  display: flex;
  height: 100%;
}

.task-detail-view {
  width: 38%;
  height: 100%;
}
.task-detail-view.is-right-sidebar-expanded {
  width: calc(38% - 194px);
}
@media screen and (max-width: 1479px) {
  .task-detail-view.is-right-sidebar-expanded {
    width: calc(38% - 169px);
  }
}
@media screen and (max-width: 1023px) {
  .task-detail-view.is-right-sidebar-expanded {
    width: 100%;
  }
}
@media only screen and (max-width : 1023px) {
  .task-detail-view {
    width: 100%;
    padding: 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media only screen and (max-width : 1023px) {
  .hide-detail-view {
    display: none;
  }
}

.scrollable-content {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 185px);
  margin: 0 13px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: inherit;
}
.scrollable-content::-webkit-scrollbar {
  overflow-y: scroll;
  width: 6px;
}
.scrollable-content::-webkit-scrollbar-thumb {
  background: #DADEE2;
}
.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #A8B0B9;
}
.scrollable-content.searchmode {
  height: calc(100% - 155px);
}
@media only screen and (max-width : 1023px) {
  .scrollable-content.searchmode {
    height: calc(100% - 104px);
  }
}
@media only screen and (max-width : 1023px) {
  .scrollable-content.search-dialog-active {
    height: 100%;
  }
}
@media only screen and (max-width : 1023px) {
  .scrollable-content {
    height: calc(100% - 51px);
    margin: 0;
    margin-top: 2px;
  }
}

.task-edit-scrollable {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width : 1023px) {
  .mdl-grid {
    padding: 0;
  }
}
@media screen and (min-width: 1023px) {
  .mdl-grid {
    padding: 4px;
  }
}

.task-details-project {
  display: flex;
  width: 100%;
  color: #666;
  background-color: #eee;
}
@media only screen and (max-width : 1023px) {
  .task-details-project {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 0;
  }
}
.task-details-project h4 {
  flex-grow: 1;
  padding: 10px 15px 15px;
  margin: 0;
  text-align: center;
}
.task-details-project mdl-icon {
  min-width: 32px;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
  line-height: 32px;
  cursor: pointer;
}

.task-edit-panel--header {
  display: flex;
  margin: 0;
  margin-bottom: -20px;
  background-color: #dbdbdb;
  align-items: center;
}
.task-edit-panel--header .mdl-textfield,
.task-edit-panel--header span {
  flex-grow: 1;
  text-align: center;
}
.task-edit-panel--header .mdl-textfield__input {
  font-size: 20px;
  text-align: center;
}
.task-edit-panel--header mdl-icon.remove {
  color: #ed1b23;
}
.task-edit-panel--header mdl-icon.add {
  color: #2d7bbe;
}
.task-edit-panel--header.edit {
  padding: 0 15px;
}

vp-task-main-layout-component > .mdl-grid.pad-bottom {
  padding-bottom: 70px;
}
vp-task-main-layout-component > .mdl-grid > .mdl-cell--12-col {
  z-index: 3;
  width: 100%;
}
vp-task-main-layout-component .profile {
  padding: 0 10px;
  text-align: right;
}
vp-task-main-layout-component .task__add-message-button.brand-color {
  z-index: 3;
  background-color: #09c856;
}
vp-task-main-layout-component .task__add-message-button.brand-color:active, vp-task-main-layout-component .task__add-message-button.brand-color:focus, vp-task-main-layout-component .task__add-message-button.brand-color:focus:not(:active), vp-task-main-layout-component .task__add-message-button.brand-color:hover {
  background-color: #09c856;
}

vp-vnctask-list .empty-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 215px);
  font-size: 15px;
  color: #606B76;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}
vp-vnctask-list .add-a-task {
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #317bbc;
  text-transform: uppercase;
  margin-top: 30px;
  cursor: pointer;
}

vp-vnctask-filter .icons__holder {
  display: flex;
}
vp-vnctask-filter .icons__holder_icon {
  padding: 0 10px 0 0;
  color: #bbb;
  cursor: default;
}
vp-vnctask-filter .icons__holder_icon.active {
  color: #333;
  cursor: pointer;
}

vp-vnctask-list-item .task-item {
  display: flex;
  color: #666;
  cursor: pointer;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 2px;
  padding: 0 14px 0 0px;
}
@media only screen and (max-width : 1023px) {
  vp-vnctask-list-item .task-item {
    padding: 8px 8px 8px 0px;
  }
}
vp-vnctask-list-item .task-item__title {
  flex-grow: 1;
  font-size: 18px;
}
vp-vnctask-list-item .task-item__check {
  display: flex;
  width: 90%;
  word-break: break-word;
  align-items: center;
}
vp-vnctask-list-item .task-item__check mdl-checkbox {
  width: 15px;
  height: 15px;
  margin-right: 20px;
}
vp-vnctask-list-item .task-item__check mdl-checkbox .mdl-checkbox__box-outline {
  width: 15px;
  height: 15px;
  top: 0;
}
@media only screen and (max-width : 1023px) {
  vp-vnctask-list-item .task-item__check mdl-checkbox {
    display: none;
  }
}
vp-vnctask-list-item .task-item__check .mdl-checkbox.is-upgraded {
  padding-left: 0px;
}
vp-vnctask-list-item .task-item__more mdl-icon {
  font-size: 18px;
  vertical-align: middle;
}
vp-vnctask-list-item .task-item__more {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 50px;
  align-items: center;
}
@media only screen and (min-width : 768px) {
  vp-vnctask-list-item .task-item__more {
    align-items: flex-end;
  }
}
vp-vnctask-list-item .task-item__more__status {
  color: #888;
}
@media only screen and (min-width : 768px) {
  vp-vnctask-list-item .task-item__more__status {
    float: right;
  }
}
vp-vnctask-list-item .task-item__more__due {
  line-height: 16px;
  text-align: right;
  color: #888;
}
@media only screen and (min-width : 768px) {
  vp-vnctask-list-item .task-item__more__due {
    float: left;
    min-width: 37px;
  }
}
vp-vnctask-list-item .task-item__more__due_date {
  color: rgb(255, 0, 0);
}
vp-vnctask-list-item .task-item__more__due span {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: right;
  letter-spacing: normal;
}
vp-vnctask-list-item .task-item__more__progress {
  width: 37px;
  height: 7px;
  opacity: 0.7;
  border-radius: 2px;
  border: 1px solid #888;
  background-color: #ffffff;
  margin-top: 3px;
  float: left;
}
vp-vnctask-list-item .task-item__more__progress div {
  width: 0%;
  height: 5px;
  background-color: #0071bb;
}
vp-vnctask-list-item .task-item .mdl-checkbox__ripple-container.mdl-ripple--center {
  width: auto;
  height: auto;
}
vp-vnctask-list-item .task-item .summary-pane-detail {
  margin-left: 12px;
  color: #000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  word-break: break-all;
  word-break: break-word;
  width: 100%;
}
vp-vnctask-list-item .task-item .summary-pane-detail .header-text {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
vp-vnctask-list-item .task-item .summary-pane-detail .tag-list-item {
  word-break: break-word;
}
vp-vnctask-list-item .task-item .summary-pane-detail .tag-list-item .tag-text {
  display: inline-table;
  height: 14px;
  padding: 0 5px;
  margin-right: 3px;
  line-height: 15px;
  background-color: #a2aab3;
  border-radius: 3px;
  color: #ffffff;
  font-size: 10px;
  word-break: break-word;
}
vp-vnctask-list-item .task-item .summary-pane-detail .tag-list-item .view-all-text {
  width: 41px;
  height: 10px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #317bbc;
}
vp-vnctask-list-item .task-item .summary-pane-detail .repeat-icon mdl-icon {
  font-size: 18px;
  vertical-align: middle;
  color: #888;
  margin-left: 3px;
}
vp-vnctask-list-item .task-item:hover:not(.selected-hightlight) {
  background-color: #eef3f8;
}
vp-vnctask-list-item .collected-task {
  background-color: #eef3f8;
}
vp-vnctask-list-item .task-item.selected-hightlight {
  color: #fff;
  background-color: #eef3f8;
}

vp-add-task-component .add-tast-options {
  padding: 0;
}
vp-add-task-component .add-tast-options .mdl-list {
  padding: 0;
}
vp-add-task-component .add-tast-options .mdl-list__item {
  border-bottom: 1px solid #eee;
}
vp-add-task-component .add-tast-options .mdl-list li:nth-child(1) {
  border-top: 1px solid #eee;
}
vp-add-task-component .add-tast-options .mdl-list__item:hover {
  background: #e9e9e9;
}
vp-add-task-component .task-input {
  padding-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
}
vp-add-task-component .task-input input {
  width: 100%;
  height: 40px;
}
vp-add-task-component .content {
  position: relative;
}

vp-add-task-user-component .mdl-list__item,
vp-task-action-link-project .mdl-list__item {
  cursor: pointer;
}
vp-add-task-user-component .mdl-list__item .title,
vp-task-action-link-project .mdl-list__item .title {
  padding: 0 10px;
}

vp-action-add-start-date .mdl-list__item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
vp-action-add-start-date .mdl-list__item span {
  display: flex;
}
vp-action-add-start-date .mdl-list__item_title {
  width: 200px;
}
vp-action-add-start-date .mdl-list__item_date {
  width: 70px;
}
vp-action-add-start-date .mdl-list__item mdl-icon {
  display: flex;
  cursor: pointer;
}
vp-action-add-start-date .mdl-list__item mdl-icon:hover {
  color: #444;
}
vp-action-add-start-date .show-picker {
  position: relative;
  display: flex;
  padding: 5px 0 17px;
  justify-content: center;
}
vp-action-add-start-date .show-picker button {
  padding-top: 1px;
}
vp-action-add-start-date .show-picker button i.material-icons {
  padding-right: 9px;
  margin-bottom: 6px;
}

.btn-centered {
  position: relative;
  display: flex;
  padding: 5px 0 17px;
  justify-content: center;
}
.btn-centered button {
  padding-top: 1px;
}
.btn-centered button i.material-icons {
  padding-right: 9px;
  margin-bottom: 6px;
}
.btn-centered button[disabled]:hover {
  color: rgba(0, 0, 0, 0.26);
  background: #fff;
}

vp-vnctask-compose-input div#compose-div {
  padding: 0 14px;
}
vp-vnctask-compose-input .wrapper {
  display: flex;
  margin: 0px 0px 15px 0px;
  align-items: center;
}
vp-vnctask-compose-input .wrapper > mdl-icon {
  font-size: 18px;
  color: #ababab;
}
vp-vnctask-compose-input .subject-line-1 {
  height: 26px;
}
vp-vnctask-compose-input .subject-line-2 {
  height: 44px;
}
vp-vnctask-compose-input .subject-line-3 {
  height: 65px;
}
vp-vnctask-compose-input .subject-line-4 {
  height: 84px;
}
@-moz-document url-prefix() {
  vp-vnctask-compose-input .subject-line-1 {
    height: 28px;
  }
  vp-vnctask-compose-input .subject-line-2 {
    height: 49px;
  }
  vp-vnctask-compose-input .subject-line-3 {
    height: 70px;
  }
  vp-vnctask-compose-input .subject-line-4 {
    height: 92px;
  }
}
vp-vnctask-compose-input .task-subject-div .new_task_input_div {
  width: 100%;
  resize: none;
  border: 0;
  font-size: 16px;
  outline: none;
  word-break: break-all;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
vp-vnctask-compose-input .task-subject-div .new_task_input_div:focus {
  border-bottom: 1px solid #2d7bbe;
  outline: none;
}
vp-vnctask-compose-input .container {
  width: 100%;
  min-height: 30px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  align-items: center;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
vp-vnctask-compose-input .container:focus {
  border-bottom: 1px solid #2d7bbe;
  outline: none;
}
vp-vnctask-compose-input .container div[contenteditable] {
  outline: none;
  display: inline-flex;
  -moz-user-select: text;
}
vp-vnctask-compose-input .container div[contenteditable]:last-child {
  flex-grow: 1;
  padding-right: 15px;
}
vp-vnctask-compose-input .container div[contenteditable]::before {
  content: "﻿";
}
vp-vnctask-compose-input .container .input-tag {
  display: inline-flex;
  height: 25px;
  padding: 0 3px;
  margin: 2px;
  line-height: 25px;
  white-space: nowrap;
  cursor: pointer;
  background-color: #e7e7e7;
  border: 0;
  border-radius: 12px;
}
vp-vnctask-compose-input .container .input-tag i {
  margin-left: 3px;
  color: #317bbc;
}

vp-vnctask-task-compose .vnctask-compose-dialog {
  font-size: 14px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container {
  display: flex;
  flex-wrap: wrap;
  color: #666;
  justify-content: center;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container.mainView {
  height: calc(100% - 48px);
}
@media only screen and (max-width : 1023px) {
  vp-vnctask-task-compose .vnctask-compose-dialog__options-container.mainView {
    position: relative;
    display: block;
    max-height: calc(100% - 120px);
    height: auto;
  }
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option {
  width: 33%;
  margin-top: 13px;
  color: #666;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option mdl-icon {
  font-size: 38px;
  color: #29b6f6;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option mat-icon {
  height: 38px;
  width: 38px;
  font-size: 38px;
  color: #29b6f6;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option svg {
  margin-bottom: 5px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option svg g {
  fill: #29b6f6;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-icon {
  text-align: center;
  cursor: pointer;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-icon p {
  margin-bottom: 5px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-icon.selected {
  opacity: 0.5;
  cursor: not-allowed;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item {
  padding: 12px 0px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  border-bottom: none;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item mdl-icon {
  font-size: 18px;
  color: #888888;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item .date-items {
  margin-left: 30px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item .align-right {
  float: right;
  opacity: 0.5;
  font-size: 14px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item .date-picker-button {
  height: 36px;
  color: #29b6f6;
  text-transform: capitalize;
  padding: 0px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item .date-picker-button:hover {
  background-color: transparent;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item .date-picker-button mdl-icon {
  font-size: 18px;
  vertical-align: middle;
  color: #29b6f6;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item .date-picker-button span {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  text-transform: initial;
  letter-spacing: normal;
  margin-left: 27px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item.date-list-last-item {
  border-bottom: none;
  cursor: auto;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list__item.date-list-last-item:hover {
  background: none;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .footer-div {
  height: 50px;
  padding-top: 7px;
  justify-content: center;
  align-items: center;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item {
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.03);
  border-bottom: none;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item .mat-list-item-content {
  padding: 0 !important;
  gap: 20px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item span {
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item mat-icon {
  font-size: 18px;
  color: #888888;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item .date-items {
  margin-left: 30px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item .align-right {
  float: right;
  opacity: 0.5;
  font-size: 14px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item .date-picker-button {
  height: 36px;
  color: #29b6f6;
  text-transform: capitalize;
  padding: 0px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item .date-picker-button:hover {
  background-color: transparent;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item .date-picker-button mat-icon {
  font-size: 18px;
  vertical-align: middle;
  color: #29b6f6;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mat-list-item .date-picker-button span {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  text-transform: initial;
  letter-spacing: normal;
  margin-left: 27px;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list-item.date-list-last-item {
  border-bottom: none;
  cursor: auto;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .mdl-list-item.date-list-last-item:hover {
  background: none;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option .option-list .footer-div {
  height: 50px;
  padding-top: 7px;
  justify-content: center;
  align-items: center;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container .option.active {
  flex-grow: 1;
  width: 100%;
}
vp-vnctask-task-compose .vnctask-compose-dialog__options-container.optionview .option {
  margin: 0;
}
vp-vnctask-task-compose .vnctask-compose-dialog .task__dialog-body {
  padding: 14px 0;
}

vp-new-vnctask-option-start-date .option-list,
vp-new-vnctask-option-due-date .option-list,
vp-vnctask-option-due-date .option-list,
vp-vnctask-option-start-date .option-list {
  height: 100%;
  padding: 0 14px;
}
vp-new-vnctask-option-start-date .option-list mat-list,
vp-new-vnctask-option-due-date .option-list mat-list,
vp-vnctask-option-due-date .option-list mat-list,
vp-vnctask-option-start-date .option-list mat-list {
  height: 100%;
}
vp-new-vnctask-option-start-date .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-due-date .option-list mat-list mat-list-item:hover,
vp-vnctask-option-due-date .option-list mat-list mat-list-item:hover,
vp-vnctask-option-start-date .option-list mat-list mat-list-item:hover {
  cursor: pointer;
  background: #f8f8f8;
}
vp-new-vnctask-option-start-date .option-list mat-list mat-list-item .mat-list-item-content,
vp-new-vnctask-option-due-date .option-list mat-list mat-list-item .mat-list-item-content,
vp-vnctask-option-due-date .option-list mat-list mat-list-item .mat-list-item-content,
vp-vnctask-option-start-date .option-list mat-list mat-list-item .mat-list-item-content {
  gap: 0 !important;
  margin: 0 !important;
}
vp-new-vnctask-option-start-date .option-list .mat-list-item:last-child,
vp-new-vnctask-option-due-date .option-list .mat-list-item:last-child,
vp-vnctask-option-due-date .option-list .mat-list-item:last-child,
vp-vnctask-option-start-date .option-list .mat-list-item:last-child {
  border: 0;
}
vp-new-vnctask-option-start-date .option-list .mat-list-item,
vp-new-vnctask-option-due-date .option-list .mat-list-item,
vp-vnctask-option-due-date .option-list .mat-list-item,
vp-vnctask-option-start-date .option-list .mat-list-item {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
vp-new-vnctask-option-start-date .option-list .mat-list-item span,
vp-new-vnctask-option-due-date .option-list .mat-list-item span,
vp-vnctask-option-due-date .option-list .mat-list-item span,
vp-vnctask-option-start-date .option-list .mat-list-item span {
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}
vp-new-vnctask-option-start-date .option-list .mat-list-item div,
vp-new-vnctask-option-due-date .option-list .mat-list-item div,
vp-vnctask-option-due-date .option-list .mat-list-item div,
vp-vnctask-option-start-date .option-list .mat-list-item div {
  width: 100%;
  margin-left: 15px;
}
vp-new-vnctask-option-start-date .option-list .mat-list-item .date-picker-button .mat-icon,
vp-new-vnctask-option-due-date .option-list .mat-list-item .date-picker-button .mat-icon,
vp-vnctask-option-due-date .option-list .mat-list-item .date-picker-button .mat-icon,
vp-vnctask-option-start-date .option-list .mat-list-item .date-picker-button .mat-icon {
  height: 18px !important;
  width: 18px !important;
}
vp-new-vnctask-option-start-date .option-list .mat-list-item .align-right,
vp-new-vnctask-option-due-date .option-list .mat-list-item .align-right,
vp-vnctask-option-due-date .option-list .mat-list-item .align-right,
vp-vnctask-option-start-date .option-list .mat-list-item .align-right {
  float: right;
}

vp-new-vnctask-option-priority .option-list,
vp-new-vnctask-option-add-user .option-list,
vp-new-vnctask-option-add-repeats .option-list,
vp-new-vnctask-option-add-estimate .option-list,
vp-new-vnctask-option-add-watchers .option-list,
vp-new-vnctask-option-link-location .option-list,
vp-new-vnctask-option-add-tags .option-list,
vp-new-vnctask-option-add-description .option-list,
vp-new-vnctask-option-link-list .option-list,
vp-new-vnctask-option-link-project .option-list,
vp-vnctask-option-add-repeats .option-list,
vp-vnctask-option-add-user .option-list,
vp-vnctask-option-link-project .option-list,
vp-vnctask-option-priority .option-list,
vp-vnctask-option-link-list .option-list,
vp-vnctask-option-link-location .option-list,
vp-vnctask-option-add-watchers .option-list {
  height: 100%;
  padding: 0 14px;
}
vp-new-vnctask-option-priority .option-list mat-list,
vp-new-vnctask-option-add-user .option-list mat-list,
vp-new-vnctask-option-add-repeats .option-list mat-list,
vp-new-vnctask-option-add-estimate .option-list mat-list,
vp-new-vnctask-option-add-watchers .option-list mat-list,
vp-new-vnctask-option-link-location .option-list mat-list,
vp-new-vnctask-option-add-tags .option-list mat-list,
vp-new-vnctask-option-add-description .option-list mat-list,
vp-new-vnctask-option-link-list .option-list mat-list,
vp-new-vnctask-option-link-project .option-list mat-list,
vp-vnctask-option-add-repeats .option-list mat-list,
vp-vnctask-option-add-user .option-list mat-list,
vp-vnctask-option-link-project .option-list mat-list,
vp-vnctask-option-priority .option-list mat-list,
vp-vnctask-option-link-list .option-list mat-list,
vp-vnctask-option-link-location .option-list mat-list,
vp-vnctask-option-add-watchers .option-list mat-list {
  height: 100%;
}
vp-new-vnctask-option-priority .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-add-user .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-add-repeats .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-add-estimate .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-add-watchers .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-link-location .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-add-tags .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-add-description .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-link-list .option-list mat-list mat-list-item:hover,
vp-new-vnctask-option-link-project .option-list mat-list mat-list-item:hover,
vp-vnctask-option-add-repeats .option-list mat-list mat-list-item:hover,
vp-vnctask-option-add-user .option-list mat-list mat-list-item:hover,
vp-vnctask-option-link-project .option-list mat-list mat-list-item:hover,
vp-vnctask-option-priority .option-list mat-list mat-list-item:hover,
vp-vnctask-option-link-list .option-list mat-list mat-list-item:hover,
vp-vnctask-option-link-location .option-list mat-list mat-list-item:hover,
vp-vnctask-option-add-watchers .option-list mat-list mat-list-item:hover {
  cursor: pointer;
  background: #f8f8f8;
}
vp-new-vnctask-option-priority .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-add-user .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-add-repeats .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-add-estimate .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-add-watchers .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-link-location .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-add-tags .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-add-description .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-link-list .option-list .mdl-list__item:last-child,
vp-new-vnctask-option-link-project .option-list .mdl-list__item:last-child,
vp-vnctask-option-add-repeats .option-list .mdl-list__item:last-child,
vp-vnctask-option-add-user .option-list .mdl-list__item:last-child,
vp-vnctask-option-link-project .option-list .mdl-list__item:last-child,
vp-vnctask-option-priority .option-list .mdl-list__item:last-child,
vp-vnctask-option-link-list .option-list .mdl-list__item:last-child,
vp-vnctask-option-link-location .option-list .mdl-list__item:last-child,
vp-vnctask-option-add-watchers .option-list .mdl-list__item:last-child {
  border: 0;
}
vp-new-vnctask-option-priority .option-list .mdl-list__item,
vp-new-vnctask-option-add-user .option-list .mdl-list__item,
vp-new-vnctask-option-add-repeats .option-list .mdl-list__item,
vp-new-vnctask-option-add-estimate .option-list .mdl-list__item,
vp-new-vnctask-option-add-watchers .option-list .mdl-list__item,
vp-new-vnctask-option-link-location .option-list .mdl-list__item,
vp-new-vnctask-option-add-tags .option-list .mdl-list__item,
vp-new-vnctask-option-add-description .option-list .mdl-list__item,
vp-new-vnctask-option-link-list .option-list .mdl-list__item,
vp-new-vnctask-option-link-project .option-list .mdl-list__item,
vp-vnctask-option-add-repeats .option-list .mdl-list__item,
vp-vnctask-option-add-user .option-list .mdl-list__item,
vp-vnctask-option-link-project .option-list .mdl-list__item,
vp-vnctask-option-priority .option-list .mdl-list__item,
vp-vnctask-option-link-list .option-list .mdl-list__item,
vp-vnctask-option-link-location .option-list .mdl-list__item,
vp-vnctask-option-add-watchers .option-list .mdl-list__item {
  width: 100%;
  padding-top: 15px;
  border-bottom: 1px solid #ebebeb;
}
vp-new-vnctask-option-priority .option-list .mdl-list__item span,
vp-new-vnctask-option-add-user .option-list .mdl-list__item span,
vp-new-vnctask-option-add-repeats .option-list .mdl-list__item span,
vp-new-vnctask-option-add-estimate .option-list .mdl-list__item span,
vp-new-vnctask-option-add-watchers .option-list .mdl-list__item span,
vp-new-vnctask-option-link-location .option-list .mdl-list__item span,
vp-new-vnctask-option-add-tags .option-list .mdl-list__item span,
vp-new-vnctask-option-add-description .option-list .mdl-list__item span,
vp-new-vnctask-option-link-list .option-list .mdl-list__item span,
vp-new-vnctask-option-link-project .option-list .mdl-list__item span,
vp-vnctask-option-add-repeats .option-list .mdl-list__item span,
vp-vnctask-option-add-user .option-list .mdl-list__item span,
vp-vnctask-option-link-project .option-list .mdl-list__item span,
vp-vnctask-option-priority .option-list .mdl-list__item span,
vp-vnctask-option-link-list .option-list .mdl-list__item span,
vp-vnctask-option-link-location .option-list .mdl-list__item span,
vp-vnctask-option-add-watchers .option-list .mdl-list__item span {
  margin-left: 30px;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  letter-spacing: normal;
}

vp-vnctask-autocomplete .autocomplete__input .mdl-textfield {
  width: auto;
}
vp-vnctask-autocomplete .autocomplete__list div {
  padding: 15px;
}
vp-vnctask-autocomplete .autocomplete__list .active {
  background-color: #f7f7f7;
}
vp-vnctask-autocomplete .autocomplete .datepicker__input {
  visibility: hidden;
}
vp-vnctask-autocomplete .autocomplete .editdateselect {
  display: block;
  height: 36px;
  padding: 6px 0 0;
  margin-top: 10px;
  margin-right: 5%;
  margin-bottom: 10px;
  margin-left: 5%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background: #2d7bbe;
  border-radius: 20px;
}
vp-vnctask-autocomplete .autocomplete .editdateselect:hover {
  cursor: pointer;
}
vp-vnctask-autocomplete .autocomplete .datepicker__calendar {
  margin: -70% 0 0 24%;
}
vp-vnctask-autocomplete .autocomplete material-datepicker {
  position: absolute;
}

mdl-dialog-host-component .mat-dialog__title {
  text-align: center;
}
mdl-dialog-host-component .mat-dialog__content-block_item__date {
  display: flex;
}
mdl-dialog-host-component .task-search-dialog .mat-dialog__content-block {
  max-height: calc(100% - 120px);
  padding: 0 0 15px;
  overflow: intial;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 600px) {
  mdl-dialog-host-component .task-search-dialog .mat-dialog__content-block {
    height: calc(100% - 60px);
    max-height: initial;
    overflow-y: auto;
  }
}
mdl-dialog-host-component .task-search-dialog .include_comments {
  padding: 15px;
  color: #666;
  background-color: #eee;
}
mdl-dialog-host-component .task-search-dialog .include_comments .mdl-switch__track {
  top: 0;
  height: 24px;
}
mdl-dialog-host-component .task-search-dialog .include_comments .mdl-switch.is-checked .mdl-switch__track {
  background: #09c856;
}
mdl-dialog-host-component .task-search-dialog .include_comments .mdl-switch.is-checked .mdl-switch__thumb {
  background: #fff;
}
mdl-dialog-host-component .task-search-dialog .add-more__btn {
  margin: 15px 15px 0;
  color: #0079a6;
  text-align: center;
  cursor: pointer;
}
mdl-dialog-host-component .task-search-dialog .add-more__btn mdl-icon {
  vertical-align: middle;
}
mdl-dialog-host-component .task-search-dialog .add-more__select {
  padding: 0 15px;
}
mdl-dialog-host-component .mat-dialog__content-block {
  display: flex;
  padding: 15px 15px 0;
  align-items: center;
}
mdl-dialog-host-component .mat-dialog__content-block > span {
  padding: 0 5px;
}
mdl-dialog-host-component .mat-dialog__content-block > span:first-child {
  padding-left: 0;
}
mdl-dialog-host-component .mat-dialog__content-block > span:last-child {
  padding-right: 0;
}
mdl-dialog-host-component .mat-dialog__content-block .label:first-child {
  min-width: 70px;
}
mdl-dialog-host-component .mat-dialog__content-block .flex-grow-2 {
  flex-grow: 2;
}
mdl-dialog-host-component .mat-dialog__content-block .pre-filter {
  max-width: 100px;
}
mdl-dialog-host-component .mat-dialog__content-block .pre-filter .mdl-popover {
  min-width: 200px;
}
mdl-dialog-host-component .mat-dialog__content-block.no-value .pre-filter {
  flex-grow: 2;
  max-width: initial;
}
mdl-dialog-host-component .mat-dialog__content-block mdl-icon {
  height: 9px;
  padding: 10px 0;
  margin-left: 15px;
  cursor: pointer;
}
mdl-dialog-host-component .mat-dialog__content-block mdl-icon:hover {
  color: #000;
}
mdl-dialog-host-component .mat-dialog__content-block material-datepicker {
  display: flex;
  width: 100%;
  padding: 10px 0;
}
mdl-dialog-host-component .mat-dialog__content-block material-datepicker .datepicker {
  width: 100%;
}
mdl-dialog-host-component .mat-dialog__content-block material-datepicker .datepicker input {
  width: 100%;
}
mdl-dialog-host-component .mat-dialog__content-block material-datepicker .datepicker__input {
  padding-bottom: 7px;
  border: 0 !important;
  border-bottom: 1px solid #ccc !important;
}
mdl-dialog-host-component .mat-dialog__content-block mdl-select .mdl-textfield {
  padding: 8px 0;
}
mdl-dialog-host-component .include_comments {
  display: flex;
  justify-content: space-between;
}
mdl-dialog-host-component .include_comments span {
  flex-grow: 1;
}
mdl-dialog-host-component .include_comments mdl-switch {
  width: auto;
  margin-right: 10px;
}
mdl-dialog-host-component .mdl-textfield {
  width: 100%;
}
mdl-dialog-host-component .extra-fileds {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
mdl-dialog-host-component .show {
  max-height: 400px;
  overflow: visible;
}
mdl-dialog-host-component .show-more {
  padding: 10px 0;
  justify-content: center;
}
mdl-dialog-host-component .show-more span {
  color: #2d7bbe;
  cursor: pointer;
}

.task-search-dialog {
  max-height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.confirm-dialog h3 {
  padding-left: 10px;
}

@media only screen and (max-width : 1023px) {
  vp-task-main-layout-component {
    width: 100%;
    height: 100%;
  }
}
vp-task-main-layout-component .mdl-grid {
  position: relative;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width : 1023px) {
  .mdl-dialog {
    min-width: 100%;
    height: 100%;
  }
}

@media (max-width: 839px) and (min-width: 320px) {
  .mdl-cell {
    margin: 0;
    width: 100%;
  }
  mdl-layout-content .mdl-grid > .mdl-cell {
    margin-top: 0;
  }
}
.taskListItem {
  display: flex;
  margin-bottom: 2px;
}

.prorityMark {
  width: 6px;
  height: 58px;
  border-radius: 2px;
}
@media only screen and (max-width : 1023px) {
  .prorityMark {
    width: 6px;
  }
}

vp-vnctask-list-item {
  width: 100%;
}

@media only screen and (min-width : 768px) {
  .sidebar-expanded {
    width: 250px;
  }
  .content-in-sidebar-expanded {
    width: calc(100% - 255px);
    margin: 0;
    margin-left: 4px;
  }
  .content-in-sidebar-collapsed {
    width: calc(100% - 64px);
    margin: 0;
    margin-left: 4px;
  }
}
.mdl-layout__content {
  height: 100%;
  overflow: visible;
}
.mdl-layout__content .mdl-grid {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
}
.mdl-layout__content .mdl-grid .mdl-cell {
  height: 100%;
}
@media only screen and (min-width : 1024px) {
  .mdl-layout__content .mdl-grid .mdl-cell {
    margin: 4px;
  }
}

@media only screen and (max-width : 1023px) {
  .mdl-layout__content .mdl-grid .mdl-cell {
    width: 100%;
  }
}
.width-15 {
  width: 15%;
}

.width-70 {
  width: 70%;
}

.align-right {
  float: right;
}

.loading {
  padding: 12px;
  margin-bottom: 2px;
  font-size: 15px;
  text-align: center;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
}

html.vnctalksafari *::\--webkit-media-controls-play-button,
html.vnctalksafari *::\-webkit-media-controls-panel,
html.vnctalksafari *::\-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none;
}

html.vnctalkchrome *::\--webkit-media-controls-play-button,
html.vnctalkchrome *::\-webkit-media-controls-panel,
html.vnctalkchrome *::\-webkit-media-controls-start-playback-button {
  display: none;
  -webkit-appearance: none;
}

.vnctask-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200000;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}
.vnctask-loading.pulltorefresh {
  height: 38%;
}
.vnctask-loading .indicator {
  width: 40px;
  height: 40px;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: top 0.1s;
}
.vnctask-loading mat-progress-spinner circle, .vnctask-loading .mat-spinner circle {
  stroke: #317bbc;
}
.vnctask-loading .mdl-spinner__layer-1,
.vnctask-loading .mdl-spinner__layer-2,
.vnctask-loading .mdl-spinner__layer-3,
.vnctask-loading .mdl-spinner__layer-4 {
  border-color: #317bbc;
}

.disable-select, .disable-select input {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hide-header-view {
  margin-top: -50px;
}
.hide-header-view .task-search-header {
  display: none;
}

.disable-event {
  pointer-events: none;
  opacity: 0.5;
}

.context-item {
  padding: 8px;
  display: flex;
  font-size: 16px;
  cursor: pointer;
}
.context-item mdl-icon {
  font-size: 16px;
  line-height: inherit;
  margin-right: 10px;
}

vp-mention-list {
  position: absolute;
  left: 0;
  bottom: 80px;
  display: block;
  width: 260px;
  height: 170px;
}
@media screen and (max-width: 767px) {
  vp-mention-list {
    width: 100%;
  }
}
vp-mention-list.hide-list {
  display: none;
}
vp-mention-list ul {
  list-style: none;
  padding: 15px 0;
  margin: 0;
  max-height: 170px;
  position: absolute;
  bottom: 0;
  box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 4px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  vp-mention-list ul {
    margin-left: 5px;
    width: calc(100% - 10px);
  }
}
vp-mention-list ul li {
  margin-top: 10px;
  padding: 5px 15px;
}
vp-mention-list ul li:hover, vp-mention-list ul li.active {
  background: rgba(0, 0, 0, 0.05);
}
vp-mention-list ul li:first-child {
  margin-top: 0;
}
vp-mention-list ul li .mention-full-name, vp-mention-list ul li .mention-bare {
  color: #000000;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 240px;
  display: block;
  font-weight: normal;
}
@media screen and (max-width: 767px) {
  vp-mention-list ul li .mention-full-name, vp-mention-list ul li .mention-bare {
    max-width: 100%;
  }
}
vp-mention-list ul li .mention-bare {
  color: #888888;
  font-size: 14px;
  line-height: 20px;
}

.mentioned-user {
  font-weight: 700;
  color: #0071bb;
}
.mentioned-user a {
  font-weight: 700;
}

vnc-action-wheel-menu .menu {
  overflow: hidden;
  border-radius: 50%;
}
vnc-action-wheel-menu .current-avatar img {
  width: 100%;
  height: 100%;
}
vnc-action-wheel-menu .wheel-action-menu .icon {
  right: 11%;
  top: 20%;
}

vp-right-sidebar {
  width: 400px;
  display: block;
  position: absolute;
  top: 48px;
  right: 0;
  height: calc(100vh - 50px);
  overflow: hidden;
  z-index: 9999;
}
@media screen and (max-width: 1479px) {
  vp-right-sidebar {
    width: 350px;
  }
}
@media screen and (max-width: 1279px) {
  vp-right-sidebar {
    width: 100%;
  }
}
vp-right-sidebar .recent-activity .mat-tab-group .mat-ink-bar {
  background: #0071bb;
  height: 3px;
  width: 111px !important;
}
vp-right-sidebar .recent-activity .mat-tab-group .mat-tab-label {
  opacity: 1;
  min-width: 110px;
  height: 41px;
}
vp-right-sidebar .recent-activity .mat-tab-group .mat-tab-label .mat-tab-label-content {
  font-family: "Montserrat";
  color: #888888;
}
vp-right-sidebar .recent-activity .mat-tab-group .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
  color: #0071bb;
  opacity: 1;
  font-weight: bold;
}
vp-right-sidebar .recent-activity .mat-tab-group .mat-tab-list {
  border-bottom: 1px solid #e1e1e1;
  height: 41px;
}
vp-right-sidebar .recent-activity .recent-item {
  cursor: pointer;
}
vp-right-sidebar .recent-activity .recent-item .left {
  margin: 0px 10px 0 10px;
}
vp-right-sidebar .recent-activity .recent-item .left .avatar-item vp-avatar .avatar {
  height: 36px;
  width: 36px;
  line-height: 36px;
}
vp-right-sidebar .recent-activity .recent-item .left .avatar-item vp-avatar .avatar img {
  width: inherit;
  height: inherit;
  border-radius: 50%;
}
vp-right-sidebar .recent-activity .recent-item .right .title {
  font-size: 14px;
  font-family: "Montserrat";
}
vp-right-sidebar .recent-activity .recent-item .right .title .item-type {
  color: #888888;
}
vp-right-sidebar .recent-activity .recent-item .right .title .title-content {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 365px;
  display: inline-flex;
  font-family: "Montserrat";
}
vp-right-sidebar .recent-activity .recent-item .right .time {
  font-size: 12px;
  font-family: "Montserrat";
  color: #888888;
  font-family: "Montserrat";
}
vp-right-sidebar .recent-activity .recent-item .mat-divider {
  margin: 1% 0 0 1%;
  color: #e1e1e1;
}
vp-right-sidebar .recent-activity .recent-item .recen-item-inner {
  display: flex;
  margin: 10px 0 0 0;
}

.mdl-layout {
  overflow-y: hidden !important;
}

.vnc-tabs-container ul.vnc-tabs {
  padding: 0 16px;
}

vp-right-sidebar vnc-profile li.vnc-tab {
  width: 100%;
}

vp-sidebar-drawer .mobile-sidebar-component {
  position: fixed;
  width: calc(100vw - 20%);
  height: 100vh;
  background: white;
  z-index: 9999;
  top: 0;
  left: 0;
}
vp-sidebar-drawer .mobile-sidebar-component .user-menu__mask {
  position: fixed;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  right: 0;
  top: 0;
  width: 20%;
  z-index: 999;
}

/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */
mdl-backdrop-overlay.dialog-backdrop {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  mdl-dialog-host-component:not(.confirmation) > div:nth-child(2) {
    height: 100%;
  }
  mdl-dialog-host-component.confirmation {
    width: 95vw !important;
    min-width: 0 !important;
    height: auto;
  }
}
mdl-dialog-host-component.confirmation {
  width: 320px;
  border-radius: 7px;
}
@media (max-width: 319px) {
  mdl-dialog-host-component.confirmation {
    width: 100%;
  }
}

.mdl-checkbox.is-checked .mdl-checkbox__tick-outline,
.mdl-radio__inner-circle {
  background-color: #29b6f6;
}

vnc-checkbox .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
  border: solid 2px #29b6f6 !important;
}

vnc-checkbox.no-rounded .mat-checkbox-checked .mat-checkbox-background {
  background-color: #29b6f6 !important;
  border: solid 2px #29b6f6 !important;
}
vnc-checkbox.no-rounded .mat-checkbox-inner-container {
  height: 17px !important;
  width: 16px !important;
  margin-right: 20px !important;
}
vnc-checkbox.no-rounded .mat-checkbox-inner-container .mat-checkbox-frame {
  border-radius: 4px !important;
  border: solid 3px #737373 !important;
}

.mdl-checkbox.is-checked .mdl-checkbox__box-outline,
.mdl-radio.is-checked .mdl-radio__outer-circle {
  border: 2px solid #29b6f6;
}

.task-account-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.task-account-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.task-account-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.task-account-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.task-account-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}
.task-account-radio-group .mat-list-item-content {
  padding: 0 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #29b6f6 !important;
}

.add-reminder-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
.add-reminder-radio-group .radio-button-style {
  width: 100%;
  padding: 14px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.031372549);
}
.add-reminder-radio-group .radio-button-style.mat-radio-checked .mat-radio-outer-circle {
  border-color: #29b6f6 !important;
}
.add-reminder-radio-group .radio-button-style.mat-radio-checked .mat-radio-inner-circle {
  background-color: #29b6f6 !important;
}
.add-reminder-radio-group .radio-button-style .mat-radio-label-content {
  padding-left: 22px;
}

vp-app-switcher .switch-holder {
  position: absolute;
  top: 60px;
  left: 2px;
  z-index: 1001;
  display: flex;
  flex-wrap: wrap;
  max-width: 440px;
  padding: 10px;
  background: #fff;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  background-color: #fff;
  grid-gap: 8px;
  z-index: 1001;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 16px 24px rgba(7, 29, 51, 0.2), 0 12px 12px rgba(7, 29, 51, 0.1019607843);
  box-sizing: inherit;
  max-width: 400px;
  justify-content: flex-start;
  top: 50px;
}
@media only screen and (max-width : 767px) {
  vp-app-switcher .switch-holder {
    width: 100vw;
    max-width: 100vw !important;
  }
}
@media only screen and (max-width : 599px) {
  vp-app-switcher .switch-holder {
    right: 0;
    bottom: 0;
    max-width: 100%;
    overflow: auto;
    justify-content: space-between;
    align-content: flex-start;
  }
}
vp-app-switcher .switch-holder .switch-box {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 125px;
  cursor: pointer;
  position: relative;
  height: 132px;
  width: 120px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width : 767px) {
  vp-app-switcher .switch-holder .switch-box {
    height: 110px;
    width: 48%;
  }
}
vp-app-switcher .switch-holder .switch-box .switch-icon {
  position: relative;
  margin-top: 16px;
}
@media only screen and (max-width : 767px) {
  vp-app-switcher .switch-holder .switch-box .switch-icon {
    position: relative;
    padding: 20px 15px 5px 43px;
    color: #666;
    height: 132px;
    cursor: pointer;
    border-radius: 8px;
    padding-left: 0;
    padding-right: 0;
  }
}
vp-app-switcher .switch-holder .switch-box .switch-icon mdl-icon {
  padding: 10px;
  border-radius: 50%;
}
@media only screen and (max-width : 767px) {
  vp-app-switcher .switch-holder .switch-box .switch-icon mdl-icon {
    padding: 10px;
    border-radius: 50%;
  }
}
vp-app-switcher .switch-holder .switch-box .switch-icon img {
  width: 44px;
  width: 72px;
  height: 72px;
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width : 767px) {
  vp-app-switcher .switch-holder .switch-box .switch-icon img {
    width: 72px;
    height: 72px;
    display: block;
    margin: 0 auto;
  }
}
vp-app-switcher .switch-holder .switch-box .switch-title {
  font-family: Manrope;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #606b76;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 12px;
}
vp-app-switcher .switch-holder .switch-box:hover {
  background: #f5f8fa;
}
vp-app-switcher .switch-holder .switch-box:hover .switch-title {
  color: #000;
}
vp-app-switcher .switch-holder .active-switch {
  background: rgba(0, 0, 0, 0.1);
}
vp-app-switcher .switch-list {
  position: relative;
  width: 100%;
  padding-top: 10px;
  color: #555;
}
vp-app-switcher .switch-list div {
  position: relative;
  padding-left: 60px;
  color: #777;
}
vp-app-switcher .switch-list div .switch-list-icon {
  position: absolute;
  left: -30px;
}
vp-app-switcher .switch-list div p {
  padding: 0;
}
vp-app-switcher .last-switch-list {
  padding-bottom: 20px;
}
vp-app-switcher .first-switch-list {
  padding-top: 20px;
}

mdl-layout-content.mdl-layout__content {
  -webkit-overflow-scrolling: touch;
}
mdl-layout-content.mdl-layout__content.autoScroll {
  -webkit-overflow-scrolling: auto;
}

.mdl-snackbar {
  z-index: 111111111111;
}

mdl-snackbar-component .mdl-snackbar {
  top: 10%;
  height: 50px;
  margin: 0 auto;
  background-color: #000;
  z-index: 111111111111;
  border-radius: 25px;
  opacity: 0.7;
}
@media only screen and (max-width : 599px) {
  mdl-snackbar-component .mdl-snackbar {
    left: 10%;
    width: 80%;
  }
}
mdl-snackbar-component .mdl-snackbar .mdl-snackbar__text {
  float: none;
  width: 100%;
  padding: 14px 5px 14px 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
}
mdl-snackbar-component .mdl-snackbar .mdl-snackbar__action {
  overflow: visible;
}

.cdk-overlay-pane .mat-tooltip {
  margin: 0;
  font-size: 14px;
  background: #000;
  box-shadow: 0 8px 16px 0 rgba(8, 45, 61, 0.2), 0 4px 12px 0 rgba(8, 45, 61, 0.1) !important;
  background-color: #131313 !important;
}

vnc-action-wheel-menu .wheel-action-menu {
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 16px 24px rgba(7, 29, 51, 0.2);
  border: solid 1px #C5CBD1;
}
vnc-action-wheel-menu .avatar-wrapper {
  height: 42px !important;
  width: 42px !important;
  margin-left: -21px !important;
  margin-top: -21px !important;
}
vnc-action-wheel-menu .current-avatar img {
  height: 42px !important;
  width: 42px !important;
}

vp-change-server-dialog {
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
vp-change-server-dialog form {
  background: white;
  width: 80vw;
  height: 170px;
}
vp-change-server-dialog form .mdl-textfield__input {
  color: black;
}
vp-change-server-dialog .mat-dialog__title {
  text-align: left;
  font-size: 1.4rem;
  padding-top: 10px;
}
vp-change-server-dialog .mat-dialog__content-block {
  padding-bottom: 0;
  padding-top: 0;
}
vp-change-server-dialog .mat-dialog__actions-block {
  padding: 8px 8px 8px 24px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}
vp-change-server-dialog .mat-dialog__actions-block .mdl-button--primary.mdl-button--primary {
  color: #0071bb;
}